import React from "react";
import { Table, Icon, Popup } from "semantic-ui-react";

export const renderDynamicValuesTable = (isConfirmationEmailMedium, isEmail, displayReceptionistInfo, showKeylockerPlaceholders, t) => {
  const renderInfoPopup = () => {
    return (
      <Popup
        size="large"
        flowing
        hoverable
        content={
          <div style={{ width: "300px" }}>
            {t("template_editor_info_popup_content").message ||
              "There are five css classes available to style the background and title of the content generated by this variable called CustomBackground, CustomTitle, CustomContent, CustomTotal and CustomVAT. They can be used to set background color or font size/color. Example:"}
            <pre>
              {`<style>
.CustomBackground {
  background-color: #eee;
}
.CustomTitle {
  font-size: 24px;
  color: red;
}
.CustomContent {
  font-size: 24px;
  color: red;
}
</style>`}
            </pre>
          </div>
        }
        trigger={<Icon name="question" />}
      />
    );
  };

  return (
    <Table celled>
      <Table.Body>
        <Table.Row>
          <Table.Cell content="{{.Appointment.VIN}}" />
          <Table.Cell content="{{.Customer.Title}}" />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="{{.Appointment.WONr}}" />
          <Table.Cell content="{{.Customer.FirstName}}" />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="{{.Appointment.CarMake}}" />
          <Table.Cell content="{{.Customer.Surname}}" />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="{{.Appointment.CarModel}}" />
          <Table.Cell content="{{.Link}}" />
        </Table.Row>
        <Table.Row>
          <Table.Cell content="{{.Appointment.RegNumber}}" />
          <Table.Cell content="{{.DealerName}}" />
        </Table.Row>
        <Table.Row>
          <Table.Cell content={"{{.LocationPhone}}"} />
          <Table.Cell content="{{.LocationName}}" />
        </Table.Row>
        {isEmail && displayReceptionistInfo && (
          <>
            <Table.Row>
              <Table.Cell content="{{.Receptionist}}" />
              <Table.Cell content={"{{.ReceptionistProfilePictureURL}}"} />
            </Table.Row>
            <Table.Row>
              <Table.Cell content="{{.ReceptionistPhoneNumber}}" />
              <Table.Cell />
            </Table.Row>
          </>
        )}
        {isConfirmationEmailMedium && (
          <>
            <Table.Row>
              <Table.Cell
                content={
                  <>
                    {"{{.YourOrder}}"}
                    {renderInfoPopup()}
                  </>
                }
              />
              <Table.Cell
                content={
                  <>
                    {"{{.ContactedFor}}"}
                    {renderInfoPopup()}
                  </>
                }
              />
            </Table.Row>
            <Table.Row>
              <Table.Cell
                content={
                  <>
                    {"{{.ScheduledServices}}"}
                    {renderInfoPopup()}
                  </>
                }
              />
              <Table.Cell
                content={
                  <>
                    {"{{.ScheduledServicesAndYourOrder}}"}
                    {renderInfoPopup()}
                  </>
                }
              />
            </Table.Row>
          </>
        )}
        {showKeylockerPlaceholders && (
          <Table.Row>
            <Table.Cell content={"{{.KeyLockerPIN}}"} />
            <Table.Cell content={"{{.ShareboxLink}}"} />
          </Table.Row>
        )}

        <Table.Row>
          <Table.Cell content={"{{.Date}}"} />
          <Table.Cell />
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
