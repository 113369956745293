import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getDevices(requestData) {
    return axios.post(ENV.apiBaseB + "/devices/list", requestData, this.requestConfig());
  }

  static updateLease(requestData) {
    return axios.post(ENV.apiBaseB + "/devices/update_lease", requestData, this.requestConfig());
  }

  static getExportData(requestData) {
    return axios.post(ENV.apiBaseB + "/devices/export", requestData, this.requestConfig());
  }

  static getModels() {
    return axios.get(ENV.apiBaseB + "/devices/list_models", this.requestConfig());
  }

  static getBrands() {
    return axios.get(ENV.apiBaseB + "/devices/list_brands", this.requestConfig());
  }

  static getOsVersions() {
    return axios.get(ENV.apiBaseB + "/devices/list_os_versions", this.requestConfig());
  }
}

export default Service;
