import React from "react";
import moment from "moment";
import { Label, Icon } from "semantic-ui-react";
import i18n from "i18next";

import UrlSearchParams from "./UrlSearchParams";
import { SEASONS } from "../modules/Appointments/common";
import { ROLES } from "../modules/Users/roles";

export function randomString(length) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function round2(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const reloadZohoWhenClosed = hideWhenNotNeeded => {
  let observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (e) {
      if (!e.target.classList.contains("zohohc_asap_web_app_opend")) {
        window.ZohoHCAsap.UnInstall("DOM_AND_ACTIONAPIS");
        hideWhenNotNeeded();
        window.ZohoHCAsap.Install();
        setTimeout(() => {
          reloadZohoWhenClosed(hideWhenNotNeeded);
        }, 500);
      }
    });
  });

  if (document.getElementById("zohohc-asap-web-helper")) observer.observe(document.getElementById("zohohc-asap-web-helper"), { subtree: false, attributes: true });
  else
    setTimeout(() => {
      reloadZohoWhenClosed(hideWhenNotNeeded);
    }, 1000);
};

export const hideZohoWhenNotNeeded = pathname => {
  let routesToHideZoho = [
    "/users",
    "/dealers",
    "/checklist-settings",
    "/devices",
    "/news",
    "/question-elements",
    "/tags",
    "/brands",
    "/support",
    "/templates",
    "/versions",
    "/acl",
    "/manuals",
    "/status-list-global",
    "/mobile-versions",
    "/translations",
    "/tyres",
    "/admin-dealers",
    "/admin/access-keys",
    "/checkin/agreements",
    "/dealer_reports",
    "/location_reports",
  ];

  window.ZohoHCAsapReady(() => {
    let shouldHide = routesToHideZoho.some(item => pathname.includes(item));

    if (shouldHide) {
      window.ZohoHCAsap.Action("hideLauncher");
      document.getElementById("pseudo-zoho-icon").style.display = "none";
    } else {
      window.ZohoHCAsap.Action("showLauncher");
      reloadZohoWhenClosed(() => hideZohoWhenNotNeeded(pathname));
      document.getElementById("pseudo-zoho-icon").style.display = "inline-block";
    }
  });
};

export const isFeatureVisible = options => {
  return options.byConfig && options.byVersion;
};

export const objectToParams = obj => {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

export const renderBasedOnTemplateAndOptions = params => {
  const { template, item, options, toRender } = params;

  if (!item) return toRender;

  let pass_template = template ? template[item] : true;

  if (!pass_template) return null;

  if (options) return options.some(o => o.name === item && o.value) ? toRender : null;

  return toRender;
};

export const formatDateIfValid = dateToFormat => {
  let formattedDate = "";

  if (dateToFormat && (moment.isMoment(dateToFormat) || !dateToFormat.startsWith("0001-01-01T00:00:00"))) {
    const md = moment(dateToFormat);
    formattedDate = md.format("DD-MM-YYYY");
  }
  return formattedDate;
};

export const getDefaultStatusLabel = statusId =>
  ({
    0: "Canceled",
    1: "New car",
    2: "Car check",
    3: "Pricing OK",
    4: "Customer OK",
    5: "Car ready",
    6: "Diagnose",
    7: "Initial check done",
    8: "Calculating price",
    9: "Customer being contacted",
    10: "Car check started",
    11: "Quality check finished",
    12: "Customer answered",
    19: "Backorder",
    50: "CheckIn to be done",
    51: "CheckIn done",
    52: "Car in shop",
    53: "Car ready repair overview sent",
    54: "Quality check finished repair overview sent",
    55: "Car out of shop",
  }[statusId]);

export const ANSWER_STATUSES = [
  {
    value: 0,
    text: (
      <span
        style={{
          color: "green",
        }}
      >
        <Icon name="check" />
      </span>
    ),
    className: "positive",
    color: "green",
  },
  {
    value: 1,
    text: (
      <span
        style={{
          color: "green",
        }}
      >
        <Icon name="file outline" />
      </span>
    ),
    className: "positive",
    color: "green",
  },
  {
    value: 2,
    text: (
      <span
        style={{
          color: "orange",
        }}
      >
        <Icon name="attention" />
      </span>
    ),
    className: "warning",
    color: "orange",
  },
  {
    value: 3,
    text: (
      <span
        style={{
          color: "red",
        }}
      >
        <Icon name="dont" />
      </span>
    ),
    className: "emergency",
    color: "red",
  },
  {
    value: 4,
    text: (
      <span
        style={{
          color: "grey",
        }}
      >
        <Icon name="x" />
      </span>
    ),
    className: "nvt",
    color: "grey",
  },
];

export const SUPPORT_STATUSES = {
  NEW: {
    index: 0,
    value: "NEW",
    text: <Icon name="circle" color="orange" />,
    color: "orange",
    displayText: "NEW",
  },
  CUSTOMER_REPLY: {
    index: 1,
    value: "CUSTOMER_REPLY",
    // text: ,
    color: "yellow",
    displayText: "CUSTOMER REPLIED",
  },
  PROGRESS: {
    index: 2,
    value: "PROGRESS",
    // text: ,
    color: "blue",
    displayText: "PROGRESS",
  },
  SOLVED: {
    index: 3,
    value: "SOLVED",
    text: <Icon name="check circle" color="green" />,
    color: "green",
    displayText: "RESOLVED",
  },
  CLOSED: {
    index: 4,
    value: "CLOSED",
    text: <Icon name="remove circle" color="red" />,
    color: "red",
    displayText: "CLOSED",
  },
};

export const removeGenerationParam = urlPath => {
  if (!urlPath) {
    return "";
  }

  if (urlPath.indexOf("generation") === -1) {
    return urlPath;
  }

  return urlPath.replace("generation", "noversion");

  // const U = new URL(urlPath); var params = searchToObject(U); delete
  // params['generation']; var P = U.pathname + '?' + serialize(params); return
  // U.origin + P;
};

export const searchToObject = URL => {
  var pairs = URL.search.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

export const serialize = obj => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const copyToClipboard = txt => {
  const el = document.createElement("textarea");
  el.value = txt;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const getYYYMMDD = () => {
  var now = new Date();
  var y = now.getFullYear();
  var m = now.getMonth() + 1;
  var d = now.getDate();
  var mm = m < 10 ? "0" + m : m;
  var dd = d < 10 ? "0" + d : d;
  return "" + y + mm + dd;
};

export const getBrowserLanguage = () => {
  const locale = localStorage.getItem("locale");
  if (locale && (locale === "en-GB" || locale === "nl-NL" || locale === "de-DE" || locale === "fr-FR")) {
    return locale;
  } else {
    const queryLanguage = new UrlSearchParams(window.location.search).get("lang");
    if (queryLanguage) return queryLanguage;

    let language = "";
    if (navigator.language.includes("en")) {
      language = "en-GB";
    } else if (navigator.language.includes("nl")) {
      language = "nl-NL";
    } else if (navigator.language.includes("de")) {
      language = "de-DE";
    } else if (navigator.language.includes("fr")) {
      language = "fr-FR";
    } else {
      language = "en-GB";
    }
    return language;
  }
};

export const validateEmail = email => {
  const expression =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return expression.test(String(email).toLowerCase());
};

export const translateDataForCharts = (data, t) => {
  data = JSON.parse(data);
  let translatedItem = {};

  Object.keys(data).forEach(key => {
    let tkey = key;
    if (!["name", "value", "total"].includes(key)) {
      if (key.startsWith("total.")) {
        tkey = key.replace("total.", "");
        tkey = "total." + (t(tkey).message || tkey);
      } else if (key.startsWith("percent.")) {
        tkey = key.replace("percent.", "");
        tkey = "percent." + (t(tkey).message || tkey);
      } else if (key.startsWith("stack_a.")) {
        tkey = key.replace("stack_a.", "");
        tkey = "stack_a." + (t(tkey).message || tkey);
      } else if (key.startsWith("stack_b.")) {
        tkey = key.replace("stack_b.", "");
        tkey = "stack_b." + (t(tkey).message || tkey);
      } else tkey = t(key).message || key;
    }

    translatedItem[tkey] = t(data[key]).message || data[key];
  });

  return translatedItem;
};

export const translatedColorsKeys = (colors, t) => {
  colors.forEach(color => (color["name"] = t(color["name"]).message || color["name"]));

  return colors;
};

export const handleBarChartWidth = () => {
  return window.screen.availWidth * 0.81;
};

export const handleBarChartHeight = () => {
  return window.screen.availHeight * 0.45;
};

export const extractAllNameFields = data => {
  let namesCollected = [];
  data.forEach(d => {
    const names = Object.keys(d);
    names.forEach(name => {
      if (!namesCollected.includes(name)) namesCollected.push(name);
    });
  });

  return namesCollected;
};

export const DMS_CAPABILITIES = {
  CAR_IN_SHOP: 1,
  SCHEDULED_IN_OUT: 2,
  CANCEL_APPOINTMENT: 3,
  MILEAGE: 4,
};

export const getDealerAndLocationById = (dealers, location_id) => {
  const dealer = dealers.find(d => Array.isArray(d.locations) && d.locations.some(l => l.id === location_id));
  const location = dealer?.locations?.find(l => l.id === location_id);

  return { dealer: dealer || {}, location: location || {} };
};

export const getLocationById = (dealers, location_id) => {
  let location = {};

  dealers.some(dealer => {
    const foundLocation = dealer.locations?.find(location => location.id === location_id);
    if (foundLocation) location = foundLocation;
    return foundLocation;
  });

  return location;
};

export const isAdmin = role_id => [ROLES.SUPER_ADMIN, ROLES.CLAIRE_ADMIN, ROLES.SUPPORT_ADMIN].includes(role_id);

export const transformCamelCaseToSnakeCase = str => {
  if (typeof str !== "string") return str;
  if (!str?.length) return "";

  const strArr = str.split("");

  return strArr.reduce((acc, curr, idx) => {
    if (/[A-Z]/.test(curr)) {
      if (idx === 0) return (acc += curr.toLowerCase());
      else return (acc += `_${curr.toLowerCase()}`);
    } else return (acc += curr);
  }, "");
};

export const getSessionCookie = () =>
  document.cookie
    .split(";")
    ?.find(x => x.includes("_sessionToken"))
    ?.trim()
    ?.replace("_sessionToken=", "") || "";

export const clearSessionCookie = () => (document.cookie = "_sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;");

export const COUNTRIES = [
  { value: "AF", text: "AF - Afghanistan" },
  { value: "AX", text: "AX - Åland Islands" },
  { value: "AL", text: "AL - Albania" },
  { value: "DZ", text: "DZ - Algeria" },
  { value: "AS", text: "AS - American Samoa" },
  { value: "AD", text: "AD - Andorra" },
  { value: "AO", text: "AO - Angola" },
  { value: "AI", text: "AI - Anguilla" },
  { value: "AQ", text: "AQ - Antarctica" },
  { value: "AG", text: "AG - Antigua and Barbuda" },
  { value: "AR", text: "AR - Argentina" },
  { value: "AM", text: "AM - Armenia" },
  { value: "AW", text: "AW - Aruba" },
  { value: "AU", text: "AU - Australia" },
  { value: "AT", text: "AT - Austria" },
  { value: "AZ", text: "AZ - Azerbaijan" },
  { value: "BS", text: "BS - Bahamas" },
  { value: "BH", text: "BH - Bahrain" },
  { value: "BD", text: "BD - Bangladesh" },
  { value: "BB", text: "BB - Barbados" },
  { value: "BY", text: "BY - Belarus" },
  { value: "BE", text: "BE - Belgium" },
  { value: "BZ", text: "BZ - Belize" },
  { value: "BJ", text: "BJ - Benin" },
  { value: "BM", text: "BM - Bermuda" },
  { value: "BT", text: "BT - Bhutan" },
  { value: "BO", text: "BO - Bolivia, Plurinational State of" },
  { value: "BQ", text: "BQ - Bonaire, Sint Eustatius and Saba" },
  { value: "BA", text: "BA - Bosnia and Herzegovina" },
  { value: "BW", text: "BW - Botswana" },
  { value: "BV", text: "BV - Bouvet Island" },
  { value: "BR", text: "BR - Brazil" },
  { value: "IO", text: "IO - British Indian Ocean Territory" },
  { value: "BN", text: "BN - Brunei Darussalam" },
  { value: "BG", text: "BG - Bulgaria" },
  { value: "BF", text: "BF - Burkina Faso" },
  { value: "BI", text: "BI - Burundi" },
  { value: "KH", text: "KH - Cambodia" },
  { value: "CM", text: "CM - Cameroon" },
  { value: "CA", text: "CA - Canada" },
  { value: "CV", text: "CV - Cape Verde" },
  { value: "KY", text: "KY - Cayman Islands" },
  { value: "CF", text: "CF - Central African Republic" },
  { value: "TD", text: "TD - Chad" },
  { value: "CL", text: "CL - Chile" },
  { value: "CN", text: "CN - China" },
  { value: "CX", text: "CX - Christmas Island" },
  { value: "CC", text: "CC - Cocos (Keeling) Islands" },
  { value: "CO", text: "CO - Colombia" },
  { value: "KM", text: "KM - Comoros" },
  { value: "CG", text: "CG - Congo" },
  { value: "CD", text: "CD - Congo, the Democratic Republic of the" },
  { value: "CK", text: "CK - Cook Islands" },
  { value: "CR", text: "CR - Costa Rica" },
  { value: "CI", text: "CI - Côte d'Ivoire" },
  { value: "HR", text: "HR - Croatia" },
  { value: "CU", text: "CU - Cuba" },
  { value: "CW", text: "CW - Curaçao" },
  { value: "CY", text: "CY - Cyprus" },
  { value: "CZ", text: "CZ - Czech Republic" },
  { value: "DK", text: "DK - Denmark" },
  { value: "DJ", text: "DJ - Djibouti" },
  { value: "DM", text: "DM - Dominica" },
  { value: "DO", text: "DO - Dominican Republic" },
  { value: "EC", text: "EC - Ecuador" },
  { value: "EG", text: "EG - Egypt" },
  { value: "SV", text: "SV - El Salvador" },
  { value: "GQ", text: "GQ - Equatorial Guinea" },
  { value: "ER", text: "ER - Eritrea" },
  { value: "EE", text: "EE - Estonia" },
  { value: "ET", text: "ET - Ethiopia" },
  { value: "FK", text: "FK - Falkland Islands (Malvinas)" },
  { value: "FO", text: "FO - Faroe Islands" },
  { value: "FJ", text: "FJ - Fiji" },
  { value: "FI", text: "FI - Finland" },
  { value: "FR", text: "FR - France" },
  { value: "GF", text: "GF - French Guiana" },
  { value: "PF", text: "PF - French Polynesia" },
  { value: "TF", text: "TF - French Southern Territories" },
  { value: "GA", text: "GA - Gabon" },
  { value: "GM", text: "GM - Gambia" },
  { value: "GE", text: "GE - Georgia" },
  { value: "DE", text: "DE - Germany" },
  { value: "GH", text: "GH - Ghana" },
  { value: "GI", text: "GI - Gibraltar" },
  { value: "GR", text: "GR - Greece" },
  { value: "GL", text: "GL - Greenland" },
  { value: "GD", text: "GD - Grenada" },
  { value: "GP", text: "GP - Guadeloupe" },
  { value: "GU", text: "GU - Guam" },
  { value: "GT", text: "GT - Guatemala" },
  { value: "GG", text: "GG - Guernsey" },
  { value: "GN", text: "GN - Guinea" },
  { value: "GW", text: "GW - Guinea-Bissau" },
  { value: "GY", text: "GY - Guyana" },
  { value: "HT", text: "HT - Haiti" },
  { value: "HM", text: "HM - Heard Island and McDonald Islands" },
  { value: "VA", text: "VA - Holy See (Vatican City State)" },
  { value: "HN", text: "HN - Honduras" },
  { value: "HK", text: "HK - Hong Kong" },
  { value: "HU", text: "HU - Hungary" },
  { value: "IS", text: "IS - Iceland" },
  { value: "IN", text: "IN - India" },
  { value: "ID", text: "ID - Indonesia" },
  { value: "IR", text: "IR - Iran, Islamic Republic of" },
  { value: "IQ", text: "IQ - Iraq" },
  { value: "IE", text: "IE - Ireland" },
  { value: "IM", text: "IM - Isle of Man" },
  { value: "IL", text: "IL - Israel" },
  { value: "IT", text: "IT - Italy" },
  { value: "JM", text: "JM - Jamaica" },
  { value: "JP", text: "JP - Japan" },
  { value: "JE", text: "JE - Jersey" },
  { value: "JO", text: "JO - Jordan" },
  { value: "KZ", text: "KZ - Kazakhstan" },
  { value: "KE", text: "KE - Kenya" },
  { value: "KI", text: "KI - Kiribati" },
  { value: "KP", text: "KP - Korea, Democratic People's Republic of" },
  { value: "KR", text: "KR - Korea, Republic of" },
  { value: "KR", text: "KR - XK" },
  { value: "KW", text: "KW - Kuwait" },
  { value: "KG", text: "KG - Kyrgyzstan" },
  { value: "LA", text: "LA - Lao People's Democratic Republic" },
  { value: "LV", text: "LV - Latvia" },
  { value: "LB", text: "LB - Lebanon" },
  { value: "LS", text: "LS - Lesotho" },
  { value: "LR", text: "LR - Liberia" },
  { value: "LY", text: "LY - Libya" },
  { value: "LI", text: "LI - Liechtenstein" },
  { value: "LT", text: "LT - Lithuania" },
  { value: "LU", text: "LU - Luxembourg" },
  { value: "MO", text: "MO - Macao" },
  { value: "MK", text: "MK - Macedonia, the former Yugoslav Republic of" },
  { value: "MG", text: "MG - Madagascar" },
  { value: "MW", text: "MW - Malawi" },
  { value: "MY", text: "MY - Malaysia" },
  { value: "MV", text: "MV - Maldives" },
  { value: "ML", text: "ML - Mali" },
  { value: "MT", text: "MT - Malta" },
  { value: "MH", text: "MH - Marshall Islands" },
  { value: "MQ", text: "MQ - Martinique" },
  { value: "MR", text: "MR - Mauritania" },
  { value: "MU", text: "MU - Mauritius" },
  { value: "YT", text: "YT - Mayotte" },
  { value: "MX", text: "MX - Mexico" },
  { value: "FM", text: "FM - Micronesia, Federated States of" },
  { value: "MD", text: "MD - Moldova, Republic of" },
  { value: "MC", text: "MC - Monaco" },
  { value: "MN", text: "MN - Mongolia" },
  { value: "ME", text: "ME - Montenegro" },
  { value: "MS", text: "MS - Montserrat" },
  { value: "MA", text: "MA - Morocco" },
  { value: "MZ", text: "MZ - Mozambique" },
  { value: "MM", text: "MM - Myanmar" },
  { value: "NA", text: "NA - Namibia" },
  { value: "NR", text: "NR - Nauru" },
  { value: "NP", text: "NP - Nepal" },
  { value: "NL", text: "NL - Netherlands" },
  { value: "NC", text: "NC - New Caledonia" },
  { value: "NZ", text: "NZ - New Zealand" },
  { value: "NI", text: "NI - Nicaragua" },
  { value: "NE", text: "NE - Niger" },
  { value: "NG", text: "NG - Nigeria" },
  { value: "NU", text: "NU - Niue" },
  { value: "NF", text: "NF - Norfolk Island" },
  { value: "MP", text: "MP - Northern Mariana Islands" },
  { value: "NO", text: "NO - Norway" },
  { value: "OM", text: "OM - Oman" },
  { value: "PK", text: "PK - Pakistan" },
  { value: "PW", text: "PW - Palau" },
  { value: "PS", text: "PS - Palestina" },
  { value: "PA", text: "PA - Panama" },
  { value: "PG", text: "PG - Papua New Guinea" },
  { value: "PY", text: "PY - Paraguay" },
  { value: "PE", text: "PE - Peru" },
  { value: "PH", text: "PH - Philippines" },
  { value: "PN", text: "PN - Pitcairn" },
  { value: "PL", text: "PL - Poland" },
  { value: "PT", text: "PT - Portugal" },
  { value: "PR", text: "PR - Puerto Rico" },
  { value: "QA", text: "QA - Qatar" },
  { value: "RE", text: "RE - Réunion" },
  { value: "RO", text: "RO - Romania" },
  { value: "RU", text: "RU - Russian Federation" },
  { value: "RW", text: "RW - Rwanda" },
  { value: "BL", text: "BL - Saint Barthélemy" },
  { value: "SH", text: "SH - Saint Helena, Ascension and Tristan da Cunha" },
  { value: "KN", text: "KN - Saint Kitts and Nevis" },
  { value: "LC", text: "LC - Saint Lucia" },
  { value: "MF", text: "MF - Saint Martin (French part)" },
  { value: "PM", text: "PM - Saint Pierre and Miquelon" },
  { value: "VC", text: "VC - Saint Vincent and the Grenadines" },
  { value: "WS", text: "WS - Samoa" },
  { value: "SM", text: "SM - San Marino" },
  { value: "ST", text: "ST - Sao Tome and Principe" },
  { value: "SA", text: "SA - Saudi Arabia" },
  { value: "SN", text: "SN - Senegal" },
  { value: "RS", text: "RS - Serbia" },
  { value: "SC", text: "SC - Seychelles" },
  { value: "SL", text: "SL - Sierra Leone" },
  { value: "SG", text: "SG - Singapore" },
  { value: "SX", text: "SX - Sint Maarten (Dutch part)" },
  { value: "SK", text: "SK - Slovakia" },
  { value: "SI", text: "SI - Slovenia" },
  { value: "SB", text: "SB - Solomon Islands" },
  { value: "SO", text: "SO - Somalia" },
  { value: "ZA", text: "ZA - South Africa" },
  { value: "GS", text: "GS - South Georgia and the South Sandwich Islands" },
  { value: "SS", text: "SS - South Sudan" },
  { value: "ES", text: "ES - Spain" },
  { value: "LK", text: "LK - Sri Lanka" },
  { value: "SD", text: "SD - Sudan" },
  { value: "SR", text: "SR - Suriname" },
  { value: "SJ", text: "SJ - Svalbard and Jan Mayen" },
  { value: "SZ", text: "SZ - Swaziland" },
  { value: "SE", text: "SE - Sweden" },
  { value: "CH", text: "CH - Switzerland" },
  { value: "SY", text: "SY - Syrian Arab Republic" },
  { value: "TW", text: "TW - Taiwan, Province of China" },
  { value: "TJ", text: "TJ - Tajikistan" },
  { value: "TZ", text: "TZ - Tanzania, United Republic of" },
  { value: "TH", text: "TH - Thailand" },
  { value: "TL", text: "TL - Timor-Leste" },
  { value: "TG", text: "TG - Togo" },
  { value: "TK", text: "TK - Tokelau" },
  { value: "TO", text: "TO - Tonga" },
  { value: "TT", text: "TT - Trinidad and Tobago" },
  { value: "TN", text: "TN - Tunisia" },
  { value: "TR", text: "TR - Turkey" },
  { value: "TM", text: "TM - Turkmenistan" },
  { value: "TC", text: "TC - Turks and Caicos Islands" },
  { value: "TV", text: "TV - Tuvalu" },
  { value: "UG", text: "UG - Uganda" },
  { value: "UA", text: "UA - Ukraine" },
  { value: "AE", text: "AE - United Arab Emirates" },
  { value: "GB", text: "GB - United Kingdom" },
  { value: "US", text: "US - United States" },
  { value: "UM", text: "UM - United States Minor Outlying Islands" },
  { value: "UY", text: "UY - Uruguay" },
  { value: "UZ", text: "UZ - Uzbekistan" },
  { value: "VU", text: "VU - Vanuatu" },
  { value: "VE", text: "VE - Venezuela, Bolivarian Republic of" },
  { value: "VN", text: "VN - Viet Nam" },
  { value: "VG", text: "VG - Virgin Islands, British" },
  { value: "VI", text: "VI - Virgin Islands, U.S." },
  { value: "WF", text: "WF - Wallis and Futuna" },
  { value: "EH", text: "EH - Western Sahara" },
  { value: "YE", text: "YE - Yemen" },
  { value: "ZM", text: "ZM - Zambia" },
  { value: "ZW", text: "ZW - Zimbabwe" },
];

export const getTireSeasonName = (s, t) => {
  switch (s) {
    case SEASONS.NO_SEASON:
      return t("no_season").message || "No season";
    case SEASONS.ALL_SEASONS:
      return t("all_seasons").message || "All seasons";
    case SEASONS.WINTER:
      return t("winter").message || "Winter season";
    case SEASONS.SUMMER:
      return t("summer").message || "Summer season";
    case SEASONS.NOT_AVAILABLE:
      return t("not_available").message || "Not available";

    default:
      return "";
  }
};

export const sortTruckTires = truck_tyres => {
  Object.keys(truck_tyres).forEach(key => {
    truck_tyres[key].sort(tyre => (tyre.position % 2 === 0 ? -1 : 1));

    if (truck_tyres[key].length === 4) {
      truck_tyres[key] = [
        ...truck_tyres[key].slice(0, 2).sort((t1, t2) => (t1.position < t2.position ? -1 : 1)),
        ...truck_tyres[key].slice(2, 4).sort((t1, t2) => (t2.position < t1.position ? -1 : 1)),
      ];
    }
  });
};

export const dropdownOptionsFromObjectToList = options => options[Object.keys(options)].map(value => ({ key: value, value: value, text: value }));

export const getFilesButton = ({ count, disabled }) => (
  <Label size="large" style={{ opacity: disabled ? ".45" : "100" }} color="blue">
    <Icon name="attach" style={{ marginRight: "0px" }} /> {count && <span>{count}</span>}
  </Label>
);

export const getPreviewUrl = url => {
  url = url.replace("://www.", "://storage.");
  url = url.replace("/download/storage/v1/b/", "/");
  url = url.replace("/o/", "/");

  return url;
};

export const pinLogAttachmentList = pinLogItem =>
  pinLogItem.note_attachments.map(attachment => {
    let name = decodeURIComponent(attachment);
    name = name.indexOf("?") > -1 ? name.slice(name.lastIndexOf("/") + 1, name.indexOf("?")) : name.slice(name.lastIndexOf("/") + 1);
    const type = attachment.match(/\.([^?.]+)(\?.*)?$/);

    return {
      name,
      url: attachment,
      type: type ? type[1] : "",
      username: pinLogItem.user.first_name + " " + pinLogItem.user.last_name,
      created_on: pinLogItem.created_on,
    };
  });

export const getWeekDayName = day => {
  switch (day) {
    case 1:
      return i18n.t("monday").message || "Monday";
    case 2:
      return i18n.t("tuesday").message || "Tuesday";
    case 3:
      return i18n.t("wednesday").message || "Wednesday";
    case 4:
      return i18n.t("thursday").message || "Thursday";
    case 5:
      return i18n.t("friday").message || "Friday";
    case 6:
      return i18n.t("saturday").message || "Saturday";
    case 7:
      return i18n.t("sunday").message || "Sunday";
    default:
      return "";
  }
};

export const SMS_GATEWAYS = {
  DISABLED: 1,
  CHATSHIPPER: 2,
  TWILIO: 3,
};

export const KEYLOCKER_PIN_REQUEST_TYPES = {
  PHONE: 1,
  REGISTRATION_NUMBER: 2,
  TWO_FA_DISABLED: 3,
  ONLINE_CHECK_IN: 4,
};
