// @flow
import React from "react";
import { Checkbox } from "semantic-ui-react";

import { UserInput } from "../../components";

/*const toggleStyles = {
    thumbOff: {
        backgroundColor: '#afd6b6'
    },
    trackOff: {
        backgroundColor: '#c3e0c7'
    },
    thumbSwitched: {
        backgroundColor: '#388e3c'
    },
    trackSwitched: {
        backgroundColor: '#88c790'
    }
};*/

const VersionConfiguration = props => {
  // Render main toggle of feature
  const renderConfiguration = (feature, key) => {
    return (
      <div className={"-detail -enabled_" + feature.enabled} key={"detail_" + key}>
        <div className="-name">{feature.name}</div>
        <div className="-value">
          <Checkbox
            name={feature.name}
            className={"-toggle -editable-" + props.isEditable}
            data-type="toggle"
            key={"toggle_" + key}
            toggle
            disabled
            value={feature.name}
            checked={feature.enabled}
            onChange={props.onChange}
          />
          <span className={"-value_enabled_" + feature.enabled}>{feature.options && renderConfigurationValue(feature, key)}</span>
        </div>
      </div>
    );
  };
  // Render configuration detail-options value based on the display type of
  // feature
  const renderConfigurationValue = (feature, key) => {
    if (feature.display_type === "number") {
      if (feature.options) {
        return Object.keys(feature.options).map((o, k) => {
          return (
            <div className="-number_options" key={k}>
              <span className="-opt_name">{o} : </span>
              <UserInput
                transparent
                name={feature.name}
                data-type="number-options"
                data-feature-option={o}
                disabled={!props.isEditable}
                // className="textinput"
                style={{ width: "25px" }}
                value={feature.options[o]}
                onChange={props.onChange}
              />
            </div>
          );
        });
      } else {
        return (
          <UserInput
            transparent
            name="aa"
            disabled={!props.isEditable}
            // className="textinput"
            style={{ width: "25px" }}
            value="12"
            onChange={props.onChange}
          />
        );
      }
    }
    if (feature.display_type === "checkbox") {
      return (
        <div className="-checkboxes">
          {Object.keys(feature.options).map((o, k) => {
            return (
              <Checkbox
                key={k}
                label={o}
                data-type="checkbox"
                disabled={!props.isEditable}
                name={feature.name}
                data-feature-option={o}
                checked={feature.options[o]}
                onChange={props.onChange}
                // labelStyle={{
                //     marginLeft: '-15px'
                // }}
                className="-checkbox"
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <UserInput
          transparent
          name={feature.name}
          // className="textinput"
          style={{ width: "25px" }}
          disabled={!props.isEditable}
          value={feature.price}
          onChange={props.onChange}
        />
      );
    }
  };

  return (
    <div className="VersionConfiguration">
      {/* <h1>{props.version.name}</h1> */}
      <div className="configuration-section -core">{props.version && props.version.features && props.version.features.map((f, k) => renderConfiguration(f, k))}</div>
    </div>
  );
};

export default VersionConfiguration;
