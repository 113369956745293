import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AgreementForm from "./AgreementForm";
import Service from "./service";
import CustomConfirm from "../../components/CustomConfirm";
import AlertContainer from "react-alert";
import { Can } from "../../modules";

class AgreementModal extends Component {
  state = {
    agreement: this.props.agreement,
    loading: false,
    deleteModalOpen: false,
    deleteError: "",
    alertOptions: {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 10000,
      transition: "fade",
    },
  };

  handleSave = () => {
    if (this.state.loading) return;

    let agreement = { ...this.state.agreement };

    Object.keys(agreement).forEach(key => {
      if (key.startsWith("optional_")) {
        const visibleKey = key.replace("optional_", "visible_");
        if (agreement[key] && !agreement[visibleKey]) agreement[key] = false;
      }
    });

    this.setState({ loading: true }, () => {
      if (this.props.mode === "create") {
        Service.createAgreement(agreement)
          .then(result => {
            agreement.id = result.data.data;
            this.props.onAdd(agreement);
            this.setState({ loading: false });
          })
          .catch(error => {
            error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
            console.log("error while saving agreement: ", error);
            this.setState({ loading: false });
          });
      } else {
        Service.updateAgreement(agreement)
          .then(result => {
            this.props.onUpdate(result.data.data);
            this.setState({ loading: false });
          })
          .catch(error => {
            error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
            console.log("Error while updating agreement: ", error);
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDelete = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      Service.deleteAgreement(this.state.agreement.id)
        .then(() => {
          this.setState({ deleteModalOpen: false }, () => {
            this.props.onRemove(this.state.agreement.id);
            this.setState({ loading: false });
          });
        })
        .catch(error => {
          this.setState({ deleteError: "Error while deleting agreement", loading: false });
          console.log("error while deleting agreement: ", error);
        });
    });
  };

  handleUpdateAgreement = agreement => {
    this.setState({ agreement });
  };

  error = msg => {
    this.msg.show(msg, { type: "error" });
  };

  renderAlert = () => {
    return <AlertContainer ref={a => (this.msg = a)} {...this.state.alertOptions} />;
  };

  render() {
    let { mode, selectedLocation, t, onClose } = this.props;
    let { loading, agreement, deleteModalOpen, deleteError } = this.state;

    return (
      <div className="AgreementDetail">
        <CustomConfirm
          isLoading={loading}
          type="danger"
          isOpen={deleteModalOpen}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          handleCancel={() => {
            this.setState({ deleteModalOpen: false });
          }}
          handleConfirm={this.handleDelete}
          error={deleteError}
        />

        <Modal open={true} closeOnDimmerClick={false} onClose={onClose} size="large">
          <Modal.Header>
            {mode === "create"
              ? t("add_new_communication_agreement").message || "Add new communication agreement"
              : t("edit_communication_agreement").message || "Edit communication agreement"}
            <Button floated="right" positive content={t("close").message || "Close"} onClick={onClose} />
          </Modal.Header>

          <Modal.Content scrolling>
            {this.renderAlert()}
            <AgreementForm agreement={agreement} handleUpdate={this.handleUpdateAgreement} selectedLocation={selectedLocation} />
          </Modal.Content>
          <Modal.Actions>
            {mode !== "create" && (
              <Can I="delete" the="check_in_agreement">
                <Button
                  color="red"
                  floated="left"
                  loading={loading}
                  onClick={() => {
                    this.setState({ deleteModalOpen: true });
                  }}
                >
                  <Icon name="delete" />
                  {t("delete").message || "DELETE"}
                </Button>
              </Can>
            )}
            <Button color="green" loading={loading} onClick={this.handleSave} disabled={!agreement.name || !agreement.text}>
              <Icon name="checkmark" />
              {t("save").message || "SAVE"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(AgreementModal);
