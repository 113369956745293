import React, { Component } from "react";
import { Modal, Form, Dropdown, Checkbox, Button, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { PasswordInput, UserInput } from "../../../../components";
import { Can } from "../../../";
import { DMS } from "../../util";

const config = {
  dms_location_ids: "",
  dealer_location_id: null,
  dsn: "",
  dms_databases: "",
  dms_write_back: false,
  dms_warranty_pin: false,
  keep_importing_after_work_started: false,
  is_keyloop_event_enabled: false,
  autoflex_username: "",
  autoflex_password: "",
  automatic_appointment_refresh_interval: null,
  dms_maintenance_intervention: "",
  dms_exclude_from_import: "",
  dms_not_visible_to_customer: "",
  dms_customer_not_approved: "",
};

class LocationDetail extends Component {
  state = { locationConfiguration: this.props.selectedLocation ? { ...this.props.selectedLocation, dealer_location_id: this.props.selectedLocation.id } : config };

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: value },
    }));
  };

  handleNumberChange = (_e, { name, value }) => {
    if (isNaN(value)) return;

    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: Number(value) },
    }));
  };

  handleChangeDropdown = (_e, { name, value }) => {
    this.setState(({ locationConfiguration }) => ({
      locationConfiguration: { ...locationConfiguration, [name]: value },
    }));
  };

  handleCheckboxChange = (_, { name, checked }) => {
    this.setState(({ locationConfiguration }) => ({ locationConfiguration: { ...locationConfiguration, [name]: checked } }));
  };

  render() {
    const { locationConfiguration } = this.state;
    const {
      dms_location_ids,
      dealer_location_id,
      dsn,
      dms_databases,
      dms_write_back,
      dms_warranty_pin,
      keep_importing_after_work_started,
      automatic_appointment_refresh_interval,
      is_keyloop_event_enabled,
      autoflex_username,
      autoflex_password,
      has_autoflex_password,
      dms_maintenance_intervention,
      dms_customer_not_approved,
      dms_not_visible_to_customer,
      dms_exclude_from_import,
    } = locationConfiguration;
    const {
      t,
      isEditingLocation,
      locationOptions,
      handleToggleAddLocation,
      handleToggleEditLocation,
      handleAddOrUpdateLocation,
      handleDeleteLocation,
      locationValidationError,
    } = this.props;

    return (
      <Modal open={true} closeOnDimmerClick={false}>
        <Modal.Header>
          <div style={{ display: "inline-block" }}>{isEditingLocation ? t("edit_location").message || "Edit Location" : t("add_location").message || "Add Location"}</div>
        </Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("select_location").message || "Select Location"}</label>
                <Dropdown
                  options={locationOptions}
                  value={dealer_location_id}
                  name="dealer_location_id"
                  onChange={this.handleChangeDropdown}
                  selection
                  selectOnBlur={false}
                  clearable
                  disabled={isEditingLocation}
                  search
                />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("enter_dms_location_ids").message || "Enter DMS Location ID(s)"}</label>
                <UserInput value={dms_location_ids} onChange={this.handleInputChange} name="dms_location_ids" />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("enter_dsn").message || "Enter DSN"}</label>
                <UserInput value={dsn} onChange={this.handleInputChange} name="dsn" />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("enter_databases").message || "Enter Databases"}</label>
                <UserInput value={dms_databases} onChange={this.handleInputChange} name="dms_databases" />
              </Form.Field>
            </Form.Group>

            {locationConfiguration.dms_id === DMS.AUTOFLEX && (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("autoflex_username").message || "Autoflex username"}</label>
                  <UserInput value={autoflex_username} onChange={this.handleInputChange} name="autoflex_username" />
                </Form.Field>

                <Form.Field>
                  <label>{t("autoflex_password").message || "Autoflex password"}</label>
                  <PasswordInput
                    type="password"
                    value={autoflex_password}
                    onChange={this.handleInputChange}
                    name="autoflex_password"
                    showAsteriskPlaceholder={has_autoflex_password}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Field width={8}>
                <label>{t("automatic_appointment_refresh_interval").message || "Automatic appointment refresh interval"}</label>
                <UserInput
                  type="number"
                  value={automatic_appointment_refresh_interval}
                  onChange={this.handleNumberChange}
                  name="automatic_appointment_refresh_interval"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>{t("dms_write_back").message || "DMS Write Back"}</label>
                <Checkbox toggle name="dms_write_back" checked={dms_write_back} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("set_warranty_pin_from_dms").message || "Set Warranty Pin From DMS"}</label>
                <Checkbox toggle name="dms_warranty_pin" checked={dms_warranty_pin} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("keyloop_event").message || "Keyloop event"}</label>
                <Checkbox toggle name="is_keyloop_event_enabled" checked={is_keyloop_event_enabled} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field>
                <label>{t("keep_importing_after_work_started").message || "Keep Importing After Work Started"}</label>
                <Checkbox toggle name="keep_importing_after_work_started" checked={keep_importing_after_work_started} onChange={this.handleCheckboxChange} />
              </Form.Field>
            </Form.Group>

            <Form.Group width="equal">
              <Form.Field>
                <label>{t("customer_not_approved").message || "Customer not approved"}</label>
                <UserInput value={dms_customer_not_approved} subfield="keyloop_config" onChange={this.handleInputChange} name="dms_customer_not_approved" />
              </Form.Field>

              <Form.Field>
                <label>{t("maintenance_intervention").message || "Maintenance intervention"}</label>
                <UserInput value={dms_maintenance_intervention} subfield="keyloop_config" onChange={this.handleInputChange} name="dms_maintenance_intervention" />
              </Form.Field>

              <Form.Field>
                <label>{t("not_visible_to_customer").message || "Not visible to customer"}</label>
                <UserInput value={dms_not_visible_to_customer} subfield="keyloop_config" onChange={this.handleInputChange} name="dms_not_visible_to_customer" />
              </Form.Field>

              <Form.Field>
                <label>{t("exclude_from_import").message || "Exclude from import"}</label>
                <UserInput value={dms_exclude_from_import} subfield="keyloop_config" onChange={this.handleInputChange} name="dms_exclude_from_import" />
              </Form.Field>
            </Form.Group>
          </Form>

          {locationValidationError && (
            <Label className="location-validation-error" basic color="red">
              {t("error_validating_dms_location").message || "Error validating DMS Location"}
            </Label>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button color="grey" onClick={isEditingLocation ? () => handleToggleEditLocation(null) : e => handleToggleAddLocation(e, null)}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Can I="update-location" the="dmsv3-setting">
            <Button color="green" onClick={() => handleAddOrUpdateLocation(locationConfiguration)}>
              {t("save").message || "Save"}
            </Button>

            {isEditingLocation && (
              <Button color="red" floated="left" onClick={handleDeleteLocation}>
                {t("delete").message || "Delete"}
              </Button>
            )}
          </Can>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(LocationDetail);
