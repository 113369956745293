import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid, Icon, Search, Button, Modal, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import _ from "lodash";

import { setAlert } from "../App/store.js";
import { getAppointment, deselectAppointment, getChecks, applyUpdateToAppointment, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "../Appointments/store";
import AppointmentDetail from "../Appointments/AppointmentDetail";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "../Cars/store";
import CarDetail from "../Cars/CarDetail";
import { SubHeader, UserMenuActionsPortal, SearchPortal, StatusFilter, PinTable, PinFilters } from "../../components";
import { PIN_TYPE, PIN_STATUS } from "../../components/Pin/enum";
import { PinOptions, initStatuses } from "../../components/PinTable";
import { getDealerAndLocationById, getLocationById } from "../../util/common";
import { getPreference, setPreference } from "../../util/preferences";
import { getPinItems, pinDeleted, pinUpdated, WARRANTY_ACTION_TYPES } from "./store.js";
import { setWebSocketLocationKey } from "../WebSocket/store.js";
import { APPOINTMENT_STATUSES, isRealStatus } from "../Appointments/common.js";

import Service from "./service";
import "./WarrantyDashboard.css";

export const ORDER_BY = {
  APPOINTMENT_DATE: 1,
  PIN_UPDATED_ON: 2,
};

class WarrantyDashboard extends Component {
  searchTimer = null;

  constructor(props) {
    super(props);

    const appStatuses = getPreference("warranty-dashboard-selected-app-statuses", initStatuses(props.globalState?.selectedLocation?.statuses));

    this.state = {
      isLoading: false,
      isLoadingPins: false,
      isLoadingPinOptions: false,
      isAppointmentDetailVisible: false,
      selectedAppointment: null,
      isCarDetailVisible: false,
      selectedCar: null,
      searchTerm: "",
      selectedCarInfo: {},
      dateFrom: getPreference("warranty-dashboard-selected-date-from", moment().subtract(1, "months")),
      dateTo: getPreference("warranty-dashboard-selected-date-to", moment()),
      selectedMechanicFixed: getPreference("warranty-dashboard-selected-mechanic-fixed"),
      selectedPinType: getPreference("warranty-dashboard-selected-pin-type", []),
      pinTypesOptions: [],
      selectedWarrantyType: getPreference("warranty-dashboard-selected-warranty-type"),
      warrantyTypeOptions: [],
      selectedPinStatus: getPreference("warranty-dashboard-selected-pin-status", []),
      pinStatusOptions: [],
      selectedQuestionStatus: getPreference("warranty-dashboard-selected-question-status"),
      brandOptions: [],
      selectedBrands: getPreference("warranty-dashboard-selected-brands", []),
      appStatuses: appStatuses.filter(s => isRealStatus(s.identifier) && s.identifier !== APPOINTMENT_STATUSES.CANCELED),
      selectedDealersIDs: getPreference("warranty-dashboard-selected-dealer-ids", []),
      selectedLocationsIDs: getPreference("warranty-dashboard-selected-location-ids", []),
      selectedDeleted: getPreference("warranty-dashboard-selected-deleted", false),
      selectedClaimed: getPreference("warranty-dashboard-selected-claimed", false),
      selectedPeriod: getPreference("warranty-dashboard-selected-period", 1),
      dateToOrderBy: ORDER_BY.APPOINTMENT_DATE,
      isExportModalOpen: false,
      isLoadingExport: false,
      exportURL: "",
      exportError: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoadingPinOptions: true }, () => {
      Promise.all([PinOptions.getPinOptions(), Service.getBrands()])
        .then(([pinResult, brandResult]) => {
          const result = brandResult?.data?.data || [];
          const brandOptions = result.map(brand => ({ text: `${brand.name} - ${brand.vin_prefix}`, value: brand.vin_prefix }));
          const pinOptions = pinResult || {};

          if (pinOptions.warrantyTypeOptions?.length) pinOptions.warrantyTypeOptions[0] = "warranty_not_set";
          if (pinOptions.pinStatusOptions?.length) pinOptions.pinStatusOptions[0] = "pinstatus_not_set";

          this.setState({ ...pinOptions, brandOptions, isLoadingPinOptions: false });
        })
        .catch(error => {
          console.log("Error getting pin options", error);
          this.props.setAlert({ type: "error", title: "Cannot load pin options" });
          this.setState({ isLoadingPinOptions: false });
        });
    });
  }

  componentDidUpdate = prevProps => {
    const { selectedAppointment, selectedCar } = this.state;
    const { appointmentsState, carsState, setAlert, t, warrantyState } = this.props;

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        }

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) this.setState({ isAppointmentDetailVisible: true, isLoading: false });

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
          this.socketAppointmentUpdate(appointmentsState.webSocketEvent);
        }
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        }

        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }

    if (prevProps.warrantyState.actionType !== warrantyState.actionType && warrantyState.actionType === WARRANTY_ACTION_TYPES.GET_PIN_ITEMS_FAIL) {
      setAlert({ type: "error", title: warrantyState.errorMessage });
    }
  };

  componentWillUnmount() {
    this.handleUpdateWebsocketLocations([this.props.globalState.selectedLocation]);
  }

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload, true);
  };

  getPinItems = page => {
    let {
      searchTerm,
      appStatuses,
      selectedDealersIDs,
      selectedLocationsIDs,
      selectedPinStatus,
      selectedPinType,
      selectedWarrantyType,
      selectedBrands,
      dateTo,
      dateFrom,
      selectedQuestionStatus,
      selectedMechanicFixed,
      selectedDeleted,
      selectedClaimed,
      dateToOrderBy,
    } = this.state;

    let params = {
      page: page,
      date_from: moment(dateFrom).format("YYYY-MM-DDT23:59:59+01:00"),
      date_to: moment(dateTo).format("YYYY-MM-DDT23:59:59+01:00"),
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      mechanic_fixed: selectedMechanicFixed !== "" ? selectedMechanicFixed : null,
      pin_type_id: selectedPinType.length ? selectedPinType : null,
      pin_status_id: selectedPinStatus.length ? selectedPinStatus : selectedDeleted ? [PIN_STATUS.DELETED] : null,
      warranty_type_id: selectedWarrantyType !== "" ? selectedWarrantyType : null,
      brands: selectedBrands.length ? selectedBrands : null,
      status: selectedQuestionStatus ? selectedQuestionStatus : null,
      claimed: selectedClaimed,
      search_term: searchTerm,
      appointment_statuses: appStatuses.filter(s => s.isActive).map(s => s.identifier),
      order_by: dateToOrderBy,
    };

    this.props.getPinItems(params);
  };

  handleRefresh = () => {
    this.getPinItems();
  };

  handleChangeDateToOrderBy = dateToOrderBy => this.setState({ dateToOrderBy }, () => this.getPinItems());

  handleAppointmentClick = result => {
    this.setState({ selectedAppointment: { id: result.appointment_id, dealer_location_id: result.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(result.appointment_id)
    );
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCarClick = (car_id, dealer_location_id) => {
    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isAppointmentDetailVisible: false,
        selectedAppointment: null,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;

    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  handleUpdateWebsocketLocations = locations => {
    this.props.setWebSocketLocationKey(locations.map(l => l.notifier_key).join(","));
  };

  handleChangeSelectedLocationsIDs = ({ location_ids, dealer_ids }) => {
    const { dealers } = this.props.globalState;

    const locations = [];

    dealers.forEach(dealer => {
      if (dealer_ids.includes(dealer.id) && dealer.locations) locations.push(...dealer.locations);

      const dealerLocations = dealer.locations?.filter(location => location_ids.includes(location.id));
      if (dealerLocations) locations.push(...dealerLocations);
    });

    setPreference("warranty-dashboard-selected-dealer-ids", dealer_ids);
    setPreference("warranty-dashboard-selected-location-ids", location_ids);

    this.setState({ selectedLocationsIDs: location_ids, selectedDealersIDs: dealer_ids }, () => {
      this.getPinItems();
      this.handleUpdateWebsocketLocations(locations);
    });
  };

  handleChangeMechanicFixed = selectedMechanicFixed => {
    setPreference("warranty-dashboard-selected-mechanic-fixed", selectedMechanicFixed);
    this.setState({ selectedMechanicFixed }, () => this.getPinItems());
  };

  handleChangePinType = selectedPinType => {
    let { selectedWarrantyType, selectedPinStatus, selectedClaimed } = this.state;

    if (selectedPinType.length === 1 && selectedPinType[0] === PIN_TYPE.REMARKS) {
      selectedPinStatus = [];
      selectedClaimed = false;
    }

    if (selectedPinType.length && !selectedPinType.includes(PIN_TYPE.WARRANTY)) selectedWarrantyType = null;

    setPreference("warranty-dashboard-selected-pin-type", selectedPinType);
    setPreference("warranty-dashboard-selected-warranty-type", selectedWarrantyType);
    setPreference("warranty-dashboard-selected-pin-status", selectedPinStatus);
    setPreference("warranty-dashboard-selected-claimed", selectedClaimed);

    this.setState({ selectedPinType, selectedWarrantyType, selectedPinStatus, selectedClaimed }, () => this.getPinItems());
  };

  handleChangeBrands = selectedBrands => {
    setPreference("warranty-dashboard-selected-brands", selectedBrands);
    this.setState({ selectedBrands }, () => this.getPinItems());
  };

  handleChangePeriod = selectedPeriod => {
    const dateFrom = selectedPeriod === -1 ? this.state.dateFrom : moment().subtract(selectedPeriod, "months");
    const dateTo = selectedPeriod === -1 ? this.state.dateTo : moment();

    setPreference("warranty-dashboard-selected-period", selectedPeriod);
    setPreference("warranty-dashboard-selected-date-from", dateFrom);
    setPreference("warranty-dashboard-selected-date-to", dateTo);

    this.setState({ selectedPeriod, dateFrom, dateTo }, () => {
      if (selectedPeriod !== -1) this.getPinItems();
    });
  };

  handleChangeDeleted = selectedDeleted => {
    const selectedPinStatus = selectedDeleted ? [] : this.state.selectedPinStatus;
    const selectedClaimed = selectedDeleted ? false : this.state.selectedClaimed;

    setPreference("warranty-dashboard-selected-deleted", selectedDeleted);
    setPreference("warranty-dashboard-selected-pin-status", selectedPinStatus);
    setPreference("warranty-dashboard-selected-claimed", selectedClaimed);

    this.setState({ selectedPinStatus, selectedClaimed, selectedDeleted }, () => this.getPinItems());
  };

  handleChangeDateFrom = dateFrom => {
    setPreference("warranty-dashboard-selected-date-from", dateFrom);
    this.setState({ dateFrom }, () => this.getPinItems());
  };

  handleChangeDateTo = dateTo => {
    setPreference("warranty-dashboard-selected-date-to", dateTo);
    this.setState({ dateTo }, () => this.getPinItems());
  };

  handleChangeQuestionStatus = selectedQuestionStatus => {
    setPreference("warranty-dashboard-selected-question-status", selectedQuestionStatus);
    this.setState({ selectedQuestionStatus }, () => this.getPinItems());
  };

  handleChangeWarrantyType = selectedWarrantyType => {
    setPreference("warranty-dashboard-selected-warranty-type", selectedWarrantyType);
    this.setState({ selectedWarrantyType }, () => this.getPinItems());
  };

  handleChangePinStatus = selectedPinStatus => {
    setPreference("warranty-dashboard-selected-pin-status", selectedPinStatus);
    this.setState({ selectedPinStatus }, () => this.getPinItems());
  };

  handleSearchChange = (evt, data) => {
    this.setState({ searchTerm: data.value }, () => this.getPinItems());
  };

  handleChangeClaimed = selectedClaimed => {
    setPreference("warranty-dashboard-selected-claimed", selectedClaimed);
    this.setState({ selectedClaimed }, () => this.getPinItems());
  };

  handleStatusClick = status => {
    const appStatuses = this.state.appStatuses.map(s => (s.id === status.id ? { ...s, isActive: !s.isActive } : s));
    setPreference("warranty-dashboard-selected-app-statuses", appStatuses);

    this.setState({ appStatuses }, () => this.getPinItems());
  };

  handleCloseExportModal = () => {
    this.setState({ isExportModalOpen: false, exportError: false, exportURL: "" });
  };

  renderExportModal = () => {
    const { t } = this.props;
    const { isExportModalOpen, isLoadingExport, exportURL, exportError } = this.state;

    return (
      <Modal open={isExportModalOpen} size="mini">
        <Modal.Content>
          {isLoadingExport && <p>{t("export_in_progress").message || "Export in progress..."}</p>}
          {!isLoadingExport && exportURL && <p>{t("export_ready").message || "Export ready to be downloaded"}</p>}
          {!isLoadingExport && (exportError || !exportURL) && <p>{t("failed_error_message").message || "Something went wrong, please try again."}</p>}
        </Modal.Content>
        <Modal.Actions>
          <Button color="grey" onClick={this.handleCloseExportModal} disabled={isLoadingExport}>
            {t("close").message || "Close"}
          </Button>
          <Button disabled={isLoadingExport || exportError} loading={isLoadingExport} onClick={() => window.location.assign(this.state.exportURL)} color="green">
            {t("download").message || "Download"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleExport = () => {
    this.setState({ isExportModalOpen: true, isLoadingExport: true, exportError: false }, async () => {
      try {
        const response = await this.handleExportPinItems();
        if (!response?.data?.data?.url) throw new Error("Export Pins Fail");

        this.setState({ exportURL: response.data.data.url, isLoadingExport: false });
      } catch (error) {
        console.error("Error exporting pins", error);

        this.setState({ isExportModalOpen: true, exportError: true, isLoadingExport: false });
      }
    });
  };

  handleExportPinItems = () => {
    let {
      page,
      searchTerm,
      appStatuses,
      selectedDealersIDs,
      selectedLocationsIDs,
      selectedPinStatus,
      selectedPinType,
      selectedWarrantyType,
      selectedBrands,
      dateTo,
      dateFrom,
      selectedQuestionStatus,
      selectedMechanicFixed,
      selectedDeleted,
      selectedClaimed,
      dateToOrderBy,
    } = this.state;

    let params = {
      page,
      date_from: moment(dateFrom).format("YYYY-MM-DDT23:59:59+01:00"),
      date_to: moment(dateTo).format("YYYY-MM-DDT23:59:59+01:00"),
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      mechanic_fixed: selectedMechanicFixed !== "" ? selectedMechanicFixed : null,
      pin_type_id: selectedPinType.length ? selectedPinType : null,
      pin_status_id: selectedPinStatus.length ? selectedPinStatus : selectedDeleted ? [PIN_STATUS.DELETED] : null,
      warranty_type_id: selectedWarrantyType !== "" ? selectedWarrantyType : null,
      brands: selectedBrands.length ? selectedBrands : null,
      status: selectedQuestionStatus ? selectedQuestionStatus : null,
      claimed: selectedClaimed,
      search_term: searchTerm,
      appointment_statuses: appStatuses.filter(s => s.isActive).map(s => s.identifier),
      order_by: dateToOrderBy,
    };

    return Service.exportPinItems(params);
  };

  render() {
    const {
      isAppointmentDetailVisible,
      isCarDetailVisible,
      isLoading,
      dateFrom,
      dateTo,
      selectedWarrantyType,
      selectedQuestionStatus,
      selectedPinStatus,
      selectedMechanicFixed,
      selectedDealersIDs,
      selectedLocationsIDs,
      selectedPinType,
      selectedBrands,
      selectedDeleted,
      selectedClaimed,
      pinTypesOptions,
      warrantyTypeOptions,
      pinStatusOptions,
      brandOptions,
      selectedPeriod,
      appStatuses,
      isLoadingPinOptions,
      dateToOrderBy,
    } = this.state;

    const {
      t,
      globalState,
      warrantyState: { page, nb_pages, items, nb_items, isLoadingPins },
      pinDeleted,
      pinUpdated,
    } = this.props;

    return (
      <div className="WarrantyDashboard">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("search_bar_warranty_dashboard_placeholder").message || "Search on Carreg - Vin - WO - Support - Claim - ref",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Column width={9} className="-no-padding">
              <h1 style={{ display: "inline-block" }}>{t("warranty_dashboard").message || "Warranty Dashboard"}</h1>
            </Grid.Column>

            <Grid.Column width={7} className="warranty-dashboard-status-export-container -no-padding">
              {appStatuses.length > 0 && (
                <div className="warranty-dashboard-status-filter-container">
                  <StatusFilter
                    statuses={appStatuses}
                    onStatusClick={this.handleStatusClick}
                    diagnose_overview_enabled={globalState.selectedLocation.diagnose_overview_enabled}
                    online_check_in_enabled={globalState.selectedLocation.online_check_in_enabled}
                  />
                </div>
              )}

              <div className="export-button-container">
                <Popup
                  position="top right"
                  content={t("warranty_export_disabled").message || "Export disabled as no location or dealer is selected."}
                  disabled={selectedLocationsIDs.length || selectedDealersIDs.length}
                  trigger={
                    <div style={{ display: "inline-block" }}>
                      <Button
                        basic
                        color="green"
                        onClick={this.handleExport}
                        disabled={!selectedLocationsIDs.length && !selectedDealersIDs.length}
                        className="export-button"
                      >
                        <span style={{ marginRight: "5px" }}>{t("export").message || "Export"}</span>
                        <FontAwesomeIcon icon={faFileExport} />
                      </Button>
                    </div>
                  }
                />
              </div>
            </Grid.Column>
          </Grid>

          <Grid.Column width={16}>
            <PinFilters
              filterPeriod
              brandOptions={brandOptions}
              selectedBrands={selectedBrands}
              isLoadingPinOptions={isLoadingPinOptions}
              selectedPeriod={selectedPeriod}
              onChangePeriod={this.handleChangePeriod}
              customDateFrom={dateFrom}
              handleChangeDateFrom={this.handleChangeDateFrom}
              customDateTo={dateTo}
              handleChangeDateTo={this.handleChangeDateTo}
              selectedPinType={selectedPinType}
              pinTypesOptions={pinTypesOptions}
              onChangePinType={this.handleChangePinType}
              selectedWarrantyType={selectedWarrantyType}
              warrantyTypeOptions={warrantyTypeOptions}
              onChangeWarrantyType={this.handleChangeWarrantyType}
              selectedMechanicFixed={selectedMechanicFixed}
              onChangeMechanicFixed={this.handleChangeMechanicFixed}
              onChangeBrands={this.handleChangeBrands}
              selectedQuestionStatus={selectedQuestionStatus}
              onChangeQuestionStatus={this.handleChangeQuestionStatus}
              selectedPinStatus={selectedPinStatus}
              pinStatusOptions={pinStatusOptions}
              onChangePinStatus={this.handleChangePinStatus}
              onChangeSelectedLocationAndDealerIDs={this.handleChangeSelectedLocationsIDs}
              selectedDeleted={selectedDeleted}
              onChangeDeleted={this.handleChangeDeleted}
              selectedClaimed={selectedClaimed}
              onChangeClaimed={this.handleChangeClaimed}
              selectedDealersIDs={selectedDealersIDs}
              selectedLocationsIDs={selectedLocationsIDs}
              filterLocations
            />
          </Grid.Column>
        </SubHeader>
        <PinTable
          showRegNr
          page={page}
          pages={nb_pages}
          nb_items={nb_items}
          isWarrantyDashboard
          loading={isLoadingPins}
          onFetchData={this.getPinItems}
          onPinDeleted={pinDeleted}
          onLogChanged={pinUpdated}
          warrantyTypeOptions={warrantyTypeOptions}
          pinStatusOptions={pinStatusOptions}
          pinTypesOptions={pinTypesOptions}
          questionStatus={selectedQuestionStatus}
          items={items}
          onAppointmentClick={this.handleAppointmentClick}
          onCarClick={this.handleCarClick}
          selectedPinType={selectedPinType}
          dateToOrderBy={dateToOrderBy}
          onChangeDateToOrderBy={this.handleChangeDateToOrderBy}
          showLocationColumn={selectedLocationsIDs.length > 1 || (selectedLocationsIDs.length > 0 && selectedLocationsIDs[0] === "all") || selectedDealersIDs.length}
        />

        {isAppointmentDetailVisible && this.renderAppointmentDetail()}
        {isCarDetailVisible && this.renderCarDetail()}
        {(isLoading || isLoadingPins) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}

        {this.renderExportModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalState: state.global,
    appointmentsState: state.appointments,
    carsState: state.cars,
    authState: state.auth,
    warrantyState: state.warranty,
    websocket: state.webSocket,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    getPinItems: requestData => dispatch(getPinItems(requestData)),
    pinUpdated: updatedPinLog => dispatch(pinUpdated(updatedPinLog)),
    pinDeleted: (groupedItems, reason) => dispatch(pinDeleted(groupedItems, reason)),
    setWebSocketLocationKey: location_key => dispatch(setWebSocketLocationKey(location_key)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WarrantyDashboard));
