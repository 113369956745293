import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Popup, Icon, Dropdown, Form } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import AlertContainer from "react-alert";
import moment from "moment";

import { TYRE_TEAM_CHANNEL, getTyreSeasonIcon } from "./common";

import Service from "./service";

import "./ReplacementTyreOrder.scss";

class ReplacementTyreOrder extends Component {
  state = {
    loading: false,
    ref5: this.props.replacementToOrder[0]?.tyre?.ean ? this.props.replacementToOrder[0]?.tyre?.ean : "",
    ref6: "",
  };

  componentDidMount() {
    this.getTyreTeamStock();
  }

  getTyreTeamStock = () => {
    const { check_id, onUpdateReplacementToOrder } = this.props;
    const replacementToOrder = [...this.props.replacementToOrder];

    this.setState({ loading: true }, () => {
      Service.getTyreTeamStock({ check_id, tyre_replacement_ids: replacementToOrder.map(rep => rep.tyre_replacement_id) })
        .then(res => {
          const stockCallResult = res?.data?.data || [];

          stockCallResult.forEach(d => {
            const idx = replacementToOrder.findIndex(
              tire => tire.tyre_team_system_number === d.system_number && tire.tyre_team_channel === d.channel && tire.tyre_team_delivery === d.delivery
            );
            if (idx > -1)
              replacementToOrder[idx] = { ...replacementToOrder[idx], stock: d.stock, gross_price: d.gross_price, net_price: d.net_price, currency: d.currency };
          });

          replacementToOrder.forEach((rep, i) => {
            if (!rep.stock) replacementToOrder[i] = { ...rep, stock: 0, gross_price: "N/A", net_price: "N/A" };
          });

          onUpdateReplacementToOrder(replacementToOrder);
          this.setState({ loading: false });
        })
        .catch(err => {
          console.log("Error getting stock", err);
          const errorMsg = typeof err.response?.data === "string" ? err : err?.response?.data?.errors?.length ? err.response.data.errors[0] : "Error getting stock";
          this.setState({ loading: false });

          this.msg.show(errorMsg, {
            time: 4000,
            type: "error",
          });
        });
    });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  getDeliveryChannel = channel => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WHEEL_TYRE:
        return "Wheel-Tyre";
      case TYRE_TEAM_CHANNEL.BANDEN_EXPRESS:
        return "Banden Express";

      default:
        return "Unknown channel";
    }
  };

  getDelivery = delivery => {
    const { t, deliveries } = this.props;

    const selectedDelivery = deliveries.find(d => d.id === delivery) || {};

    const { order_before, delivery_before, delivery_from } = {
      order_before: selectedDelivery.order_before ? moment(selectedDelivery.order_before).format("HH:mm") : null,
      delivery_before: selectedDelivery.delivery_before ? moment(selectedDelivery.delivery_before).format("HH:mm") : null,
      delivery_from: selectedDelivery.delivery_from ? moment(selectedDelivery.delivery_from).format("HH:mm") : null,
    };

    if (delivery.includes("PICKUPSAMEDAY"))
      return (
        <Popup
          content={<span>{t("tyre_team_pickupsameday_detail", { order_before, delivery_from }).message || t("pickup_sameday").message || "Pickup sameday"}</span>}
          trigger={<span>{t("pickup_sameday").message || "Pickup sameday"}</span>}
        />
      );

    if (delivery.includes("SAMEDAY"))
      return (
        <Popup
          content={<span>{t("tyre_team_sameday_detail", { order_before, delivery_before }).message || t("sameday").message || "Sameday"}</span>}
          trigger={<span>{t("sameday").message || "Sameday"}</span>}
          flowing
        />
      );

    if (delivery.includes("PICKUPOVERNIGHT"))
      return (
        <Popup
          content={<span>{t("tyre_team_pickupovernight_detail", { order_before, delivery_from }).message || t("pickup_overnight").message || "Pickup Overnight"}</span>}
          trigger={<span>{t("pickup_overnight").message || "Pickup Overnight"}</span>}
          flowing
        />
      );

    if (delivery.includes("OVERNIGHT")) {
      return (
        <Popup
          content={<span>{t("tyre_team_overnight_detail", { order_before, delivery_before }).message || t("overnight").message || "Overnight"}</span>}
          trigger={<span>{t("overnight").message || "Overnight"}</span>}
          flowing
        />
      );
    }

    return "";
  };

  getQuantityOptions = stock => {
    const options = [];
    for (let i = stock; i > -1; i--) options.push({ key: i, text: "" + i, value: i });

    return options;
  };

  handleChangeReplacementTyreQuantity = (ean, delivery, value) => {
    const replacementToOrderUpdate = [...this.props.replacementToOrder];

    const idx = this.props.replacementToOrder.findIndex(rep => rep.tyre.ean === ean && rep.tyre_team_delivery === delivery);

    if (idx > -1) {
      replacementToOrderUpdate[idx] = { ...replacementToOrderUpdate[idx], quantity: value };
      this.props.onUpdateReplacementToOrder(replacementToOrderUpdate);
    }
  };

  handlePlaceOrder = async () => {
    const { ref5, ref6 } = this.state;
    const { check_id, is_tyre_team_autofill_price_ref_on_order, onUpdateReplacementToOrder } = this.props;
    const replacementToOrder = [...this.props.replacementToOrder];

    replacementToOrder.forEach(rep => {
      if (rep.quantity > 0) rep.order_status = "pending";
    });

    onUpdateReplacementToOrder(replacementToOrder);

    for (let i = 0; i < replacementToOrder.length; i++) {
      const rep = replacementToOrder[i];

      if (rep.quantity > 0) {
        const placeOrderRequestPayload = {
          check_id,
          system_number: rep.tyre_team_system_number,
          quantity: rep.quantity,
          delivery: rep.tyre_team_delivery,
          channel: rep.tyre_team_channel,
          ref5,
          ref6,
        };

        if (is_tyre_team_autofill_price_ref_on_order) placeOrderRequestPayload.ref4 = isNaN(rep.price) ? "" : String(rep.price);

        try {
          await Service.tyreTeamPlaceOrder(placeOrderRequestPayload);
          rep.order_status = "fulfilled";

          if (Number(rep.stock)) rep.stock = Number(rep.stock) - rep.quantity;

          rep.initial_quantity = rep.initial_quantity - rep.quantity > 0 ? rep.initial_quantity - rep.quantity : 0;
          rep.quantity = 0;

          onUpdateReplacementToOrder([...replacementToOrder]);
        } catch (err) {
          console.log("failed to order", err, rep);
          rep.order_status = "reject";
          onUpdateReplacementToOrder([...replacementToOrder]);
        }
      }
    }
  };

  renderTable = () => {
    const { loading } = this.state;
    const { replacementToOrder, t } = this.props;

    return (
      <ReactTable
        className="ReplacementOrderTable ReactTable -floated-table no-overflow all-data-table -initial-margin"
        showPagination={true}
        showPageSizeOptions={true}
        defaultPageSize={100}
        sortable={false}
        resizable={false}
        scrolling={false}
        minRows={0}
        loading={loading}
        loadingText="Loading..."
        data={replacementToOrder || []}
        noDataText={<div className="Table__no-results">{t("no_tires_found").message || "No tires found"}</div>}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: "",
            id: "Season",
            accessor: d => <div>{getTyreSeasonIcon(d.tyre.season)}</div>,
            minWidth: 50,
          },
          {
            Header: t("description").message || "Description",
            id: "Description",
            accessor: d => <Popup trigger={<div className="-text-ellipsis">{d.tyre.description}</div>} content={<div>{d.tyre.description}</div>} />,
            minWidth: 260,
          },
          {
            Header: t("brand").message || "Brand",
            id: "Brand",
            accessor: d => <div className="-text-ellipsis">{d.tyre.manufacturer}</div>,
            minWidth: 140,
          },
          {
            Header: "Art/EAN",
            id: "System number",
            accessor: d => (
              <div>
                {d.tyre_team_system_number}
                {d.tyre?.ean && (
                  <>
                    /<Popup hoverable trigger={<div className="replacement-tyre-ean">{d.tyre.ean}</div>} content={d.tyre.ean} />
                  </>
                )}
              </div>
            ),
            minWidth: 120,
          },
          {
            Header: `${t("gross").message || "Gross"} €`,
            id: "Price Gross",
            accessor: d => (d.stock && d.gross_price ? <div>{d.gross_price}</div> : "0.0"),
            minWidth: 80,
          },
          {
            Header: `${t("neto").message || "Neto"} €`,
            id: "Price Neto",
            accessor: d => (d.stock && d.net_price ? <div>{d.net_price}</div> : "0.0"),
            minWidth: 80,
          },
          {
            Header: t("load").message || "Load",
            id: "carry_capacity",
            accessor: d => d?.tyre?.carry_capacity,
            minWidth: 50,
          },
          {
            Header: t("speed").message || "Speed",
            id: "speed",
            accessor: d => d?.tyre?.speed,
            minWidth: 50,
          },
          {
            Header: (
              <Popup
                trigger={<div className="-text-ellipsis">{t("flat_tire").message || "Flat Tire"}</div>}
                content={<div>{t("flat_tire").message || "Flat Tire"}</div>}
              />
            ),
            id: "run_flat_tire",
            className: "-text-center-column",
            headerClassName: "-text-center-header",
            accessor: d =>
              d?.tyre?.run_flat_tire ? (
                <div className="-text-center">
                  <Icon name="check circle" color="green" />
                </div>
              ) : null,
            minWidth: 80,
          },
          {
            Header: t("channel").message || "Channel",
            id: "Channel",
            accessor: d => (d.tyre_team_channel ? <div>{this.getDeliveryChannel(d.tyre_team_channel)}</div> : null),
            minWidth: 110,
          },
          {
            Header: t("delivery").message || "Delivery",
            id: "Delivery",
            accessor: d => (d.tyre_team_delivery ? this.getDelivery(d.tyre_team_delivery) : null),
            minWidth: 120,
          },
          {
            Header: t("stock").message || "Stock",
            id: "stock",
            accessor: d => (d.stock ? d.stock : 0),
            minWidth: 70,
          },
          {
            Header: t("quantity").message || "Quantity",
            id: "Quantity",
            accessor: d => (
              <Dropdown
                disabled={loading}
                className={d.quantity && d.quantity > d.stock ? "low-stock" : "high-stock"}
                options={this.getQuantityOptions(d.initial_quantity)}
                value={d.quantity}
                onChange={(_evt, { value }) => this.handleChangeReplacementTyreQuantity(d.tyre.ean, d.tyre_team_delivery, value)}
              />
            ),
            minWidth: 70,
          },
          {
            Header: t("status").message || "Status",
            id: "Status",
            accessor: d => (
              <div>
                {replacementToOrder.find(req => req.id === d.id).order_status === "pending" && <div className="loader"></div>}
                {replacementToOrder.find(req => req.id === d.id).order_status === "fulfilled" && <Icon name="check" color="green" />}
                {replacementToOrder.find(req => req.id === d.id).order_status === "reject" && <Icon name="close" color="red" />}
              </div>
            ),
            minWidth: 60,
          },
        ]}
      />
    );
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    if (!/^[a-zA-Z0-9|\s]+$/g.test(value) && value !== "") return;

    if (["ref5", "ref6"].includes(name) && value.length > 30) return;

    this.setState({ [name]: value });
  };

  renderRefs = () => {
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            value={this.state.ref5}
            name="ref5"
            onChange={this.handleInputChange}
            fluid
            icon={<Icon name="pencil square" className="render-refs-input-icon" />}
            iconPosition="left"
            placeholder={this.props.t("packing_slip_invoice_reference").message || "Packing slip / Invoice reference"}
          />

          <Form.Input
            value={this.state.ref6}
            name="ref6"
            onChange={this.handleInputChange}
            icon={<Icon name="pencil square" className="render-refs-input-icon" />}
            fluid
            iconPosition="left"
            placeholder={this.props.t("packing_slip_invoice_reference").message || "Packing slip / Invoice reference"}
          />
        </Form.Group>
      </Form>
    );
  };

  render() {
    const { loading } = this.state;
    const { isOpen, close, replacementToOrder, t } = this.props;

    return (
      <Modal open={isOpen} closeOnDimmerClick={false} size="fullscreen" className="tyre-team-replacement-modal">
        <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{t("place_order").message || "Place Order"}</div>
          <Icon name="close" floated="right" onClick={close} color="grey" style={{ cursor: "pointer" }} />
        </Modal.Header>

        <Modal.Content>
          {this.renderRefs()}
          {this.renderTable()}
        </Modal.Content>

        <Modal.Actions>
          <Button
            color="green"
            disabled={
              loading || replacementToOrder.every(rep => rep.quantity < 1) || replacementToOrder.some(rep => rep.quantity > 0 && rep.tyre_team_stock < rep.quantity)
            }
            onClick={this.handlePlaceOrder}
          >
            {t("order").message || "Order"}
            <Icon name="check" className="-margin-left-5" />
          </Button>
        </Modal.Actions>

        {this.renderAlert()}
      </Modal>
    );
  }
}

export default withTranslation()(ReplacementTyreOrder);
