import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Modal, Transition, Icon, Search } from "semantic-ui-react";
import moment from "moment";
import "semantic-ui-react/dist/commonjs/modules/Sidebar/SidebarPushable";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faCarAlt } from "@fortawesome/free-solid-svg-icons";
import { faCarGarage } from "@fortawesome/pro-regular-svg-icons";

import { GLOBAL_ACTION_TYPES } from "../App/store";
import { APPOINTMENTS_ACTION_TYPES } from "./store";
import { CARS_ACTION_TYPES } from "./../Cars/store";
import Service from "./service";
import { SearchPortal, UserMenuActionsPortal, SubHeader, DateOptionsPicker, StatusFilter, Timer, visorify } from "../../components";
import AppointmentsTable from "./AppointmentsTable";
import AppointmentDetail from "./AppointmentDetail";
import AddAppointmentForm from "./AddAppointmentForm";
import CarDetail from "./../Cars/CarDetail";
import Can from "../Can";
import { reloadZohoWhenClosed, hideZohoWhenNotNeeded, getLocationById, getDealerAndLocationById, isAdmin, randomString } from "../../util/common";
import { ROLES } from "../Users/roles";
import { APPOINTMENT_STATUSES } from "./common";
import { DMS } from "../DMSv3/util";

import "./Appointments.css";

const prepareDateForAppointments = options => {
  let date = options.date ? moment(options.date) : moment();

  let preparedDate = date.hour(12).minutes(28);

  if (options) {
    if (options.subtract) {
      preparedDate.subtract(options.subtract.amount, options.subtract.unit);
    }
  }

  let formatted = preparedDate.format("YYYY-MM-DDTHH:mm:ss");
  formatted += "+01:00";
  return formatted;
};

class Appointments extends Component {
  datePrepared = prepareDateForAppointments({});

  searchTimer = null;

  state = {
    addAppointmentError: "",
    appointmentsVisible: [],
    isAddAppointmentLoading: false,
    isAddAppointmentVisible: false,
    isAppointmentDetailVisible: false,
    isCarDetailVisible: false,
    isCarDetailLoading: false,
    isSearchResultsOpen: false,
    searchTerm: "",
    searchResults: [],
    selectedCarInfo: {},
    selectedDate: moment(),
    selectedDateOption: "day",
    selectedDatePrepared: this.datePrepared,
    carMakeFilterTermMulti: [],
    autoRefreshInterval: null,
    selectedWONr: null,
    sinceDate: null,
    isImportingKeyloopAppointment: null,
    carInspectionDateSelected: "car_apk_date",
  };

  handleTitleBarChange = event => {
    if (this.props.appointmentsState.show_title_bar_notif) this.props.hideTitleBarNotif();
  };

  componentDidMount = () => {
    this.showChangePasswordNotification();

    this.handleInitialLoading();

    if (JSON.parse(localStorage.getItem("was_logged_in"))) {
      this.props.setAlert({
        type: "info",
        title: this.props.t("was_logged_in").message || "You had previous sessions active and you have been logged out of them",
      });

      localStorage.removeItem("was_logged_in");
    }

    window.addEventListener("click", this.handleTitleBarChange, false);
    const head = document.getElementsByTagName("head")[0];
    const links = head.getElementsByTagName("link");
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute("data-claire") === "initial-favicon") {
        head.removeChild(links[i]);
        break;
      }
    }

    this.props.carMakesClicked("shouldCheckFromStorage");
  };

  componentWillUnmount() {
    clearInterval(this.state.autoRefreshInterval);
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.LOCATION_SELECTED) {
      if (nextProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
        this.getAppointmentsWithFilter(nextProps.globalState.selectedLocation);
      }
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED) {
      this.setState({ appointmentsVisible: nextProps.appointmentsState.appointmentsVisible });
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.FILTER_APPOINTMENTS_SUCCESS) {
      this.setState({ appointmentsVisible: nextProps.appointmentsState.appointmentsVisible });
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.CLEAR_APPOINTMENTS) {
      this.setState({ appointments: [], appointmentsVisible: [] });
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_CONNECTION_UPDATE) {
      this.socketConnectionUpdate(nextProps.appointmentsState.webSocketEvent);
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && nextProps.appointmentsState.webSocketUpdate) {
      this.socketAppointmentUpdate(nextProps.appointmentsState.webSocketEvent);
    }

    if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_REFRESH) {
      if (nextProps.appointmentsState.webSocketUpdate) {
        this.socketAppointmentRefresh(nextProps.appointmentsState.webSocketEvent);
      }
    }

    if (this.props.appointmentsState.actionType !== nextProps.appointmentsState.actionType) {
      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.STATUS_FILTER_UPDATED) {
        this.props.filterAppointments(nextProps.appointmentsState.appointmentsFilters);
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WO_FILTER_SELECTED) {
        this.props.filterAppointments(nextProps.appointmentsState.appointmentsFilters);
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.ICONS_FILTER_UPDATED) {
        this.props.filterAppointments(nextProps.appointmentsState.appointmentsFilters);
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.CAR_MAKES_UPDATED) {
        this.props.filterAppointments(nextProps.appointmentsState.appointmentsFilters);
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_FAIL) {
        this.props.setAlert({ type: "error", title: "Cannot load appointments" });
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
        this.props.setAlert({ type: "error", title: "Cannot load this appointment" });
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_SUCCESS) {
        this.setState({ appointmentsVisible: [...nextProps.appointmentsState.appointmentsVisible] });
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) {
        this.setState({ isAppointmentDetailVisible: true }, () => {
          this.props.globalState.selectedLocation.appointment_detail_users_indicator_enabled &&
            this.props.setWebSocketAppointmentID(nextProps.appointmentsState.selectedAppointment?.id || null);
          window.ZohoHCAsapReady &&
            window.ZohoHCAsapReady(() => {
              window.ZohoHCAsap.Action("hideLauncher");
            });
        });
      }

      if (nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_CHECKS_FAIL) {
        this.props.setAlert({ type: "error", title: "Cannot load this appointment cheks" });
      }

      if (
        nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_SUCCESS ||
        nextProps.appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_FAIL
      ) {
        this.setState({ isSearchResultsOpen: true });
      }
    }

    if (this.props.carsState.actionType !== nextProps.carsState.actionType) {
      if (nextProps.carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
        if (!nextProps.carsState.selectedCar) {
          return;
        }
        this.setState({
          selectedCarInfo: {
            car: nextProps.carsState.selectedCar,
            customer: nextProps.carsState.selectedCustomer,
            appointmentHistory: nextProps.carsState.selectedCarAppointments,
            snoozedQuestions: nextProps.carsState.selectedCarSnoozedQuestions,
          },
          isCarDetailVisible: true,
          isCarDetailLoading: false,
        });
      }

      if (nextProps.carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
        this.setState({ isCarDetailLoading: false }, () =>
          this.props.setAlert({ type: "error", title: this.props.t("can_not_load_this_car").message || "Can not load this car" })
        );
      } else if (nextProps.carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
        this.setState({ isCarDetailLoading: false }, () =>
          this.props.setAlert({ type: "error", title: this.props.t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
        );
      }
    }
  };

  handleInitialLoading = () => {
    let appID = parseInt(this.props.match.params.app_id, 10);
    if (appID) {
      this.props.getAppointment(appID);
      return;
    }

    let carID = parseInt(this.props.match.params.car_id, 10);
    if (carID) {
      this.props.getCar(carID);
      return;
    }

    let wo = this.props.match.params.wo;
    if (wo) {
      this.props.getAppointmentByWo(wo, this.props.authState.user.dealer_location.id);
      return;
    }

    if (this.props.globalState.selectedLocation.id) this.getAppointmentsWithFilter();
  };

  showChangePasswordNotification = () => {
    let lastPassChange = moment(this.props.authState.user.last_password_change);
    let diff = moment().diff(lastPassChange, "months", true);

    if (!JSON.parse(localStorage.getItem("isSeenChangePasswordMessage")) && diff >= 6) {
      this.props.setAlert({
        type: "info",
        title: <a href="#/account">{this.props.t("password_change_toast_notification").message}</a>,
      });
      localStorage.setItem("isSeenChangePasswordMessage", true);
    }
  };

  resetSearch = () => {
    this.setState({ searchTerm: "" });
  };

  resetSelectedDateOption = () => {
    this.setState({ selectedDateOption: "day" });
  };

  getAppointmentsWithFilter = (nextLocation = null) => {
    let filter = {
      date: this.state.selectedDatePrepared,
    };

    if (this.props.authState.user.list_appointments_by_scheduled_range) filter.list_by_scheduled_range = true;

    const clearAppointments = !!nextLocation;
    const location = nextLocation && Object.keys(nextLocation).length ? nextLocation : this.props.globalState.selectedLocation;

    if (location.id !== "all") {
      filter.location_id = location.id;
    } else {
      filter.location_ids = location.myLocations;
    }

    if (this.state.sinceDate) {
      filter.since_date = this.state.sinceDate;
    }

    filter.searchTerm = this.state.searchTerm;

    this.props.getAppointments(filter, clearAppointments);
  };

  handleSearchChange = (_event, data) => {
    this.setState(
      {
        searchTerm: data.value,
        isSearchResultsOpen: false,
      },
      () => {
        if (!this.state.searchTerm) {
          this.props.filterAppointments({
            searchTerm: null,
            deselectedStatuses: this.props.appointmentsState.appointmentsFilters.deselectedStatuses,
            selectedIcons: this.props.appointmentsState.appointmentsFilters.selectedIcons,
            carMakes: this.props.appointmentsState.appointmentsFilters.carMakes,
            woFilterSelected: this.props.appointmentsState.appointmentsFilters.woFilterSelected,
          });
          return;
        }

        if (this.searchTimer) {
          window.clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }

        this.searchTimer = window.setTimeout(() => {
          this.props.filterAppointments({
            searchTerm: this.state.searchTerm,
            deselectedStatuses: this.props.appointmentsState.appointmentsFilters.deselectedStatuses,
            selectedIcons: this.props.appointmentsState.appointmentsFilters.selectedIcons,
            carMakes: this.props.appointmentsState.appointmentsFilters.carMakes,
            woFilterSelected: this.props.appointmentsState.appointmentsFilters.woFilterSelected,
          });
        }, 400);

        document.querySelector(".App__module").scrollTop = 0;
      }
    );
  };

  handleExtendedSearch = () => {
    if (!this.state.searchTerm) {
      return;
    }

    this.props.searchAppointments({
      term: this.state.searchTerm,
      dealer_id: parseInt(this.props.globalState.selectedDealer.id, 0),
      dealer_location_id: parseInt(this.props.globalState.selectedLocation.id, 0),
      dmsId: parseInt(this.props.globalState.selectedLocation.dms_id, 0),
      page: 0,
      limit: 20,
    });
  };

  handleCloseSearchResults = () => {
    this.setState(
      {
        searchTerm: "",
        isSearchResultsOpen: false,
      },
      () => {
        this.props.filterAppointments({
          searchTerm: null,
          deselectedStatuses: this.props.appointmentsState.appointmentsFilters.deselectedStatuses,
          selectedIcons: this.props.appointmentsState.appointmentsFilters.selectedIcons,
          woFilterSelected: this.props.appointmentsState.appointmentsFilters.woFilterSelected,
        });
      }
    );
  };

  handleRefreshData = () => {
    this.getAppointmentsWithFilter();
  };

  handleDateOptionChange = (event, data) => {
    if (data.value === this.state.selectedDateOption) {
      return;
    }

    let optional = {};
    let datePrepared = prepareDateForAppointments({});
    let sinceDate = null;

    if (data.value !== "day") {
      sinceDate = prepareDateForAppointments({
        subtract: {
          unit: "day",
          amount: data.value,
        },
      });
      optional = {
        sinceDate,
      };
    }

    this.setState(
      {
        selectedDateOption: data.value,
        selectedDate: moment(),
        selectedDatePrepared: datePrepared,
        sinceDate,
        ...optional,
      },
      () => {
        this.getAppointmentsWithFilter();
      }
    );
  };

  handleDateChange = date => {
    let datePrepared = prepareDateForAppointments({ date });

    this.setState(
      {
        selectedDate: moment(date),
        selectedDatePrepared: datePrepared,
      },
      () => {
        this.getAppointmentsWithFilter();
      }
    );
  };

  handleCarMakeChange = (e, { value }) => {
    this.props.carMakesClicked(value);
  };

  handleUpdatedByColumnChange = (e, { value }) => this.props.updatedByColumnClicked(value);

  socketConnectionUpdate = event => {
    if (event.type === "disconnected") {
      this.props.setWebSocketIsOnline(false);

      if (!this.state.autoRefreshInterval) {
        // we don't know how long elspased between WS disconnection and this first event, so let's refresh every minute in case just WS is down
        clearInterval(this.state.autoRefreshInterval);
        let interval = setInterval(this.handleRefreshData, 60000);
        this.setState({ autoRefreshInterval: interval });
      }

      return this.props.noUpdateToApply();
    } else if (event.type === "connected") {
      this.props.setWebSocketIsOnline(true);

      if (this.state.autoRefreshInterval) {
        clearInterval(this.state.autoRefreshInterval);
        this.setState({ autoRefreshInterval: null });
        this.handleRefreshData(); // we were disconnected as there was an interval, we're back up, so we refresh to be sure we didn't miss anything
      }

      return this.props.noUpdateToApply();
    }
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload);
  };

  socketAppointmentRefresh = event => {
    // refresh appointments if needed

    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    const timesToRefresh = updatePayload.body?.to_refresh ? updatePayload.body?.to_refresh.map(tr => moment(tr)) : null;
    const selectedDate = moment(this.state.selectedDatePrepared);
    const sinceDate = this.state.sinceDate ? moment(this.state.sinceDate) : null;

    if (
      timesToRefresh &&
      ((!sinceDate && timesToRefresh.some(tr => selectedDate.isSame(tr, "day"))) ||
        (sinceDate && timesToRefresh.some(tr => tr.isBetween(sinceDate, selectedDate, "day", "[]"))))
    ) {
      this.props.showTitleBarNotif();
      return this.getAppointmentsWithFilter();
    } else return this.props.noUpdateToApply();
  };

  handleRowClick = (columnID, rowInfo) => {
    // Prevent fetching of new appointment while one is already being fetched
    if (this.props.appointmentsState.isLoadingAppointment) {
      return;
    }

    if (columnID === "reg_number") {
      this.handleRegistrationInRowClick(rowInfo);
      return;
    }

    if (!rowInfo.original.id) {
      this.props.setAlert({ type: "error", title: "Problem selecting this appointment" });
      return;
    }

    this.props.getAppointment(rowInfo.original.id);
  };

  handleRegistrationInRowClick = info => {
    this.setState({ isCarDetailLoading: true }, () => {
      this.props.getCar(info.original.car_id);
    });
  };

  handleRegistrationClick = (carID, selectedWONr) => {
    this.setState(
      {
        selectedWONr: selectedWONr,
      },
      () => {
        if (this.state.selectedCarInfo && this.state.selectedCarInfo.car) {
          this.props.getCar(carID);
        } else {
          this.setState({ isCarDetailLoading: true }, () => {
            this.props.getCar(carID);
          });
        }
      }
    );
  };

  handleShowAppointmentFromCarDetail = appointmentID => {
    this.props.getAppointment(appointmentID);
  };

  handleCloseAppointmentDetail = (shouldRefresh = false) => {
    this.setState({ isAppointmentDetailVisible: false });
    this.props.setWebSocketAppointmentID(null);
    if (shouldRefresh) this.handleRefreshData();

    this.props.deselectAppointment();

    if (this.props.match.params.app_id || this.props.match.params.wo) {
      this.props.history.replace("/appointments");
    }

    window.ZohoHCAsapReady &&
      window.ZohoHCAsapReady(() => {
        window.ZohoHCAsap.Action("showLauncher");
        reloadZohoWhenClosed(() => hideZohoWhenNotNeeded(this.props.location.pathname));
      });
  };

  handleShowAddAppointment = () => {
    this.setState({ isAddAppointmentVisible: true, addAppointmentError: "" });
  };

  handleHideAddAppointment = () => {
    this.setState({ isAddAppointmentVisible: false });
  };

  handleSaveAddAppointment = appointment => {
    const dms_nr = `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}_${randomString(10)}`;
    appointment.dms_nr = dms_nr;

    if (!("dms_nr" in appointment.car)) appointment.car.dms_nr = dms_nr;

    this.setState(
      {
        isAddAppointmentLoading: true,
      },
      () => {
        Service.createAppointment({
          appointments: [appointment],
          dealer_location_id: this.props.globalState.selectedLocation.id,
        })
          .then(response => {
            const { failed } = response?.data?.data;

            if (failed) {
              const keys = Object.keys(failed);
              if (keys?.length) throw new Error(failed[keys[0]]);
            }

            this.setState({ isAddAppointmentLoading: false });
            this.handleHideAddAppointment();
            this.getAppointmentsWithFilter();
          })
          .catch(error => {
            this.setState({
              isAddAppointmentLoading: false,
              addAppointmentError: error.message
                ? error.message
                : Array.isArray(error.response.data.errors)
                ? error.response.data.errors[0]
                : "Error while creating appointment",
            });
            console.log("New app error :::", error);
          });
      }
    );
  };

  handleChangeCarInspectionDateSelected = carInspectionDateSelected => {
    this.setState({ carInspectionDateSelected });
  };

  handleCloseCarDetail = () => {
    this.setState({ isCarDetailVisible: false, selectedCarInfo: {}, isAppointmentDetailVisible: true });
    this.props.globalState.selectedLocation.appointment_detail_users_indicator_enabled &&
      this.props.setWebSocketAppointmentID(this.props.appointmentsState.selectedAppointment?.id || null);
    this.props.deselectCar();
  };

  handleShowAppointment = appID => {
    window.open("#!/appointments/" + appID);
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, setAlert, getAppointment, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <Can I="browse" the="appointments-details-page">
        <Transition visible={true} animation="fade up" duration={700}>
          <AppointmentDetail
            isURLHandler={!!this.props.isURLHandler}
            keyloopEnabled={this.keyloopEnabled(dealer, location)}
            currentUser={authState.user}
            appointment={appointmentsState.selectedAppointment}
            appointmentChecks={appointmentsState.selectedAppointmentChecks}
            location={location}
            dealer={dealer}
            onRegistrationClick={this.handleRegistrationClick}
            loadingCarDetail={this.state.isCarDetailLoading}
            showAppointment={this.handleShowAppointment}
            showCar={this.handleRegistrationClick}
            getCar={() => {
              this.setState({ isCarDetailLoading: true }, () => this.props.getCar);
            }}
            getChecks={getChecks}
            setAlert={setAlert}
            onClose={e => this.handleCloseAppointmentDetail(e)}
            getAppointment={getAppointment}
            detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
            onHandleUpdateAppointments={handleUpdateAppointments}
          />
        </Transition>
      </Can>
    );
  };

  keyloopEnabled = (location = this.props.globalState.selectedLocation) => [DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS].includes(location.dms_id);

  renderAddAppointment = () => {
    return (
      <Transition visible={this.state.isAddAppointmentVisible} animation="fade up" duration={700}>
        <Modal
          className="AddAppointmentModal"
          size="large"
          dimmer="inverted"
          open={this.state.isAddAppointmentVisible}
          onClose={this.handleHideAddAppointment}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.props.t("create_workorder").message}</Modal.Header>
          <Modal.Content scrolling>
            <AddAppointmentForm
              enable_car_ready={this.props.globalState.selectedDealer.enable_car_ready}
              dealerId={this.props.globalState.selectedDealer.id}
              location={this.props.globalState.selectedLocation}
              isLoading={this.state.isAddAppointmentLoading}
              addAppointmentError={this.state.addAppointmentError}
              keyloopEnabled={this.keyloopEnabled()}
              onHide={this.handleHideAddAppointment}
              onSave={this.handleSaveAddAppointment}
            />
          </Modal.Content>
        </Modal>
      </Transition>
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <Transition visible={this.state.isCarDetailVisible} animation="fade up" duration={700}>
        <CarDetail
          isURLHandler={!!this.props.isURLHandler}
          selectedWONr={this.state.selectedWONr}
          visible={this.state.isCarDetailVisible}
          car={selectedCarInfo.car}
          customer={selectedCarInfo.customer}
          location={location}
          appointmentHistory={selectedCarInfo.appointmentHistory}
          snoozedQuestions={selectedCarInfo.snoozedQuestions}
          onHide={this.handleCloseCarDetail}
          onSelectAppointment={id => this.handleShowAppointmentFromCarDetail(id)}
        />
      </Transition>
    );
  };

  render() {
    let hasSearchResults =
      this.props.appointmentsState.searchResults?.claire?.results?.length > 0 || this.props.appointmentsState.searchResults?.keyloop?.results?.length > 0;
    const STATUSES = _.sortBy(
      this.props.globalState.selectedLocation &&
        this.props.globalState.selectedLocation.statuses_visible &&
        this.props.globalState.selectedLocation.statuses_visible.length > 0
        ? this.props.globalState.selectedLocation.statuses_visible
        : this.props.globalState.selectedLocation.statuses,
      "order"
    );

    let ico = "/static/favicon.ico";
    if (this.props.appointmentsState.show_title_bar_notif) ico = "/static/favicon_orange.ico";

    const user = this.props.authState.user;
    const canAddAppointment = isAdmin(user.role_id) || (![ROLES.MECHANIC].includes(parseInt(user.role_id, 10)) && parseInt(user.role_id, 10) < ROLES.SUPERVISOR);
    const hasKeyloopResults = this.props.appointmentsState.searchResults?.keyloop?.results?.length > 0;

    return (
      <div className="Appointments">
        <Helmet defer={false}>
          <link rel="shortcut icon" href={ico} />
        </Helmet>
        <Can I="browse" the="appointments-page" renderNoAccess={!this.props.isURLHandler}>
          <SearchPortal>
            <Search
              category={hasKeyloopResults}
              className="-large-search"
              input={{
                icon: "search",
                iconPosition: "left",
                placeholder: this.props.t("start_searching_appointments").message || "Start searching appointments...",
              }}
              loading={false}
              showNoResults={true}
              noResultsMessage={this.props.t("no_results").message || "No results found"}
              onResultSelect={(_, data) => {
                if (data.result.search_category === "keyloop") {
                  if (this.state.isImportingKeyloopAppointment) return;
                  this.handleImportKeyloopAppointment(data.result.id);
                } else this.props.getAppointment(data.result.appointment_id);
              }}
              onSearchChange={this.handleSearchChange}
              open={hasSearchResults && this.state.isSearchResultsOpen}
              results={hasKeyloopResults ? this.props.appointmentsState.searchResults : this.props.appointmentsState.searchResults?.claire?.results}
              resultRenderer={result => this.searchResultsRenderer({ isOpen: this.state.isSearchResultsOpen, result, statuses: STATUSES })}
              value={this.state.searchTerm}
              fluid
            />{" "}
            {this.state.searchTerm && this.state.searchTerm.length > 3 && (
              <Icon
                className="ExtendedSearchIcon"
                name="ellipsis horizontal"
                style={{
                  right: "2.5em",
                }}
                onClick={this.handleExtendedSearch}
              />
            )}
            {this.state.isSearchResultsOpen && <Icon className="ExtendedSearchIcon" name="close" onClick={this.handleCloseSearchResults} />}
          </SearchPortal>

          <UserMenuActionsPortal>
            <Icon name="refresh" onClick={this.handleRefreshData} /> {/*  */}
          </UserMenuActionsPortal>

          <SubHeader>
            <Grid stackable className="SubHeader_content_filters -contained-large">
              <Grid.Column width={4} className="-no-padding">
                {this.props.globalState.selectedLocation && this.props.isFeatureEnabled("_LoadTijden") && (
                  <Timer times={this.props.globalState.selectedLocation.timeslots} />
                )}
              </Grid.Column>
              <Grid.Column width={6} className="-no-padding -text-right">
                {canAddAppointment && <Icon name="add" onClick={this.handleShowAddAppointment} />}
                <DateOptionsPicker
                  isFluid={false}
                  selectedOption={this.state.selectedDateOption}
                  selectedDate={this.state.selectedDate}
                  onOptionChange={this.handleDateOptionChange}
                  onDateChange={this.handleDateChange}
                />
              </Grid.Column>

              {STATUSES && (
                <Grid.Column width={6} className="-no-padding">
                  <StatusFilter
                    statuses={STATUSES}
                    onStatusClick={this.props.statusFilterClicked}
                    diagnose_overview_enabled={this.props.globalState.selectedLocation.diagnose_overview_enabled}
                    online_check_in_enabled={this.props.globalState.selectedLocation.online_check_in_enabled}
                  />
                </Grid.Column>
              )}
            </Grid>
          </SubHeader>

          {/* Maybe move the appointments to the state of AppointmentsTable to avoid more renders */}
          {this.props.globalState.selectedLocation && (
            <AppointmentsTable
              selectedNameColumn={this.props.appointmentsState.selectedNameColumn}
              nameColumnClicked={this.props.nameColumnClicked}
              updatedByColumnClicked={this.handleUpdatedByColumnChange}
              selectedUpdatedByColumn={this.props.appointmentsState.selectedUpdatedByColumn}
              onei_planning_enabled={this.props.globalState.selectedDealer.onei_planning_enabled}
              planit_planning_enabled={this.props.globalState.selectedDealer.planit_planning_enabled}
              enable_car_ready={this.props.globalState.selectedDealer.enable_car_ready}
              appointmentDateTimeClicked={this.props.appointmentDateTimeClicked}
              dateTimeAppointment={this.props.appointmentsState.dateTimeAppointment}
              selectedIcons={this.props.appointmentsState.appointmentsFilters.selectedIcons}
              onIconClick={this.props.iconFilterClicked}
              isLoading={this.props.appointmentsState.isLoadingAppointments}
              appointments={this.state.appointmentsVisible}
              statuses={STATUSES}
              selectedLocation={this.props.globalState.selectedLocation}
              list_appointments_by_scheduled_range={this.props.authState.user.list_appointments_by_scheduled_range}
              carMakes={this.props.appointmentsState.uniqueCarMakes}
              onRowClick={this.handleRowClick}
              onCarMakeChange={this.handleCarMakeChange}
              location_enabled={this.props.authState.user.location_column_visible}
              locations={this.props.globalState.selectedDealer.locations}
              onHandleUpdateAppointments={this.props.handleUpdateAppointments}
              woFilterSelected={this.props.woFilterSelected}
              onWoFilterSelect={this.props.woFilterSelect}
              isTodaysList={moment().isSame(this.state.selectedDate, "day")}
              carInspectionDateSelected={this.state.carInspectionDateSelected}
              onHandleChangeCarInspectionDateSelected={this.handleChangeCarInspectionDateSelected}
              user={this.props.authState.user}
            />
          )}

          {this.state.isAddAppointmentVisible && this.renderAddAppointment()}
        </Can>
        {this.state.isCarDetailLoading && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
        {this.state.isCarDetailVisible && this.renderCarDetail()}
        {this.state.isAppointmentDetailVisible && this.renderAppointmentDetail()}
      </div>
    );
  }

  getImportStatus = status => {
    switch (status) {
      case "CREATED":
      case "ONHOLD":
        return 1;
      case "CHECKEDIN":
        return 15;
      case "WORKSTARTED":
        return 10;
      case "WORKCOMPLETED":
        return 2;
      case "CHECKEDOUT":
      case "CLOSED":
        return 5;
      default:
        return 0;
    }
  };

  getStatusIcon = identifier => {
    switch (identifier) {
      case 1:
        return <FontAwesomeIcon icon={faCarAlt} />;

      case 12:
        return <FontAwesomeIcon icon={faUserCheck} />;

      case 15:
        return <FontAwesomeIcon icon={faCarGarage} />;

      default:
        return null;
    }
  };

  renderStatusIcon = (id, statuses) => {
    let statusToRender = statuses.find(s => s.identifier === id);
    if (!statusToRender) {
      return "";
    }

    if ([APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CUSTOMER_ANSWERED, APPOINTMENT_STATUSES.CAR_IN_SHOP].includes(statusToRender.identifier)) {
      return (
        <span
          className="StatusIcon"
          style={{
            backgroundColor: statusToRender.color,
          }}
        >
          {this.getStatusIcon(statusToRender.identifier)}
        </span>
      );
    }

    if (id === APPOINTMENT_STATUSES.BACK_ORDER) {
      return (
        <span className="StatusIcon" style={{ backgroundColor: statusToRender.color }}>
          BO
        </span>
      );
    }

    return (
      <span className="StatusIcon" style={{ backgroundColor: statusToRender.color }}>
        <i className="material-icons">{statusToRender.icon}</i>
      </span>
    );
  };

  handleImportKeyloopAppointment = dms_nr => {
    this.setState({ isImportingKeyloopAppointment: dms_nr }, () => {
      Service.getKeyloopAppointmentID({ dealer_location_id: this.props.globalState.selectedLocation.id, dms_nr })
        .then(response => {
          this.setState({ isImportingKeyloopAppointment: null }, () => {
            if (response?.data?.appointment_id) {
              this.props.getAppointment(response.data.appointment_id);
              this.handleCloseSearchResults();
            }
          });
        })
        .catch(error => {
          console.log("Error importing keyloop appointment", error);

          this.setState({ isImportingKeyloopAppointment: null }, () => {
            const errorMessage = error?.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("failed_error_message").message || "Something went wrong, please try again.";

            this.props.setAlert({
              type: "error",
              title: errorMessage,
            });
          });
        });
    });
  };

  searchResultsRenderer = ({ isOpen, result, statuses }) => {
    if (!isOpen) {
      return "";
    }

    const isKeyloop = result.search_category === "keyloop";

    return (
      <div key={result.id}>
        <span className="SearchResult-Title -text-ellipsis">{isKeyloop ? result.make : `${result.make} ${result.model}`}</span>
        <span className="SearchResult-Detail">
          {isKeyloop ? (
            <>
              {moment(result.due_in).format("DD-MM-YYYY")} | {result.wo_number && result.wo_number + " |"} {result.reg_number}{" "}
              {this.renderStatusIcon(this.getImportStatus(result.status_identifier), statuses)}
              {this.state.isImportingKeyloopAppointment === result.wo_number && (
                <div className="importing-keyloop-appointment-loader-container">
                  <Loader type="Oval" color="#46923d" height="15" width="15" />
                </div>
              )}
            </>
          ) : (
            <>
              {moment(result.date).format("DD-MM-YYYY")} | {result.wo_number && result.wo_number + " |"} {result.ref_nr && result.ref_nr + " | "}
              <span
                className="SearchResult-RegNumber"
                onClick={e => {
                  e.stopPropagation();
                  this.handleRegistrationClick(result.car_id, result.wo_number);
                }}
              >
                {result.reg_number}
              </span>
              {` | ${result.dealer_location_name} `}
              {this.renderStatusIcon(result.status_identifier, statuses)}
            </>
          )}
        </span>
      </div>
    );
  };
}

export default withRouter(withTranslation()(visorify(Appointments)));
