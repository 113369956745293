import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import SnoozeOptions from "./SnoozeOptions";
import SnoozeModal from "./SnoozeModal";
import Service from "./service";
import { SNOOZE_STATUS } from "./enum";
import { APPOINTMENT_STATUSES } from "../../modules/Appointments/common";
import { ROLES } from "../../modules/Users/roles";

import "./Snooze.css";

class Snooze extends Component {
  state = {
    isOpen: false,
    isLoading: false,
    isLoadingDropdowns: false,
    isUpdated: false,
    errorMessage: "",
    deleteError: "",
    originalSnoozeItem: {},
    snoozeItem: {},
    snoozeOptions: null,
    snoozeLog: Array.isArray(this.props.snoozeLog) ? this.props.snoozeLog.sort((a, b) => (b.created_on > a.created_on ? 1 : -1)) : [],
  };

  componentDidMount() {
    this.getDropdownOptions();
  }

  componentDidUpdate(prevProps) {
    let { snoozeLog } = this.props;

    if (snoozeLog !== prevProps.snoozeLog) {
      snoozeLog = Array.isArray(snoozeLog) ? snoozeLog.sort((a, b) => (b.created_on > a.created_on ? 1 : -1)) : [];
      const lastLog = this.getCurrentSnooze(snoozeLog);

      const snoozeItem = { ...lastLog, note: "" };

      this.setState({ snoozeLog, snoozeItem, originalSnoozeItem: { ...snoozeItem } });
    }
  }

  getCurrentSnooze = snoozeLog => {
    return snoozeLog.length && snoozeLog[0].snooze_status_id !== SNOOZE_STATUS.DELETED ? snoozeLog[0] : {};
  };

  getDropdownOptions = () => {
    this.setState({ isLoadingDropdowns: true }, () => {
      SnoozeOptions.getDropdownOptions()
        .then(result => {
          if (result?.snoozeOptions) this.setState({ snoozeOptions: result.snoozeOptions, isLoadingDropdowns: false });
        })
        .catch(errorMessage => {
          this.setState({ isLoadingDropdowns: false, errorMessage });
          console.error(errorMessage);
        });
    });
  };

  handleOpen = () => {
    const { snoozeLog } = this.state;
    const lastLog = this.getCurrentSnooze(snoozeLog);
    const snoozeItem = { ...lastLog, note: "" };
    this.setState({ isOpen: true, snoozeItem, originalSnoozeItem: { ...snoozeItem }, errorMessage: "" });
  };

  handleClose = () => {
    this.setState({ isOpen: false, snoozeItem: {}, originalSnoozeItem: {}, errorMessage: "" });
  };

  handleChange = snoozeItem => {
    this.setState({ snoozeItem, isUpdated: !_.isEqual(snoozeItem, this.state.originalSnoozeItem) });
  };

  handleSave = () => {
    const { snoozeItem, snoozeLog } = this.state;
    const {
      intervention,
      question_result,
      appointment,
      onSnooze,
      authState: { user },
      t,
    } = this.props;

    if (intervention) snoozeItem.intervention_id = intervention.id;
    else if (question_result) snoozeItem.question_result_id = question_result.id;

    snoozeItem.appointment_id = appointment.id;
    snoozeItem.created_on = moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");

    this.setState({ isLoading: true }, () => {
      Service.addSnoozeItem(snoozeItem)
        .then(result => {
          const savedSnoozeItem = {
            ...snoozeItem,
            id: result?.data?.data || 0,
            user: {
              first_name: user.first_name,
              last_name: user.last_name,
            },
          };

          this.setState({ isLoading: false, isOpen: false, snoozeItem: {}, originalSnoozeItem: {}, snoozeLog: [savedSnoozeItem, ...snoozeLog], isUpdated: false }, () => {
            if (onSnooze) onSnooze(this.state.snoozeLog);
          });
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.errors?.shift() || t("unexpected_error").message || "Unexpected error, please try to reload the page.";

          this.setState({ isLoading: false, errorMessage });
          console.error(errorMessage);
        });
    });
  };

  handleDelete = reason => {
    this.setState({ snoozeLog: [], isOpen: false }, () => {
      this.props.onDelete && this.props.onDelete(reason);
    });
  };

  render() {
    const { snoozeOptions, snoozeLog, snoozeItem, isOpen, isLoading, isLoadingDropdowns, isDeleting, isUpdated, errorMessage } = this.state;
    const { appointment, authState } = this.props;

    const readOnly = appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED || authState.user.role_id === ROLES.MANUFACTURER;

    return (
      <>
        <div className="Snooze-Container" onClick={this.handleOpen}>
          <Label className="Snoozer__Label" color={this.getCurrentSnooze(snoozeLog).snooze_type_id ? "blue" : null}>
            <Icon name="clock" />
          </Label>
        </div>
        {!isLoadingDropdowns && snoozeOptions && (
          <SnoozeModal
            readOnly={readOnly}
            snoozeItem={snoozeItem}
            snoozeLog={snoozeLog}
            dropdownOptions={snoozeOptions}
            isOpen={isOpen}
            isLoading={isLoading}
            isDeleting={isDeleting}
            isUpdated={isUpdated}
            errorMessage={errorMessage}
            onClose={this.handleClose}
            onChange={this.handleChange}
            onSave={this.handleSave}
            onDelete={this.handleDelete}
            questionResultId={this.props.question_result?.id}
            interventionId={this.props.intervention?.id}
            getCurrentSnooze={this.getCurrentSnooze}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth };
};

export default connect(mapStateToProps)(withTranslation()(Snooze));
