import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, Icon, Grid, Form } from "semantic-ui-react";

import { setAlert } from "../App/store";
import { SubHeader, UserInput } from "./../../components";

import Service from "./service";

const emptyVersion = {
  label: "",
  minimum_label: "",
  remarks: "",
};

class CameraVersions extends Component {
  state = {
    version: emptyVersion,
    versions: [],
    isLoading: false,
    isLoadingVersions: false,
  };

  componentDidMount() {
    this.getVersions();
  }

  getVersions = () => {
    this.setState({ isLoadingVersions: true }, async () => {
      try {
        const response = await Service.getVersions();
        const versions = response?.data?.data || [];
        versions.sort((a, b) => b.created_on - a.created_on);

        this.setState({ versions, isLoadingVersions: false });
      } catch (err) {
        this.setState({ isLoadingVersions: false });
        console.error("Failed to get camera versions", err);
        this.props.setAlert({ type: "error", title: "Failed to get camera versions" });
      }
    });
  };

  handleChange = (_e, data) => this.setState({ version: { ...this.state.version, [data.name]: data.value } });

  handleSave = () => {
    const { version } = this.state;

    if (!version.label) return;

    this.setState({ isLoading: true }, async () => {
      try {
        await Service.addVersion(version);

        const versions = [version, ...this.state.versions];

        this.setState({ versions, version: emptyVersion, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        console.error("Failed to add camera version", err);
        this.props.setAlert({ type: "error", title: "Failed to add camera version" });
      }
    });
  };

  renderTable = () => {
    const { versions, isLoadingVersions } = this.state;
    const { t } = this.props;

    if (isLoadingVersions) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_versions").message || "Loading versions"}</section>
        </div>
      );
    }

    return (
      <div className="VersionsTable">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={versions}
          sortable={false}
          resizable={false}
          showPagination={false}
          showPageSizeOptions={false}
          defaultPageSize={versions.length}
          pageSize={versions.length}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="mobile alternate" style={{ fontSize: "1.75em" }} />
              <p>{t("no_versions").message || "No versions"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "Label",
              Header: t("label").message || "Label",
              maxWidth: 100,
              accessor: "label",
            },
            {
              id: "MinimumLabel",
              Header: t("minimum_label").message || "Minimum Label",
              maxWidth: 300,
              accessor: "minimum_label",
            },
            {
              id: "Note",
              Header: t("note").message || "Note",
              minWidth: 150,
              accessor: "remarks",
            },
            {
              id: "DateCreated",
              maxWidth: 100,
              Header: t("created_date").message || "Created Date",
              accessor: d => (d.created_on !== "0001-01-01T00:00:00Z" ? <span>{moment(d.created_on).format("DD-MM-YYYY")}</span> : ""),
            },
          ]}
        />
      </div>
    );
  };

  render() {
    const { version, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div>
        <SubHeader>
          <Grid.Column width={16}>
            <h1>{t("camera_versions").message || "Camera Versions"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>
            <Form>
              <Form.Group>
                <Form.Field width={2}>
                  <UserInput
                    type="text"
                    name="label"
                    value={version.label}
                    onChange={this.handleChange}
                    placeholder={t("version_label").message || "Version label"}
                    error={isLoading && !version.label.length > 0}
                  />
                </Form.Field>

                <Form.Field width={4}>
                  <UserInput
                    type="text"
                    name="minimum_label"
                    value={version.minimum_label}
                    onChange={this.handleChange}
                    placeholder={t("minimum_label").message || "Minimum label"}
                  />
                </Form.Field>

                <Form.Field width={9}>
                  <UserInput type="text" placeholder={t("remarks").message || "Remarks"} name="remarks" value={version.remarks} onChange={this.handleChange} />
                </Form.Field>

                <Form.Field width={1}>
                  <Button onClick={this.handleSave} disabled={isLoading} loading={isLoading} color="green" floated="right">
                    {t("add").message || "Add"}
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>

          <Grid.Column width={16}>{this.renderTable()}</Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(CameraVersions));
