import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getTranslations(locale) {
    return axios.get(ENV.apiBaseB + "/translations/list_by_language_id?language=" + locale);
  }

  static getAllTranslations() {
    return axios.get(ENV.apiBaseB + "/translations/list", this.requestConfig());
  }

  static getAllLanguages() {
    return axios.get(ENV.apiBaseB + "/languages/list", this.requestConfig());
  }

  static createTranslation(data) {
    return axios.post(ENV.apiBaseB + "/translations/create", data, this.requestConfig());
  }

  static updateTranslation(data) {
    return axios.post(ENV.apiBaseB + "/translations/update", data, this.requestConfig());
  }

  static deleteTranslation(requestData) {
    return axios.post(ENV.apiBaseB + "/translations/delete", requestData, this.requestConfig());
  }

  static generateMigration(data) {
    return axios.post(ENV.apiBaseB + "/translations/generate_migration", data, this.requestConfig());
  }
}

export default Service;
