import React, { Component } from "react";
import { Grid, Icon, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import AlertContainer from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExclamationTriangle, faFileAlt, faFileInvoiceDollar, faInfo, faPhoneAlt, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { faSteeringWheel } from "@fortawesome/pro-solid-svg-icons";
import { faTire } from "@fortawesome/pro-regular-svg-icons";

import AppointmentNote from "./AppointmentNote";
import CommunicationLog from "./CommunicationLog";
import DeskCommunicationLog from "./DeskCommunicationLog";
import KioskCommunicationLog from "./KioskCommunicationLog";
import KeylockerCommunicationLog from "./KeylockerCommunicationLog";
import AppointmentQuestionResult from "./AppointmentQuestionResult";
import { ROLES } from "../Users/roles";
import { visorify, CustomConfirm, FileDropDown } from "../../components";
import { COMMUNICATION_STATUSES, APPOINTMENT_STATUSES } from "./common";
import { APPOINTMENT_NOTE_TYPES } from "./util";
import { Can } from "../";

import Service from "./service";

import "./AppointmentChecklistGroup.css";

class AppointmentChecklistGroup extends Component {
  constructor(props) {
    super(props);

    props.items &&
      props.items.forEach(i => {
        if (Array.isArray(i.tyre_replacements)) {
          i.tyre_replacements.sort((a, b) => {
            if (a.created_on < b.created_on) return -1;
            else if (a.created_on > b.created_on) return 1;
            return 0;
          });
        }
      });

    this.state = {
      questions: props.items,
      tyreMediaVisible: [null, false, false, false, false, false, false, false, false],
      isDeleteImageConfirmVisible: false,
      deleteImageUpdate: null,
      isDeleteVideoConfirmVisible: false,
      deleteVideoUpdate: null,
      selectedCustomerComm: props.selectedCustomerComm || [],
      isCustomerCommPriceConfirmVisible: false,
      isCustomerCommTyreConfirmVisible: false,
      priceRequestToSave: null,
      tyreRequestToSave: null,
      notes: [],
      previousReplacementPrice: 0,
      isUpdatingAnswer: false,
      appointmentNoteEdited: null,
    };
  }

  handleOpenAppointmentNoteModal = item => {
    this.setState({ appointmentNoteEdited: item });
  };

  handleCloseAppointmentNoteModal = () => {
    this.setState({ appointmentNoteEdited: null });
  };

  handleUpdateAppointmentNote = (appointment_note_id, updatedAppointmentNote) => {
    this.props.updateAppointmentNote(appointment_note_id, updatedAppointmentNote);
    this.handleCloseAppointmentNoteModal();
  };

  handleDeleteAppointmentNote = appointment_note_id => {
    this.props.deleteAppointmentNote(appointment_note_id);
    this.handleCloseAppointmentNoteModal();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.items && nextProps.items.length > 0) {
      nextProps.items.forEach(
        i =>
          i.tyre_replacements &&
          i.tyre_replacements.sort((a, b) => {
            if (a.created_on < b.created_on) return -1;
            else if (a.created_on > b.created_on) return 1;
            return 0;
          })
      );

      this.setState({ questions: nextProps.items });
    }

    if (nextProps.appointment.notes && nextProps.appointment.notes.length > 0) this.setState({ notes: nextProps.appointment.notes });

    if (nextProps.selectedCustomerComm) this.setState({ selectedCustomerComm: nextProps.selectedCustomerComm });
  }

  handleShowDeleteImageConfirmation = update => {
    this.setState({ isDeleteImageConfirmVisible: true, deleteImageUpdate: update });
  };

  handleHideDeleteImageConfirm = () => {
    this.setState({ isDeleteImageConfirmVisible: false, deleteImageUpdate: null });
  };

  handleDeleteImageConfirm = () => {
    this.handleAnswerUpdate({
      ...this.state.deleteImageUpdate,
      shouldConfirm: false,
    });
    this.handleHideDeleteImageConfirm();
  };

  handleShowDeleteVideoConfirmation = update => {
    this.setState({ isDeleteVideoConfirmVisible: true, deleteVideoUpdate: update });
  };

  handleHideDeleteVideoConfirm = () => {
    this.setState({ isDeleteVideoConfirmVisible: false, deleteVideoUpdate: null });
  };

  handleDeleteVideoConfirm = () => {
    this.handleAnswerUpdate({
      ...this.state.deleteVideoUpdate,
      shouldConfirm: false,
    });
    this.handleHideDeleteVideoConfirm();
  };

  handleMediaToggle = questionIndex => {
    let questions = this.state.questions;
    questions[questionIndex].mediaVisible = typeof questions[questionIndex].mediaVisible === "undefined" ? true : !questions[questionIndex].mediaVisible;

    this.setState({ questions });
  };

  handleTyreMediaToggle = position => {
    let { tyreMediaVisible } = this.state;
    tyreMediaVisible[position] = !tyreMediaVisible[position];

    this.setState({ tyreMediaVisible });
  };

  handleAnswerUpdate = update => {
    if (update.type === "images" && update.shouldConfirm) {
      this.handleShowDeleteImageConfirmation(update);
      return;
    }

    if (update.type === "videos" && update.shouldConfirm) {
      this.handleShowDeleteVideoConfirmation(update);
      return;
    }

    let questions = this.state.questions;
    let questionImages = [];
    let questionVideos = [];

    if (!update.index) update.index = questions.findIndex(q => q.id === update.questionId);

    if (update.type === "images") {
      questionImages = update.allImages;
      const imageIdx = questionImages.findIndex(i => i.id === update.value.image.id);
      if (imageIdx > -1) questionImages[imageIdx] = update.value.image;
      questions[update.index].images = questionImages;
    } else if (update.type === "videos") {
      questionVideos = update.allVideos;
      const videoIdx = questionVideos.findIndex(v => v.id === update.value.video.id);
      if (videoIdx > -1) questionVideos[videoIdx] = update.value.video;
      questions[update.index].video = questionVideos;
    } else if (update.type === "snooze") {
      questions[update.index].snooze_history = update.value;
      questions[update.index].snoozed = update.value?.length > 0;
    } else if (update.type === "pin") {
      questions[update.index].pin_history = update.value;
      questions[update.index].pinned = update.value?.length > 0;
    } else {
      questions[update.index][update.type] = update.value;
    }

    const QID = update.questionId;

    this.setState({ questions }, () => this.props.getTotalPrice());

    if (update.shouldSave) {
      let updateBody = {
        id: QID,
        dealer_location_id: this.props.appointment.dealer_location_id,
        wo: this.props.appointment.wo_nr,
        reg: this.props.appointment.car.reg_nr,
        appointment_id: this.props.appointment.id,
      };

      if (update.type === "images") {
        updateBody.images_update = questionImages;
      } else if (update.type === "videos") {
        updateBody.videos_update = questionVideos;
      } else if (update.type === "snooze") {
        updateBody.car_id = this.props.appointment.car_id;
        updateBody.snoozed = questions[update.index].snoozed;
        updateBody.snooze_date = questions[update.index].snooze_date;
        updateBody.snooze_note = questions[update.index].snooze_note;
        updateBody.snooze_type = questions[update.index].snooze_type;
      } else {
        updateBody[update.type] = update.value;
      }

      this.setState({ isUpdatingAnswer: true }, () => {
        Service.updateQuestion(updateBody)
          .then(() => this.setState({ isUpdatingAnswer: false }))
          .catch(() => this.setState({ isUpdatingAnswer: false }));
      });
    }

    this.props.onAnswerUpdate();
  };

  handleOnTyreReplacementAnswerUpdate = (question_result_id, tri, options) => {
    let { selectedCustomerComm, questions } = this.state;
    let { appointment, dashboardConfig } = this.props;
    const qri = questions.findIndex(q => q.id === question_result_id);

    if (Array.isArray(questions[qri]?.tyre_replacements) && questions[qri].tyre_replacements[tri]) {
      switch (options.field) {
        case "remark_save":
          questions[qri].tyre_replacements[tri].remark = options.value;
          questions[qri].tyre_replacements[tri].remark_changed = false;
          delete questions[qri].tyre_replacements[tri].old_remark;
          if (questions[qri].tyre_replacements[tri].tyre_replacement_id > 0) {
            Service.updateTyresReplacement(questions[qri].tyre_replacements[tri]).then(() => {
              this.setState({ questions });
              document.dispatchEvent(new CustomEvent("inputChanged", { detail: false }));
            });
          } else this.setState({ questions });
          break;

        case "remark_change":
          if (questions[qri].tyre_replacements[tri].tyre_replacement_id > 0) {
            if (typeof questions[qri].tyre_replacements[tri].old_remark === "undefined") {
              questions[qri].tyre_replacements[tri].old_remark = questions[qri].tyre_replacements[tri].remark;
              questions[qri].tyre_replacements[tri].remark_changed = true;
            } else if (questions[qri].tyre_replacements[tri].old_remark === options.value) {
              delete questions[qri].tyre_replacements[tri].old_remark;
              questions[qri].tyre_replacements[tri].remark_changed = false;
            }
          } else questions[qri].tyre_replacements[tri].remark = options.value;

          this.setState({ questions });
          break;

        case "price_change":
          if (questions[qri].tyre_replacements[tri].tyre_replacement_id > 0) {
            if (typeof questions[qri].tyre_replacements[tri].old_price === "undefined") {
              questions[qri].tyre_replacements[tri].old_price = questions[qri].tyre_replacements[tri].price;
              questions[qri].tyre_replacements[tri].price_changed = true;
            } else if (questions[qri].tyre_replacements[tri].old_price === options.value) {
              delete questions[qri].tyre_replacements[tri].old_price;
              questions[qri].tyre_replacements[tri].price_changed = false;
            }
          } else questions[qri].tyre_replacements[tri].price = options.value;

          this.setState({ questions, previousReplacementPrice: options.value });
          break;

        case "price_save":
          questions[qri].tyre_replacements[tri].price = options.value;
          questions[qri].tyre_replacements[tri].price_changed = false;
          delete questions[qri].tyre_replacements[tri].old_price;
          if (questions[qri].tyre_replacements[tri].tyre_replacement_id > 0) {
            const updateTyre = () => {
              Service.updateTyresReplacement(questions[qri].tyre_replacements[tri]).then(result => {
                this.setState({ questions }, () => this.props.getTotalPrice());
                document.dispatchEvent(new CustomEvent("inputChanged", { detail: false }));
              });
            };

            if (
              dashboardConfig["diagnose_overview_enabled"] &&
              (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
                appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
              selectedCustomerComm.some(s => s.question_result_id === questions[qri].id)
            )
              this.handleCustomcomTyreChange(() => {
                updateTyre();
              });
            else updateTyre();
          } else this.setState({ questions }, () => this.props.getTotalPrice());
          break;

        case "customer_answer":
          if (!questions[qri].tyre_replacements[tri].draft) {
            questions[qri].tyre_replacements[tri].customer_answer = options.value;
            Service.updateTyresReplacement(questions[qri].tyre_replacements[tri]).then(result => {
              this.setState({ questions }, () => this.props.getTotalPrice());
            });

            if (options.value) {
              questions[qri].tyre_replacements.forEach((rep, i) => {
                if (i !== tri) questions[qri].tyre_replacements[i].customer_answer = false;
              });

              this.props.getTotalPrice();
            }
          }
          break;

        default:
          break;
      }
    }
  };

  handleOnTyreReplaceDelete = (question_result_id, tri) => {
    let { selectedCustomerComm, questions, previousReplacementPrice } = this.state;
    let { appointment, dashboardConfig } = this.props;
    const qri = questions.findIndex(q => q.id === question_result_id);

    if (Array.isArray(questions[qri]?.tyre_replacements) && questions[qri].tyre_replacements[tri]) {
      if (questions[qri].tyre_replacements[tri].tyre_replacement_id > 0) {
        const deleteTyre = () => {
          Service.deleteTyresReplacement({ tyre_replacement_id: questions[qri].tyre_replacements[tri].tyre_replacement_id }).then(result => {
            if (questions[qri].tyre_replacements[tri].customer_answer) questions[qri].customer_approved = false;
            questions[qri].tyre_replacements.splice(tri, 1);
            if (!questions[qri].tyre_replacements.length) {
              previousReplacementPrice = 0;
            }

            this.setState({ questions, previousReplacementPrice }, () => this.props.getTotalPrice());
          });
        };

        if (
          dashboardConfig["diagnose_overview_enabled"] &&
          (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
            appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
          selectedCustomerComm.some(s => s.question_result_id === questions[qri].id)
        )
          this.handleCustomcomTyreChange(() => {
            deleteTyre();
          });
        else deleteTyre();
      } else {
        if (!questions[qri].tyre_replacements[tri].draft) return;

        questions[qri].tyre_replacements.splice(tri, 1);
        if (!questions[qri].tyre_replacements.length) {
          previousReplacementPrice = 0;
        }

        this.setState({ questions, previousReplacementPrice }, () => this.props.getTotalPrice());
      }
    }
  };

  handleCustomCommPriceChange = request => {
    this.setState({ isCustomerCommPriceConfirmVisible: true, priceRequestToSave: request });
  };

  handleCustomcomTyreChange = callback => {
    this.setState({ isCustomerCommTyreConfirmVisible: true, tyreRequestToSave: callback });
  };

  renderImageConfirm = () => {
    return (
      <CustomConfirm
        type="danger"
        isOpen={this.state.isDeleteImageConfirmVisible}
        handleCancel={this.handleHideDeleteImageConfirm}
        handleConfirm={this.handleDeleteImageConfirm}
        isLoading={this.state.isUpdatingAnswer}
        confirmMsg={this.props.t("are_you_sure_delete_image").message || "Are you sure you want to delete this image?"}
        confirmText={this.props.t("Yes").message || "Yes"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  renderVideoConfirm = () => {
    return (
      <CustomConfirm
        type="danger"
        isOpen={this.state.isDeleteVideoConfirmVisible}
        handleCancel={this.handleHideDeleteVideoConfirm}
        handleConfirm={this.handleDeleteVideoConfirm}
        isLoading={this.state.isUpdatingAnswer}
        confirmMsg={this.props.t("are_you_sure_delete_video").message || "Are you sure you want to delete this video?"}
        confirmText={this.props.t("Yes").message || "Yes"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  handleChangeCustomerCommunication = (checked, id) => {
    let { selectedCustomerComm } = this.state;
    let i = selectedCustomerComm.findIndex(s => s.question_result_id === id);
    if (i > -1 && !checked) {
      selectedCustomerComm.splice(i, 1);
    } else if (checked) {
      selectedCustomerComm.push({ question_result_id: id });
    }

    this.setState({ selectedCustomerComm }, () => {
      this.props.onChangeCustomerCommunication(selectedCustomerComm);
    });
  };

  renderPriceChangeCustomerCommConfirmation = () => {
    const { isCustomerCommPriceConfirmVisible, isCustomerCommTyreConfirmVisible } = this.state;

    return (
      <CustomConfirm
        type="danger"
        isOpen={isCustomerCommPriceConfirmVisible || isCustomerCommTyreConfirmVisible}
        handleCancel={() => this.setState({ isCustomerCommPriceConfirmVisible: false, isCustomerCommTyreConfirmVisible: false })}
        handleConfirm={() =>
          this.setState({ isCustomerCommPriceConfirmVisible: false, isCustomerCommTyreConfirmVisible: false }, () => {
            if (isCustomerCommPriceConfirmVisible) this.handleAnswerUpdate(this.state.priceRequestToSave);
            else if (this.state.tyreRequestToSave) this.state.tyreRequestToSave();
          })
        }
        isLoading={this.state.isUpdatingAnswer}
        confirmMsg={this.props.t("confirm_price_changes_for_customer_comm").message || "Those changes will be send to the customer. Do you want to continue?"}
        confirmText={this.props.t("confirm").message || "Confirm"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  toggleShowMore = index => {
    let notes = this.state.notes;
    if (notes && notes.length >= index) {
      notes[index].showMore = !notes[index].showMore;
    }

    this.setState({ notes });
  };

  renderAlert = () => {
    const alertOptions = {
      position: "top right",
      time: 3000,
    };
    return <AlertContainer ref={a => (this.errorAlert = a)} {...alertOptions} />;
  };

  getNoteIcon = appointment_note_type_id => {
    switch (appointment_note_type_id) {
      case APPOINTMENT_NOTE_TYPES.INFO:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faInfo} color="#2185D0" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.BACK_ORDER:
        return (
          <Label className="label-icon">
            <span style={{ color: "#6C0699" }}>BO</span>
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.WO:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faFileAlt} color="#2185D0" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.MAIN:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faStickyNote} color="#FF0202" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faFileInvoiceDollar} color="#46B046" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.TIRESCANNER:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faTire} color="#FF8800" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faSteeringWheel} color="#5C5C5C" />
          </Label>
        );
      case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
        return (
          <Label className="label-icon">
            <FontAwesomeIcon icon={faExclamationTriangle} color="#C83628" />
          </Label>
        );
      default:
        return null;
    }
  };

  renderEditNoteButton = item => {
    const { appointment_note_type_id } = item;

    if ([APPOINTMENT_NOTE_TYPES.INFO, APPOINTMENT_NOTE_TYPES.BACK_ORDER, APPOINTMENT_NOTE_TYPES.TIRESCANNER].includes(appointment_note_type_id)) return null;

    let resource = "";

    switch (appointment_note_type_id) {
      case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
        resource = "appointment-notes-customer-call";
        break;
      case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
        resource = "appointment-notes-attachments";
        break;
      case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
        resource = "appointment-notes-temp-driver";
        break;
      case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
        resource = "appointment-notes-recurring-car";
        break;
      case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
        resource = "appointment-notes-bills";
        break;
      case APPOINTMENT_NOTE_TYPES.WO:
        resource = "appointment-notes-wo";
        break;
      default:
        resource = "appointment-notes";
        break;
    }

    return (
      <Can I="update" the={resource}>
        <Label onClick={() => this.handleOpenAppointmentNoteModal(item)}>
          <FontAwesomeIcon icon={faEdit} />
        </Label>
      </Can>
    );
  };

  render() {
    let {
      t,
      title,
      titleIcon,
      titleClassName,
      appointment,
      vat,
      includeVAT,
      checklist,
      template,
      displayFrontIcon,
      dashboardConfig,
      listVisibility,
      currentUser,
      isEditable,
      isPriceEditable,
      isFinalCheckDone,
      isCustomerCommunicationVisible,
      isImportantItem,
      openCarDetail,
      onMediaClick,
      getTotalPrice,
      location,
      keyloopEnabled,
      keylockerPhones,
      kioskPhones,
    } = this.props;
    let { questions, tyreMediaVisible, selectedCustomerComm, appointmentNoteEdited } = this.state;
    const { keep_importing_after_work_started } = location;

    const hasNotOkQuestions = this.state.questions.find(q => q.status !== 0 && q.status !== 4);
    const hasTyreQuestion = questions.some(q => q.tyre_id);

    if (!isImportantItem && !hasNotOkQuestions && !hasTyreQuestion && listVisibility === "nok-statuses") {
      return null;
    }

    const readOnly = appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED || [ROLES.MANUFACTURER, ROLES.MECHANIC].includes(currentUser.role_id);
    const readOnlyRemarks = appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED || [ROLES.MANUFACTURER].includes(currentUser.role_id);

    let remarkWidescreenWidth = includeVAT ? 5 : 6;
    let remarkComputerWidth = includeVAT ? 4 : 5;

    if (keep_importing_after_work_started) {
      remarkWidescreenWidth += 2;
      remarkComputerWidth += 2;

      if (includeVAT) {
        remarkWidescreenWidth += 2;
        remarkComputerWidth += 2;
      }
    }

    return (
      <div className="AppointmentChecklistGroup">
        <div className={`AppointmentChecklistGroup__group-title ${titleClassName} ${!isImportantItem && questions?.some(q => q.tyre_id) ? "tyre_title" : ""}`}>
          <Grid>
            <Grid.Row style={{ paddingTop: "0", paddingBottom: "0" }}>
              <Grid.Column width={includeVAT ? 3 : 4}>
                {title && (
                  <span>
                    {titleIcon && <Icon name={titleIcon} />}
                    {title}
                  </span>
                )}
              </Grid.Column>
              <Grid.Column widescreen={remarkWidescreenWidth} computer={remarkComputerWidth}>
                {!isImportantItem && <span>{t("remarks").message || "Remarks"}</span>}
              </Grid.Column>
              {!keep_importing_after_work_started && <Grid.Column width={2}>{!isImportantItem && <span>{t("no_vat_price").message || "Excl. VAT"}</span>}</Grid.Column>}
              {!keep_importing_after_work_started && includeVAT && (
                <Grid.Column width={2}>{!isImportantItem && <span>{t("vat_price").message || "Incl. VAT"}</span>}</Grid.Column>
              )}
              <Grid.Column widescreen={4} computer={5} className="-no-padding-right">
                {!isImportantItem && (
                  <div className="-pull-right CustomerSmile">
                    <Icon name="smile" className="-no-click -no-margin" />
                    <Icon name="car" className="-no-click" />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {isImportantItem && (
          <>
            {keylockerPhones.map(phone_nr => (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="AppointmentChecklistGroup__item__question">
                  <Label>
                    <Icon name="phone square" />
                  </Label>
                  <span className="-margin-right-15">
                    {t("keylocker_customer_provided_phone").message || "Keylocker: The customer provided this phone number to be contacted"}: <b>{phone_nr}</b>
                  </span>
                </Grid.Column>
              </Grid>
            ))}

            {kioskPhones.map(phone_nr => (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="AppointmentChecklistGroup__item__question">
                  <Label>
                    <Icon name="phone square" />
                  </Label>
                  <span className="-margin-right-15">
                    {t("customer_provided_phone").message || "The customer provided this phone number to be contacted"}: <b>{phone_nr}</b>
                  </span>
                </Grid.Column>
              </Grid>
            ))}

            {appointment.customcom_correct_phone && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="AppointmentChecklistGroup__item__question">
                  <Label>
                    <Icon name="phone square" />
                  </Label>
                  <span className="-margin-right-15">
                    {t("customer_provided_phone").message || "The customer provided this phone number to be contacted"}: <b>{appointment.customcom_correct_phone}</b>
                  </span>
                </Grid.Column>
              </Grid>
            )}

            {appointment.customcom_correct_email && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="AppointmentChecklistGroup__item__question">
                  <Label>
                    <Icon name="mail square" />
                  </Label>
                  <span className="-margin-right-15">
                    {t("customer_provided_email").message || "The customer provided this email address to be contacted"}: <b>{appointment.customcom_correct_email}</b>
                  </span>
                </Grid.Column>
              </Grid>
            )}

            {appointment.is_recurring && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column
                  width={16}
                  className="AppointmentChecklistGroup__item__question -cursor-pointer"
                  onClick={() => {
                    openCarDetail(appointment.car_id, appointment.wo_nr);
                  }}
                >
                  <Label className="-cursor-pointer">
                    <Icon name="info" />
                  </Label>
                  <span>{t("recurring_repair").message || "Recurring Repair"}</span>
                </Grid.Column>
              </Grid>
            )}

            {appointment.customcom_status > 0 && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="pointer AppointmentChecklistGroup__item__question">
                  <CommunicationLog appointment={appointment} includeVAT={includeVAT} vat={vat} />
                </Grid.Column>
              </Grid>
            )}
            {appointment.desk_communications?.length > 0 && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="pointer AppointmentChecklistGroup__item__question">
                  <DeskCommunicationLog appointment={appointment} includeVAT={includeVAT} vat={vat} />
                </Grid.Column>
              </Grid>
            )}

            {appointment.kiosk_communications?.length > 0 && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="pointer AppointmentChecklistGroup__item__question">
                  <KioskCommunicationLog appointment={appointment} includeVAT={includeVAT} vat={vat} />
                </Grid.Column>
              </Grid>
            )}

            {appointment.keylocker_communications?.length > 0 && (
              <Grid className="AppointmentChecklistGroup__item">
                <Grid.Column width={16} className="pointer AppointmentChecklistGroup__item__question">
                  <KeylockerCommunicationLog appointment={appointment} includeVAT={includeVAT} vat={vat} />
                </Grid.Column>
              </Grid>
            )}
          </>
        )}

        <AppointmentQuestionResult
          {...{
            vat,
            questions,
            includeVAT,
            appointment,
            template,
            checklist,
            readOnly,
            readOnlyRemarks,
            isEditable,
            isPriceEditable,
            getTotalPrice,
            isFinalCheckDone,
            displayFrontIcon,
            isImportantItem,
            dashboardConfig,
            tyreMediaVisible,
            selectedCustomerComm,
            isCustomerCommunicationVisible,
            onMediaClick,
            location,
            keep_importing_after_work_started,
            keyloopEnabled,
          }}
          onMediaToggle={this.handleMediaToggle}
          onTyreMediaToggle={this.handleTyreMediaToggle}
          onPriceChange={this.handleCustomCommPriceChange}
          onTyreReplacementAnswerUpdate={this.handleOnTyreReplacementAnswerUpdate}
          onTyreReplaceDelete={this.handleOnTyreReplaceDelete}
          onChangeCustomerCommunication={this.handleChangeCustomerCommunication}
          onAnswerUpdate={this.handleAnswerUpdate}
        />

        {isImportantItem && (
          <>
            {appointment.dms_notes && appointment.dms_notes.length > 0 && (
              <Grid className="outter-container" style={{ marginTop: "0.3em" }}>
                <Grid.Row className="notes-remarks-header-container">
                  <Grid.Column width={16}>
                    <FontAwesomeIcon icon={faStickyNote} className="notes-remarks-header-icon" />
                    <span className="notes-remarks-header-title">{t("DMS_NOTES").message || "DMS NOTES"}</span>
                  </Grid.Column>
                </Grid.Row>
                {appointment.dms_notes.map((item, index) => {
                  return (
                    <Grid.Row className="NoteContainer notes-container" key={item.id}>
                      <Grid.Column style={{ verticalAlign: "middle" }} width={16} className="dms-notes-description-container AppointmentChecklistGroup__item__question">
                        <span className="notes-description">{item.note}</span>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            )}

            {appointment.car?.dms_notes && appointment.car.dms_notes.length > 0 && (
              <Grid className="outter-container" style={{ marginTop: "0.3em" }}>
                <Grid.Row className="notes-remarks-header-container">
                  <Grid.Column width={16}>
                    <FontAwesomeIcon icon={faStickyNote} className="notes-remarks-header-icon" />
                    <span className="notes-remarks-header-title">{t("DMS_CAR_NOTES").message || "DMS CAR NOTES"}</span>
                  </Grid.Column>
                </Grid.Row>

                {appointment.car.dms_notes.map(item => {
                  return (
                    <Grid.Row className="NoteContainer notes-container" key={item.id}>
                      <Grid.Column style={{ verticalAlign: "middle" }} width={16} className="dms-notes-description-container AppointmentChecklistGroup__item__question">
                        <span className="notes-description">{item.note}</span>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            )}

            {appointment.notes && appointment.notes.length > 0 && (
              <Grid className="outter-container">
                <Grid.Row className="notes-remarks-header-container">
                  <Grid.Column width={16}>
                    <FontAwesomeIcon icon={faStickyNote} className="notes-remarks-header-icon" />
                    <span className="notes-remarks-header-title">{t("RECEPTIONIST_REMARKS").message || "RECEPTIONIST REMARKS"}</span>
                  </Grid.Column>
                </Grid.Row>
                {appointment.notes.map((item, index) => {
                  return (
                    <Grid.Row className="NoteContainer notes-container receptionist-remarks-container" key={item.id}>
                      <Grid.Column style={{ verticalAlign: "middle" }} width={16} className="AppointmentChecklistGroup__item__question">
                        <div className="note-row">
                          <div>
                            <div className="note-icon">
                              {this.getNoteIcon(item.appointment_note_type_id)}

                              {item.attachments?.length > 0 && (
                                <FileDropDown
                                  files={item.attachments}
                                  trigger={
                                    <Label>
                                      <Icon name="attach" style={{ marginRight: "0px" }} /> <span>{item.attachments.length}</span>
                                    </Label>
                                  }
                                  disableUpload
                                  showPreview
                                  showIcon
                                />
                              )}

                              {item.phone_nr && (
                                <Label>
                                  {item.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE && <Icon name="phone" color="green" />}
                                  {item.phone_nr}
                                </Label>
                              )}

                              {item.email && (
                                <Label>
                                  <Icon name="mail" /> {item.email}
                                </Label>
                              )}

                              {item.name && (
                                <Label>
                                  <Icon name="user" /> {item.name}
                                </Label>
                              )}
                              {item.backorder_date && <Label className="label-date">{moment(item.backorder_date).format("DD-MM-YYYY")}</Label>}
                            </div>

                            <div className={`${item.showMore ? "no-elipsis" : "elipsis"} -cursor-pointer-no-color`} onClick={() => this.toggleShowMore(index)}>
                              {item.note}
                            </div>
                          </div>
                          <div className="note-details">
                            <div className="note-date">
                              {item.updated_by
                                ? item.user_id !== 0
                                  ? `${t("updated_by").message || "Updated by"} ${item.updated_by.first_name} ${item.updated_by.last_name} ${
                                      t("at").message || "at"
                                    } ${moment(item.updated_on).format("DD-MM-YYYY HH:mm")}`
                                  : `${t("updated_on").message || "Updated on"} ${moment(item.updated_on).format("DD-MM-YYYY HH:mm")}`
                                : item.user_id !== 0
                                ? `${t("Added_by").message || "Added by"} ${item.user.first_name} ${item.user.last_name} ${t("at").message || "at"} ${moment(
                                    item.created_on
                                  ).format("DD-MM-YYYY HH:mm")}`
                                : `${t("added_on").message || "Added on"} ${moment(item.created_on).format("DD-MM-YYYY HH:mm")}`}
                            </div>
                            {this.renderEditNoteButton(item) || <div className="placeholder-element"></div>}
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            )}

            <AppointmentNote
              handleDeleteAppointmentNote={this.handleDeleteAppointmentNote}
              handleUpdateAppointmentNote={this.handleUpdateAppointmentNote}
              onClose={this.handleCloseAppointmentNoteModal}
              note={appointmentNoteEdited}
              appointment={appointment}
            />
          </>
        )}

        {this.state.isDeleteImageConfirmVisible && this.renderImageConfirm()}
        {this.state.isDeleteVideoConfirmVisible && this.renderVideoConfirm()}
        {this.renderPriceChangeCustomerCommConfirmation()}
        {this.renderAlert()}
      </div>
    );
  }
}

export default withTranslation()(visorify(AppointmentChecklistGroup));
