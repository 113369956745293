import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Label, Grid, Icon, Button, Tab } from "semantic-ui-react";
import moment from "moment";

import printJS from "../../lib/print-js";
import { COMMUNICATION_EVENTS, CHANNELS } from "./common";
import { getPriceForDisplay } from "./util";

import "./CommunicationLog.scss";

const TAB = {
  ONLINE_CHECKIN: 1,
  DIAGNOSE_OVERVIEW: 2,
  DIAGNOSE_OVERVIEW_UPDATE: 3,
  REPAIR_OVERVIEW: 4,
};

class CommunicationLog extends Component {
  state = {
    isOpen: false,
    isAgreementModalOpen: false,
    isPrintLoading: false,
    selectedAgreement: null,
  };

  handleToggleAgreement = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleOpenAgreementModal = selectedAgreement => {
    this.setState({ isAgreementModalOpen: true, selectedAgreement });
  };

  handleCloseAgreementModal = () => {
    this.setState({ isAgreementModalOpen: false, selectedAgreement: null });
  };

  getInterventionPrice = (price, vat) => {
    if (price <= 0.01) return;

    return <span>{vat ? getPriceForDisplay(price * (1 + vat / 100)) : getPriceForDisplay(price)} €</span>;
  };

  renderAppointmentInformation = () => {
    const { appointment, t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">
          <span>{t("appointment_information").message || "Appointment information"}</span>
        </div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={4}>
              <span className="icon-text">
                {t("wo_nr").message || "WO"} <Icon name="hashtag" color="green" />
              </span>
              {appointment.wo_nr}
            </Grid.Column>

            <Grid.Column width={4}>
              <span className="icon-text">
                {t("reg").message || "Reg."} <Icon name="hashtag" color="green" />
              </span>
              {appointment.reg_number}
            </Grid.Column>

            {appointment.due_in && (moment.isMoment(appointment.due_in) || !appointment.due_in.startsWith("0001-01-01T00:00:00")) ? (
              <>
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.due_in).format("DD-MM-YYYY")}
                </Grid.Column>

                <Grid.Column width={4}>
                  <Icon name="time" color="green" />
                  {moment(appointment.due_in).format("HH:mm")}
                </Grid.Column>
              </>
            ) : (
              appointment.time_car_app &&
              (moment.isMoment(appointment.time_car_app) || !appointment.time_car_app.startsWith("0001-01-01T00:00:00")) && (
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.time_car_app).format("DD-MM-YYYY")}
                </Grid.Column>
              )
            )}
          </Grid>
        </div>
      </div>
    );
  };

  communicationEventName = (event, t) => {
    switch (event.type) {
      case COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT:
      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT:
      case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT:
        return t("sent").message || "Sent";

      case COMMUNICATION_EVENTS.ONLINE_CHECKIN_OPENED:
      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_OPENED:
      case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_OPENED:
        return t("opened").message || "Opened";

      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT:
      case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT:
        return t("resent").message || "Resent";

      case COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED:
      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED:
        return t("answered").message || "Answered";

      default:
        return "";
    }
  };

  communicationEventInfo = event => {
    const fullname = event.customer && `${event.customer.title} ${event.customer.firstname} ${event.customer.surname}`;

    return (
      <Grid.Row width={16} className="section-rows">
        {event.receivers?.length > 0 &&
          event.receivers.map((receiver, i) => (
            <React.Fragment key={i}>
              <span>
                {receiver.channel === CHANNELS.EMAIL ? <Icon name="mail" color="green" /> : <Icon name="phone" color="green" />}
                {receiver.destination}
              </span>
              {receiver.reason && (
                <span className="-margin-left-10">
                  <Icon key={i} name="times circle outline" color="red" />
                  {receiver.reason}
                </span>
              )}
            </React.Fragment>
          ))}

        {fullname && (
          <span>
            <Icon name="user" color="green" />
            {fullname}
          </span>
        )}

        {event.customer_name && (
          <span>
            <Icon name="write" color="green" />
            {event.customer_name}
          </span>
        )}

        {event.ip && (
          <>
            <span className="icon-text">
              IP
              <Icon name="map marker alternate" color="green" />
            </span>
            {event.ip}
          </>
        )}

        {event.key_locker_pin && event.type === COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED && <span>{this.props.t("pin_sent").message || "Pin sent"}</span>}
      </Grid.Row>
    );
  };

  renderCommunicationEvents = events => {
    const { t } = this.props;

    return (
      events?.length > 0 && (
        <div className="info-section">
          <div className="section-header">{t("communication_events").message || "Communication events"}</div>
          <div className="section-content">
            <Grid>
              {events.map((event, key) => {
                return (
                  <Grid.Row key={key}>
                    <Grid.Column width={12}>{this.communicationEventName(event, t)}</Grid.Column>

                    <Grid.Column width={4} className="event-timestamp">
                      {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
                    </Grid.Column>

                    {this.communicationEventInfo(event)}
                  </Grid.Row>
                );
              })}
            </Grid>
          </div>
        </div>
      )
    );
  };

  renderAgreementModal = () => {
    const { selectedAgreement, isAgreementModalOpen } = this.state;
    return (
      selectedAgreement && (
        <Modal dimmer={true} closeOnDimmerClick={false} open={isAgreementModalOpen} onClose={this.handleCloseAgreementModal}>
          <Modal.Header>
            {selectedAgreement.name}
            <Button onClick={this.handleCloseAgreementModal} floated="right" color="green">
              {this.props.t("close").message || "Close"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling>
            <div dangerouslySetInnerHTML={{ __html: selectedAgreement.text }}></div>
          </Modal.Content>
        </Modal>
      )
    );
  };

  renderAgreements = agreements => {
    if (!agreements?.length) return null;
    const { t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">{t("agreements").message || "Agreements"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              {agreements.map((item, key) => (
                <div className="agreements-label" key={key} onClick={() => this.handleOpenAgreementModal(item)}>
                  <Label>
                    <Icon name="check square" />
                  </Label>
                  <span className="-margin-left-10">{item.name}</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderAgreedInterventions = events => {
    const { t } = this.props;

    const event = events.find(e => [COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED, COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED].includes(e.type));

    let interventions =
      event?.type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED ? event?.diagnose_overview_agreed_interventions : event?.check_in_agreed_interventions;

    if (!interventions?.length) return null;

    const vat = interventions[0].vat;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("accepted_scheduled_services").message || "Accepted scheduled services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {interventions.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  {this.getInterventionPrice(i.price, vat)}
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderDeclinedInterventions = events => {
    const { t } = this.props;

    const event = events.find(e => COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED === e.type);

    if (!event?.check_in_declined_interventions?.length) return null;

    const vat = event.check_in_declined_interventions[0].vat;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("declined_scheduled_services").message || "Declined scheduled services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {event.check_in_declined_interventions.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  {this.getInterventionPrice(i.price, vat)}
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderAgreedQuestionResults = events => {
    const { t } = this.props;

    const event = events.find(e => e.type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED);

    if (!event?.diagnose_overview_agreed_results?.length) return null;

    const vat = event.diagnose_overview_agreed_results[0].vat;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("accepted_extra_services").message || "Accepted extra services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {event.diagnose_overview_agreed_results.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  <span>{vat ? getPriceForDisplay(i.price * (1 + vat / 100)) : getPriceForDisplay(i.price)} €</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderDeclinedQuestionResults = events => {
    const { t } = this.props;

    const event = events.find(e => e.type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED);

    if (!event?.diagnose_overview_declined_results?.length) return null;

    const vat = event.diagnose_overview_declined_results[0].vat;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("declined_extra_services").message || "Declined extra services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {event.diagnose_overview_declined_results.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  <span>{vat ? getPriceForDisplay(i.price * (1 + vat / 100)) : getPriceForDisplay(i.price)} €</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderContactQuestionResults = events => {
    const { t } = this.props;

    const event = events.find(e => e.type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED);

    if (!event?.diagnose_overview_contact_results?.length) return null;

    const vat = event.diagnose_overview_contact_results[0].vat;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("contact_extra_services").message || "Contact extra services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {event.diagnose_overview_contact_results.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  <span>{vat ? getPriceForDisplay(i.price * (1 + vat / 100)) : getPriceForDisplay(i.price)} €</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderOnlineCheckinServices = () => {
    const { t, appointment, includeVAT, vat } = this.props;
    const { check_in_results } = appointment;

    if (!check_in_results?.length) return null;

    return (
      <div className="info-section">
        <div className="section-header">{t("extra_services").message || "Extra services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{includeVAT ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {check_in_results.map((result, key) => (
                <div className="service" key={key}>
                  <span>{result.name}</span>
                  <span>{includeVAT ? getPriceForDisplay(result.price * (1 + vat / 100)) : getPriceForDisplay(result.price)} €</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderTabContent = (tab, event_id) => {
    const { appointment } = this.props;
    let events = [],
      agreements = [];

    if (tab === TAB.ONLINE_CHECKIN) {
      events = appointment.communication_events.filter(e =>
        [COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT, COMMUNICATION_EVENTS.ONLINE_CHECKIN_OPENED, COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED].includes(e.type)
      );
      agreements = appointment.online_checkin_agreements;
    } else if (tab === TAB.REPAIR_OVERVIEW) {
      events = appointment.communication_events.filter(e =>
        [COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT, COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT, COMMUNICATION_EVENTS.REPAIR_OVERVIEW_OPENED].includes(e.type)
      );
    } else {
      const diagnoseEvents = appointment.communication_events.filter(e =>
        [
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT,
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_OPENED,
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT,
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED,
        ].includes(e.type)
      );

      let eventIndex = diagnoseEvents.findIndex(e => e.id === event_id);

      if (eventIndex > -1) {
        const pushAgreements = agreement => {
          if (agreement.communication_event_id === diagnoseEvents[eventIndex].id) agreements.push(agreement);
        };

        do {
          events.push(diagnoseEvents[eventIndex]);
          if (appointment.diagnose_overview_agreements) appointment.diagnose_overview_agreements.forEach(pushAgreements);
          eventIndex++;
        } while (eventIndex < diagnoseEvents.length && diagnoseEvents[eventIndex].type !== COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT);
      }
    }

    return (
      <>
        {this.renderCommunicationEvents(events)}
        {this.renderAgreedInterventions(events)}
        {this.renderDeclinedInterventions(events)}
        {this.renderAgreedQuestionResults(events)}
        {this.renderDeclinedQuestionResults(events)}
        {this.renderContactQuestionResults(events)}
        {this.renderOnlineCheckinServices()}
        {this.renderAgreements(agreements)}
      </>
    );
  };

  renderTabs = () => {
    const { appointment, t } = this.props;
    const panes = [];

    appointment.communication_events.forEach(event => {
      switch (event.type) {
        case COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT:
          panes.push({
            menuItem: t("online_check_in").message || "Online Check-in",
            render: () => this.renderTabContent(TAB.ONLINE_CHECKIN, event.id),
          });
          break;

        case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT:
          panes.push({
            menuItem: t("diagnose_overview").message || "Diagnose Overview",
            render: () => this.renderTabContent(TAB.DIAGNOSE_OVERVIEW, event.id),
          });
          break;

        case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT:
          panes.push({
            menuItem: t("diagnose_overview_update").message || "Diagnose Overview Update",
            render: () => this.renderTabContent(TAB.DIAGNOSE_OVERVIEW_UPDATE, event.id),
          });
          break;

        case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT:
          panes.push({
            menuItem: t("repair_overview").message || "Repair overview",
            render: () => this.renderTabContent(TAB.REPAIR_OVERVIEW, event.id),
          });
          break;

        default:
          break;
      }
    });
    return panes.length > 0 && <Tab menu={{ secondary: true }} panes={panes} />;
  };

  stopPrintLoading = () => this.setState({ isPrintLoading: false });

  handlePrint = () => {
    return this.setState({ isPrintLoading: true }, () => {
      printJS({
        printable: "communication-agreement-content",
        type: "html",
        copyStyles: true,
        onLoadingEnd: this.stopPrintLoading,
        onError: this.stopPrintLoading,
      });
    });
  };

  render() {
    const { t, appointment } = this.props;

    if (!Array.isArray(appointment.communication_events)) return null;

    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.state.isOpen}
          onOpen={this.handleToggleAgreement}
          onClose={this.handleToggleAgreement}
          className="CommunicationLog-Modal"
          trigger={
            <div className="comm-agreement-label">
              <Label>
                <Icon name="user" />
              </Label>
              <span className="-margin-right-15">{t("customer_comm_log").message || "Customer communication log"}</span>
            </div>
          }
        >
          <Modal.Header>
            <Icon name="list" />
            {t("customer_comm_log").message || "Customer communication log"}
            <Button onClick={this.handlePrint} loading={this.state.isPrintLoading} className="print-btn" color="green">
              {t("print").message || "Print"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling id="communication-agreement-content">
            {this.renderAppointmentInformation()}
            {this.renderTabs()}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleToggleAgreement}>{t("close").message || "Close"}</Button>
          </Modal.Actions>
        </Modal>
        {this.renderAgreementModal()}
      </>
    );
  }
}

export default withTranslation()(CommunicationLog);
