import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FloatingActions, SubHeader } from "../../components";
import { Grid, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import AgreementModal from "./AgreementModal";
import Service from "./service";
import { setAlert } from "../App/store";

class CommunicationAgreements extends Component {
  state = {
    agreements: [],
    openAgreementModal: false,
    selectedAgreement: {},
    mode: "create",
    loadingAgreements: false,
  };

  handleNewAgreement = () => {
    this.setState({ selectedAgreement: { dealer_id: this.props.globalState.selectedDealer.id }, mode: "create", openAgreementModal: true });
  };

  handleEditAgreement = id => {
    let selectedAgreement = this.state.agreements.find(item => item.id === id);

    this.setState({ selectedAgreement, mode: "edit", openAgreementModal: true });
  };

  handleCloseAgreementModal = () => {
    this.setState({ selectedAgreement: {}, openAgreementModal: false });
  };

  handleAddAgreement = agreement => {
    let agreements = this.state.agreements;
    agreements.push(agreement);

    this.setState({ agreements, selectedAgreement: {}, openAgreementModal: false });
  };

  handleRemoveAgreement = id => {
    let agreements = this.state.agreements.filter(item => item.id !== id);

    this.setState({ agreements, selectedAgreement: {}, openAgreementModal: false });
  };

  handleUpdateAgreement = agreement => {
    let { selectedAgreement, agreements } = this.state;

    agreements = agreements.filter(item => item.id !== selectedAgreement.id);
    agreements.push(agreement);

    this.setState({ agreements, selectedAgreement: {}, openAgreementModal: false });
  };

  loadAgreements = () => {
    this.setState({ loadingAgreements: true }, () => {
      Service.getAgreements(this.props.globalState.selectedDealer.id)
        .then(result => {
          let agreements = [];
          if (result && result.data && result.data.data) agreements = result.data.data;

          this.setState({ agreements, loadingAgreements: false });
        })
        .catch(error => {
          this.props.setAlert({ type: "error", title: "Cannot load agreements" });
          this.setState({ loadingAgreements: false });
        });
    });
  };

  componentDidMount() {
    this.loadAgreements();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalState.selectedDealer.id !== this.props.globalState.selectedDealer.id) this.loadAgreements();
  }

  renderAgreementsTable = () => {
    let t = this.props.t;
    let agreements = this.state.agreements;

    if (this.state.loadingAgreements) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{this.props.t("loading_agreements").message || "Loading agreements"}</section>
        </div>
      );
    } else {
      return (
        <Grid className="-contained-large">
          <Grid.Column width={16}>
            <div className="CommunicationAgreementsTable">
              <ReactTable
                className="ReactTable -floated-table -contained-large"
                data={agreements || []}
                showPagination={false}
                showPageSizeOptions={false}
                sortable={false}
                resizable={false}
                defaultPageSize={agreements.length}
                pageSize={agreements.length}
                noDataText={
                  <div className="Table__no-results">
                    <Icon disabled name="file" style={{ fontSize: "1.75em", marginTop: "250px" }} />
                    <p>{t("no_checkin_agreements").message || "No check-in agreements"}</p>
                  </div>
                }
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: e => {
                      this.handleEditAgreement(rowInfo.original.id);
                    },
                  };
                }}
                column={{
                  ...ReactTableDefaults.column,
                  headerClassName: "ReactTable__column-header",
                  className: "ReactTable__column",
                }}
                columns={[
                  {
                    id: "name",
                    Header: t("name").message || "Name",
                    accessor: "name",
                  },
                  {
                    id: "text",
                    Header: t("text").message || "Text",
                    accessor: "text",
                  },
                ]}
              />
            </div>
          </Grid.Column>
        </Grid>
      );
    }
  };

  render() {
    let { mode, openAgreementModal, selectedAgreement } = this.state;

    return (
      <React.Fragment>
        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Column>
              <h1>{this.props.t("agreements").message || "Agreements"}</h1>
            </Grid.Column>
          </Grid>
        </SubHeader>

        {this.renderAgreementsTable()}

        {openAgreementModal && (
          <AgreementModal
            agreement={selectedAgreement}
            mode={mode}
            selectedLocation={this.props.globalState.selectedLocation}
            onAdd={this.handleAddAgreement}
            onRemove={this.handleRemoveAgreement}
            onUpdate={this.handleUpdateAgreement}
            onClose={this.handleCloseAgreementModal}
          />
        )}

        <FloatingActions items={[]} onRootClick={this.handleNewAgreement} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CommunicationAgreements));
