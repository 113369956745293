import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Step, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faCarGarage } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { COMMUNICATION_STATUSES, APPOINTMENT_STATUSES } from "./common";

function getCarReadyStatus(appointmentStatusId) {
  return appointmentStatusId === APPOINTMENT_STATUSES.CAR_READY ||
    appointmentStatusId === APPOINTMENT_STATUSES.QUALITY_CHECK ||
    appointmentStatusId === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
    appointmentStatusId === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW
    ? "active"
    : "none";
}

function getStatusFor(appointmentStatusId, statusId) {
  return appointmentStatusId === statusId ? "active" : "none";
}

function getCarInShopStatus(appointment) {
  return appointment.car_in_shop && !appointment.car_out_of_shop;
}

const AppointmentSteps = ({ onClick, appointment, onStepClick, dashConfig, commEnabled, isUpdatingAppointmentStatus, t }) => {
  let statusesConfig = [];
  statusesConfig[APPOINTMENT_STATUSES.PRICING_OK] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CALCULATING || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
  };
  statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CONTACT &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.PRICING_OK &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CUSTOMER_ANSWERED &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.BACK_ORDER,
  };
  statusesConfig[APPOINTMENT_STATUSES.CAR_READY] = {
    disabled:
      (appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_READY ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW) &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.BACK_ORDER,
  };
  statusesConfig[APPOINTMENT_STATUSES.CALCULATING] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CAR_CHECK || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
  };
  statusesConfig[APPOINTMENT_STATUSES.CONTACT] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.PRICING_OK || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
  };
  statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED] = {
    disabled: true,
  };

  const carInShop = {
    payload: { type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CAR_IN_SHOP },
    icon: getCarInShopStatus(appointment) ? faCarGarage : faCar,
    className: getCarInShopStatus(appointment) ? "on" : "off",
  };

  if (appointment.car_in_shop && !appointment.car_out_of_shop) {
    carInShop.payload.car_out_of_shop = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    carInShop.payload.car_in_shop = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    carInShop.payload.car_out_of_shop = null;
  }

  return (
    <div className="AppointmentSteps">
      <Step.Group size="mini">
        <Step className={`-active-status-${carInShop.className}`} link onClick={() => onStepClick(carInShop.payload)} disabled={isUpdatingAppointmentStatus}>
          <FontAwesomeIcon className="hideOnSmallerScreen" icon={carInShop.icon} style={{ fontSize: "22px", marginRight: "12px" }} />
          <Popup
            content={t("car_in_shop").message || "Car in shop"}
            trigger={<FontAwesomeIcon className="hideOnBigScreen" icon={carInShop.icon} style={{ fontSize: "24px" }} />}
          ></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{t("car_in_shop").message || "Car in shop"}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${getStatusFor(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CALCULATING)}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CALCULATING })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CALCULATING].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name="file outline" />
          <Popup content={t("calculating").message} trigger={<Icon className="hideOnBigScreen" name="file outline" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{t("calculating").message}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${getStatusFor(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.PRICING_OK)}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.PRICING_OK })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.PRICING_OK].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name="check" />
          <Popup content={t("proposal_ok").message} trigger={<Icon className="hideOnBigScreen" name="check" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{t("proposal_ok").message}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${getStatusFor(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CONTACT)}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CONTACT })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CONTACT].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name={`${appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone"}`} />
          <Popup
            content={t("contact").message}
            trigger={<Icon className="hideOnBigScreen" name={`${appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone"}`} />}
          ></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{t("contact").message}</Step.Title>
          </Step.Content>
        </Step>

        {commEnabled && (
          <Step
            className={`-active-status-${getStatusFor(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CUSTOMER_ANSWERED)}`}
            link
            disabled={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED].disabled || isUpdatingAppointmentStatus}
          >
            <FontAwesomeIcon className="hideOnSmallerScreen" icon={faUserCheck} style={{ marginRight: "1rem,", fontSize: "2.3em" }} />
            <Popup
              content={t("customer_replied").message}
              trigger={<FontAwesomeIcon className="hideOnBigScreen" icon={faUserCheck} style={{ marginRight: "1rem,", fontSize: "2.3em" }} />}
            ></Popup>
            <Step.Content>
              <Step.Title className="hideOnSmallerScreen">{t("customer_replied").message}</Step.Title>
            </Step.Content>
          </Step>
        )}

        <Step
          className={`-active-status-${getStatusFor(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CUSTOMER_OK)}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CUSTOMER_OK })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK].disabled || isUpdatingAppointmentStatus}
        >
          <Icon name="smile" className="-no-click hideOnSmallerScreen" />
          <Popup content={t("customer_ok").message || "Customer OK"} trigger={<Icon name="smile" className="hideOnBigScreen" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{t("customer_ok").message}</Step.Title>
          </Step.Content>
        </Step>

        {dashConfig.car_ready_btn_visible && (
          <Step
            disabled={statusesConfig[APPOINTMENT_STATUSES.CAR_READY].disabled || isUpdatingAppointmentStatus}
            link
            onClick={() => onStepClick({ type: "step", value: APPOINTMENT_STATUSES.CAR_READY })}
            className={`-active-status-${getCarReadyStatus(appointment.appointment_status_identifier)}`}
          >
            <Icon className="hideOnSmallerScreen" name="car" />
            <Popup content={t("car_ok").message} trigger={<Icon className="hideOnBigScreen" name="car" />}></Popup>
            <Step.Content>
              <Step.Title className="hideOnSmallerScreen">{t("car_ok").message}</Step.Title>
            </Step.Content>
          </Step>
        )}
      </Step.Group>
    </div>
  );
};

export default withTranslation()(AppointmentSteps);
