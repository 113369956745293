import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { Button, Modal, Icon, Grid, Search, Dropdown, Checkbox } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";

import { SearchPortal, SubHeader, UserMenuActionsPortal, UserInput } from "./../../components";
import { setAlert } from "../App/store";

import Service from "./service";

import "./Devices.css";

const SORT_BY = {
  LAST_USED_ASC: 1,
  LAST_USED_DESC: 2,
  FREE_SPACE_ASC: 3,
  FREE_SPACE_DESC: 4,
};
class Devices extends Component {
  state = {
    originalDevices: [],
    devices: [],
    nb_pages: 0,
    nb_items: 0,
    page: 0,
    loadingDevices: false,
    selectedDevice: {},
    isDeviceUserHistoryVisible: false,
    deviceUserHistory: [],
    selectedModels: [],
    selectedDealers: [],
    selectedBrands: [],
    selectedOsVersions: [],
    selectedLocations: [],
    sortBy: null,
    searchTerm: "",
    isLoadingExport: false,
    modelOptions: [],
    brandOptions: [],
    osVersionOptions: [],
    dealerOptions: [],
    locationOptions: [],
  };

  componentDidMount() {
    this.getDropdownOptions();

    const dealerOptions = [];
    const locationOptions = [];

    this.props.globalState.dealers.forEach(dealer => {
      dealerOptions.push({ key: dealer.id, value: dealer.id, text: dealer.name });

      if (dealer.locations?.length) {
        dealer.locations.forEach(location => locationOptions.push({ key: location.id, value: location.id, text: location.name, dealer_id: dealer.id }));
      }
    });

    this.setState({ dealerOptions, locationOptions });
  }

  getDevices = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.loadingDevices) return;

    const { selectedDealers, selectedLocations, selectedModels, selectedBrands, selectedOsVersions, searchTerm, sortBy } = this.state;

    this.setState(
      {
        loadingDevices: true,
        page,
      },
      () => {
        Service.getDevices({
          brands: selectedBrands,
          dealer_location_ids: selectedLocations,
          dealer_ids: selectedDealers,
          os_versions: selectedOsVersions,
          models: selectedModels,
          search_term: searchTerm,
          order_by: sortBy,
          page,
        })
          .then(response => {
            if (response?.data?.data) {
              const { items, nb_pages, nb_items } = response.data.data;
              const devices = items ? items : [];
              this.setState({ loadingDevices: false, devices, originalDevices: [...devices], nb_pages, nb_items });
            }
          })
          .catch(err => {
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
            console.error("Error getting devices.", err);
            this.setState({ loadingDevices: false });
          });
      }
    );
  };

  getDropdownOptions = async () => {
    try {
      const [brands, models, osVersions] = await Promise.all([Service.getBrands(), Service.getModels(), Service.getOsVersions()]);

      const brandOptions = brands.data?.data?.map((b, i) => ({ key: i, value: b, text: b })) || [];
      const modelOptions = models.data?.data?.map((m, i) => ({ key: i, value: m, text: m })) || [];
      const osVersionOptions = osVersions.data?.data?.map((o, i) => ({ key: i, value: o, text: o })) || [];

      this.setState({ brandOptions, modelOptions, osVersionOptions });
    } catch (error) {
      const errorMessage = error?.response?.data?.errors?.length
        ? error.response.data.errors[0]
        : this.props.t("error_filter_options").message || "Failed to load filter options";

      this.props.setAlert({
        type: "error",
        title: errorMessage,
      });
      console.error("Failed to load filter options", error);
    }
  };

  updateLease = (device, isLeased) => {
    Service.updateLease({
      device_id: device.id,
      is_leased: isLeased,
    })
      .then(response => {
        if (response.data.data && response.data.data.status !== "OK") {
          return;
        }

        let { originalDevices } = this.state;

        let i = originalDevices.findIndex(x => x.id === device.id);
        if (i !== -1) {
          originalDevices[i].is_leased = isLeased;
        }

        this.props.setAlert({
          type: "success",
          title: "Lease status of tablet updated",
        });
        this.setState({ originalDevices }, () => {
          this.filterDevices();
        });
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: "Problem updating lease status",
        });
        console.error("Error saving lease status", err);
      });
  };

  handleLeaseCheck = (device, isChecked) => {
    this.updateLease(device, isChecked);
  };

  handleUserHistoryClick = device => {
    this.setState({ isDeviceUserHistoryVisible: true, deviceUserHistory: device.user_history });
  };

  handleHideDeviceUserHistory = () => {
    this.setState({ isDeviceUserHistoryVisible: false });
  };

  handleDropdownChange = (_e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      this.getDevices();
    });
  };

  handleSpaceSort = () => {
    const sortBy = this.state.sortBy === SORT_BY.FREE_SPACE_DESC ? SORT_BY.FREE_SPACE_ASC : SORT_BY.FREE_SPACE_DESC;

    this.setState({ sortBy }, () => {
      this.getDevices();
    });
  };

  handleSortByLastUsed = () => {
    const sortBy = this.state.sortBy === SORT_BY.LAST_USED_DESC ? SORT_BY.LAST_USED_ASC : SORT_BY.LAST_USED_DESC;

    this.setState({ sortBy }, () => {
      this.getDevices();
    });
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => {
      this.getDevices();
    });
  };

  handleRefreshData = () => {
    this.getDevices();
  };

  getDealerNameById = id => {
    const dealer = this.props.globalState.dealers?.find(d => d.id === id);
    if (dealer) return dealer.name;
  };

  getLocationNameByDealerIdAndLocationId = (dealerId, locationId) => {
    if (!dealerId || !locationId) return;

    const dealer = this.props.globalState.dealers?.find(d => d.id === dealerId);
    const location = dealer?.locations?.find(l => l.id === locationId);

    if (location) return location.name;
  };

  getLocationsByDealerIds = dealerIds => {
    const { globalState } = this.props;
    let locations = [];

    globalState.dealers.forEach(d => {
      if (dealerIds.includes(d.id) && d.locations) {
        locations.push(...d.locations);
      }
    });
    return locations;
  };

  exportData = () => {
    const { page, searchTerm, selectedDealers, selectedLocations, selectedBrands, selectedModels, selectedOsVersions, sortBy } = this.state;

    this.setState({ isLoadingExport: true }, () => {
      Service.getExportData({
        brands: selectedBrands,
        dealer_location_ids: selectedLocations,
        dealer_ids: selectedDealers,
        os_versions: selectedOsVersions,
        models: selectedModels,
        search_term: searchTerm,
        order_by: sortBy,
        page,
      })
        .then(res => {
          if (res?.data?.data?.url) saveAs(res.data.data.url, "Tablets_" + moment().format("YYYYMMDD") + ".csv");
          this.setState({ isLoadingExport: false });
        })
        .catch(err => {
          console.error("Error getting devices.", err);

          const errorMsg =
            typeof err.response?.data === "string" ? err.response.data : err.response.data.errs ? err.response.data.errors[0] : "Failed to download the csv file";

          this.props.setAlert({
            type: "error",
            title: errorMsg,
          });
          this.setState({ isLoadingExport: false });
        });
    });
  };

  renderFilters = () => {
    const {
      dealerOptions,
      locationOptions,
      brandOptions,
      modelOptions,
      osVersionOptions,
      selectedDealers,
      selectedLocations,
      selectedBrands,
      selectedModels,
      selectedOsVersions,
    } = this.state;
    const { t } = this.props;

    return (
      <Grid className="DevicesFilters">
        <Grid.Column width={4}>
          <Dropdown
            fluid
            selection
            multiple
            clearable
            search
            selectOnBlur={false}
            options={dealerOptions}
            value={selectedDealers}
            placeholder={t("select_dealers").message || "Select dealer(s)"}
            onChange={this.handleDropdownChange}
            name="selectedDealers"
          />
        </Grid.Column>

        <Grid.Column width={4}>
          <Dropdown
            fluid
            selection
            multiple
            clearable
            search
            selectOnBlur={false}
            options={selectedDealers?.length ? locationOptions.filter(l => selectedDealers.includes(l.dealer_id)) : locationOptions}
            value={selectedLocations}
            placeholder={t("select_locations").message || "Select location(s)"}
            onChange={this.handleDropdownChange}
            name="selectedLocations"
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <Dropdown
            fluid
            selection
            multiple
            clearable
            search
            selectOnBlur={false}
            options={brandOptions}
            value={selectedBrands}
            placeholder={t("select_brands").message || "Select brand(s)"}
            onChange={this.handleDropdownChange}
            name="selectedBrands"
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <Dropdown
            fluid
            selection
            multiple
            clearable
            search
            selectOnBlur={false}
            options={modelOptions}
            value={selectedModels}
            placeholder={t("select_models").message || "Select model(s)"}
            onChange={this.handleDropdownChange}
            name="selectedModels"
          />
        </Grid.Column>

        <Grid.Column width={2}>
          <Dropdown
            fluid
            selection
            multiple
            clearable
            search
            selectOnBlur={false}
            options={osVersionOptions}
            value={selectedOsVersions}
            placeholder={t("select_os_versions").message || "Select OS Version(s)"}
            onChange={this.handleDropdownChange}
            name="selectedOsVersions"
          />
        </Grid.Column>
      </Grid>
    );
  };

  renderTable = () => {
    const { devices, page, nb_pages, loadingDevices } = this.state;
    const { t } = this.props;

    if (loadingDevices && !devices?.length) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_tablets").message || "Loading Tablets"}</section>
        </div>
      );
    } else {
      return (
        <div className="DevicesTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={devices}
            showPagination={nb_pages > 1}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            loading={loadingDevices}
            page={page - 1}
            pages={nb_pages === null ? -1 : nb_pages}
            pageSize={devices.length}
            manual
            onFetchData={(state, instance) => this.getDevices(state.page + 1)}
            nextText={t("next").message || "Next"}
            previousText={t("previous").message || "Previous"}
            pageText={t("page").message || "Page"}
            ofText={t("of").message || "of"}
            renderPageJump={({ onChange, onBlur, onKeyPress, inputType, pageJumpText }) => (
              <div className="-pageJump">
                <UserInput
                  aria-label={pageJumpText}
                  type={inputType}
                  onChange={evt => {
                    onChange(evt);

                    const newPage = evt.target.value - 0;
                    if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) this.getDevices(newPage);
                  }}
                  value={page}
                  onBlur={onBlur}
                  onKeyPress={onKeyPress}
                />
              </div>
            )}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="tablet alternate" style={{ fontSize: "1.75em" }} />
                <p>{t("no_tablets").message || "No tablets"}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "leased",
                Header: t("leased").message || "Leased",
                maxWidth: 42,
                accessor: d => <Checkbox checked={d.is_leased} onChange={(_event, data) => this.handleLeaseCheck(d, data.checked)} />,
              },
              {
                id: "last_used",
                maxWidth: 100,
                Header: (
                  <span className="-cursor-pointer" onClick={this.handleSortByLastUsed}>
                    <Icon name="sort" />
                    {t("last_used").message || "Last used"}
                  </span>
                ),
                accessor: d => (d.last_used !== "0001-01-01T00:00:00Z" ? <span>{moment(d.last_used).format("DD-MM-YY")}</span> : ""),
              },
              {
                id: "current_user",
                Header: t("current_user").message || "Current user",
                accessor: d => d.current_user.first_name + " " + d.current_user.last_name,
              },
              {
                id: "dealer",
                headerClassName: "-overflow-inherit -border-right-none",
                Header: t("dealer").message || "Dealer",
                accessor: d => <span>{this.getDealerNameById(parseInt(d.current_user.dealer_id, 10))}</span>,
              },
              {
                id: "location",
                headerClassName: "-overflow-inherit -border-right-none",
                Header: t("location").message || "Location",
                accessor: d => (
                  <span>{this.getLocationNameByDealerIdAndLocationId(parseInt(d.current_user.dealer_id, 10), parseInt(d.current_user.dealer_location_id, 10))}</span>
                ),
              },
              {
                id: "brand",
                accessor: "brand",
                headerClassName: "-overflow-inherit -border-right-none",
                Header: t("brand").message || "Brand",
              },
              {
                Header: t("model").message || "Model",
                accessor: "model",
                headerClassName: "-overflow-inherit -border-right-none",
                resizable: false,
              },
              {
                id: "os",
                accessor: "os_version",
                minWidth: 50,
                headerClassName: "-overflow-inherit -border-right-none",
                Header: t("os_version").message || "OS Version",
              },
              {
                id: "free_space",
                Header: (
                  <span className="-cursor-pointer" onClick={this.handleSpaceSort}>
                    <Icon name="sort" />
                    {t("free_space").message || "Free space(mb)"}
                  </span>
                ),
                accessor: "free_space",
              },
              {
                id: "mac_address",
                Header: t("mac_address").message || "MAC Address",
                accessor: "mac_address",
              },
              {
                id: "email_address",
                Header: t("email_address").message || "Email Address",
                accessor: d => d.current_user.email,
              },
              {
                id: "action",
                Header: "",
                accessor: d => d.user_history && d.user_history.length > 0 && <Icon name="user" onClick={() => this.handleUserHistoryClick(d)} />,
              },
            ]}
          />
        </div>
      );
    }
  };

  renderDeviceUserHistory = history => {
    const { t } = this.props;
    return (
      <Modal open={this.state.isDeviceUserHistoryVisible} onClose={this.handleHideDeviceUserHistory} closeOnDimmerClick={false} size="small">
        <Modal.Content>
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={history}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={history.length}
            pageSize={history.length}
            noDataText={
              <div className="Table__no-results">
                <p>{t("no_records_found_in_user_history").message || "No records found in user history for this device."}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "name",
                Header: t("name").message || "Name",
                accessor: "full_name",
              },
              {
                id: "dealer",
                Header: t("dealer").message || "Dealer",
                accessor: "dealer_name",
              },
              {
                id: "location",
                Header: t("location").message || "Location",
                accessor: "location_name",
              },
              {
                id: "role",
                Header: t("role").message || "Role",
                accessor: "role_name",
              },
              {
                id: "used_until",
                Header: t("used_until").message || "Used Until",
                accessor: "used_until",
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            floated="right"
            labelPosition="right"
            icon="cancel"
            content={t("close").message || "Close"}
            style={{
              width: "10em",
              marginBottom: "10px",
            }}
            onClick={this.handleHideDeviceUserHistory}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { isLoadingExport } = this.state;

    return (
      <div className="Devices">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: this.props.t("start_searching_devices").message || "Start searching devices...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 300)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefreshData} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid.Column width={14}>
            <h1>{this.props.t("tablets").message || "Tablets"}</h1>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button color="green" basic floated="right" onClick={this.exportData} disabled={isLoadingExport} loading={isLoadingExport}>
              <span style={{ marginRight: "10px" }}>{this.props.t("export").message || "Export"}</span>
              <FontAwesomeIcon icon={faFileExport} />
            </Button>
          </Grid.Column>

          {this.renderFilters()}
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderTable()}</Grid.Column>
        </Grid>

        {this.state.isDeviceUserHistoryVisible && this.renderDeviceUserHistory(this.state.deviceUserHistory)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Devices));
