import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";

import { FileDropDown, visorify } from "./../../components";
import { ROLES } from "../Users/roles";

import CarTyres from "./CarTyres";
import TruckTyres from "./TruckTyres";
import CarDetailMenu from "./CarDetailMenu";
import CarDetailHeader from "./CarDetailHeader";
import CarSnoozed from "./CarSnoozed";
import CarPins from "./CarPins";
import CarDrivingTrend from "./CarDrivingTrend";
import AppointmentHistory from "./AppointmentHistory";
import CarNotes from "./CarNotes";
import { applyWebsocketUpdate, addCarAttachment, deleteCarAttachment, updateSelectedCar, CARS_ACTION_TYPES } from "./store";
import { sortTruckTires, getFilesButton } from "../../util/common";
import { TYRE_POSITION } from "../Appointments/common";
import IntegrationsService from "../../integrations/service";

import Service from "./service";

import "./CarDetail.css";

class CarDetail extends Component {
  constructor(props) {
    super(props);

    if (props.car.is_truck) this.truck_tyres = this.prepareTruckTires(props.car.truck_tyres);

    this.state = {
      car_widget: "",
      button_label: "S",
      car: this.props.car,
      appointmentHistory: [],
    };
  }

  prepareTruckTires = tires => {
    const truck_tyres = {};
    let spareTiresCount = 0;

    tires.forEach(tyre => {
      const placeholderTyre = { ...tyre };

      let tiresGroup;
      if (placeholderTyre.position === TYRE_POSITION.TRUCK_SPARE) {
        placeholderTyre.spareTiresCount = ++spareTiresCount;
        tiresGroup = `spare_${spareTiresCount}`;
      } else tiresGroup = placeholderTyre.axle;

      if (!truck_tyres[tiresGroup]) truck_tyres[tiresGroup] = [];

      truck_tyres[tiresGroup].push(placeholderTyre);
    });

    sortTruckTires(truck_tyres);

    return truck_tyres;
  };

  componentDidMount() {
    this.getCarWidget();

    const appointmentHistory = this.props.appointmentHistory.map(app => {
      let location_name = "";

      this.props.globalState.dealers.forEach(d => {
        if (d.locations)
          d.locations.forEach(l => {
            if (l.id === app.dealer_location_id) location_name = l.name;
          });
      });

      return { ...app, location_name };
    });

    this.setState({ appointmentHistory });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carsState.actionType !== this.props.carsState.actionType) {
      if (this.props.carsState.actionType === CARS_ACTION_TYPES.WEB_SOCKET_UPDATE) {
        this.props.applyWebsocketUpdate(this.props.carsState.webSocketUpdate);
      } else if (this.props.carsState.actionType === CARS_ACTION_TYPES.CAR_UPDATE_APPLIED) {
        this.setState({ car: this.props.carsState.selectedCar });
      }
    }
  }

  getCarWidget = () => {
    let { location, car } = this.props;

    if (car) {
      IntegrationsService.getCarWidget({ location_id: location.id, car_id: car.id })
        .then(result => {
          if (result?.data?.data?.RedirectURL) {
            this.setState({ car_widget: result.data.data.RedirectURL, button_label: result.data.data.ButtonLabel ? result.data.data.ButtonLabel : "S" });
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ car_widget: "", button_label: "S" });
        });
    }
  };

  handleOpenSWidget = () => {
    window.open(this.state.car_widget, "_blank");
  };

  handleAddFile = file => {
    const {
      car,
      globalState: { dealers },
    } = this.props;

    let fd = new FormData();

    const dealer = dealers.find(d => d.id === car.dealer_id);

    const date = moment(car.created_on).format("YYYY/MM/DD");
    const path = `${dealer?.id}_${dealer?.name}/${date}/${car.id}_${car.reg_nr}`;
    fd.append("file", file);
    fd.append("path", path);

    const attachment = {
      type: file.type,
      name: file.name,
    };

    Service.uploadAttachment(fd)
      .then(result => {
        if (!result?.data?.data) return;

        attachment.url = result.data.data.URL;

        Service.appendAttachment({
          id: this.state.car.id,
          attachment,
        })
          .then(() => {
            attachment.username = this.props.authState.user.first_name + " " + this.props.authState.user.last_name;
            attachment.created_on = new Date();
            this.props.addCarAttachment(attachment);
          })
          .catch(error => {
            console.error("Error saving the car attachment updates.", error);

            attachment.failed = true;
            this.props.addCarAttachment(attachment);
          });
      })
      .catch(error => {
        console.error("Error uploading the car attachment updates.", error);

        attachment.failed = true;
        this.props.addCarAttachment(attachment);
      });
  };

  handleRemoveFile = url => {
    Service.deleteAttachment(url)
      .then(() => this.props.deleteCarAttachment(url))
      .catch(error => {
        console.error("Error deleting attachment on car detail.", error);
      });
  };

  render() {
    const { car, appointmentHistory } = this.state;

    const { customer, visible, onHide, globalState, authState, isURLHandler, isFeatureEnabled, location, updateSelectedCar, t } = this.props;

    if (!car) {
      return null;
    }

    return (
      <span>
        <Modal className="CarDetailModal" size="fullscreen" dimmer="inverted" onClose={onHide} closeOnDimmerClick={false} open={visible}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={5} floated="right">
                {!isURLHandler && (
                  <Button
                    color="green"
                    size="large"
                    floated="right"
                    onClick={onHide}
                    style={{
                      fontSize: "1.05rem",
                    }}
                    className="-margin-left-10"
                  >
                    {t("close").message || "Close"}
                  </Button>
                )}

                {globalState.selectedLocation.service_box_visible_on_car ? (
                  <Button basic size="large" floated="right" className="-margin-left-10" style={{ fontSize: "1.05rem" }} onClick={this.handleOpenSWidget}>
                    {this.state.button_label}
                  </Button>
                ) : (
                  ""
                )}

                {isFeatureEnabled("File Attachment") && (
                  <div className="FileDropDownCarDetail">
                    <FileDropDown
                      resource="cars"
                      position="left"
                      addFileText={t("drag_here_or_browse").message || "Drag here or browse"}
                      noFilesText={t("no_files").message || "No files"}
                      files={this.state.car.attachments}
                      trigger={getFilesButton({
                        count: this.state.car.attachments ? this.state.car.attachments.filter(attachment => !attachment.failed).length : null,
                      })}
                      onFileAdded={this.handleAddFile}
                      deleteFile={this.handleRemoveFile}
                      showPreview
                      showCount={false}
                      showIcon={true}
                      multiple
                    />
                  </div>
                )}
              </Grid.Column>
              <Grid.Column width={16}>
                <CarDetailMenu />
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content scrolling id="carDetailModalScrollContainer">
            <Grid className="CarDetailModal__Content">
              <Grid.Column width={16} id="cardetailscrollsection-cardetails">
                <CarDetailHeader car={car} customer={customer} selectedLocation={location} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-dmsnotes">
                <CarNotes car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-snoozeditems">
                <CarSnoozed car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-pinitems">
                <CarPins car={car} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-appointment_history">
                <AppointmentHistory appointmentHistory={appointmentHistory} isManufacturer={authState.user.role_id === ROLES.MANUFACTURER} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-drivingtrend">
                <CarDrivingTrend appointmentHistory={appointmentHistory} car_lead_prediction={car.car_lead_prediction} />
              </Grid.Column>
              <Grid.Column width={16} id="cardetailscrollsection-tyres">
                {car.is_truck ? (
                  <TruckTyres truck_tyres={this.truck_tyres} selectedLocation={location} />
                ) : (
                  <CarTyres
                    car={car}
                    selectedLocation={location}
                    lastAppointment={appointmentHistory?.length && appointmentHistory[0]}
                    updateSelectedCar={updateSelectedCar}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global, carsState: state.cars };
};

const mapDispatchToProps = dispatch => {
  return {
    applyWebsocketUpdate: payload => dispatch(applyWebsocketUpdate(payload)),
    addCarAttachment: attachment => dispatch(addCarAttachment(attachment)),
    deleteCarAttachment: url => dispatch(deleteCarAttachment(url)),
    updateSelectedCar: update => dispatch(updateSelectedCar(update)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(visorify(CarDetail)));
