import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static exportSnoozed(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/export_snoozed", requestData, this.requestConfig());
  }

  static exportNotSnoozed(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/export_not_snoozed", requestData, this.requestConfig());
  }

  static exportCarLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/export_cars", requestData, this.requestConfig());
  }

  static getTags() {
    return axios.get(ENV.apiBaseB + "/tags/list", this.requestConfig());
  }

  static getChecklists(requestData) {
    return axios.post(ENV.apiBaseB + "/checklists/list", requestData, this.requestConfig());
  }

  static getSnoozedLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/snoozed", requestData, this.requestConfig());
  }

  static getNotSnoozedLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/not_snoozed", requestData, this.requestConfig());
  }

  static getCarLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/cars", requestData, this.requestConfig());
  }

  static getTiresLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/tires", requestData, this.requestConfig());
  }

  static exportTiresLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/export_tires", requestData, this.requestConfig());
  }

  static getBackOrderLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/backorder", requestData, this.requestConfig());
  }

  static exportBackOrderLeads(requestData) {
    return axios.post(ENV.apiBaseB + "/leads/export_backorder", requestData, this.requestConfig());
  }

  static getTiresWidths(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/list_widths_by_size", requestData, this.requestConfig());
  }

  static getTiresHeight(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/list_heights_by_size_and_width", requestData, this.requestConfig());
  }

  static getTiresSpeeds() {
    return axios.get(ENV.apiBaseB + "/tyres/list_speeds", this.requestConfig());
  }

  static getTiresSize() {
    return axios.get(ENV.apiBaseB + "/tyres/list_sizes", this.requestConfig());
  }

  static getTiresBrands() {
    return axios.get(ENV.apiBaseB + "/tyres/list_brands", this.requestConfig());
  }
}

export default Service;
