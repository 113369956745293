import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Icon, Grid, Dropdown, Search, Form, Modal, Checkbox } from "semantic-ui-react";
import { saveAs } from "file-saver";
import _ from "lodash";

import ENV from "../../util/env-config";
import TranslationsTable from "./TranslationsTable";
import { UserMenuActionsPortal, SubHeader, SearchPortal, UserInput } from "../../components";
import Can from "../Can";
import { setAlert } from "../App/store";
import Service from "./service";

const disabled = ENV.envName === "prod";

class Translations extends Component {
  state = {
    isLoadingTranslations: false,
    isLoadingLangages: false,
    allTranslations: [],
    translationsToRender: [],
    allLanguages: [],
    languagesOptions: [],
    selectedLanguage: "en-GB",
    selectedTranslationKey: false,
    isAddingNewTranslation: false,
    currentTranslations: [],
    searchTerm: "",
    selectedMissingTranslations: false,
  };

  componentDidMount() {
    this.getAllTranslations();
    this.getAllLanguages();
  }

  getAllTranslations = () => {
    this.setState({ isLoadingTranslations: true }, async () => {
      try {
        const result = await Service.getAllTranslations();
        const translationsToRender = this.getTranslationsToRender(this.state.selectedMissingTranslations, result.data.data.translations);

        this.setState({ allTranslations: result.data.data.translations, isLoadingTranslations: false, translationsToRender });
      } catch (err) {
        console.error(err);
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        this.setState({ isLoadingTranslations: false });
      }
    });
  };

  getAllLanguages = () => {
    this.setState({ isLoadingLanguages: true }, async () => {
      try {
        const result = await Service.getAllLanguages();
        const languagesOptions = this.languageDropdownOptions(result.data.data.languages);

        this.setState({ allLanguages: result.data.data.languages, isLoadingLanguages: false, languagesOptions });
      } catch (err) {
        console.error(err);
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        this.setState({ isLoadingLanguages: false });
      }
    });
  };

  handleDeleteTranslation = async translationKey => {
    try {
      await Service.deleteTranslation({ key: translationKey });
      const allTranslations = this.state.allTranslations.filter(translation => translation.key !== translationKey);
      const translationsToRender = this.state.translationsToRender.filter(translation => translation.key !== translationKey);

      this.setState({ allTranslations, translationsToRender });
    } catch (err) {
      console.error(err);
      this.props.setAlert({
        type: "error",
        title: err.message,
      });
    }
  };

  handleOpenModal = selectedTranslationKey => {
    let { isAddingNewTranslation, allLanguages, allTranslations, currentTranslations } = this.state;

    if (!selectedTranslationKey) {
      currentTranslations = allLanguages.map(language => ({ key: "", value: "", language: language.language_code }));
      isAddingNewTranslation = true;
    } else if (selectedTranslationKey) {
      const selectedTranslation = allTranslations.filter(t => t.key === selectedTranslationKey);

      currentTranslations = allLanguages.map(language => ({
        key: selectedTranslationKey,
        value: selectedTranslation.find(t => t.language === language.language_code)?.value,
        language: language.language_code,
      }));
    }

    this.setState({ isAddingNewTranslation, selectedTranslationKey, currentTranslations });
  };

  handleCloseAddNewModal = () => {
    this.setState({ isAddingNewTranslation: false });
  };

  handleLanguageChange = (e, data) => {
    const { selectedMissingTranslations, allTranslations } = this.state;

    const translationsToRender = this.getTranslationsToRender(selectedMissingTranslations, allTranslations, data.value);
    this.setState({ selectedLanguage: data.value, translationsToRender });
  };

  handleRefresh = () => {
    this.getAllTranslations();
    this.getAllLanguages();
  };

  handleTranslationsSearch = (evt, data) => {
    this.setState({ searchTerm: data.value.toLowerCase() }, () => {
      const newTranslationsToRender = this.state.allTranslations.filter(
        translation =>
          translation.language === this.state.selectedLanguage &&
          (translation.value.toLowerCase().includes(this.state.searchTerm) || translation.key.toLowerCase().includes(this.state.searchTerm))
      );
      this.setState({ translationsToRender: newTranslationsToRender });
    });
  };

  renderTranslationsTable = () => {
    const { isLoadingTranslations, isLoadingLanguages, translationsToRender } = this.state;
    const { t } = this.props;

    if (isLoadingTranslations || isLoadingLanguages) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading").message || "Loading"}</section>
        </div>
      );
    }

    return <TranslationsTable disabled={disabled} items={translationsToRender} handleEditClick={this.handleOpenModal} handleDeleteClick={this.handleDeleteTranslation} />;
  };

  handleUpdateTranslation = async () => {
    let { currentTranslations, translationsToRender, allTranslations } = this.state;

    try {
      await Service.updateTranslation({ translations: currentTranslations });

      translationsToRender = translationsToRender.map(translation => {
        if (currentTranslations[0].key === translation.key) {
          const currentLanguageTranslation = currentTranslations.find(t => t.language === translation.language);
          if (currentLanguageTranslation) translation.value = currentLanguageTranslation.value;
        }
        return translation;
      });

      allTranslations = allTranslations.map(translation => {
        if (currentTranslations[0].key === translation.key) {
          const currentLanguageTranslation = currentTranslations.find(t => t.language === translation.language);
          if (currentLanguageTranslation) translation.value = currentLanguageTranslation.value;
        }
        return translation;
      });

      this.setState({ allTranslations, translationsToRender, selectedTranslationKey: null });
    } catch (err) {
      console.error(err);
      this.props.setAlert({
        type: "error",
        title: err.message,
      });
    }
  };

  handleSaveNewTranslation = async () => {
    let { currentTranslations, selectedLanguage, allTranslations, translationsToRender } = this.state;

    try {
      await Service.createTranslation({ translations: currentTranslations });
      const currentLanguageToRender = currentTranslations.find(t => t.language === selectedLanguage);
      allTranslations = [...currentTranslations, ...allTranslations];

      if (currentLanguageToRender) translationsToRender = [currentLanguageToRender, ...translationsToRender];

      this.setState({ allTranslations, translationsToRender, isAddingNewTranslation: false });
    } catch (err) {
      console.error(err);
      this.props.setAlert({
        type: "error",
        title: err.message,
      });
    }
  };

  handleLanguageLabelText = languageCode => {
    switch (languageCode) {
      case "fr-FR":
        return "French";
      case "nl-NL":
        return "Dutch";
      case "de-DE":
        return "German";
      case "en-GB":
        return "English";
      case "th-FR":
        return "TH French";
      default:
        return languageCode;
    }
  };

  handleChangeTranslationKey = e => {
    const currentTranslations = this.state.currentTranslations.map(translation => ({ ...translation, key: e.target.value }));
    this.setState({ currentTranslations });
  };

  handleChangeTranslationValue = e => {
    const currentTranslations = this.state.currentTranslations.map(translation => {
      if (translation.language === e.target.name) translation.value = e.target.value;
      return translation;
    });
    this.setState({ currentTranslations });
  };

  renderModal = () => {
    const { selectedTranslationKey, currentTranslations, isAddingNewTranslation } = this.state;
    const { t } = this.props;

    return (
      <Modal open onClose={this.handleCloseAddNewModal} closeOnDimmerClick={false} size="mini">
        <Modal.Content>
          <h3>{isAddingNewTranslation ? t("add_translation").message || "Add Translation" : t("edit_translation").message || "Edit Translation"}</h3>
          <Form>
            <Form.Field>
              <label>{t("translation_key").message || "Translation key"}</label>
              <UserInput value={selectedTranslationKey} name="key" onChange={this.handleChangeTranslationKey} disabled={!!selectedTranslationKey || disabled} />
            </Form.Field>
            {currentTranslations.map((translation, index) => (
              <Form.Field key={index}>
                <label>{this.handleLanguageLabelText(translation.language)}</label>
                <UserInput disabled={disabled} value={translation.value} name={translation.language} onChange={this.handleChangeTranslationValue} />
              </Form.Field>
            ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            floated="left"
            onClick={() => {
              this.handleCloseAddNewModal();
              this.setState({ selectedTranslationKey: null });
            }}
          >
            <Icon name="cancel" /> {t("cancel").message || "Cancel"}
          </Button>
          <Button
            disabled={disabled}
            color="green"
            onClick={() => {
              if (isAddingNewTranslation) {
                this.handleSaveNewTranslation();
                return;
              }
              this.handleUpdateTranslation();
            }}
          >
            <Icon name="checkmark" /> {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  languageDropdownOptions = languages => {
    const languageOptions = languages.map(lang => {
      const text = this.handleLanguageLabelText(lang.language_code);

      return { text, value: lang.language_code, key: lang.language_code };
    });
    return languageOptions;
  };

  generateMigration = async () => {
    try {
      const res = await Service.generateMigration();
      saveAs(new File([res.data], { type: "text/plain;charset=utf-8" }), "migration.sql");
    } catch (err) {
      console.error(err);
      this.props.setAlert({
        type: "error",
        title: err.message,
      });
    }
  };

  handleMissingTranslationsToggleChange = (e, data) => {
    const { allTranslations } = this.state;
    const translationsToRender = this.getTranslationsToRender(data.checked, allTranslations);

    this.setState({ selectedMissingTranslations: data.checked, translationsToRender });
  };

  getTranslationsToRender = (selectedMissingTranslations, allTranslations, selectedLanguage) => {
    if (!selectedLanguage) selectedLanguage = this.state.selectedLanguage;

    if (selectedMissingTranslations) return allTranslations.filter(t => t.value === "" && t.language === selectedLanguage);
    return allTranslations.filter(translation => translation.language === selectedLanguage);
  };

  render() {
    const { selectedTranslationKey, isAddingNewTranslation, selectedLanguage, languagesOptions, selectedMissingTranslations } = this.state;
    const { t } = this.props;

    return (
      <div className="Translations">
        <SearchPortal>
          <Search
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_translations").message || "Start searching translations...",
            }}
            onSearchChange={_.debounce(this.handleTranslationsSearch, 500)}
            loading={false}
            open={false}
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid className="SubHeader_content_filters -contained-large">
            <Grid.Column width={4} className="SubHeader_content_title_container">
              <h1 className="SubHeader_content_title">{t("translations").message || "Translations"}</h1>
            </Grid.Column>

            <Grid.Column width={4}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Dropdown selection options={languagesOptions} value={selectedLanguage} onChange={this.handleLanguageChange} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width={2}>
              <Form.Field className="translations-toggle">
                <label>{t("missing_translations").message || "Missing translations"}</label>
                <Checkbox checked={selectedMissingTranslations} toggle onChange={this.handleMissingTranslationsToggleChange} />
              </Form.Field>
            </Grid.Column>

            <Grid.Column width={5}>
              <Can I="generatemigration" the="translations">
                <Button color="green" floated="right" onClick={this.generateMigration}>
                  {t("download_migration_file").message || "Download migration file"}
                </Button>
              </Can>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button color="green" floated="right" onClick={() => this.handleOpenModal()}>
                {t("new").message || "New"}
              </Button>
            </Grid.Column>
          </Grid>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderTranslationsTable()}</Grid.Column>
        </Grid>
        {(isAddingNewTranslation || selectedTranslationKey) && this.renderModal()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(Translations));
