import React, { Component } from "react";
import { Label, Form, Dropdown, Button, Icon, Modal, Checkbox, Segment, Grid, Header } from "semantic-ui-react";

import { COUNTRIES } from "../../util/common";
import { Can } from "../../modules";
import UserInput from "../UserInput";
import Versions from "./Versions";

class LocationCreate extends Component {
  state = {
    isSubmit: false,
    location: {},
  };

  handleInputChange = e => {
    let { location } = this.state;
    location[e.target.name] = e.target.value;
    this.setState({ location });
  };

  handleSelectChange = (e, data) => {
    let { location } = this.state;
    location[data.name] = data.value;
    this.setState({ location });
  };

  handleCheckboxChange = (e, data) => {
    let { location } = this.state;
    location[data.name] = data.checked;
    this.setState({ location });
  };

  handleFileChange = event => {
    let { location } = this.state;
    if (!event || !event.target || !event.target.files) {
      return;
    }
    location.photo = event.target.files[0];
    this.setState({ location });
  };

  handleOnChange = location => {
    this.setState({ location });
  };

  handleSave = () => {
    this.setState({ isSubmit: true }, () => {
      this.props.onSave(this.state.location);
    });
  };

  render() {
    const { t, languages, loading, versions } = this.props;
    let { location, isSubmit } = this.state;

    return (
      <Modal open={true} closeOnDimmerClick={false} size="small">
        <Modal.Header>
          <div>
            {t("create_location").message || "Create location"}
            <Checkbox name="active" label={t("active").message || "Active"} className="-pull-right" onChange={this.handleCheckboxChange} />
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid columns="equal">
            <Grid.Column>
              <Segment placeholder>
                <Header as="h5">
                  <Icon name="warehouse" />
                  <Header.Content>{t("location_settings").message || "Location algemeen"}</Header.Content>
                </Header>
                <Segment>
                  <Form>
                    <Form.Field className="full-width-input" required>
                      <label>{t("version").message || "Version"}</label>
                      <Versions type="basic" isSubmit={isSubmit} versions={versions} location={location} handleChange={this.handleOnChange} t={t} {...this.props} />
                      {isSubmit && !location.version_id && (
                        <Label basic color="red" pointing>
                          {t("field_is_required").message || "This field is required."}
                        </Label>
                      )}
                    </Form.Field>
                    <Form.Field className="full-width-input" required>
                      <label>{t("location_name").message || "Location name"}</label>
                      <UserInput name="name" placeholder={t("location_name").message || "Location name"} onChange={this.handleInputChange} />
                      {isSubmit && !location.name && (
                        <Label basic color="red" pointing>
                          {t("field_is_required").message || "This field is required."}
                        </Label>
                      )}
                    </Form.Field>
                    <Form.Field className="full-width-input" required>
                      <label>{t("commercial_name").message || "Commercial name"}</label>
                      <UserInput name="commercial_name" placeholder={t("commercial_name").message || "Commercial name"} onChange={this.handleInputChange} />
                      {isSubmit && !location.commercial_name && (
                        <Label basic color="red" pointing>
                          {t("field_is_required").message || "This field is required."}
                        </Label>
                      )}
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("location_header").message || "Location header"}</label>
                      <UserInput placeholder={t("location_header").message || "Location header"} name="headline" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("location_footerline").message || "Location footer"}</label>
                      <UserInput placeholder={t("location_footerline").message || "Location footer"} name="footerline" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input" required>
                      <label>{t("country").message || "Country"}</label>
                      <Dropdown
                        placeholder={t("country").message || "Country"}
                        options={
                          COUNTRIES
                            ? COUNTRIES.map((a, key) => {
                                return { text: a.text, value: a.value, key };
                              })
                            : []
                        }
                        search
                        selection
                        name="country"
                        onChange={this.handleSelectChange}
                      />
                      {isSubmit && !location.country && (
                        <Label basic color="red" pointing>
                          {t("field_is_required").message || "This field is required."}
                        </Label>
                      )}
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("city").message || "City"}</label>
                      <UserInput placeholder={t("city").message || "City"} name="city" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("street").message || "Street"}</label>
                      <UserInput placeholder={t("street").message || "Street"} name="street" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("postal_code").message || "Postal code"}</label>
                      <UserInput placeholder={t("postal_code").message || "Postal code"} name="postal_code" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input">
                      <label>{t("phone").message || "Phone"}</label>
                      <UserInput name="phone_nr" placeholder={t("phone").message || "Phone"} onChange={this.handleInputChange} />
                    </Form.Field>
                    <Form.Field className="full-width-input" required>
                      <label>{t("language").message || "Language"}</label>
                      <Dropdown
                        placeholder={t("language").message || "Language"}
                        options={
                          languages
                            ? languages.languages.map((a, key) => {
                                return {
                                  text: a.language_code,
                                  value: a.language_code,
                                  key,
                                };
                              })
                            : []
                        }
                        selection
                        search
                        name="language_code"
                        onChange={this.handleSelectChange}
                      />
                      {isSubmit && !location.language_code && (
                        <Label basic color="red" pointing>
                          {t("field_is_required").message || "This field is required."}
                        </Label>
                      )}
                    </Form.Field>
                    <Can I="upload_logo" the="files">
                      <Form.Field className="full-width-input">
                        <label>Upload Logo (.jpg, .jpeg, .png)</label>
                        <input id="newLocationLogo" className="locationLogoUploader" type="file" onChange={this.handleFileChange} />
                      </Form.Field>
                    </Can>
                  </Form>
                </Segment>
              </Segment>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            loading={loading}
            disabled={loading}
            onClick={() => {
              this.props.onHide();
            }}
            floated="left"
          >
            <Icon name="close" /> {this.props.t("discard").message || "DISCARD"}
          </Button>
          <Button color="green" onClick={this.handleSave} loading={loading} disabled={loading}>
            <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default LocationCreate;
