import React, { Component } from "react";
import { connect } from "react-redux";

export default function visorify(WrappedComponent, data) {
  class V extends Component {
    isFeatureEnabled = name => {
      if (!name) {
        return true;
      }

      const GS = this.props.globalState;
      const VERSION = GS && GS.selectedLocation && GS.selectedLocation.version ? GS.selectedLocation.version.features : [];

      let vI = VERSION.findIndex(v => v.name === name);

      return vI > -1 && VERSION[vI].active;
    };

    renderOutput() {
      return <WrappedComponent productVersion={this.props.globalState.selectedLocation.version} isFeatureEnabled={this.isFeatureEnabled} {...this.props} />;
    }

    render() {
      return this.renderOutput();
    }
  }

  const mapStateToProps = state => {
    return { globalState: state.global };
  };

  return connect(mapStateToProps)(V);
}
