import React, { Component } from "react";
import moment from "moment";
import AceEditor from "react-ace";
import AlertContainer from "react-alert";
import { saveAs } from "file-saver";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Form, Grid, Icon, Label, Modal, TextArea } from "semantic-ui-react";

import { getWeekDayName } from "../../util/common";
import { CustomConfirm, UserInput } from "../../components";
import DealerLocationsDropdown from "../../components/DealerLocationsDropdown";
import { MODE, FREQUENCY, QUERY_RANGE, STATUS } from "./enum";

import Service from "./service";

import "./index.scss";

const defaultExport = {
  name: "",
  factory_export_id: null,
  factory_report_id: null,
  emails: "",
  frequency: null,
  query_range: null,
  dealer_ids: [],
  dealer_location_ids: [],
  email_subject: "Claire report {{.ReportName}} from {{.StartDate}} to {{.EndDate}}",
  email_message: "Attached is the report {{.ReportName}} for the period {{.StartDate}}  - {{.EndDate}}",
};

class FactoryExports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actionLoading: false,
      reportList: [],
      listExports: [],
      exportLog: [],
      mode: MODE.CREATE,
      currentExport: { ...defaultExport },
      frequencyOptions: this.getFrequencyOptions(),
      rangeOptions: this.getRangeOptions(),
      monthDayOptions: this.getSentDateOptions(FREQUENCY.EVERY_MONTH),
      weekDayOptions: this.getSentDateOptions(FREQUENCY.WEEK),
      modalVisible: false,
      logVisible: false,
      showDeleteConfirmation: false,
      showValidationError: false,
      showEmailEditor: false,
      exportURL: "",
      exportName: "",
    };
  }

  componentDidMount() {
    this.getExports();
    this.getReports();
  }

  getExports = () => {
    this.setState({ loading: true }, async () => {
      try {
        const response = await Service.listExports();

        this.setState({ listExports: response?.data?.data?.exports || [] });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

        this.msg.show(errorMsg, { type: "error" });
      }
    });
  };

  getReports = async () => {
    try {
      const response = await Service.listReports();

      this.setState({ reportList: response?.data?.data?.reports || [], loading: false });
    } catch (error) {
      const errorMsg =
        typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

      this.msg.show(errorMsg, { type: "error" });
      this.setState({ loading: false });
    }
  };

  getFrequencyOptions = () => Object.values(FREQUENCY).map(f => this.getFrequency(f));

  getFrequency = frequency => {
    const { t } = this.props;

    switch (frequency) {
      case FREQUENCY.WEEK:
        return { text: t("weekly").message || "Weekly", value: frequency, name: "frequency" };
      case FREQUENCY.TWO_WEEKS:
        return { text: t("every_two_weeks").message || "Every two weeks", value: frequency, name: "frequency" };
      case FREQUENCY.EVERY_MONTH:
        return { text: t("monthly").message || "Monthly", value: frequency, name: "frequency" };
      case FREQUENCY.EVERY_DAY:
        return { text: t("every_day").message || "Every day", value: frequency, name: "frequency" };
      default:
        return "";
    }
  };

  getStatus = status => {
    const { t } = this.props;

    switch (status) {
      case STATUS.NOT_SENT:
        return t("not_sent").message || "Not sent";
      case STATUS.PENDING:
        return t("pending").message || "Pending";
      case STATUS.SUCCESS:
        return t("success").message || "Success";
      case STATUS.FAILED:
        return t("failed").message || "Failed";
      default:
        return "";
    }
  };

  getSentDateOptions = frequency => {
    const sentDateOptions = [];

    if (frequency === FREQUENCY.EVERY_MONTH) {
      for (let i = 1; i <= 28; i++) {
        sentDateOptions.push({ text: i, value: i });
      }
    } else if ([FREQUENCY.WEEK, FREQUENCY.TWO_WEEKS].includes(frequency)) {
      for (let i = 1; i <= 7; i++) {
        sentDateOptions.push({ text: getWeekDayName(i), value: i });
      }
    }

    return sentDateOptions;
  };

  getRangeOptions = () => Object.values(QUERY_RANGE).map(r => ({ text: this.getRangeValue(r), value: r }));

  getRangeValue = queryRange => {
    const { t } = this.props;

    switch (queryRange) {
      case QUERY_RANGE.ONE_DAY:
        return t("one_day").message || "One day";
      case QUERY_RANGE.ONE_WEEK:
        return t("one_week").message || "One week";
      case QUERY_RANGE.TWO_WEEKS:
        return t("two_weeks").message || "Two weeks";
      case QUERY_RANGE.ONE_MONTH:
        return t("one_month").message || "One month";
      case QUERY_RANGE.TWO_MONTHS:
        return t("two_months").message || "Two months";
      case QUERY_RANGE.THREE_MONTHS:
        return t("three_months").message || "Three months";
      default:
        return "";
    }
  };

  handleValidateExport = currentExport => {
    const requiredFields = ["factory_report_id", "emails", "frequency", "query_range"];

    if (!currentExport.dealer_ids?.length && !currentExport.dealer_location_ids?.length) return false;
    if (currentExport.frequency !== FREQUENCY.EVERY_DAY) requiredFields.push("day");
    return requiredFields.every(field => currentExport[field]);
  };

  handleToggleModal = data => {
    this.setState(
      state => ({
        mode: !data ? MODE.CREATE : MODE.EDIT,
        modalVisible: !state.modalVisible,
        currentExport: data || { ...defaultExport },
        showEmailEditor: false,
      }),
      () => {
        if (this.state.modalVisible) this.renderHTML();
      }
    );
  };

  handleChange = (_e, { name, value }) => {
    this.setState(state => ({ currentExport: { ...state.currentExport, [name]: value }, showValidationError: false }));
  };

  handleChangeFrequency = (_e, { value }) => {
    this.setState(state => ({
      currentExport: { ...state.currentExport, frequency: value, day: value === FREQUENCY.EVERY_DAY ? 1 : state.currentExport.day },
      showValidationError: false,
    }));
  };

  handleChangeEmailMessage = email_message => {
    this.setState(state => ({ currentExport: { ...state.currentExport, email_message } }));
  };

  handleChangeDealerLocations = ({ dealer_ids, location_ids }) => {
    this.setState(state => ({ currentExport: { ...state.currentExport, dealer_ids, dealer_location_ids: location_ids }, showValidationError: false }));
  };

  handleDeleteExport = () => {
    this.setState({ actionLoading: true }, async () => {
      try {
        await Service.deleteExport(this.state.currentExport.factory_export_id);

        this.setState({
          actionLoading: false,
          showDeleteConfirmation: false,
          modalVisible: false,
          listExports: this.state.listExports.filter(exp => exp.factory_export_id !== this.state.currentExport.factory_export_id),
        });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

        this.exportModalMsg.show(errorMsg, { type: "error" });
        this.setState({ actionLoading: false });
      }
    });
  };

  handleExportData = async data => {
    try {
      const response = await Service.exportData(data.factory_export_id);
      if (response?.data?.data?.url) {
        this.setState({
          exportURL: response.data.data.url,
          exportName: `Claire_${data.name}.ods`,
        });
      }
    } catch (error) {
      const errorMsg =
        typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

      this.msg.show(errorMsg, { type: "error" });
    }
  };

  handleDownloadCSVFile = () => {
    const { exportURL, exportName } = this.state;

    saveAs(exportURL, exportName);
    this.handleCloseExportCSVModal();
  };

  handleCloseExportCSVModal = () => {
    this.setState({
      exportURL: "",
      exportName: "",
    });
  };

  renderExportCSVModal = () => {
    const { t } = this.props;
    const { exportURL, isCSVLoadingExport } = this.state;

    return (
      <Modal open={!!exportURL} size="mini">
        <Modal.Content>
          <div style={{ textAlign: "center" }}>{t("export_ready").message || "Export ready to be downloaded"}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="grey" onClick={this.handleCloseExportCSVModal} disabled={isCSVLoadingExport}>
            {t("close").message || "Close"}
          </Button>
          <Button onClick={this.handleDownloadCSVFile} color="green">
            {t("download").message || "Download"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleShowLog = factory_export_id => {
    this.setState({ logVisible: true }, async () => {
      try {
        const response = await Service.listReceivers(factory_export_id);

        this.setState({ exportLog: response?.data?.data?.receivers || [] });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

        this.msg.show(errorMsg, { type: "error" });
      }
    });
  };

  handleCloseLog = () => this.setState({ logVisible: false });

  handleSaveExport = () => {
    const { currentExport, reportList } = this.state;

    if (!this.handleValidateExport(currentExport)) {
      this.setState({ showValidationError: true });
      return;
    }

    this.setState({ actionLoading: true, showValidationError: false }, async () => {
      try {
        const formattedEmails = currentExport.emails
          .split(",")
          .map(email => (email = email.trim()))
          .join(",");

        if (this.state.mode === MODE.CREATE) {
          const response = await Service.addExport({ ...currentExport, emails: formattedEmails });
          const additionalData = {
            factory_export_id: response.data.data.factory_export_id,
            factory_report_name: reportList.find(report => report.factory_report_id === currentExport.factory_report_id)?.name,
          };

          this.setState({
            actionLoading: false,
            listExports: this.state.listExports.concat({ ...currentExport, ...additionalData, emails: formattedEmails }),
            modalVisible: false,
          });
        } else {
          await Service.updateExport({ ...currentExport, emails: formattedEmails });
          this.setState({
            actionLoading: false,
            listExports: this.state.listExports.map(exp => {
              if (exp.factory_export_id === currentExport.factory_export_id) return currentExport;
              else return exp;
            }),
            modalVisible: false,
          });
        }
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to get list of exports";

        this.exportModalMsg.show(errorMsg, { type: "error" });
        this.setState({ actionLoading: false });
      }
    });
  };

  toggleDeleteConfirmation = factory_export_id => {
    this.setState(state => ({
      currentExport: { ...state.currentExport, factory_export_id: factory_export_id || state.currentExport.factory_export_id },
      showDeleteConfirmation: !state.showDeleteConfirmation,
    }));
  };

  toggleEmailEditor = () => {
    this.setState(
      state => ({ showEmailEditor: !state.showEmailEditor }),
      () => {
        if (!this.state.showEmailEditor) this.renderHTML();
      }
    );
  };

  handleRevertEmailToDefault = () => {
    this.setState(
      state => ({ currentExport: { ...state.currentExport, email_subject: defaultExport.email_subject, email_message: defaultExport.email_message } }),
      () => {
        if (!this.state.showEmailEditor) this.renderHTML();
      }
    );
  };

  renderEmailEditor = () => {
    const { t } = this.props;
    const { currentExport } = this.state;

    return (
      <Form.Field width={16}>
        <label>{t("email_message").message || "Email message"}</label>
        <AceEditor
          mode="html"
          theme="monokai"
          name="html"
          width="100%"
          value={currentExport.email_message}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={false}
          onChange={this.handleChangeEmailMessage}
          minLines={2}
          height="200px"
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </Form.Field>
    );
  };

  renderEmailDesign = () => {
    return (
      <Form.Field width={16}>
        <label>{this.props.t("email_preview").message || "Email preview"}</label>
        <iframe title="medium" id="email_design" width="100%" scrolling="no" style={{ border: "none" }}></iframe>
      </Form.Field>
    );
  };

  renderHTML = () => {
    const divElement = document.getElementById("email_design");

    if (divElement) {
      divElement.contentWindow.document.open();
      divElement.contentWindow.document.write(this.state.currentExport.email_message);
      divElement.contentWindow.document.close();
      divElement.style.height = divElement.contentWindow.document.body.scrollHeight + "px";
    }
  };

  renderDeleteConfirmation = () => {
    const { showDeleteConfirmation, actionLoading } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={this.handleDeleteExport}
        handleCancel={() => this.toggleDeleteConfirmation()}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={actionLoading}
      />
    );
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderExportModalAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return (
      <div className="react-alert-container">
        <AlertContainer ref={a => (this.exportModalMsg = a)} {...props} />
      </div>
    );
  };

  renderLog = () => {
    const { exportLog, logVisible } = this.state;
    const { t } = this.props;

    return (
      <Modal size="small" dimmer={true} closeOnDimmerClick={false} open={logVisible} onClose={() => this.handleCloseLog()}>
        <Modal.Header>{t("log").message || "Log"}</Modal.Header>
        <Modal.Content scrolling>
          <ReactTable
            className="ReactTable -highlight"
            data={exportLog}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            minRows={0}
            pageSize={exportLog.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="sitemap" style={{ fontSize: "1.75em", marginTop: "250px" }} />
                <p>{t("no_log_data").message || "No log data"}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "date",
                Header: t("date").message || "Date",
                accessor: d => moment(d.created_on).format("DD-MM-YYYY"),
              },
              {
                id: "status",
                Header: t("status").message || "Status",
                accessor: d => this.getStatus(d.status),
              },
              {
                id: "email",
                Header: t("email").message || "email",
                accessor: "email",
              },
              {
                id: "reason",
                Header: t("reason").message || "Reason",
                accessor: "reason",
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.handleCloseLog()}>{t("cancel").message || "Cancel"}</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderExportModal = () => {
    const { t } = this.props;
    const {
      mode,
      actionLoading,
      modalVisible,
      currentExport,
      reportList,
      rangeOptions,
      frequencyOptions,
      monthDayOptions,
      weekDayOptions,
      showValidationError,
      showEmailEditor,
    } = this.state;

    return (
      <Modal size="small" dimmer={true} closeOnDimmerClick={false} open={modalVisible} onClose={() => this.handleToggleModal()}>
        <Modal.Header>{t("add_export").message || "Add export"}</Modal.Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("export_name").message || "Export name"}*</label>
                <UserInput name="name" value={currentExport.name} onChange={this.handleChange} />

                {!currentExport.name && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("choose_report").message || "Choose report"}*</label>
                <Dropdown
                  search
                  selection
                  clearable
                  disabled={mode === MODE.EDIT}
                  options={reportList.map(r => ({ text: r.name, value: r.factory_report_id }))}
                  selectOnBlur={false}
                  value={currentExport.factory_report_id}
                  onChange={this.handleChange}
                  name="factory_report_id"
                />
                {!currentExport.factory_report_id && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>

            <Form.Field width={16}>
              <label>{t("recipients_emails").message || "Recipient's emails"}*</label>
              <TextArea name="emails" value={currentExport.emails} onChange={this.handleChange} rows={1} />
              {!currentExport.emails && showValidationError && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>

            <Form.Group>
              <Form.Field width={8}>
                <label>{t("frequency").message || "Frequency"}*</label>
                <Dropdown
                  search
                  selection
                  clearable
                  options={frequencyOptions}
                  selectOnBlur={false}
                  value={currentExport.frequency}
                  onChange={this.handleChangeFrequency}
                  name="frequency"
                />
                {!currentExport.frequency && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              {currentExport.frequency && currentExport.frequency !== FREQUENCY.EVERY_DAY && (
                <Form.Field width={8}>
                  <label>
                    {currentExport.frequency === FREQUENCY.EVERY_MONTH
                      ? t("select_day_of_the_month").message || "Select a day of the month"
                      : t("select_day_of_the_week").message || "Select a day of the week"}
                    *
                  </label>
                  <Dropdown
                    search
                    selection
                    clearable
                    options={currentExport.frequency === FREQUENCY.EVERY_MONTH ? monthDayOptions : weekDayOptions}
                    selectOnBlur={false}
                    value={currentExport.day}
                    onChange={this.handleChange}
                    name="day"
                  />
                  {!currentExport.day && showValidationError && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
              )}
            </Form.Group>

            <Form.Field width={16}>
              <label>{t("query_range").message || "Query range"}*</label>
              <Dropdown
                search
                selection
                clearable
                selectOnBlur={false}
                options={rangeOptions}
                onChange={this.handleChange}
                value={currentExport.query_range}
                name="query_range"
              />
              {!currentExport.query_range && showValidationError && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>

            <Form.Field width={16}>
              <label>{t("dealer_location_selection").message || "Dealer/Location"}*</label>
              <DealerLocationsDropdown
                options={{ dealer_ids: currentExport.dealer_ids, location_ids: currentExport.dealer_location_ids }}
                selectAll={false}
                selectDealers
                onChange={this.handleChangeDealerLocations}
              />
              {!currentExport.dealer_ids?.length && !currentExport.dealer_location_ids?.length && showValidationError && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>

            <Form.Field width={16}>
              <label>{t("email_subject").message || "Email subject"}</label>
              <UserInput value={currentExport.email_subject} onChange={this.handleChange} name="email_subject" />
            </Form.Field>

            {showEmailEditor ? this.renderEmailEditor() : this.renderEmailDesign()}

            <Form.Field width={16} className="EmailEditorButtons">
              <Button primary onClick={this.toggleEmailEditor}>
                {showEmailEditor ? t("show_design").message || "Show design" : t("show_editor").message || "Show editor"}
              </Button>
              <Button color="yellow" onClick={this.handleRevertEmailToDefault}>
                {t("revert_to_default").message || "Revert to default"}
              </Button>
            </Form.Field>

            {this.renderExportModalAlert()}
          </Form>
        </Modal.Content>

        <Modal.Actions>
          {mode === MODE.EDIT && (
            <Button color="red" disabled={actionLoading} onClick={() => this.toggleDeleteConfirmation()} floated="left">
              {t("delete").message || "Delete"}
            </Button>
          )}

          <Button onClick={() => this.handleToggleModal()}>{t("cancel").message || "Cancel"}</Button>
          <Button disabled={actionLoading} loading={actionLoading} onClick={this.handleSaveExport} color="green">
            {t("save").message || "Save"}
          </Button>
        </Modal.Actions>

        {this.state.showDeleteConfirmation && this.renderDeleteConfirmation()}
      </Modal>
    );
  };

  renderFrequency = frequency => {
    const { t } = this.props;

    switch (frequency) {
      case FREQUENCY.WEEK:
        return t("weekly").message || "Weekly";
      case FREQUENCY.TWO_WEEKS:
        return t("every_two_weeks").message || "Every Two Weeks";
      case FREQUENCY.EVERY_MONTH:
        return t("monthly").message || "Monthly";
      case FREQUENCY.EVERY_DAY:
        return t("every_day").message || "Every day";
      default:
        return "";
    }
  };

  renderDay = (day, frequency) => {
    if (frequency === FREQUENCY.EVERY_DAY) return null;

    return [FREQUENCY.WEEK, FREQUENCY.TWO_WEEKS].includes(frequency) ? getWeekDayName(day) : day;
  };

  renderExportTable = () => {
    const { t } = this.props;

    if (this.state.loading) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
          <section>{this.props.t("loading_exports").message || "Loading exports"}</section>
        </div>
      );
    }

    return (
      <div className="FactoryExports-table">
        <ReactTable
          className="ReactTable -factory-exports-table -floated-table -contained-large"
          data={this.state.listExports}
          pageSize={this.state.listExports.length}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          minRows={0}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="sitemap" style={{ fontSize: "1.75em", marginTop: "250px" }} />
              <p>{t("no_exports_added").message || "No exports added"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "export_name",
              Header: t("export_name").message || "Export name",
              accessor: "name",
              minWidth: 200,
              maxWidth: 300,
            },
            {
              id: "report_name",
              Header: t("report_name").message || "Report name",
              accessor: "factory_report_name",
            },
            {
              id: "emails",
              Header: t("recipients_emails").message || "Recipient's emails",
              accessor: "emails",
              minWidth: 430,
              maxWidth: 600,
            },
            {
              id: "frequency",
              Header: t("frequency").message || "Frequency",
              accessor: d => this.renderFrequency(d.frequency),
            },
            {
              id: "day",
              Header: t("day").message || "Day",
              accessor: d => this.renderDay(d.day, d.frequency),
            },
            {
              id: "query_range",
              Header: t("query_range").message || "Query range",
              accessor: d => this.getRangeValue(d.query_range),
            },
            {
              id: "actions",
              accessor: d => (
                <div className="FactoryExports-row-actions">
                  <Button icon onClick={() => this.handleShowLog(d.factory_export_id)}>
                    <Icon name="list alternate" color="green" />
                  </Button>
                  <Button icon onClick={() => this.handleExportData(d)}>
                    <Icon name="download" color="green" />
                  </Button>
                  <Button icon onClick={() => this.handleToggleModal(d)}>
                    <Icon name="pencil" color="green" />
                  </Button>
                  <Button icon onClick={() => this.toggleDeleteConfirmation(d.factory_export_id)}>
                    <Icon name="trash" color="green" />
                  </Button>
                </div>
              ),

              minWidth: 200,
              maxWidth: 250,
            },
          ]}
        />
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="FactoryExports">
        <Grid className="FactoryExports-subheader -contained-large">
          <Grid.Column width={14}>
            <h1>{t("factory_exports").message || "Factory exports"}</h1>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button floated="right" icon labelPosition="left" basic onClick={() => this.handleToggleModal()}>
              <Icon name="plus" color="green" className="export-btn-icon" />
              {t("export").message || "Export"}
            </Button>
          </Grid.Column>
        </Grid>

        {this.renderLog()}
        {this.renderExportModal()}
        {this.renderExportTable()}
        {this.renderAlert()}
        {this.renderExportCSVModal()}
        {!this.state.modalVisible && this.renderDeleteConfirmation()}
      </div>
    );
  }
}

export default withTranslation()(FactoryExports);
