import React from "react";
import { Grid, Icon, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";

import { AnswerStatusSelector, ThumbToggle, InlineInputIcon, Pin, Snooze } from "../../components";
import { sortTruckTires } from "../../util/common";
import { getPriceForDisplay } from "./util";
import { renderReadyStatus, renderMedia, TYRE_POSITION, QUESTION_RESULT_STATUS, getTyreSeasonIcon, SEASONS } from "./common";

import MechanicNote from "./MechanicNote";

import "./AppointmentTyres.css";

const AppointmentTruckTyres = ({
  price_enabled,
  isPriceEditable,
  isEditable,
  appointment,
  displayCustomcomStatus,
  onChangeCustomerCommunication,
  includeVAT,
  vat,
  selectedCustomerComm,
  isCustomerCommunicationVisible,
  answer,
  tyreMediaVisible,
  onAnswerUpdate,
  t,
  onMediaClick,
  onTyreMediaToggle,
  dashboardConfig,
  readOnlyRemarks,
  location,
}) => {
  if (!answer) return;

  const renderTyrePrice = (price, disabled, onAnswerUpdate, question_result_id) => (
    <InlineInputIcon
      type="price"
      textAreaWidth="90%"
      textAreaClassName="TyreTextArea"
      value={price > 0 ? price : null}
      icon="save"
      leftIcon="euro"
      displayValueFunc={getPriceForDisplay}
      config={{
        placeholder: "0.00",
        style: { width: "4em", paddingBottom: "0px", paddingTop: "0px", paddingRight: "0px" },
        disabled,
      }}
      iconConfig={{ className: "m-t-0" }}
      onSave={value => onAnswerUpdate({ questionId: question_result_id, shouldSave: true, type: "price", value })}
    />
  );

  const getTitle = position => {
    switch (position) {
      case TYRE_POSITION.TRUCK_LEFT:
        return t("left").message || "Left";

      case TYRE_POSITION.TRUCK_RIGHT:
        return t("right").message || "Right";

      case TYRE_POSITION.TRUCK_EXTERIOR_LEFT:
        return t("exterior_left").message || "Exterior Left";

      case TYRE_POSITION.TRUCK_EXTERIOR_RIGHT:
        return t("exterior_right").message || "Exterior Right";

      case TYRE_POSITION.TRUCK_INTERIOR_LEFT:
        return t("interior_left").message || "Interior Left";

      case TYRE_POSITION.TRUCK_INTERIOR_RIGHT:
        return t("interior_right").message || "Interior Right";

      case TYRE_POSITION.TRUCK_SPARE:
        return t("spare_tyre").message || "Spare Tyre";
      default:
        break;
    }
  };

  const getAxle = tyre => {
    if (tyre.tyre_position === TYRE_POSITION.TRUCK_SPARE) return `${t("spare_tyre").message || "Spare tyre"} ${tyre.spareTiresCount}`;
    else return `${t("axle").message || "Axle"} ${tyre.truck_axle}`;
  };

  const getTyreDescription = tyre => {
    return !tyre.description.includes(`${tyre.carry_capacity}/${tyre.speed}`) && !tyre.description.includes(`${tyre.carry_capacity}${tyre.speed}`)
      ? `${tyre.description} ${tyre.carry_capacity}${tyre.speed}`
      : tyre.description;
  };

  if (!answer || !Object.keys(answer).length) {
    return "";
  }

  return (
    <div className="TyreAnswer">
      <span>
        <Grid className="TyreAnswerTable__head">
          <h3>
            <Label className="TyreAnswerTable__head__label" color="green">
              <FontAwesomeIcon className="icon" icon={faTruck} />
            </Label>
            {[SEASONS.ALL_SEASONS, SEASONS.WINTER, SEASONS.SUMMER].includes(Object.values(answer)[0][0]?.tyre?.season)
              ? t("on_vehicle").message || "ON VEHICLE"
              : t("on_truck").message || "ON TRUCK"}
          </h3>
          <Grid.Row>
            <Grid.Column className="TyreAnswerTable__heading" width={2}>
              {t("question").message || "Question"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading" width={1}>
              {t("size").message || "Size"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading" width={2}>
              {t("type").message || "Type"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading tyre_brand_header" width={1}>
              {t("brand").message || "Brand"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading tyre_profile_header" width={1}>
              {t("profile").message || "Profile"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading" width={1}>
              {t("no_vat_price").message || "Excl. VAT"}
            </Grid.Column>
            {includeVAT && (
              <Grid.Column className="TyreAnswerTable__heading" width={1}>
                {t("vat_price").message || "Incl. VAT"}
              </Grid.Column>
            )}
            <Grid.Column className="TyreAnswerTable__heading" width={includeVAT ? 3 : 4}>
              {t("remarks").message || "Remarks"}
            </Grid.Column>
            <Grid.Column className="TyreAnswerTable__heading -no-padding" width={4}></Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className="TyreAnswerTable__body">
          {Object.keys(answer).map(key => {
            sortTruckTires(answer);

            return (
              <Grid key={key} style={{ padding: "0px", marginTop: "0px", width: "100%" }}>
                <div className="axle-title">{getAxle(answer[key][0])}</div>
                {answer[key].map((question_result, index) => {
                  const customcomSelected = selectedCustomerComm.some(scc => scc.question_result_id === question_result.id);

                  const images = question_result.images?.filter(img => img.active) || [];
                  const videos = question_result.video?.filter(video => video.active) || [];

                  return (
                    <React.Fragment key={question_result.id}>
                      <Grid.Row className={`TyreAnswerTable__row -item-status-${question_result.status}`}>
                        <Grid.Column className="TyreAnswerTable__column" width={2}>
                          {isCustomerCommunicationVisible && (
                            <Label style={{ marginRight: "5px" }}>
                              <Icon
                                style={{ cursor: "pointer", margin: "0px" }}
                                color={customcomSelected ? "green" : "red"}
                                onClick={() => onChangeCustomerCommunication(!customcomSelected, question_result.id)}
                                name={customcomSelected ? "eye" : "eye slash"}
                              />
                            </Label>
                          )}

                          {getTitle(question_result.tyre_position)}
                          {getTyreSeasonIcon(question_result.tyre.season)}
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column" width={1}>
                          {question_result.tyre.width}-{question_result.tyre.height}-R{question_result.tyre.size}
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column" width={2}>
                          {getTyreDescription(question_result.tyre)}
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column tyre_brand_column" width={1}>
                          {question_result.tyre.manufacturer}
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column tyre_profile_column" width={1}>
                          {question_result.tyre_profile}mm
                        </Grid.Column>
                        <Grid.Column className="TyreAnswerTable__column" width={1}>
                          {price_enabled && renderTyrePrice(question_result.price, !isPriceEditable, onAnswerUpdate, question_result.id)}
                        </Grid.Column>
                        {includeVAT && (
                          <Grid.Column className="TyreAnswerTable__column" width={1}>
                            {price_enabled &&
                              renderTyrePrice(
                                question_result.price && question_result.price * (1 + vat / 100),
                                !isPriceEditable,
                                value => {
                                  value.value = value.value / (1 + vat / 100);
                                  onAnswerUpdate(value);
                                },
                                question_result.id
                              )}
                          </Grid.Column>
                        )}
                        <Grid.Column className="TyreAnswerTable__column" width={includeVAT ? 3 : 4}>
                          <InlineInputIcon
                            type="textarea"
                            textAreaWidth="81%"
                            textAreaClassName="TyreTextArea"
                            placeholder={t("answer").message}
                            value={question_result.raw ? question_result.raw.trim() : ""}
                            icon="save"
                            iconConfig={{ className: "m-t-0" }}
                            config={{ disabled: readOnlyRemarks }}
                            onSave={value => onAnswerUpdate({ questionId: question_result.id, shouldSave: true, type: "raw", value })}
                          />
                        </Grid.Column>
                        <Grid.Column
                          textAlign="right"
                          width={4}
                          className="AppointmentChecklistGroup__item__modifiers TyreAnswerTable__column_statuses -answer-status-column -no-padding-right"
                        >
                          <AnswerStatusSelector
                            value={question_result.status}
                            disabled={!isEditable}
                            onChange={value => onAnswerUpdate({ questionId: question_result.id, type: "status", shouldSave: true, value })}
                          />

                          {(images.length > 0 || videos.length > 0) && (
                            <Label
                              color="teal"
                              style={{
                                width: "auto",
                              }}
                              onClick={() => onTyreMediaToggle(question_result.tyre_position)}
                            >
                              <Icon name="images" /> {dashboardConfig["video_enabled"] && videos.length ? videos.length + images.length : images.length}
                            </Label>
                          )}

                          <>
                            {!isCustomerCommunicationVisible && displayCustomcomStatus(question_result.status, appointment.diagnose_overview_results, question_result.id)}

                            {dashboardConfig["schedule_enabled"] && question_result.status !== QUESTION_RESULT_STATUS.OK && (
                              <Snooze
                                question_result={question_result}
                                appointment={appointment}
                                snoozeLog={question_result.snooze_history}
                                onSnooze={snoozeInfo =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "snooze",
                                    shouldSave: false,
                                    value: snoozeInfo,
                                  })
                                }
                                onDelete={() =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "snooze",
                                    shouldSave: false,
                                    value: [],
                                  })
                                }
                              />
                            )}

                            {dashboardConfig["pin_visible"] && (
                              <Pin
                                question_result={question_result}
                                appointment={appointment}
                                pinLog={question_result.pin_history}
                                onPin={pinLog => {
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "pin",
                                    shouldSave: false,
                                    value: pinLog,
                                  });
                                }}
                                onDelete={() => {
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    type: "pin",
                                    shouldSave: false,
                                    value: [],
                                  });
                                }}
                              />
                            )}

                            {question_result.status !== QUESTION_RESULT_STATUS.OK && (
                              <ThumbToggle
                                status={question_result.customer_approved}
                                disabled={!isEditable || question_result.mechanic_fixed}
                                onClick={() =>
                                  onAnswerUpdate({
                                    questionId: question_result.id,
                                    shouldSave: true,
                                    type: "customer_approved",
                                    value: !question_result.customer_approved,
                                  })
                                }
                              />
                            )}

                            <span className="AnswerReadyStatus">{renderReadyStatus(question_result.mechanic_fixed)}</span>
                          </>
                        </Grid.Column>

                        {tyreMediaVisible[question_result.tyre_position] &&
                          renderMedia(isCustomerCommunicationVisible, appointment, images, videos, onMediaClick, onAnswerUpdate, true, [], question_result.id, location)}
                      </Grid.Row>

                      {question_result.mechanic_notes && !isCustomerCommunicationVisible && <MechanicNote note={question_result.mechanic_notes} />}
                    </React.Fragment>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </span>
    </div>
  );
};

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps)(AppointmentTruckTyres));
