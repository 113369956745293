import React, { Component } from "react";
import { Icon, Form, Segment, Header, Dropdown } from "semantic-ui-react";

class Versions extends Component {
  handleSelectChange = (e, data) => {
    let { location, handleChange } = this.props;
    location[data.name] = data.value;
    handleChange(location);
  };

  render() {
    const { location, t, versions } = this.props;

    const drop = (
      <Dropdown
        placeholder={t("versions").message || "Versions"}
        options={
          versions
            ? versions.map((a, key) => {
                return { text: a.name, value: a.id, key };
              })
            : []
        }
        selection
        fluid
        search
        value={location.version_id}
        name="version_id"
        onChange={this.handleSelectChange}
      />
    );

    if (this.props.type === "basic") {
      return drop;
    }

    return (
      <div>
        <Segment>
          <Header as="h5">
            <Icon name="settings" />
            <Header.Content>{t("version_settings").message || "Version algemeen"}</Header.Content>
          </Header>
          <Segment.Inline>
            <Form>{drop}</Form>
          </Segment.Inline>
        </Segment>
      </div>
    );
  }
}

export default Versions;
