import Service from "./service";

class DeliveryTypesLoader {
  constructor() {
    if (DeliveryTypesLoader._instance) {
      return DeliveryTypesLoader._instance;
    }

    DeliveryTypesLoader._instance = this;

    this.isLoading = false;
    this.options = null;
    this.promises = [];
  }

  getDeliveryTypes(dealer_location_id) {
    return new Promise((resolve, reject) => {
      this.promises.push({ resolve, reject });
      this.load(dealer_location_id);
    });
  }

  load(dealer_location_id) {
    if (this.options) return this.resolvePromises();

    if (!this.isLoading) {
      this.isLoading = true;
      Service.getDeliveryTypes({ dealer_location_id })
        .then(result => {
          if (!result?.data?.data) throw new Error("invalid response when loading pin dropdowns options");

          this.options = result.data.data;
          this.resolvePromises();
          this.isLoading = false;
        })
        .catch(err => {
          if (err?.response?.data?.errors?.length) this.rejectPromises(err.response.data.errors[0]);
          else this.rejectPromises(err.message);

          this.isLoading = false;
        });
    }
  }

  rejectPromises(err) {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.reject(err);
    }
  }

  resolvePromises() {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.resolve(this.options);
    }
  }
}

const DeliveryTypes = new DeliveryTypesLoader();

export default DeliveryTypes;
