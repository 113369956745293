import React from "react";
import { connect } from "react-redux";
import { Container, Message, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Service from "./service";

class KeyLockerWarning extends React.Component {
  state = { showWarning: false };

  componentDidMount() {
    this.checkIfOnlineCheckInSendPINs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
      this.checkIfOnlineCheckInSendPINs();
    }
  }

  checkIfOnlineCheckInSendPINs = () => {
    Service.getCCDetail({ dealer_location_id: this.props.globalState.selectedLocation.id })
      .then(response => {
        let detail = response.data?.data ? response.data.data : {};
        this.setState({ showWarning: !detail.online_check_in_send_pin });
      })
      .catch(err => {
        console.error("Error getting CC detail.", err);
      });
  };

  render() {
    const { t } = this.props;

    if (!this.state.showWarning) return null;

    return (
      <Container text>
        <Message warning icon>
          <Icon name="warning" />
          <Message.Content>
            <Message.Header>{t("keylocker_not_usable").message || "Key Lockers are not usable"}</Message.Header>
            {t("online_checkin_do_not_send_pin").message ||
              "PIN codes to use the key lockers are not sent to customers by the Online Check-in. Go to the Customer communication setting page to enable it."}
          </Message.Content>
        </Message>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps)(KeyLockerWarning));
