import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { CustomConfirm } from "../../components";

import Service from "./service";

import "./AppointmentPin.scss";

class AppointmentPin extends Component {
  constructor(props) {
    super(props);
    this.isAuthorized = this.props.authState.user.acl?.appointments?.some(({ activity }) => activity === "update-pin");

    this.state = {
      showConfirmation: false,
      isLoading: false,
      errorMessage: "",
    };
  }

  handleOpenConfirmation = evt => {
    evt && evt.stopPropagation();

    if (!this.isAuthorized) return;

    this.setState({ showConfirmation: true });
  };

  handleCloseConfirmation = evt => {
    evt && evt.stopPropagation();

    this.setState({ showConfirmation: false, errorMessage: "" });
  };

  handleChangeIsPinned = evt => {
    evt && evt.stopPropagation();

    if (!this.isAuthorized) return;

    const appointment = { ...this.props.appointment };

    const { onHandleUpdateAppointments } = this.props;
    const { id: appointment_id, is_pinned } = appointment;

    this.setState({ isLoading: true, errorMessage: "" }, () => {
      Service.updateIsAppointmentPinned({ appointment_id, is_pinned: !is_pinned })
        .then(() => {
          this.setState({ isLoading: false, showConfirmation: false }, () =>
            onHandleUpdateAppointments({ ...appointment, is_pinned: !is_pinned, is_pinned_manually: !is_pinned })
          );
        })
        .catch(error => {
          console.error("Error changing is appointment pinned", error);
          const errorMessage =
            typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to unpin appointment";

          this.setState({ errorMessage, isLoading: false });
        });
    });
  };

  render() {
    const { showConfirmation, isLoading, errorMessage } = this.state;

    const {
      appointment: { is_pinned, is_pinned_manually },
      t,
    } = this.props;

    return (
      <div
        className={`appointment-pin-container ${this.isAuthorized ? "cursor-pointer" : ""}`}
        onClick={is_pinned ? this.handleOpenConfirmation : this.handleChangeIsPinned}
      >
        {is_pinned ? <Icon name="pin" color={is_pinned_manually ? "red" : ""} /> : <Icon name="circle outline" />}

        <CustomConfirm
          type="warning"
          isOpen={showConfirmation}
          isLoading={isLoading}
          handleCancel={this.handleCloseConfirmation}
          handleConfirm={this.handleChangeIsPinned}
          confirmMsg={t("unpin_appointment_confirm_message").message || "Do you want to unpin the selected appointment? This action will affect other users as well."}
          error={errorMessage}
          confirmText={t("yes").message || "Yes"}
          cancelText={t("no").message || "No"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authState: state.auth,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(AppointmentPin));
