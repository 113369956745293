// @flow
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Tab, Grid } from "semantic-ui-react";

import { SubHeader } from "./../../components";
import VersionConfiguration from "./VersionConfiguration";
import Service from "./service";
import "./Versions.css";

// CHECK if options_enc should be set on client-side
/*
const TYPE_HANDLER = {
    'toggle': {
        'handle': (f, isInputChecked, e) => {
            f.enabled = isInputChecked;
        }
    },
    'checkbox': {
        'handle': (f, isInputChecked, e) => {
            f.options[e.target.dataset.featureOption] = isInputChecked;
            f.options_enc = JSON.stringify(f.options);
        }
    },
    'number-options': {
        'handle': (f, val, e) => {
            f.options[e.target.dataset.featureOption] = parseInt(val);
            f.options_enc = JSON.stringify(f.options);
        }
    },
    'default': {
        'handle': (f, isInputChecked, e) => {
            f.price = parseInt(e.target.value);
        }
    }
};*/

class Versions extends Component {
  state = {
    loadingVersions: false,
    loadingVersionsError: null,
    versions: [],
    selectedVersion: null,
  };

  componentDidMount() {
    this.getVersions();
  }

  getVersions = () => {
    if (this.state.loadingVersions) {
      return;
    }
    this.setState(
      {
        loadingVersions: true,
      },
      () => {
        Service.getVersions()
          .then(result => {
            if (!result || !result.data || (result.data.errors && result.data.errors.length > 0)) {
              this.setState({ loadingVersions: false, versions: [], selectedVersion: null, loadingVersionsError: result.data.errors });
            } else {
              // Sort versions by tier, lowest->highest
              let v = result.data.data;
              v.sort((a, b) => a.tier - b.tier);

              this.setState({ loadingVersions: false, versions: v, selectedVersion: result.data.data[0], loadingVersionsError: null });
            }
          })
          .catch(error => {
            this.setState({ loadingVersions: false, versions: [], selectedVersion: null, loadingVersionsError: error });
            console.log("Error getting versions: ", error);
          });
      }
    );
  };

  saveVersions = () => {
    return;
  };

  handleTabSelect = version => {
    this.setState({ selectedVersion: version });
  };

  handleConfigurationChange = (e, isInputChecked) => {
    return; // Editing is disabled at the moment
    /*
        let sv = this.state.selectedVersion;

        for (let f of sv.features) {
            if (f.name === e.target.name) {
                if (e.target.dataset.type) {
                    TYPE_HANDLER[e.target.dataset.type].handle(f, isInputChecked, e);
                } else {
                    TYPE_HANDLER['default'].handle(f, isInputChecked, e);
                }
            }
        }

        // this.setState({selectedVersion: sv}); - If only single version is saved at a time, ie. sent in a request
        this.setState({versions: this.state.versions}); // If all features are sent always in a request
        */
  };

  // In case a dropdown feature is needed, but should be handled in TYPE_HANDLERs
  // handleConfigurationDropdownChange(e, i, v) {
  //     console.log("DP ::: ", e, v);
  // }

  renderPageTitleActions = () => {
    return (
      <div>
        {/* <RaisedButton
                    className="page-title-actions-btn"
                    label={< Translation t = {
                    this.props.translations.refresh
                }
                f = "REFRESH" />}
                    backgroundColor="green"
                    labelColor="#fff"
                    onClick={this.getVersions}/>

                <RaisedButton
                    className="page-title-actions-btn"
                    label={< Translation t = {
                    this.props.translations.save_changes
                }
                f = "SAVE CHANGES" />}
                    backgroundColor="green"
                    labelColor="#fff"
                    onClick={this.saveVersions}/> */}
      </div>
    );
  };

  renderVersions = () => {
    let { versions } = this.state;

    if (!this.state.loadingVersions && this.state.versions && this.state.versions.length > 0) {
      let panes = versions.map((v, key) => {
        return {
          menuItem: v.name,
          render: () => (
            <Tab.Pane attached={false}>
              <VersionConfiguration version={v} isEditable={false} onChange={this.handleConfigurationChange} />
            </Tab.Pane>
          ),
        };
      });

      return (
        <div className="-tabs">
          <Tab menu={{ pointing: false }} panes={panes} />
        </div>
      );
    } else if (!this.state.loadingVersions && this.state.versions.length === 0) {
      return (
        <p
          style={{
            textAlign: "center",
            padding: "10px",
          }}
        >
          <strong>{this.props.t("there_are_no_versions") || "There are no versions"}</strong>
        </p>
      );
    } else {
      return (
        <div className="Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>Loading versions</section>
        </div>
      );
    }
  };

  renderVersionConfiguration = () => {
    return <VersionConfiguration version={this.state.selectedVersion} isEditable={false} onChange={this.handleConfigurationChange} />;
  };

  render() {
    return (
      <div className="VersionsDashboard page_inner_wrapper">
        <SubHeader>
          <Grid.Column width={9}>
            <h1>{this.props.t("claire_versions").message || "Claire versions"}</h1>
          </Grid.Column>
        </SubHeader>
        <div className="page-content">
          {/* <div className="page-title-actions">
                            {this.renderPageTitleActions()}
                        </div> */}
          <div className="versions">{this.renderVersions()}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Versions);
