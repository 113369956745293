import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, Form, Grid, Icon } from "semantic-ui-react";
import { renderDynamicValuesTable } from "./common";
import { COMMUNICATION_EVENTS, KEYLOCKER_COMMUNICATION_EVENTS } from "../Appointments/common";
import AceEditor from "react-ace";

import Service from "./service";
import { copyToClipboard } from "../../util/common";
import { UserInput } from "../../components";
import Can from "../Can";

import "./index.scss";

class DefaultTemplate extends Component {
  state = {
    showEditor: true,
    subjectTitle: this.props.selectedMedium.subject,
    templateBody: this.props.selectedMedium.message,
    uploadError: null,
    uploadImages: [],
    isUploading: false,
    carBrand: this.props.selectedMedium.car_brand,
    carModel: this.props.selectedMedium.car_model,
    validationError: false,
  };

  revertToDefault = () => {
    const { defaultTemplates, language_code, selectedMedium } = this.props;
    let defaultTemplate = defaultTemplates.find(
      temp => temp.language_code === language_code && temp.event_type === selectedMedium.event_type && temp.channel === selectedMedium.channel
    );

    if (!defaultTemplate) {
      defaultTemplate = {
        subject: "default not found",
        message: "default not found for medium type " + selectedMedium.event_type + ", channel " + selectedMedium.channel + " and language code " + language_code,
      };
    }

    this.setState({ templateBody: defaultTemplate.message, subjectTitle: defaultTemplate.subject, showEditor: true });
  };

  renderHTML = () => {
    const divElement = document.getElementById("template");
    divElement.contentWindow.document.open();
    divElement.contentWindow.document.write(this.state.templateBody);
    divElement.contentWindow.document.close();
    divElement.onload = () => {
      divElement.style.height = divElement.contentWindow.document.body.scrollHeight + "px";
    };
  };

  handleOnChange = value => this.setState({ templateBody: value });

  handleSubjectChange = e => this.setState({ subjectTitle: e.target.value });

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileUpload = images => {
    let fd = new FormData();
    fd.append("location_id", this.props.location_id);
    images.forEach(image => {
      fd.append("files", image);
    });

    this.setState({ isUploading: true }, () => {
      Service.uploadEmailTemplateImages(fd)
        .then(res => {
          let uploadImages = [];
          if (Array.isArray(res?.data?.data)) uploadImages = res.data.data;

          this.setState({ uploadImages, isUploading: false });
        })
        .catch(err => {
          const errMsg = err?.response?.data?.errors?.length ? err.response.data.errors[0] : "unknown error occured";
          this.setState({ isUploading: false, uploadError: `Error uploading images - ${errMsg}`, uploadImages: [] });
        });
    });
  };

  handleFileChange = e => {
    const fileList = Array.from(e.target.files);

    if (!fileList.length) {
      this.setState({ uploadError: "", uploadImages: [] });
      return;
    }

    if (fileList.length > 3) {
      this.setState({ uploadError: this.props.t("upload_error_max_files").message || "Upload is limited to maximum of 3 files", uploadImages: [] });
      return;
    }

    const file_max_size = 2 * 1024 * 1024;
    let max_filesize_exceeded = false;
    let not_allowed_files = false;

    fileList.forEach(file => {
      if (file.size > file_max_size) max_filesize_exceeded = true;
      if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg") not_allowed_files = true;
    });

    if (max_filesize_exceeded) {
      this.setState({ uploadError: this.props.t("max_filesize_excedeed").message || "Maximum filesize exceeded", uploadImages: [] });
      return;
    }

    if (not_allowed_files) {
      this.setState({ uploadError: this.props.t("images_only").message || "Image files only allowed", uploadImages: [] });
      return;
    }

    this.setState({ uploadError: null }, () => {
      this.handleFileUpload(fileList);
    });
  };

  handleCopyToClipboard = input => {
    copyToClipboard(input);
  };

  toggleEditor = () => {
    this.setState(
      prevState => ({ showEditor: !prevState.showEditor }),
      () => {
        if (!this.state.showEditor) this.renderHTML();
      }
    );
  };

  submitTemplate = () => {
    const { subjectTitle, templateBody, carBrand, carModel } = this.state;

    if (this.props.selectedMedium.car_brand && !carBrand) {
      this.setState({ validationError: true });
      return;
    }

    const mediumUpdate = {
      subjectTitle,
      templateBody,
      carBrand,
      carModel,
    };

    this.props.handleEmailMediumUpdate(mediumUpdate);
    this.setState({ showEditor: true });
  };

  render() {
    const { t, isSubmit, handleCloseMedium, isConfirmationEmailMedium, selectedMedium, renderAlert, toggleDeleteConfirmation, renderDeleteConfirmation } = this.props;
    const { showEditor, templateBody, subjectTitle, uploadImages, uploadError, carBrand, carModel, validationError, isUploading } = this.state;
    const displayReceptionistInfo = [
      COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT,
      COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT,
      COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT,
      COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT,
    ].includes(selectedMedium.event_type);

    const showKeylockerPlaceholders =
      COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED === selectedMedium.event_type ||
      KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT === selectedMedium.keylocker_communication_event_type;

    return (
      <Modal open={true} onClose={handleCloseMedium} closeOnDimmerClick={false} size={showEditor ? "large" : "small"}>
        <Modal.Header>
          <h1 style={{ color: "#665e5e", display: "inline-block", margin: "0em" }}>{t("email_editor").message || "Email Editor"}</h1>
          <Button floated="right" color="yellow" onClick={this.revertToDefault}>
            {t("revert_to_default").message || "Revert to default"}
          </Button>
          <Button floated="right" primary onClick={this.toggleEditor}>
            {showEditor ? t("show_design").message || "Show Design" : t("show_source").message || "Show Source"}
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          {showEditor && renderDynamicValuesTable(isConfirmationEmailMedium, true, displayReceptionistInfo, showKeylockerPlaceholders, t)}
          <Form className="-margin-bottom-10 -margin-top-10">
            {showEditor && (
              <Form.Field>
                <label>Upload images (PNG, JPG, JPEG, Max. 2 MB)</label>
                {isUploading && (
                  <div className="Table__loading Loader-Placeholder">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                    <section>{t("uploading").message || "Uploading"}</section>
                  </div>
                )}
                {!isUploading && (
                  <Can I="update_medium" the="customcom">
                    <div className="file-upload">
                      <input type="file" multiple onChange={this.handleFileChange} accept="image/png, image/jpeg, image/jpg" />
                      <Grid>
                        {uploadImages.map(imgUrl => (
                          <Grid.Row className="image-links-container" key={imgUrl}>
                            <Grid.Column width={10}>
                              <UserInput type="text" readOnly={true} value={imgUrl} />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Button basic color="green" onClick={() => this.handleCopyToClipboard(imgUrl)}>
                                {t("copy").message || "Copy"}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        ))}
                        {uploadError && <span className="error-message">{uploadError}</span>}
                      </Grid>
                    </div>
                  </Can>
                )}
              </Form.Field>
            )}
            <Form.Field>
              <label>{t("Subject").message || "Subject"}</label>
              <UserInput name="subject" value={subjectTitle} placeholder={t("Subject").message || "Subject"} onChange={this.handleSubjectChange} />
            </Form.Field>

            {selectedMedium.car_brand && (
              <>
                <Form.Field required error={validationError && !carBrand}>
                  <label>{t("Make").message || "Make"}</label>
                  <UserInput name="carBrand" value={carBrand} placeholder={t("Make").message || "Make"} onChange={this.handleInputChange} />
                </Form.Field>

                <Form.Field>
                  <label>{t("Model").message || "Model"}</label>
                  <UserInput name="carModel" value={carModel} placeholder={t("Model").message || "Model"} onChange={this.handleInputChange} />
                </Form.Field>
              </>
            )}
          </Form>
          {showEditor ? (
            <Form.Field>
              <label>{t("Message").message || "Message"}</label>
              <AceEditor
                mode="html"
                theme="monokai"
                name="html"
                width="100%"
                value={templateBody}
                fontSize={14}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={false}
                onChange={this.handleOnChange}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </Form.Field>
          ) : (
            <div>
              <iframe title="medium" id="template" width="100%" scrolling="no" style={{ border: "none" }}></iframe>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          {selectedMedium.car_brand && (
            <Button color="red" loading={isSubmit} disabled={isSubmit} onClick={toggleDeleteConfirmation} floated="left">
              <Icon name="trash" className="-no-margin" />
            </Button>
          )}
          <Can I="update_medium" the="customcom">
            <Button floated="right" loading={isSubmit} color="green" onClick={this.submitTemplate}>
              {t("save").message || "Save"}
            </Button>
          </Can>
          <Button color="red" onClick={handleCloseMedium}>
            {t("close").message || "Close"}
          </Button>
        </Modal.Actions>
        {renderAlert()}
        {renderDeleteConfirmation()}
      </Modal>
    );
  }
}

export default withTranslation()(DefaultTemplate);
