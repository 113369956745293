import React, { Component } from "react";
import { connect } from "react-redux";
import { Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";

import { renderPinTypeIcon } from "./util";
import PinModal from "./PinModal";
import PinOptions from "./PinOptions";
import Service from "./service";
import { visorify } from "../../components";
import { PIN_STATUS, PIN_TYPE } from "./enum";
import { APPOINTMENT_STATUSES } from "../../modules/Appointments/common";
import { ROLES } from "../../modules/Users/roles";
import { MANUFACTURER_REQUEST_STATUSES } from "../../modules/ManufacturerDashboard/request_statuses";

import "./Pin.scss";

class Pin extends Component {
  state = {
    isOpen: false,
    isLoading: false,
    isLoadingDropdowns: false,
    isUpdated: false,
    errorMessage: "",
    deleteError: "",
    pin: {},
    originalPin: {},
    pinOptions: null,
    pinLog: Array.isArray(this.props.pinLog) ? this.props.pinLog.sort((a, b) => (b.created_on > a.created_on ? 1 : -1)) : [],
    readOnly:
      this.props.appointment?.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED ||
      (this.props.manufacturer_request && this.props.manufacturer_request.status !== MANUFACTURER_REQUEST_STATUSES.APPROVED),
    noteOnly: [ROLES.MANUFACTURER, ROLES.MECHANIC].includes(this.props.authState.user.role_id),
    attachments: [],
  };

  componentDidMount() {
    this.getDropdownOptions();
  }

  componentDidUpdate(prevProps) {
    let { pinLog } = this.props;

    if (pinLog !== prevProps.pinLog) {
      pinLog = Array.isArray(pinLog) ? pinLog.sort((a, b) => (b.created_on > a.created_on ? 1 : -1)) : [];

      const pin = this.getCurrentPin(pinLog);
      this.setState({ pinLog, pin, originalPin: { ...pin } });
    }
  }

  getCurrentPin = pinLog => {
    if (!pinLog?.length || pinLog[0].pin_status_id === PIN_STATUS.DELETED) return {};

    return {
      ...pinLog[0],
      note: "",
      visible_important_items: false,
      visible_mechanic: false,
      info: false,
      keep_parts: false,
      manufacturer_id: 0,
    };
  };

  isWarrantyPinSignatureMissing = pin => {
    const { globalState, appointment, intervention, question_result } = this.props;
    const { selectedLocation } = globalState;

    return (
      selectedLocation.is_warranty_pin_signature_missing_warning_enabled &&
      pin.pin_type_id === PIN_TYPE.WARRANTY &&
      ((intervention && !intervention.is_signed_by_customer) || (question_result && !question_result.is_signed_by_customer)) &&
      (!selectedLocation?.warranty_pin_signature_brands?.length ||
        selectedLocation.warranty_pin_signature_brands.some(brand => brand.vin_prefix && appointment.vin_nr?.startsWith(brand.vin_prefix)))
    );
  };

  getPinColor = pin => {
    const { selectedLocation } = this.props.globalState;

    if (this.isWarrantyPinSignatureMissing(pin)) return "red";

    if (pin.pin_type_id === PIN_TYPE.WARRANTY) {
      if (selectedLocation.is_warranty_pin_support_warning_enabled && !pin.support_nr) return "orange";
      if (selectedLocation.is_warranty_pin_claim_warning_enabled && !pin.claim_nr) return "orange";
    }

    if (pin.pin_type_id === PIN_TYPE.RECALL) {
      if (selectedLocation.is_recall_pin_support_warning_enabled && !pin.support_nr) return "orange";
      if (selectedLocation.is_recall_pin_claim_warning_enabled && !pin.claim_nr) return "orange";
    }

    if (pin.pin_type_id) return "blue";
  };

  getDropdownOptions = () => {
    this.setState({ isLoadingDropdowns: true }, () => {
      PinOptions.getDropdownOptions()
        .then(result => {
          if (result?.pin_options) this.setState({ pinOptions: result.pin_options, isLoadingDropdowns: false });
        })
        .catch(errorMessage => {
          this.setState({ isLoadingDropdowns: false, errorMessage });
          console.error(errorMessage);
        });
    });
  };

  handleOpen = () => {
    const { pinLog } = this.state;

    const pin = this.getCurrentPin(pinLog);
    this.setState({ isOpen: true, pin, originalPin: { ...pin }, isUpdated: false, errorMessage: "" });
  };

  handleClose = () => {
    this.setState({ isOpen: false, pin: {}, originalPin: {}, attachments: [], errorMessage: "" });
  };

  handleChange = pin => {
    this.setState({ pin, isUpdated: !_.isEqual(pin, this.state.originalPin) });
  };

  handleAddAttachments = (file, url) =>
    this.setState(({ attachments }) => {
      const attachment = {
        type: file.type,
        name: file.name,
        username: this.props.authState.user.first_name + " " + this.props.authState.user.last_name,
        url,
      };

      return { attachments: [...attachments, attachment] };
    });

  handleSave = () => {
    const { pin, pinLog, noteOnly, attachments } = this.state;
    const {
      intervention,
      question_result,
      appointment,
      onPin,
      authState: { user },
      t,
    } = this.props;

    if (intervention) pin.intervention_id = intervention.id;
    else if (question_result) pin.question_result_id = question_result.id;

    pin.appointment_id = appointment.id;
    pin.created_on = moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");
    pin.order_status = pin.order_status ? pin.order_status : null;
    pin.user_id = user.id;

    pin.note_attachments = pin.note ? attachments.map(({ url }) => url) : [];

    const serviceCall = noteOnly ? Service.addPinNote(pin) : Service.appendPin(pin);

    this.setState({ isLoading: true }, () => {
      serviceCall
        .then(result => {
          const savedPin = {
            ...pin,
            id: result?.data?.data?.pin_id || 0,
            user: {
              first_name: user.first_name,
              last_name: user.last_name,
            },
          };

          this.setState({ isLoading: false, isOpen: false, pinLog: [savedPin, ...pinLog], isUpdated: false, attachments: [] }, () => {
            if (onPin) onPin(this.state.pinLog);
          });
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.errors?.shift() || t("unexpected_error").message || "Unexpected error, please try to reload the page.";
          console.error("failed to save the pin:", errorMessage, error);

          this.setState({ isLoading: false, errorMessage, attachments: [] });
        });
    });
  };

  handleToggleVisibility = (id, data) => {
    const { pinLog } = this.state;
    const { onPin, t } = this.props;

    const i = pinLog.findIndex(p => p.id === id);

    if (i !== -1) {
      const checked = !data.checked;
      const currentPin = { ...pinLog[i], [data.name]: checked };

      Service.updateVisibility(currentPin)
        .then(result => {
          pinLog[i][data.name] = checked;

          this.setState({ pinLog }, () => {
            if (onPin) onPin(this.state.pinLog);
          });
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.errors?.shift() || t("unexpected_error").message || "Unexpected error, please try to reload the page.";

          this.setState({ errorMessage });
          console.error(errorMessage);
        });
    }
  };

  handleDelete = reason => {
    this.setState({ pinLog: [], isOpen: false }, () => {
      this.props.onDelete && this.props.onDelete(reason);
    });
  };

  render() {
    const { pin, pinLog, isOpen, isLoading, isLoadingDropdowns, isDeleting, isUpdated, errorMessage, pinOptions, readOnly, noteOnly, attachments } = this.state;
    const { appointment, question_result, intervention, globalState, disabled } = this.props;

    const showInfoToggle = false; // disabled till properly refined by antoine // isFeatureEnabled("Info Status") && selectedLocation.diagnose_status_visible && appointment_status_identifier !== 6;
    const pinDisabled = disabled || (!pinLog.length && noteOnly);

    return (
      <>
        <div className={`Pin-Container ${pinDisabled && "disabled"}`} onClick={!pinDisabled && this.handleOpen}>
          <Label color={this.getPinColor(this.getCurrentPin(pinLog))}>{renderPinTypeIcon(pinLog[0]?.pin_type_id)}</Label>
        </div>
        {!disabled && !isLoadingDropdowns && pinOptions && (
          <PinModal
            readOnly={readOnly}
            noteOnly={noteOnly}
            pin={pin}
            pinLog={pinLog}
            dropdownOptions={pinOptions}
            isOpen={isOpen}
            isLoading={isLoading}
            isDeleting={isDeleting}
            isUpdated={isUpdated}
            errorMessage={errorMessage}
            onClose={this.handleClose}
            onChange={this.handleChange}
            onSave={this.handleSave}
            onDelete={this.handleDelete}
            onVisibilityUpdate={this.handleToggleVisibility}
            appointment={appointment}
            questionResult={question_result}
            intervention={intervention}
            getCurrentPin={this.getCurrentPin}
            showInfoToggle={showInfoToggle}
            location={globalState.selectedLocation}
            attachments={attachments}
            onHandleAddAttachments={this.handleAddAttachments}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

export default connect(mapStateToProps)(withTranslation()(visorify(Pin)));
