import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";

import { Can } from "../../../";
import { getDMSName, DMS } from "../../util";

class DealersTable extends Component {
  state = {
    expanded: {},
    selectedDealer: null,
    selectedLocation: null,
  };

  render() {
    const { expanded } = this.state;
    const { t, dealers, handleToggleAddLocation, handleToggleEditLocation, handleToggleAddOrEditDealer, allDealers } = this.props;

    return (
      <ReactTable
        className="ReactTable -highlight"
        data={dealers}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        pageSize={dealers.length}
        noDataText={
          <div className="Table__no-results">
            <Icon disabled name="sitemap" style={{ fontSize: "1.75em", marginTop: "250px" }} />
            <p>{t("no_dealers").message || "No dealers"}</p>
          </div>
        }
        getTdProps={(_state, rowInfo, column) => ({
          onClick: (_e, handleOriginal) => {
            if (!column.expander && column.id !== "action") handleToggleAddOrEditDealer(rowInfo.original);

            if (handleOriginal) handleOriginal();
          },
        })}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("dealer").message || "Dealer",
            accessor: "dealer_name",
          },
          {
            id: "dms",
            Header: "DMS",
            accessor: d => getDMSName(d.dms_id),
          },
          {
            Header: "Next call at",
            accessor: "next_call_at",
          },
          {
            id: "add_location",
            Header: "",
            accessor: d => {
              const allLocations = allDealers.find(dealer => d.dealer_id === dealer.id)?.locations;
              const allLocationsConfigured = allLocations?.every(location => location.dms_id > DMS.UNKNOWN);
              if (!allLocations || allLocationsConfigured) return null;

              return (
                <Can I="update-location" the="dmsv3-setting">
                  <Button className="add-location-button" size="tiny" color="green" floated="right" onClick={e => handleToggleAddLocation(e, d)}>
                    {t("add_location").message || "Add location"}
                  </Button>
                </Can>
              );
            },
          },
        ]}
        expanded={expanded}
        onExpandedChange={expanded => this.setState({ expanded })}
        SubComponent={row => {
          const dmsLocations = [];

          allDealers.forEach(dealer => {
            if (row.original.dealer_id === dealer.id) {
              if (dealer.locations)
                dealer.locations.forEach(loc => {
                  if (loc.dms_id === row.original.dms_id) dmsLocations.push(loc);
                });
            }
          });

          return (
            <div style={{ padding: "20px" }}>
              <ReactTable
                className="ReactTable -highlight"
                data={dmsLocations || []}
                sortable={false}
                resizable={false}
                showPagination={false}
                showPageSizeOptions={false}
                minRows={0}
                noDataText={t("no_locations").message || "No locations"}
                getTrProps={(_state, rowInfo) => ({
                  onClick: () => handleToggleEditLocation(rowInfo.original),
                })}
                column={{
                  ...ReactTableDefaults.column,
                  headerClassName: "ReactTable__column-header",
                  className: "ReactTable__column",
                }}
                columns={[
                  {
                    Header: t("Location").message || "Location",
                    accessor: "name",
                  },
                ]}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default withTranslation()(DealersTable);
