import React from "react";
import i18n from "i18next";
import { SHAREBOX_TRIGGER, SHAREBOX_RESULT } from "../ShareboxDashboard";

import Service from "./service";
import { PIN_STATUS } from "../../components/Pin/enum";
import { renderPinTypeIconWithColor } from "../../components/Pin/util";
import { getDealerAndLocationById } from "../../util/common";

function sortByName(a, b) {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

export const GLOBAL_ACTION_TYPES = {
  ACCEPT_PRIVACY_AGREEMENT: "global/ACCEPT_PRIVACY_AGREEMENT",
  ACCEPT_PRIVACY_AGREEMENT_FAIL: "global/ACCEPT_PRIVACY_AGREEMENT_FAIL",
  ACCEPT_PRIVACY_AGREEMENT_SUCCESS: "global/ACCEPT_PRIVACY_AGREEMENT_SUCCESS",
  ALERT_SET: "global/ALERT_SET",
  ADD_KEYLOCKER_ALERT: "global/ADD_KEYLOCKER_ALERT",
  REMOVE_KEYLOCKER_ALERT: "global/REMOVE_KEYLOCKER_ALERT",
  ALERT_PROCESSED: "global/ALERT_PROCESSED",
  DEALER_SELECTED: "global/DEALER_SELECTED",
  GET_DEALERS: "global/GET_DEALERS",
  GET_DEALERS_FAIL: "global/GET_DEALERS_FAIL",
  GET_DEALERS_SUCCESS: "global/GET_DEALERS_SUCCESS",
  GET_DEALER_PRIVACY_DATA_FAIL: "global/GET_DEALER_PRIVACY_DATA_FAIL",
  GET_DEALER_PRIVACY_DATA_SUCCESS: "global/GET_DEALER_PRIVACY_DATA_SUCCESS",
  GET_SETTINGS_FAIL: "global/GET_SETTINGS_FAIL",
  GET_SETTINGS_SUCCESS: "global/GET_SETTINGS_SUCCESS",
  LOCATION_SELECTED: "global/LOCATION_SELECTED",
  UPDATE_LOCATION: "global/UPDATE_LOCATION",
  UPDATE_LOCATION_IN_SELECTED_DEALER: "global/UPDATE_LOCATION_IN_SELECTED_DEALER",
  UPDATE_LOCATION_IN_DEALERS: "global/UPDATE_LOCATION_IN_DEALERS",
  UPDATE_DEALER: "global/UPDATE_DEALER",
  SET_MANUFACTURER_PENDING_REQUESTS_COUNT: "global/SET_MANUFACTURER_PENDING_REQUESTS_COUNT",
  GET_NOTIFICATION_ELEMENTS_SUCCESS: "global/GET_NOTIFICATION_ELEMENTS_SUCCESS",
  GET_NOTIFICATION_ELEMENTS_FAIL: "global/GET_NOTIFICATION_ELEMENTS_FAIL",
  RESET_GLOBAL_STORE: "global/RESET_GLOBAL_STORE",
};

export function socketUpdateSelectedLocation(updatePayload) {
  return (dispatch, getState) => {
    const { selectedLocation } = getState().global;
    const { _topic } = updatePayload;

    let manufacturerRequestsCount = selectedLocation.manufacturerRequestsCount || 0;

    if (_topic === "ManufacturerRequestCreatedMessage") manufacturerRequestsCount++;
    else if (_topic === "ManufacturerRequestUpdatedMessage" && manufacturerRequestsCount) manufacturerRequestsCount--;

    dispatch({ type: GLOBAL_ACTION_TYPES.SET_MANUFACTURER_PENDING_REQUESTS_COUNT, selectedLocation: { ...selectedLocation, manufacturerRequestsCount } });
  };
}

export function getNotificationElements() {
  return dispatch => {
    Service.getListOfNotificationElements()
      .then(response => dispatch({ type: GLOBAL_ACTION_TYPES.GET_NOTIFICATION_ELEMENTS_SUCCESS, notificationElements: response?.data?.data.notification_elements || [] }))
      .catch(error => {
        const errorMessages = error?.response?.data?.errors?.length ? error.response.data.errors[0] : "Error while getting desktop notification elements";
        dispatch({ type: GLOBAL_ACTION_TYPES.GET_NOTIFICATION_ELEMENTS_FAIL, errorMessages });
      });
  };
}

export function setAlert(alertOptions) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.ALERT_SET, alertOptions });
}

export function addKeyLockerAlert(keyLockerCallForHelpID) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.ADD_KEYLOCKER_ALERT, keyLockerCallForHelpID });
}

export function removeKeyLockerAlert(keyLockerCallForHelpID) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.REMOVE_KEYLOCKER_ALERT, keyLockerCallForHelpID });
}

export function processedAlert() {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.ALERT_PROCESSED });
}

export function updateLocation(location) {
  return (dispatch, getState) => {
    const state = getState();

    const newDealers = [...state.global.dealers];
    let { selectedLocation, selectedDealer } = state.global;

    if (location.id === selectedLocation.id) {
      selectedLocation = location;
      selectedLocation.statuses_visible = [...state.global.selectedLocation.statuses_visible];

      selectedLocation.statuses_visible.forEach(status => {
        const updatedStatus = location.statuses?.find(s => s.id === status.id);
        if (updatedStatus) {
          status.name = updatedStatus.name;
          status.color = updatedStatus.color;
        }
      });
    }

    if (selectedDealer.id === location.dealer_id) {
      selectedDealer = { ...state.global.selectedDealer };
      let newLocationIndex = selectedDealer.locations?.findIndex(l => l.id === location.id);
      if (newLocationIndex > -1) selectedDealer.locations[newLocationIndex] = location;
    }

    let newDealer = newDealers.find(d => d.id === location.dealer_id);
    if (newDealer && newDealer.locations) {
      let newLocationIndex = newDealer.locations.findIndex(l => l.id === location.id);
      if (newLocationIndex > -1) newDealer.locations[newLocationIndex] = location;
    }

    dispatch({ type: GLOBAL_ACTION_TYPES.UPDATE_LOCATION, newDealers, selectedDealer, selectedLocation });
  };
}

export const updateDealer = dealer => {
  return (dispatch, getState) => {
    const state = getState();

    const allDealers = [...state.global.dealers];
    let { selectedDealer } = state.global;

    let dealerIndex = allDealers.findIndex(d => d.id === dealer.id);

    if (dealerIndex > -1) {
      if (!dealer.locations && allDealers[dealerIndex].locations) dealer.locations = allDealers[dealerIndex].locations;

      allDealers[dealerIndex] = dealer;

      if (selectedDealer.id === dealer.id) selectedDealer = dealer;
    }

    return dispatch({ type: GLOBAL_ACTION_TYPES.UPDATE_DEALER, allDealers, selectedDealer });
  };
};

export function getDealerPrivacyData(dealer_id) {
  return dispatch => {
    Service.getDealerPrivacyStatus(dealer_id)
      .then(res => {
        if (res && res.data.data) {
          dispatch(getDealerPrivacyDataSuccess(res.data.data));
        }
      })
      .catch(err => {
        console.log("Error getting dealer security data ::: ", err);
      });
  };
}

export function getDealerPrivacyDataSuccess(dealerPrivacyData) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.GET_DEALER_PRIVACY_DATA_SUCCESS, dealerPrivacyData });
}

export function getDealerPrivacyDataFail(errorMessages) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.GET_DEALER_PRIVACY_DATA_FAIL, errorMessages });
}

export function acceptPrivacyAgreement(dealer_id) {
  return dispatch => {
    Service.acceptPrivacyAgreement(dealer_id)
      .then(response => {
        dispatch(acceptPrivacyAgreementSuccess());
      })
      .catch(error => {
        let e = error.response ? error.response.data.error : error;
        dispatch(acceptPrivacyAgreementFail(e));
      });
  };
}

export function acceptPrivacyAgreementSuccess() {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.ACCEPT_PRIVACY_AGREEMENT_SUCCESS });
}

export function acceptPrivacyAgreementFail(errorMessages) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.ACCEPT_PRIVACY_AGREEMENT_FAIL, errorMessages });
}

export function getSettings() {
  return (dispatch, getState) => {
    Service.getSettings()
      .then(response => {
        let settings = response.data.data.settings;
        let manual_url;

        // Handles the old way of getting manuals which is still on PROD now
        if (settings.manual_url) {
          // This is the old way
          manual_url = settings.manual_url;
        } else {
          manual_url = settings.find(s => s.locale === getState().auth.user.language_code && s.name === "web");
          manual_url = manual_url ? manual_url.url : "";
        }
        localStorage.setItem("manual_url", manual_url);
        dispatch(getSettingsSuccess(settings, manual_url));
      })
      .catch(error => {
        let e = error.response ? error.response.data.error : error;
        dispatch(getSettingsFail(e));
      });
  };
}

export function getSettingsSuccess(settings, manual_url) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.GET_SETTINGS_SUCCESS, settings, manual_url });
}

export function getSettingsFail(errorMessages) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.GET_SETTINGS_FAIL, errorMessages });
}

export function getDealers(urlDealerID, urlLocationID) {
  return dispatch => {
    Service.getDealers()
      .then(response => {
        dispatch(getDealersSuccess(response.data.data.dealers, urlDealerID, urlLocationID));
      })
      .catch(error => {
        let e = error.response ? error.response.data.error : error;
        dispatch(getDealersFail(e));
      });
  };
}

export function getDealersSuccess(dealers, urlDealerID, urlLocationID) {
  let sortedDealers = [];
  let optional = {};
  let selectedDealer = null;

  return async (dispatch, getState) => {
    if (dealers && dealers.length > 0) {
      sortedDealers = dealers.sort((a, b) => sortByName(a, b));

      let locationIdToSelect;

      if (urlDealerID) {
        selectedDealer = dealers.find(d => d.id === urlDealerID);
        if (!selectedDealer) {
          dispatch(getDealersFail(["Dealer not found"]));
          return;
        }

        locationIdToSelect = selectedDealer.locations?.length > 0 ? selectedDealer.locations[0].id : 0;
      } else if (urlLocationID) {
        selectedDealer = dealers.find(d => d.locations?.find(l => l.id === urlLocationID));
        if (!selectedDealer) {
          dispatch(getDealersFail(["Dealer not found"]));
          return;
        }

        locationIdToSelect = urlLocationID;
      } else {
        let dealerIdToSelect;

        if (getState().global.selectedDealer?.id) dealerIdToSelect = getState().global.selectedDealer.id;
        else if (getState().auth.user?.dealer_id) dealerIdToSelect = getState().auth.user.dealer_id;

        selectedDealer = dealers.find(d => d.id === dealerIdToSelect);
        if (!selectedDealer) {
          dispatch(getDealersFail(["Dealer not found"]));
          return;
        }

        if (getState().global.selectedLocation?.id) locationIdToSelect = getState().global.selectedLocation.id;
        else if (getState().auth.user?.dealer_location_id) locationIdToSelect = getState().auth.user.dealer_location_id;
      }

      if (selectedDealer.locations?.length > 0) {
        selectedDealer.locations.sort((a, b) => sortByName(a, b));

        if (locationIdToSelect === "all") optional.myLocations = getState().auth.user.my_locations;
        else {
          const selectedLocation = selectedDealer.locations.find(l => l.id === locationIdToSelect);
          if (selectedLocation) dispatch(getLocation(selectedLocation.id));
        }
      }

      optional.selectedDealer = selectedDealer;
    }

    dispatch({
      type: GLOBAL_ACTION_TYPES.GET_DEALERS_SUCCESS,
      dealers: sortedDealers,
      ...optional,
    });
  };
}

export function getDealersFail(errorMessages) {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.GET_DEALERS_FAIL, errorMessages });
}

export let _getCallCenterDefaultLocation = ({ user, dealers }) => {
  if (!user.my_locations || user.my_locations.length === 0) {
    return {};
  }

  const myDealer = dealers.find(d => d.id === user.dealer_id);

  if (!myDealer) {
    return {};
  }

  return myDealer.locations && myDealer.locations.length > 0 ? myDealer.locations.find(l => l.id === user.dealer_location_id) : [];
};

export function selectDealer(selectedDealer) {
  return (dispatch, getState) => {
    dispatch({ type: GLOBAL_ACTION_TYPES.DEALER_SELECTED, selectedDealer });

    let selectedLocation = {};
    if (selectedDealer.locations?.length > 0) {
      const user = getState().auth.user;
      if (user.my_locations?.length > 0) {
        selectedLocation = selectedDealer.locations.find(l => l.active && (user.dealer_location_id === l.id || user.my_locations.includes(l.id))) || {};
      } else if (user.location_ids?.length > 0) {
        selectedLocation = selectedDealer.locations.find(l => l.active && (user.dealer_location_id === l.id || user.location_ids.includes(l.id))) || {};
      } else {
        selectedLocation = selectedDealer.locations.find(l => l.active && user.dealer_location_id === l.id);
        if (!selectedLocation) selectedLocation = selectedDealer.locations.find(l => l.active);
      }
    }

    if (selectedLocation?.id) dispatch(getLocation(selectedLocation.id));
    else dispatch({ type: GLOBAL_ACTION_TYPES.LOCATION_SELECTED, selectedLocation: {} });
  };
}

export function selectLocation(selectedLocation) {
  return dispatch => {
    let id = selectedLocation.id;
    if (id === "all") id = selectedLocation.defaultLocationId;

    dispatch(getLocation(id, selectedLocation));
  };
}

function getManufacturerPendingRequestsCount(selectedLocation) {
  return dispatch => {
    Service.getPendingRequestsCount({ dealer_location_id: selectedLocation.id })
      .then(res => {
        dispatch({
          type: GLOBAL_ACTION_TYPES.SET_MANUFACTURER_PENDING_REQUESTS_COUNT,
          selectedLocation: { ...selectedLocation, manufacturerRequestsCount: res?.data?.data || 0 },
        });
      })
      .catch(err => {
        console.error("Error getting manufacturer pending requests count", err);
      });
  };
}

export function websocketShareboxUpdate(payload) {
  const { sharebox_trigger_id, sharebox_result_id } = payload?.body || {};

  return dispatch => {
    if (sharebox_trigger_id === SHAREBOX_TRIGGER.SHAREBOX_TRIGGER_RECEIVER_DROP_KEY && sharebox_result_id === SHAREBOX_RESULT.REG_NUMBER_DO_NOT_MATCH) {
      dispatch({
        type: GLOBAL_ACTION_TYPES.ALERT_SET,
        alertOptions: {
          type: "info",
          time: 0,
          title:
            i18n.t("websocket_sharebox_error_message").message ||
            "A customer dropped a key in a sharebox cabinet but there was no match in Claire, see the Sharebox log dashboard for more informations",
        },
      });
    }
  };
}

function getLocation(id, selectedLocation = false) {
  return (dispatch, getState) => {
    Service.getLocation({ id })
      .then(response => {
        let newLocation = response?.data?.data ? response.data.data : {};

        if (newLocation?.statuses?.length > 0) {
          let sortable = [];

          for (const status of newLocation.statuses) {
            status.isActive = true;
            sortable.push(status);
          }

          newLocation.statuses_visible = sortable.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
        }

        if (selectedLocation && selectedLocation.id === "all") {
          newLocation.id = "all";
          newLocation.myLocations = selectedLocation.myLocations;
          newLocation.name = "all";
        }

        let currentLocation = getState().global.selectedLocation;
        if (currentLocation && currentLocation.statuses_visible) {
          for (const cs of currentLocation.statuses_visible) {
            for (let ns of newLocation.statuses_visible) {
              if (cs.identifier === ns.identifier) ns.isActive = cs.isActive;
            }
          }
        }

        if (newLocation?.statuses?.length > 0) {
          let dealers = getState().global.dealers;
          let dealer = dealers.find(d => d.id === newLocation.dealer_id);
          if (dealer) {
            let location = dealer.locations.find(l => l.id === newLocation.id);
            if (location) {
              location.statuses = newLocation.statuses;
              location.statuses_visible = newLocation.statuses_visible;
              dispatch({ type: GLOBAL_ACTION_TYPES.UPDATE_LOCATION_IN_DEALERS, dealers });
            }

            if (getState().global.selectedDealer.id === newLocation.dealer_id) {
              let selectedDealer = getState().global.selectedDealer;
              let location = selectedDealer.locations.find(l => l.id === newLocation.id);
              if (location) {
                location.statuses = newLocation.statuses;
                location.statuses_visible = newLocation.statuses_visible;
                dispatch({ type: GLOBAL_ACTION_TYPES.UPDATE_LOCATION_IN_SELECTED_DEALER, selectedDealer });
              }
            }
          }
        }

        dispatch({ type: GLOBAL_ACTION_TYPES.LOCATION_SELECTED, selectedLocation: newLocation });
        if (getState().auth.user.acl && getState().auth.user.acl["manufacturer"]?.some(a => a.activity === "update-request") && newLocation?.id !== "all") {
          dispatch(getManufacturerPendingRequestsCount(newLocation));
        }
      })
      .catch(error => {
        console.error("ERROR GETTING LOCATION", error);
      });
  };
}

export function websocketWarrantyNotification(payload) {
  const { update: pin } = payload.body;

  return (dispatch, getState) => {
    const { dealers } = getState().global;

    const { dealer, location } = getDealerAndLocationById(dealers, pin.dealer_location_id);
    const pinStatus = Object.keys(PIN_STATUS)[pin.pin_status_id]?.toLowerCase();

    const onOpenAppointmentDetailPage = () => window.open(`${document.location.origin}/#!/appointments/wo/${pin.appointment.wo_nr}`, "_blank");

    const icon = (
      <div className="websocket-warranty-notification-icon-container" onClick={onOpenAppointmentDetailPage}>
        {renderPinTypeIconWithColor(location, pin)}
      </div>
    );

    const message = (
      <div className="websocket-warranty-notification" onClick={onOpenAppointmentDetailPage}>
        <div>WO: {pin.appointment.wo_nr}</div>
        <div>
          {i18n.t("name").message || "Name"}: {pin.question_result?.title || pin.intervention?.title}
        </div>
        <div>
          {i18n.t("status").message || "Status"}: {i18n.t(`pinstatus_${pinStatus}`).message || pinStatus}
        </div>
        <div>
          {i18n.t("dealer").message || "Dealer"}: {dealer.name}
        </div>
        <div>
          {i18n.t("location").message || "Location"}: {location.name}
        </div>
      </div>
    );

    dispatch({
      type: GLOBAL_ACTION_TYPES.ALERT_SET,
      alertOptions: {
        type: "info",
        time: 0,
        title: message,
        icon,
      },
    });
  };
}

export const resetGlobalStore = () => {
  return dispatch => dispatch({ type: GLOBAL_ACTION_TYPES.RESET_GLOBAL_STORE });
};

const INITIAL_STATE = {
  actionType: "",
  alertOptions: {},
  dealers: [],
  dealerPrivacyData: {},
  errorMessages: {},
  myLocations: [],
  selectedDealer: {},
  selectedLocation: {},
  settings: {},
  manual_url: "",
  manufacturerRequestsCount: 0,
  keyLockerCallForHelpID: null,
  notificationElements: [],
};
const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_ACTION_TYPES.SET_MANUFACTURER_PENDING_REQUESTS_COUNT:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
      };
    case GLOBAL_ACTION_TYPES.UPDATE_LOCATION_IN_SELECTED_DEALER:
      return {
        ...state,
        selectedDealer: action.selectedDealer,
      };
    case GLOBAL_ACTION_TYPES.UPDATE_LOCATION_IN_DEALERS:
      return {
        ...state,
        dealers: action.dealers,
      };
    case GLOBAL_ACTION_TYPES.ALERT_SET:
      return {
        ...state,
        actionType: action.type,
        alertOptions: action.alertOptions,
      };
    case GLOBAL_ACTION_TYPES.ADD_KEYLOCKER_ALERT:
      return {
        ...state,
        actionType: action.type,
        keyLockerCallForHelpID: action.keyLockerCallForHelpID,
      };
    case GLOBAL_ACTION_TYPES.REMOVE_KEYLOCKER_ALERT:
      return {
        ...state,
        actionType: action.type,
        keyLockerCallForHelpID: action.keyLockerCallForHelpID,
      };
    case GLOBAL_ACTION_TYPES.ALERT_PROCESSED:
      return {
        ...state,
        actionType: action.type,
      };
    case GLOBAL_ACTION_TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        settings: action.settings,
        manual_url: action.manual_url,
      };
    case GLOBAL_ACTION_TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessages: action.errorMessages,
      };
    case GLOBAL_ACTION_TYPES.GET_DEALERS:
      return {
        ...state,
        actionType: action.type,
      };

    case GLOBAL_ACTION_TYPES.UPDATE_LOCATION:
      return {
        ...state,
        actionType: action.type,
        selectedLocation: action.selectedLocation,
        selectedDealer: action.selectedDealer,
        dealers: action.newDealers,
      };
    case GLOBAL_ACTION_TYPES.UPDATE_DEALER:
      return {
        ...state,
        actionType: action.type,
        dealers: action.allDealers,
        selectedDealer: action.selectedDealer,
      };
    case GLOBAL_ACTION_TYPES.GET_DEALERS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        dealers: action.dealers,
        selectedDealer: action.selectedDealer,
        myLocations: action.myLocations,
      };
    case GLOBAL_ACTION_TYPES.GET_DEALERS_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessages: action.errorMessages,
      };
    case GLOBAL_ACTION_TYPES.GET_DEALER_PRIVACY_DATA_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        dealerPrivacyData: action.dealerPrivacyData,
      };
    case GLOBAL_ACTION_TYPES.GET_DEALER_PRIVACY_DATA_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessages: action.errorMessages,
      };
    case GLOBAL_ACTION_TYPES.ACCEPT_PRIVACY_AGREEMENT_SUCCESS:
      return {
        ...state,
        actionType: action.type,
      };
    case GLOBAL_ACTION_TYPES.ACCEPT_PRIVACY_AGREEMENT_FAIL:
      return {
        ...state,
        errorMessages: action.errorMessages,
      };
    case GLOBAL_ACTION_TYPES.DEALER_SELECTED:
      return {
        ...state,
        actionType: action.type,
        selectedDealer: action.selectedDealer,
      };
    case GLOBAL_ACTION_TYPES.LOCATION_SELECTED:
      return {
        ...state,
        actionType: action.type,
        selectedLocation: action.selectedLocation,
      };
    case GLOBAL_ACTION_TYPES.GET_NOTIFICATION_ELEMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        notificationElements: action.notificationElements,
      };
    case GLOBAL_ACTION_TYPES.GET_NOTIFICATION_ELEMENTS_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessages: action.errorMessages,
      };
    case GLOBAL_ACTION_TYPES.RESET_GLOBAL_STORE: {
      return {
        ...INITIAL_STATE,
        actionType: action.type,
      };
    }
    case "persist/REHYDRATE":
      var incoming = action.payload;
      if (incoming && incoming.global) {
        return {
          ...state,
          ...incoming.global,
          errorMessages: state.errorMessages,
          alertOptions: state.alertOptions,
          dealerPrivacyData: state.dealerPrivacyData,
        };
      }
      return state;
    default:
      return state;
  }
};

export default globalReducer;
