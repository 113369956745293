import React, { Component } from "react";
import { Button, Checkbox, Dropdown, Form, FormSelect, Icon, Modal, Segment, Grid, List } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import "./EditChecklists.css";
import Service from "./service";
import AlertContainer from "react-alert";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Can from "../Can";
import { isAdmin } from "../../util/common";
import { CustomConfirm, UserInput } from "../../components";

class EditChecklist extends Component {
  state = {
    questionTag: null,
    questionSearchTerm: "",
    addQuestionToGroupIndex: null,
    filteredDealerQuestions: this.props.dealerQuestions,
    filteredDefaultQuestions: this.props.defaultQuestions,
    isLoading: false,
    checklist: {},
    selectedBrands: [],
    selectedTags: [],
    questionsModalOpen: false,
    showConfirmation: false,
    alertOptions: {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 10000,
      transition: "fade",
    },
    extendDefaultChecklistLoading: false,
  };

  checklistSettingsOptions = [
    { value: 0, text: this.props.t("regular").message || "Regular" },
    { value: 1, text: this.props.t("onboarding").message || "Onboarding" },
    { value: 2, text: this.props.t("quality").message || "Quality" },
    { value: 3, text: this.props.t("info").message || "Info" },
    { value: 4, text: this.props.t("extra_parts").message || "Extra parts" },
    { value: 5, text: this.props.t("extension_list").message || "Extension list" },
    { value: 6, text: this.props.t("test_drive").message || "Test drive" },
    { value: 7, text: this.props.t("warranty").message || "Warranty" },
  ];

  escapeModal = evt => {
    if (evt.keyCode === 27) {
      this.props.handleDiscardChecklist();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escapeModal, false);
    this.setState({ isLoading: true }, () => {
      Service.getChecklist(this.props.checklistID)
        .then(result => {
          if (result.data && result.data.data) {
            let checklist = result.data.data;

            let selectedBrands;
            if (checklist.parent_checklist) {
              selectedBrands =
                checklist.parent_checklist.brands && checklist.parent_checklist.brands.length > 0
                  ? checklist.parent_checklist.brands.map(item => {
                      return item.id;
                    })
                  : [];
            } else {
              selectedBrands =
                checklist.brands && checklist.brands.length > 0
                  ? checklist.brands.map(item => {
                      return item.id;
                    })
                  : [];
            }

            let selectedTags;
            if (checklist.parent_checklist) {
              selectedTags =
                checklist.parent_checklist.tags && checklist.parent_checklist.tags.length > 0
                  ? checklist.parent_checklist.tags.map(item => {
                      return item.id;
                    })
                  : [];
            } else {
              selectedTags =
                checklist.tags && checklist.tags.length > 0
                  ? checklist.tags.map(item => {
                      return item.id;
                    })
                  : [];
            }

            if (checklist.question_groups && checklist.question_groups.length > 0) {
              checklist.question_groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
              checklist.question_groups = this.reindexChecklistQuestionGroups(checklist.question_groups);
              checklist.question_groups.forEach(val => {
                if (!val.questions || val.questions.length === 0) return;

                val.questions.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
                val.questions = this.reindexChecklistQuestions(val.questions);
              });
            }

            this.setState({ isLoading: false, checklist, selectedBrands, selectedTags });
          } else {
            console.error("error while fetching the checklist");
            this.error("error while fetching the checklist");
            this.setState({ isLoading: false, checklist: {} });
          }
        })
        .catch(error => {
          console.error("error while fetching the checklist: ", error);
          this.error("error while fetching the checklist");
          this.setState({ isLoading: false, checklist: {} });
        });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeModal, false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateError) this.error(nextProps.updateError);
  }

  reindexChecklistQuestionGroups = question_groups => {
    return question_groups.reduce((accu, item, index) => {
      item.order = index;
      accu.push(item);
      return accu;
    }, []);
  };

  reindexChecklistQuestions = questions => {
    return questions.reduce((accu, item, index) => {
      item.order = index;
      accu.push(item);
      return accu;
    }, []);
  };

  handleUpdateChecklist = () => {
    let { checklist, selectedTags, selectedBrands } = this.state;

    if (Array.isArray(checklist.question_groups) && checklist.question_groups.some(group => group.name === "")) {
      this.error(this.props.t("group_name_empty").message || "Group name cannot be empty");
      return;
    }

    checklist.brands = selectedBrands;
    checklist.tags = selectedTags;

    this.props.handleUpdateChecklist(checklist);
  };

  handleBrandsUpdate = (event, data) => {
    let checklist = this.state.checklist;
    checklist.brands = data.value;
    this.setState({ checklist, selectedBrands: data.value });
  };

  handleUpdate = (e, data) => {
    let checklist = this.state.checklist;
    checklist[data.name] = data.value;
    this.setState({ checklist });
  };

  handleUpdateCheckbox = (e, data) => {
    let checklist = this.state.checklist;
    checklist[data.name] = data.checked;
    this.setState({ checklist });
  };

  handleTagsUpdate = (e, data) => {
    let checklist = this.state.checklist;
    checklist.tags = data.value;
    this.setState({ selectedTags: data.value, checklist });
  };

  handleQuestionTagsUpdate = (e, data) => {
    this.setState({ questionTag: data.value }, () => this.applyFilters());
  };

  handleQuestionSearch = (_e, data) => {
    this.setState({ questionSearchTerm: data.value }, () => this.applyFilters());
  };

  applyFilters = () => {
    const { dealerQuestions, defaultQuestions } = this.props;
    const { questionTag, questionSearchTerm } = this.state;

    let filteredDealerQuestions = dealerQuestions;
    let filteredDefaultQuestions = defaultQuestions;

    if (questionSearchTerm) {
      filteredDealerQuestions = filteredDealerQuestions.filter(question => question.title.toLowerCase().includes(questionSearchTerm.toLowerCase()));
      filteredDefaultQuestions = filteredDefaultQuestions.filter(question => question.title.toLowerCase().includes(questionSearchTerm.toLowerCase()));
    }

    if (questionTag) {
      filteredDealerQuestions = filteredDealerQuestions.filter(item => item.tags?.some(tag => tag.id === questionTag));
      filteredDefaultQuestions = filteredDefaultQuestions.filter(item => item.tags?.some(tag => tag.id === questionTag));
    }

    this.setState({ filteredDealerQuestions, filteredDefaultQuestions });
  };

  handleRemoveQuestion = (questionGroupIndex, questionIndex) => {
    let checklist = this.state.checklist;
    checklist.question_groups[questionGroupIndex].questions.splice(questionIndex, 1);
    checklist.question_groups[questionGroupIndex].questions = this.reindexChecklistQuestions(checklist.question_groups[questionGroupIndex].questions);
    this.setState({ checklist });
  };

  handleMoveQuestionGroup = (from, to) => {
    let checklist = this.state.checklist;

    if (from < 0) from = 0;

    if (from > checklist.question_groups.length) from = checklist.question_groups.length;

    if (to < 0) to = 0;

    if (to > checklist.question_groups.length) to = checklist.question_groups.length;

    if (checklist.question_groups && checklist.question_groups.length > 1) {
      let fromOrder = checklist.question_groups[from].order;
      checklist.question_groups[from].order = checklist.question_groups[to].order;
      checklist.question_groups[to].order = fromOrder;

      checklist.question_groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

      this.setState({ checklist });
    }
  };

  handleRemoveQuestionGroup = index => {
    let checklist = this.state.checklist;
    checklist.question_groups.splice(index, 1);
    checklist.question_groups = this.reindexChecklistQuestionGroups(checklist.question_groups);
    this.setState({ checklist });
  };

  handleQuestionGroupNameUpdate = (e, data) => {
    let checklist = this.state.checklist;
    checklist.question_groups[data.questiongroupindex].name = data.value;
    this.setState({ checklist });
  };

  handleAddQuestion = question => {
    let { checklist, addQuestionToGroupIndex } = this.state;
    let newQuestion = Object.assign({}, question);
    if (newQuestion.contains_tyre_element && checklist.question_groups[addQuestionToGroupIndex].questions.length > 0) {
      this.setState({ addQuestionToGroupIndex: null, questionsModalOpen: false });
      return this.error("Can not add a tyre question because a this group is not empty.");
    }
    if (
      newQuestion.contains_tyre_element &&
      (checklist.question_groups.findIndex(
        qg => qg.questions.findIndex(q => q.question_elements && q.question_elements.findIndex(qe => qe.template === "tyre") !== -1) !== -1
      ) !== -1 ||
        checklist.question_groups.findIndex(qg => qg.questions.findIndex(q => q.contains_tyre_element) !== -1) !== -1)
    ) {
      this.setState({ addQuestionToGroupIndex: null, questionsModalOpen: false });
      return this.error("Can not add a new tyre question because a question with a tyre element is already present in this checklist.");
    }

    if (
      newQuestion.contains_tyre_element &&
      checklist.parent_checklist &&
      checklist.parent_checklist.question_groups &&
      checklist.parent_checklist.question_groups.findIndex(
        qg => qg.questions.findIndex(q => q.question_elements && q.question_elements.findIndex(qe => qe.template === "tyre") !== -1) !== -1
      ) !== -1
    ) {
      this.setState({ addQuestionToGroupIndex: null, questionsModalOpen: false });
      return this.error("Can not add a new tyre question because a question with a tyre element is already present in this checklist.");
    }

    if (!checklist.question_groups[addQuestionToGroupIndex].questions) {
      checklist.question_groups[addQuestionToGroupIndex].questions = [];
    }
    newQuestion.order = checklist.question_groups[addQuestionToGroupIndex].questions.length;
    checklist.question_groups[addQuestionToGroupIndex].questions.push(newQuestion);
    this.setState({ checklist, addQuestionToGroupIndex: null, questionsModalOpen: false });
  };

  handleOpenQuestionsModal = questionGroupIndex => {
    let questions = this.state.checklist.question_groups[questionGroupIndex].questions;
    if (
      questions &&
      (questions.findIndex(q => q.question_elements && q.question_elements.findIndex(qe => qe.template === "tyre") !== -1) !== -1 ||
        questions.findIndex(q => q.contains_tyre_element) !== -1)
    )
      return this.error("Can not add a new question because a question with a tyre element is present in this group.");

    this.setState({ questionsModalOpen: true, addQuestionToGroupIndex: questionGroupIndex });
  };

  handleCloseQuestionsModal = () => {
    this.setState({ questionsModalOpen: false });
  };

  handleAddGroup = () => {
    let checklist = this.state.checklist;
    if (!checklist.question_groups) checklist.question_groups = [];
    else if (checklist.question_groups.findIndex(g => !g.questions || g.questions.length === 0) > -1) return this.error("You already have an empty group");

    let group = { name: "", order: checklist.question_groups.length, checklist_id: checklist.id, questions: [] };
    checklist.question_groups.push(group);
    this.setState({ checklist });
  };

  handleRemoveConfirmationChecklist = () => {
    this.setState({ showConfirmation: true });
  };

  handleCancelRemoveChecklist = () => {
    this.setState({ showConfirmation: false });
  };

  handleRemoveChecklist = () => {
    this.setState({ showConfirmation: false });
    if (this.props.currentList === "location") this.props.handleDetachLocation(this.state.checklist.id);
    else if (this.props.currentList === "dealer" && !this.state.checklist.dealer_id) this.props.handleDetachDealer(this.state.checklist.id);
    else if (this.props.currentList === "dealer" && this.state.checklist.dealer_id) this.props.handleRemoveChecklist(this.state.checklist.id);
    else if (this.props.currentList === "default" && !this.state.checklist.dealer_id) this.props.handleRemoveChecklist(this.state.checklist.id);
  };

  error = msg => {
    this.msg.show(msg, { type: "error" });
  };

  renderAlert = () => {
    return <AlertContainer ref={a => (this.msg = a)} {...this.state.alertOptions} />;
  };

  onSortQuestionEnd = (questiongroupindex, oldIndex, newIndex, event) => {
    if (!event) return;

    let checklist = { ...this.state.checklist };
    let questionGroup = { ...checklist.question_groups[questiongroupindex] };
    questionGroup.questions = [...questionGroup.questions];

    const question = questionGroup.questions.splice(oldIndex, 1);
    questionGroup.questions.splice(newIndex, 0, question[0]);

    questionGroup.questions.forEach((question, index) => (question.order = index));

    checklist.question_groups[questiongroupindex] = questionGroup;

    this.setState({ checklist });
  };

  //added by me
  handleExtendDefaultChecklist = () => {
    const { checklist } = this.state;
    const { currentList, selectedLocationID, selectedDealerID } = this.props;
    const name = checklist.name + " extended";
    const parent_id = checklist.id;

    let attachedID = currentList === "location" ? selectedLocationID : selectedDealerID;

    this.setState({ extendDefaultChecklistLoading: true }, () => {
      Service.extendDefaultChecklist(parent_id, name, currentList, attachedID)
        .then(response => {
          const { handleNewChildChecklist } = this.props;
          let newChecklist = {
            id: response.data.data,
            name,
            dealer_id: selectedDealerID,
            parent_checklist: checklist,
          };

          handleNewChildChecklist(newChecklist, currentList);
        })
        .catch(error => {
          this.error("An error occured while extending the checklist");
          console.log("Extend Checklist Error", error);
        });
    });
  };

  render() {
    let { checklist, selectedBrands, selectedTags, isLoading, questionsModalOpen, questionTag, questionSearchTerm, filteredDealerQuestions, filteredDefaultQuestions } =
      this.state;
    let { templatesListShort, brands, tags, loading } = this.props;

    let hideButtons = isLoading || loading;

    if (checklist && checklist.dealer_id) {
      templatesListShort = templatesListShort.filter(item => !item.default_only);
    }

    let canEdit = checklist.dealer_id || isAdmin(this.props.authUser.role_id);
    const isExtended = !!this.state.checklist.parent_checklist;

    let questionGroups =
      checklist.question_groups && checklist.question_groups.length > 0
        ? checklist.question_groups.map((item, questionGroupIndex) => {
            const SortableItem = SortableElement(({ offset, value }) => {
              return (
                <Segment key={"question-" + offset}>
                  {canEdit && (
                    <Icon className="-cursor-pointer-no-color" name="times" size="large" onClick={() => this.handleRemoveQuestion(questionGroupIndex, offset)} />
                  )}{" "}
                  {value.title}
                </Segment>
              );
            });

            const SortableList = SortableContainer(({ items }) => (
              <div>
                {items &&
                  items.length > 0 &&
                  items
                    .sort((a, b) => {
                      return a.order - b.order;
                    })
                    .map((item, key) => <SortableItem disabled={!canEdit} index={key} key={`item-${key}`} offset={key} value={item} />)}
              </div>
            ));

            return (
              <Segment.Group className="QuestionGroup" key={"question_group-" + questionGroupIndex + "-" + item.order}>
                <Segment className="QuestionGroup">
                  <Form.Field>
                    <div className="two fields">
                      <Form.Field className="fourteen wide">
                        {canEdit && (
                          <UserInput
                            onChange={this.handleQuestionGroupNameUpdate}
                            name="name"
                            questiongroupindex={questionGroupIndex}
                            value={item.name}
                            placeholder={this.props.t("group_name").message || "Group name"}
                            className="QuestionGroup"
                          />
                        )}
                        {!canEdit && <span>{item.name}</span>}
                      </Form.Field>

                      <Form.Field className="three wide middle aligned -text-right">
                        {canEdit && questionGroupIndex + 1 < checklist.question_groups.length ? (
                          <Icon
                            size="large"
                            className="-cursor-pointer-no-color"
                            name="angle down"
                            onClick={() => this.handleMoveQuestionGroup(questionGroupIndex, questionGroupIndex + 1)}
                          />
                        ) : (
                          ""
                        )}
                        {canEdit && questionGroupIndex - 1 >= 0 ? (
                          <Icon
                            size="large"
                            className="-cursor-pointer-no-color"
                            name="angle up"
                            onClick={() => {
                              this.handleMoveQuestionGroup(questionGroupIndex, questionGroupIndex - 1);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {canEdit && (
                          <Icon
                            size="large"
                            name="plus"
                            className="-cursor-pointer-no-color"
                            onClick={() => {
                              this.handleOpenQuestionsModal(questionGroupIndex);
                            }}
                          />
                        )}
                        {canEdit && (!item.questions || item.questions.length === 0) ? (
                          <Icon
                            size="large"
                            name="trash"
                            onClick={() => {
                              this.handleRemoveQuestionGroup(questionGroupIndex);
                            }}
                            className="-cursor-pointer-no-color"
                          />
                        ) : (
                          ""
                        )}
                      </Form.Field>
                    </div>
                  </Form.Field>
                </Segment>
                <Segment.Group>
                  <SortableList
                    helperClass="ChecklistQuestionsSortableHelper"
                    items={item.questions}
                    distance={5}
                    onSortEnd={({ oldIndex, newIndex }, e) => {
                      this.onSortQuestionEnd(questionGroupIndex, oldIndex, newIndex, e);
                    }}
                  />
                </Segment.Group>
              </Segment.Group>
            );
          })
        : [];

    let parentQuestionGroups =
      checklist.parent_checklist && checklist.parent_checklist.question_groups && checklist.parent_checklist.question_groups.length > 0
        ? checklist.parent_checklist.question_groups.map((item, questionGroupIndex) => {
            return (
              <Segment.Group className="QuestionGroup" key={"parent-question_group-" + questionGroupIndex + "-" + item.order}>
                <Segment className="QuestionGroup">
                  <Form.Field>
                    <div className="two fields">
                      <Form.Field className="thirteen wide">
                        <span>{item.name}</span>
                      </Form.Field>

                      <Form.Field className="three wide middle aligned -text-right"></Form.Field>
                    </div>
                  </Form.Field>
                </Segment>
                <Segment.Group>
                  <div>
                    {item.questions &&
                      item.questions.length > 0 &&
                      item.questions
                        .sort((a, b) => {
                          return a.order - b.order;
                        })
                        .map((item, index) => <Segment key={"parent-question-" + index}>{item.title}</Segment>)}
                  </div>
                </Segment.Group>
              </Segment.Group>
            );
          })
        : [];

    return (
      <div>
        <CustomConfirm
          type="danger"
          isOpen={this.state.showConfirmation}
          handleConfirm={this.handleRemoveChecklist}
          handleCancel={this.handleCancelRemoveChecklist}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          error={this.props.updateError}
        />

        <Modal open={true} closeOnDimmerClick={false} className="EditChecklist">
          <Modal.Header>
            {this.props.t("edit_checklist").message || "Edit checklist"}

            <div className="-pull-right">
              {checklist.dealer_id === null && this.props.currentList !== "default" && (
                <Button color="yellow" onClick={this.handleExtendDefaultChecklist}>
                  {this.props.t("extend").message || "Extend"}
                </Button>
              )}
              {canEdit && (
                <Can I="delete" the="checklists">
                  <Button loading={hideButtons} disabled={hideButtons} color="red" onClick={this.handleRemoveConfirmationChecklist}>
                    <Icon name="trash" size="small" className="-no-margin" />
                  </Button>
                </Can>
              )}
              <Button loading={hideButtons} disabled={hideButtons} onClick={this.props.handleDiscardChecklist}>
                {this.props.t("discard").message || "Discard"}
              </Button>
              {canEdit && (
                <Button
                  loading={hideButtons}
                  disabled={hideButtons}
                  color="green"
                  onClick={() => {
                    this.handleUpdateChecklist();
                  }}
                >
                  {this.props.t("confirm").message || "Confirm"}
                </Button>
              )}
            </div>

            {this.renderAlert()}
          </Modal.Header>
          <Modal.Content scrolling>
            {isLoading && (
              <div className="Table__loading Loader-Placeholder">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
                <section>{this.props.t("loading_checklist").message || "Loading checklist"}</section>
              </div>
            )}
            {!isLoading && (
              <Form>
                <Form.Field>
                  <label>{this.props.t("checklist_name").message || "Checklist name"}</label>
                  <Form.Input
                    name="name"
                    disabled={!canEdit}
                    defaultValue={checklist.name}
                    placeholder={this.props.t("checklist_name").message || "Checklist name"}
                    onChange={this.handleUpdate}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{this.props.t("checklist_template").message || "Checklist template"}</label>
                  <FormSelect
                    name="template_id"
                    disabled={!canEdit || isExtended}
                    options={templatesListShort.map(a => {
                      return { text: a.name, value: a.checklist_template_id, key: a.checklist_template_id };
                    })}
                    defaultValue={isExtended ? checklist.parent_checklist.template_id : checklist.template_id}
                    onChange={this.handleUpdate}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{this.props.t("brands").message || "Brands"}</label>
                  <Dropdown
                    placeholder={this.props.t("brands").message || "Brands"}
                    selection
                    disabled={!canEdit || isExtended}
                    fluid
                    search
                    options={brands}
                    defaultValue={selectedBrands}
                    multiple={true}
                    onChange={this.handleBrandsUpdate}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{this.props.t("checklist_tags").message || "Checklist tags"}</label>
                  <Dropdown
                    placeholder={this.props.t("checklist_tags").message || "Checklist tags"}
                    selection
                    fluid
                    search
                    disabled={!canEdit || isExtended}
                    options={tags}
                    defaultValue={selectedTags}
                    multiple={true}
                    onChange={this.handleTagsUpdate}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{this.props.t("checklist_settings").message || "Checklist settings"}</label>
                  <FormSelect
                    name="checklist_type"
                    disabled={!canEdit || isExtended}
                    options={this.checklistSettingsOptions}
                    defaultValue={isExtended ? checklist.parent_checklist.checklist_type : checklist.checklist_type}
                    onChange={this.handleUpdate}
                  />
                </Form.Field>

                <Form.Group widths="equal">
                  <Checkbox
                    className="Checkbox"
                    disabled={!canEdit || isExtended}
                    toggle
                    name="active"
                    defaultChecked={isExtended ? checklist.parent_checklist.active : checklist.active}
                    label={this.props.t("active").message || "Active"}
                    onChange={this.handleUpdateCheckbox}
                  />

                  <Checkbox
                    className="Checkbox"
                    disabled={!canEdit || isExtended}
                    toggle
                    name="is_active_for_mechanic"
                    defaultChecked={isExtended ? checklist.parent_checklist.is_active_for_mechanic : checklist.is_active_for_mechanic}
                    label={this.props.t("active_for_mechanic").message || "Active for mechanic"}
                    onChange={this.handleUpdateCheckbox}
                  />

                  <Checkbox
                    className="Checkbox"
                    disabled={!canEdit || isExtended}
                    toggle
                    name="include_in_reports"
                    defaultChecked={isExtended ? checklist.parent_checklist.include_in_reports : checklist.include_in_reports}
                    label={this.props.t("report").message || "Report"}
                    onChange={this.handleUpdateCheckbox}
                  />

                  <Checkbox
                    className="Checkbox"
                    disabled={!canEdit || isExtended}
                    toggle
                    name="is_category_mode"
                    defaultChecked={isExtended ? checklist.parent_checklist.is_category_mode : checklist.is_category_mode}
                    label={this.props.t("category_mode").message || "Category mode"}
                    onChange={this.handleUpdateCheckbox}
                  />

                  <Checkbox
                    className="Checkbox"
                    disabled={!canEdit || isExtended}
                    toggle
                    name="check_in_customer"
                    defaultChecked={isExtended ? checklist.parent_checklist.check_in_customer : checklist.check_in_customer}
                    label={this.props.t("check_in_customer").message || "Check-in customer"}
                    onChange={this.handleUpdateCheckbox}
                  />
                </Form.Group>

                <Form.Field>{parentQuestionGroups}</Form.Field>

                <Form.Field>{questionGroups}</Form.Field>

                <Form.Field>
                  {canEdit && (
                    <Form.Button onClick={this.handleAddGroup} fluid color="green">
                      {this.props.t("add_group").message || "Add group"}
                    </Form.Button>
                  )}
                </Form.Field>
              </Form>
            )}
          </Modal.Content>
        </Modal>

        <Modal open={questionsModalOpen} closeOnDimmerClick={false} className="ChecklistQuestions">
          <Modal.Header>
            <div className="ChecklistQuestionsHeader">
              <div className="HeaderTitle">{this.props.t("available_questions").message || "Available questions"}</div>

              <div className="HeaderActions">
                <UserInput
                  value={questionSearchTerm}
                  onChange={this.handleQuestionSearch}
                  placeholder={this.props.t("search_questions_by_title").message || "Search questions by title..."}
                />

                <Dropdown
                  placeholder={this.props.t("question_tags").message || "Question tags"}
                  options={tags}
                  clearable
                  defaultValue={questionTag}
                  selection
                  onChange={this.handleQuestionTagsUpdate}
                />

                <Button
                  onClick={() => {
                    this.setState({ questionsModalOpen: false });
                  }}
                  color="green"
                  content={this.props.t("close").message || "Close"}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Grid columns={2} divided>
              <Grid.Row className="-no-margin -no-padding">
                {checklist.dealer_id && (
                  <Grid.Column width={isAdmin(this.props.authUser.role_id) ? 8 : 16} className="-no-padding-left">
                    <h4>Dealer Questions</h4>
                    <List className="Scrollbar">
                      {filteredDealerQuestions.map(item => (
                        <div className={!item.active ? "question red" : "question green"} key={"question" + item.id} onClick={() => this.handleAddQuestion(item)}>
                          {item.title}
                          {item.tags?.length > 0 &&
                            item.tags.map((tag, index) => (
                              <span key={index + "question" + item.id + "tag" + tag.id} style={{ backgroundColor: tag.color }}>
                                {tag.name}
                              </span>
                            ))}
                        </div>
                      ))}
                    </List>
                  </Grid.Column>
                )}

                {(!checklist.dealer_id || isAdmin(this.props.authUser.role_id)) && (
                  <Grid.Column width={!checklist.dealer_id ? 16 : 8} className="Scrollbar">
                    <h4>Default Questions</h4>
                    <List className="Scrollbar">
                      {filteredDefaultQuestions.map(item => (
                        <div className={!item.active ? "question red" : "question green"} key={"question" + item.id} onClick={() => this.handleAddQuestion(item)}>
                          {item.title}
                          {item.tags?.length > 0 &&
                            item.tags.map((tag, index) => (
                              <span key={index + "question" + item.id + "tag" + tag.id} style={{ backgroundColor: tag.color }}>
                                {tag.name}
                              </span>
                            ))}
                        </div>
                      ))}
                    </List>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(EditChecklist);
