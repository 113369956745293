import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid, Dropdown, Icon, Image, Label, Popup, Search } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faStickyNote,
  faPhoneAlt,
  faExclamationTriangle,
  faUserCheck,
  faCarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faCarGarage, faEllipsisH, faTag, faTire } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _ from "lodash";

import { setAlert } from "../App/store";
import { CARS_ACTION_TYPES, deselectCar, getCar } from "../Cars/store";
import { getPreference, setPreference } from "../../util/preferences";
import { getDealerAndLocationById, getLocationById } from "../../util/common";
import { applyUpdate, updateAppointmentStatusIdentifier, APPOINTMENT_NOTE_TYPES } from "../Appointments/util";
import { SearchPortal, SubHeader, UserMenuActionsPortal } from "../../components";
import { APPOINTMENT_STATUSES } from "../Appointments/common";
import { CHECKLIST_TYPE } from "../Checklists/enum";
import {
  APPOINTMENTS_ACTION_TYPES,
  applyUpdateToAppointment,
  deletePinHistory,
  deselectAppointment,
  getAppointment,
  getChecks,
  handleUpdateAppointments,
  updatePinHistory,
  webSocketDayplannerUpdatesApplied,
} from "../Appointments/store";
import CarDetail from "../Cars/CarDetail";
import AppointmentDetail from "../Appointments/AppointmentDetail";
import PinNotification from "../../components/Pin/PinNotification";
import { AppointmentCardStatus, InterventionQuickView, CarQuickView } from "./components";
import DayplannerStatusFilters, { ShouldBeFiltered } from "./components/StatusFilters";

import Service from "./service";

import "./index.scss";

const DAYPLANNER_COLUMNS = {
  APPOINTMENTS: 1,
  UNASSIGNED: 2,
  MECHANICS: 3,
  CAR_READY: 4,
  QUALITY_CHECK: 5,
};

const MECHANIC_FILTER = {
  HIDE_OCCUPIED_MECHANICS: 1,
  HIDE_UNOCCUPIED_MECHANICS: 2,
};

class Dayplanner extends Component {
  state = {
    appointmentsColumn: [],
    defaultAppointmentsColumn: [],
    unassignedColumn: [],
    defaultUnassignedColumn: [],
    mechanicsColumn: [],
    defaultMechanicsColumn: [],
    selectedMechanicFilter: getPreference("dayplanner-selectedMechanicFilter", null),
    carReadyColumn: [],
    defaultCarReadyColumn: [],
    qualityControlColumn: [],
    defaultQualityControlColumn: [],
    searchTerm: "",
    selectedCar: {},
    selectedAppointment: null,
    isAppointmentDetailVisible: false,
    isCarDetailVisible: false,
    isLoading: false,
    draggedAppointment: null,
    draggedFromMechanic: null,
    appointmentEventsToComplete: [],
    dayplannerEventsToComplete: [],
    appointments: [],
    collapsedColumns: getPreference("dayplanner-collapsedColumns", []),
    numberOfCardShownInMechanicColumn: 0,
    activeFilter: getPreference("dayplanner-activeFilter", null),
    extendedSearchResults: [],
    extendedSearchLoading: false,
    backorderFilterInactive: true,
    scrollTo: -1,
  };

  appointmentsColumnRef = React.createRef();
  unassignedColumnRef = React.createRef();
  mechanicsColumnRef = React.createRef();
  carReadyColumnRef = React.createRef();
  qualityControlColumnRef = React.createRef();
  resizeObserver = null;

  componentDidMount() {
    document.addEventListener("dragenter", e => e.preventDefault());
    document.addEventListener("dragover", e => e.preventDefault());
    document.addEventListener("dragleave", e => this.cancelDragAppointmentIfOutOfScreen(e));
    document.addEventListener("drop", e => this.cancelDragAppointment(e));

    this.getDayplannerData();

    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        const marginValue = 30;
        const numberOfCards = Math.floor(width / (160 + marginValue));

        this.setState({ numberOfCardShownInMechanicColumn: numberOfCards });
      }
    });

    this.resizeObserver.observe(this.mechanicsColumnRef.current);
  }

  componentDidUpdate(prevProps) {
    const { selectedAppointment, selectedCar, scrollTo } = this.state;
    const { globalState, carsState, appointmentsState, setAlert, t } = this.props;

    if (scrollTo > 0) {
      document.getElementsByClassName("-mechanics-tasks")[0].scrollTo({ top: scrollTo });
    }

    if (globalState.selectedLocation.id !== prevProps.globalState.selectedLocation.id) {
      this.getDayplannerData(globalState.selectedLocation.id);
      return;
    }

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        } else if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) {
          this.setState({ isAppointmentDetailVisible: true, isLoading: false });
        }
      }

      if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
        if (selectedAppointment) this.socketAppointmentUpdate(appointmentsState.webSocketEvent);

        this.dayplannerSocketAppointmentUpdate(appointmentsState.webSocketEvent);
      } else if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_ASSIGNMENT_UPDATED) {
        this.websocketAppointmentAssignmentUpdate(appointmentsState.webSocketEvent);
      } else if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_MECHANIC_AVAILABILITY_UPDATED) {
        this.websocketMechanicAvailabilityUpdate(appointmentsState.webSocketEvent);
      } else if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_REFRESH) {
        this.websocketAppointmentsRefresh(appointmentsState.webSocketEvent);
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  getDayplannerData = (dealer_location_id = null) => {
    this.setState({ isLoading: true }, async () => {
      try {
        if (!Number.isInteger(dealer_location_id)) dealer_location_id = this.props.globalState.selectedLocation.id;
        const [appointmentsResponse = [], mechanicsResponse = []] = await Promise.all([
          Service.getAppointments({ dealer_location_id }),
          Service.getMechanics({ dealer_location_id }),
        ]);

        const appointmentsData = appointmentsResponse?.data?.data || [];
        const mechanicsData = mechanicsResponse?.data?.data || [];

        const { appointments, appointmentsColumn, unassignedColumn, mechanicsColumn, carReadyColumn, qualityControlColumn } = this.getDataPerColumn(
          appointmentsData,
          mechanicsData
        );

        this.setState(
          {
            appointments,
            appointmentsColumn,
            defaultAppointmentsColumn: appointmentsColumn,
            unassignedColumn,
            defaultUnassignedColumn: unassignedColumn,
            mechanicsColumn,
            defaultMechanicsColumn: mechanicsColumn,
            carReadyColumn,
            defaultCarReadyColumn: carReadyColumn,
            qualityControlColumn,
            defaultQualityControlColumn: qualityControlColumn,
            isLoading: false,
            draggedAppointment: null,
            draggedFromMechanic: null,
            appointmentEventsToComplete: [],
            dayplannerEventsToComplete: [],
          },
          () => {
            this.applySearchAndFilterIfNeeded();
          }
        );
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to load dayplanner data";

        this.props.setAlert(errorMsg, { type: "error" });
        console.log("Error loading dayplanner data", error);
        this.setState({ isLoading: false });
      }
    });
  };

  getDataPerColumn = (appointments, mechanics) => {
    const appointmentsColumn = [];
    const unassignedColumn = [];
    const carReadyColumn = [];
    const qualityControlColumn = [];
    const boardAppointments = [];

    mechanics.forEach(mechanic => {
      if (!mechanic.appointments) mechanic.appointments = [];
    });

    const sortedAppointments = this.sortAppointments(appointments);

    sortedAppointments.forEach(app => {
      updateAppointmentStatusIdentifier(app);

      if (!app.assigned_mechanic) {
        switch (app.appointment_status_identifier) {
          case APPOINTMENT_STATUSES.CAR_READY:
          case APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW:
            carReadyColumn.push(app);
            boardAppointments.push(app);
            break;

          case APPOINTMENT_STATUSES.QUALITY_CHECK:
          case APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW:
            qualityControlColumn.push(app);
            boardAppointments.push(app);
            break;

          default:
            if (
              [APPOINTMENT_STATUSES.CAR_CHECK_STARTED].includes(app.appointment_status_identifier) &&
              app.status_history?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))?.[0].checklist_type === CHECKLIST_TYPE.QUALITY
            ) {
              carReadyColumn.push(app);
              boardAppointments.push(app);
            } else if (app.car_in_shop && !app.car_out_of_shop) {
              unassignedColumn.push(app);
              boardAppointments.push(app);
            } else if (app.car_out_of_shop) {
              appointmentsColumn.push(app);
              boardAppointments.push(app);
            } else {
              switch (app.appointment_status_identifier) {
                case APPOINTMENT_STATUSES.CAR_CHECK:
                case APPOINTMENT_STATUSES.BACK_ORDER:
                case APPOINTMENT_STATUSES.PRICING_OK:
                case APPOINTMENT_STATUSES.CONTACT:
                case APPOINTMENT_STATUSES.CUSTOMER_OK:
                case APPOINTMENT_STATUSES.DIAGNOSE:
                  unassignedColumn.push(app);
                  boardAppointments.push(app);
                  break;

                default:
                  appointmentsColumn.push(app);
                  boardAppointments.push(app);
                  break;
              }
            }
        }
      } else {
        const mechanicIndex = mechanics.findIndex(m => m.id === app.assigned_mechanic);

        if (mechanicIndex > -1) {
          if (!mechanics[mechanicIndex].appointments.some(a => a.id === app.id)) {
            mechanics[mechanicIndex].appointments.push(app);
            boardAppointments.push(app);
          }
        }
      }
    });

    mechanics.forEach(mechanic => {
      // not needed, but things can go wrong, a mechanic with assignment should not be shown as not available
      if (!mechanic.is_available && mechanic.appointments.length > 0) mechanic.is_available = true;
    });

    mechanics = this.getSortedMechanics(mechanics);

    return { appointments: boardAppointments, appointmentsColumn, unassignedColumn, mechanicsColumn: mechanics, carReadyColumn, qualityControlColumn };
  };

  sortAppointments = appointments => {
    const sortWO = (app1, app2) => app1.wo_nr.localeCompare(app2.wo_nr, undefined, { sensitivity: "base", numeric: true });

    const backorderAppointments = [],
      appointmentsWithdates = [],
      appointmentsWithoutdates = [];

    appointments &&
      appointments.forEach(appointment => {
        if (appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER) backorderAppointments.push(appointment);
        else if (appointment.car_return_time || appointment.planning_work_stop) appointmentsWithdates.push(appointment);
        else appointmentsWithoutdates.push(appointment);
      });

    backorderAppointments.sort(sortWO);
    appointmentsWithoutdates.sort(sortWO);

    appointmentsWithdates.sort((app1, app2) => {
      const app1Time = app1.car_return_time || app1.planning_work_stop;
      const app2Time = app2.car_return_time || app2.planning_work_stop;

      if (moment(app1Time).isSame(app2Time)) return sortWO(app1, app2);

      return moment(app1Time).diff(app2Time);
    });

    return [...appointmentsWithdates, ...appointmentsWithoutdates, ...backorderAppointments];
  };

  getSortedMechanics = mechanics => {
    const { selectedLocation } = this.props.globalState;

    const mechanicsOrder = getPreference(`mechanics-order-${selectedLocation.id}`, []);
    const newMechanicsOrder = [],
      sortedMechanics = [];

    const availableMechanics = mechanics.filter(m => m.is_available).map(m => m.id);
    const unavailableMechanics = mechanics.filter(m => !m.is_available).map(m => m.id);

    if (!mechanicsOrder.length) {
      newMechanicsOrder.push(...availableMechanics, ...unavailableMechanics);
    } else {
      const orderedAvailableMechanics = mechanicsOrder.filter(id => availableMechanics.includes(id));
      const unorderedAvailableMechanics = availableMechanics.filter(id => !mechanicsOrder.includes(id));
      newMechanicsOrder.push(...orderedAvailableMechanics, ...unorderedAvailableMechanics, ...unavailableMechanics);
    }

    newMechanicsOrder.forEach(mechanicId => sortedMechanics.push(mechanics.find(mechanic => mechanic.id === mechanicId)));

    setPreference(`mechanics-order-${selectedLocation.id}`, newMechanicsOrder);

    return sortedMechanics;
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    this.props.applyUpdateToAppointment(updatePayload);
  };

  websocketAppointmentsRefresh = event => {
    let updatePayload = null;

    try {
      if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

      const { body } = updatePayload;

      if (body?.to_refresh?.some(tr => moment(tr).isSame(moment(), "day"))) {
        this.getDayplannerData();
        this.props.webSocketDayplannerUpdatesApplied();
      }
    } catch (err) {
      console.log(err);
    }
  };

  dayplannerSocketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    if (!updatePayload?.body?.update || (!updatePayload.body.appointment_id && !updatePayload.body.car_id)) return;

    const { body } = updatePayload;
    const { defaultAppointmentsColumn, defaultUnassignedColumn, defaultMechanicsColumn, defaultCarReadyColumn, defaultQualityControlColumn, draggedAppointment } =
      this.state;

    if (draggedAppointment && draggedAppointment.id === body.appointment_id) {
      this.setState(state => ({ dayplannerEventsToComplete: state.dayplannerEventsToComplete.concat(event) }));
      this.props.webSocketDayplannerUpdatesApplied();
      return;
    }

    const appointments = [...defaultAppointmentsColumn, ...defaultUnassignedColumn, ...defaultCarReadyColumn, ...defaultQualityControlColumn];
    const emptyMechanics = defaultMechanicsColumn.map(mechanic => {
      appointments.push(...mechanic.appointments);
      return { ...mechanic, appointments: [] };
    });

    const appointment = appointments.find(a => a.id === body.appointment_id || a.car_id === body.car_id);

    if (!appointment) return this.props.webSocketDayplannerUpdatesApplied();

    let newAppointment = { ...appointment };
    if (body.car_id) {
      const car_profile_picture = body.update.profile_picture ? body.update.profile_picture : newAppointment.car_profile_picture;
      newAppointment = { ...newAppointment, car_profile_picture, ...body.update };
    }

    const { authState, globalState } = this.props;

    applyUpdate(newAppointment, updatePayload, authState.user, globalState.notificationElements || []);

    if (["PinUpdateMessage", "PinDeleteMessage"].includes(updatePayload._topic) && newAppointment.interventions?.length && body.update.intervention_id) {
      const interventionIdx = newAppointment.interventions.findIndex(i => i.id === body.update.intervention_id);

      if (interventionIdx !== -1) {
        if (updatePayload._topic === "PinUpdateMessage") {
          const { appointment, intervention, ...update } = body.update;
          updatePinHistory(newAppointment.interventions[interventionIdx], update);
        } else {
          deletePinHistory(newAppointment.interventions[interventionIdx]);
        }
      }
    }

    const newAppointments = appointments.map(app => (app.id === newAppointment.id ? newAppointment : app));
    const data = this.getDataPerColumn(newAppointments, emptyMechanics);

    data.mechanicsColumn.forEach(mechanic => {
      // not needed, but things can go wrong, a mechanic with assignment should not be shown as not available
      if (!mechanic.is_available && mechanic.appointments.length > 0) mechanic.is_available = true;
    });

    this.setState(
      {
        appointments: data.appointments,
        appointmentsColumn: data.appointmentsColumn,
        defaultAppointmentsColumn: data.appointmentsColumn,
        unassignedColumn: data.unassignedColumn,
        defaultUnassignedColumn: data.unassignedColumn,
        mechanicsColumn: data.mechanicsColumn,
        defaultMechanicsColumn: data.mechanicsColumn,
        carReadyColumn: data.carReadyColumn,
        defaultCarReadyColumn: data.carReadyColumn,
        qualityControlColumn: data.qualityControlColumn,
        defaultQualityControlColumn: data.qualityControlColumn,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => {
        this.applySearchAndFilterIfNeeded();
      }
    );

    this.props.webSocketDayplannerUpdatesApplied();
  };

  websocketAppointmentAssignmentUpdate = event => {
    if (!event?.body || !event.body.appointment_id) return;

    const { body } = event;
    const { defaultAppointmentsColumn, defaultUnassignedColumn, defaultMechanicsColumn, defaultCarReadyColumn, defaultQualityControlColumn, draggedAppointment } =
      this.state;

    if (draggedAppointment && draggedAppointment.id === body.appointment_id) {
      this.setState(state => ({ appointmentEventsToComplete: state.appointmentEventsToComplete.concat(event) }));
      this.props.webSocketDayplannerUpdatesApplied();
      return;
    }

    const appointments = [...defaultAppointmentsColumn, ...defaultUnassignedColumn, ...defaultCarReadyColumn, ...defaultQualityControlColumn];
    const emptyMechanics = defaultMechanicsColumn.map(mechanic => {
      appointments.push(...mechanic.appointments);
      return { ...mechanic, appointments: [] };
    });

    const appointment = appointments.find(a => a.id === body.appointment_id);

    if (!appointment) return this.props.webSocketDayplannerUpdatesApplied();

    const newAppointment = {
      ...appointment,
      last_assigned_mechanic: body.assigned_mechanic ? body.assigned_mechanic : appointment.last_assigned_mechanic,
      assigned_mechanic: body.assigned_mechanic,
      assigned_mechanic_order: body.assigned_mechanic_order,
    };

    let newAppointments = appointments.map(app => (app.id === newAppointment.id ? newAppointment : app));
    const fromMechanic = defaultMechanicsColumn.find(mechanic => mechanic.appointments.some(app => app.id === newAppointment.id));

    if (fromMechanic)
      newAppointments = newAppointments.map(app =>
        app.assigned_mechanic === fromMechanic.id && app.id !== newAppointment.id && app.assigned_mechanic_order > appointment.assigned_mechanic_order
          ? { ...app, assigned_mechanic_order: --app.assigned_mechanic_order }
          : app
      );

    if (body.assigned_mechanic)
      newAppointments = newAppointments.map(app =>
        app.assigned_mechanic === body.assigned_mechanic && app.id !== newAppointment.id && app.assigned_mechanic_order >= body.assigned_mechanic_order
          ? { ...app, assigned_mechanic_order: ++app.assigned_mechanic_order }
          : app
      );

    const data = this.getDataPerColumn(newAppointments, emptyMechanics);

    data.mechanicsColumn.forEach(mechanic => {
      // not needed, but things can go wrong, a mechanic with assignment should not be shown as not available
      if (!mechanic.is_available && mechanic.appointments.length > 0) mechanic.is_available = true;
    });

    this.setState(
      {
        appointments: data.appointments,
        appointmentsColumn: data.appointmentsColumn,
        defaultAppointmentsColumn: data.appointmentsColumn,
        unassignedColumn: data.unassignedColumn,
        defaultUnassignedColumn: data.unassignedColumn,
        mechanicsColumn: data.mechanicsColumn,
        defaultMechanicsColumn: data.mechanicsColumn,
        carReadyColumn: data.carReadyColumn,
        defaultCarReadyColumn: data.carReadyColumn,
        qualityControlColumn: data.qualityControlColumn,
        defaultQualityControlColumn: data.qualityControlColumn,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => {
        this.applySearchAndFilterIfNeeded();
      }
    );

    this.props.webSocketDayplannerUpdatesApplied();
  };

  websocketMechanicAvailabilityUpdate = event => {
    if (!event?.body || !event.body.mechanic_id) return;

    const { body } = event;
    const newMechanics = this.state.mechanicsColumn.map(mechanic => (mechanic.id === body.mechanic_id ? { ...mechanic, is_available: body.is_available } : mechanic));
    const newDefaultMechanics = this.state.defaultMechanicsColumn.map(mechanic =>
      mechanic.id === body.mechanic_id ? { ...mechanic, is_available: body.is_available } : mechanic
    );
    const sortedMechanics = this.getSortedMechanics(newMechanics);

    this.setState(
      {
        mechanicsColumn: sortedMechanics,
        defaultMechanicsColumn: newDefaultMechanics,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => {
        this.applySearchAndFilterIfNeeded();
      }
    );

    this.props.webSocketDayplannerUpdatesApplied();
  };

  completeEvents = () => {
    const { appointmentEventsToComplete, dayplannerEventsToComplete } = this.state;

    if (!appointmentEventsToComplete.length && !dayplannerEventsToComplete.length) return;

    this.setState({ isLoading: true, scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => {
      appointmentEventsToComplete.length && appointmentEventsToComplete.forEach(event => this.websocketAppointmentAssignmentUpdate(event));
      dayplannerEventsToComplete.length && dayplannerEventsToComplete.forEach(event => this.dayplannerSocketAppointmentUpdate(event));

      this.setState({
        isLoading: false,
        appointmentEventsToComplete: [],
        dayplannerEventsToComplete: [],
      });
    });
  };

  handleCarClick = (car_id, dealer_location_id, e) => {
    e && e.stopPropagation();

    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isCarDetailVisible: true,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleAppointmentClick = (e, appointment) => {
    e && e.stopPropagation();

    this.setState(
      {
        selectedAppointment: { id: appointment.id, dealer_location_id: appointment.dealer_location_id },
        isLoading: true,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => this.props.getAppointment(appointment.id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value, extendedSearchResults: [] }, _.debounce(this.applySearchAndFilter, 500));
  };

  handleExtendedSearch = () => {
    const { selectedDealer, selectedLocation } = this.props.globalState;
    const { searchTerm } = this.state;

    if (!searchTerm) return;

    this.setState({ extendedSearchLoading: true }, async () => {
      try {
        const response = await Service.searchAppointments({
          term: searchTerm,
          dealer_id: selectedDealer.id,
          dealer_location_id: selectedLocation?.id,
          page: 0,
          limit: 20,
        });

        const results = response.data?.data
          ? response.data.data
              .map((r, i) => ({
                id: r.id,
                car_id: r.car_id,
                appointment_id: r.id,
                title: i + "",
                make: r.car_make,
                model: r.car_model,
                date: r.time_car_app,
                wo_number: r.wo_nr,
                ref_nr: r.ref_nr,
                reg_number: r.reg_number,
                status_identifier: r.appointment_status_identifier,
                dealer_location_name: r.dealer_location?.name || "",
              }))
              .sort((a, b) => (b.date > a.date ? 1 : -1))
          : [];

        this.setState({ extendedSearchResults: results, extendedSearchLoading: false });
      } catch (err) {
        console.log(err);

        this.setState({ extendedSearchResults: [], extendedSearchLoading: false });
      }
    });
  };

  handleClearSearch = () => this.setState({ searchTerm: "", extendedSearchResults: [] }, () => this.applySearchAndFilter());

  applySearchAndFilterIfNeeded = () => {
    const { activeFilter, searchTerm, backorderFilterInactive } = this.state;

    if (activeFilter || searchTerm) this.applySearchAndFilter();
    this.handleBackOrderStatusFilterClick(backorderFilterInactive);
  };

  applySearchAndFilter = () => {
    const {
      activeFilter,
      searchTerm,
      defaultUnassignedColumn,
      defaultAppointmentsColumn,
      defaultMechanicsColumn,
      defaultCarReadyColumn,
      defaultQualityControlColumn,
      backorderFilterInactive,
    } = this.state;

    const updateAppointmentActivity = app =>
      app.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER
        ? { ...app, inactive_: backorderFilterInactive }
        : app.inactive_
        ? { ...app, inactive_: false }
        : app;

    let filteredAppointmentsColumn = defaultAppointmentsColumn.map(updateAppointmentActivity);
    let filteredUnassignedColumn = defaultUnassignedColumn.map(updateAppointmentActivity);
    let filteredCarReadyColumn = defaultCarReadyColumn.map(updateAppointmentActivity);
    let filteredQualityControlColumn = defaultQualityControlColumn.map(updateAppointmentActivity);
    let filteredMechanicsColumn = defaultMechanicsColumn.map(mechanic => {
      return {
        ...mechanic,
        appointments: mechanic.appointments.map(updateAppointmentActivity),
      };
    });

    const searchedAppointment = (app, lowerCaseSearchTerm) => {
      if (app.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER && backorderFilterInactive) return false;

      return (
        app.wo_nr.toLowerCase().includes(lowerCaseSearchTerm) ||
        app.reg_number.toLowerCase().includes(lowerCaseSearchTerm) ||
        `${app.driver_firstname} ${app.driver_surname}`.toLowerCase().includes(lowerCaseSearchTerm) ||
        `${app.owner_firstname} ${app.owner_surname}`.toLowerCase().includes(lowerCaseSearchTerm)
      );
    };

    if (activeFilter) {
      const handleFilterAppointments = app =>
        app.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER
          ? { ...app, inactive_: backorderFilterInactive }
          : ShouldBeFiltered(app, activeFilter, backorderFilterInactive)
          ? app
          : { ...app, inactive_: true };

      filteredMechanicsColumn = filteredMechanicsColumn.map(mechanic => {
        return {
          ...mechanic,
          appointments: mechanic.appointments.map(handleFilterAppointments),
        };
      });

      filteredAppointmentsColumn = filteredAppointmentsColumn.map(handleFilterAppointments);
      filteredUnassignedColumn = filteredUnassignedColumn.map(handleFilterAppointments);
      filteredCarReadyColumn = filteredCarReadyColumn.map(handleFilterAppointments);
      filteredQualityControlColumn = filteredQualityControlColumn.map(handleFilterAppointments);
    }

    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filteredAppointmentsColumn = filteredAppointmentsColumn.map(app => (searchedAppointment(app, lowerCaseSearchTerm) ? app : { ...app, inactive_: true }));
      filteredUnassignedColumn = filteredUnassignedColumn.map(app => (searchedAppointment(app, lowerCaseSearchTerm) ? app : { ...app, inactive_: true }));
      filteredCarReadyColumn = filteredCarReadyColumn.map(app => (searchedAppointment(app, lowerCaseSearchTerm) ? app : { ...app, inactive_: true }));
      filteredQualityControlColumn = filteredQualityControlColumn.map(app => (searchedAppointment(app, lowerCaseSearchTerm) ? app : { ...app, inactive_: true }));
      filteredMechanicsColumn = filteredMechanicsColumn.map(mechanic => {
        return {
          ...mechanic,
          appointments: mechanic.appointments.map(app => (searchedAppointment(app, lowerCaseSearchTerm) ? app : { ...app, inactive_: true })),
        };
      });
    }

    this.setState({
      unassignedColumn: filteredUnassignedColumn,
      appointmentsColumn: filteredAppointmentsColumn,
      mechanicsColumn: filteredMechanicsColumn,
      carReadyColumn: filteredCarReadyColumn,
      qualityControlColumn: filteredQualityControlColumn,
      scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
    });
  };

  handleMechanicFilter = (_e, data) => {
    this.setState({ selectedMechanicFilter: data.value }, () => {
      setPreference("dayplanner-selectedMechanicFilter", data.value);
    });
  };

  handleToggleIsAvailableMechanic = (evt, mechanic) => {
    evt.stopPropagation();

    const is_available = !mechanic.is_available;

    Service.setMechanicActive({ user_id: mechanic.id, is_available })
      .then(() => {
        const { mechanicsColumn, defaultMechanicsColumn } = this.state;

        const updatedMechanics = mechanicsColumn.map(m => (m.id === mechanic.id ? { ...m, is_available } : m));
        const updatedDefaultMechanics = defaultMechanicsColumn.map(m => (m.id === mechanic.id ? { ...m, is_available } : m));
        const sortedMechanics = this.getSortedMechanics(updatedMechanics);

        this.setState({
          mechanicsColumn: sortedMechanics,
          defaultMechanicsColumn: updatedDefaultMechanics,
          scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
        });
      })
      .catch(err => console.log(err));
  };

  handleToggleExpandMechanic = (evt, mechanic) => {
    evt.stopPropagation();

    const expanded = !mechanic.expanded;

    const { mechanicsColumn, defaultMechanicsColumn } = this.state;

    const updatedMechanics = mechanicsColumn.map(m => (m.id === mechanic.id ? { ...m, expanded, auto_expand: false } : m));
    const updatedDefaultMechanics = defaultMechanicsColumn.map(m => (m.id === mechanic.id ? { ...m, expanded, auto_expand: false } : m));
    const sortedMechanics = this.getSortedMechanics(updatedMechanics);

    this.setState({
      mechanicsColumn: sortedMechanics,
      defaultMechanicsColumn: updatedDefaultMechanics,
      scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
    });
  };

  onSortMechanicStart = () => {
    this.appointmentsColumnRef.current.classList.add("-column-not-allowed-to-drop");
    this.unassignedColumnRef.current.classList.add("-column-not-allowed-to-drop");
    this.carReadyColumnRef.current.classList.add("-column-not-allowed-to-drop");
    this.qualityControlColumnRef.current.classList.add("-column-not-allowed-to-drop");
  };

  shouldCancelSortMechanicStart = e =>
    !(e.target.classList.contains("MechanicBox") || e.target.classList.contains("MechanicBox-header") || e.target.classList.contains("MechanicBox-side"));

  onSortMechanicEnd = ({ oldIndex, newIndex }) => {
    const updatedMechanics = [...this.state.mechanicsColumn];

    const mechanic = updatedMechanics.splice(oldIndex, 1);
    updatedMechanics.splice(newIndex, 0, mechanic[0]);

    this.setState({ mechanicsColumn: updatedMechanics, scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => {
      setPreference(
        `mechanics-order-${this.props.globalState.selectedLocation.id}`,
        updatedMechanics.map(mechanic => mechanic.id)
      );
      this.appointmentsColumnRef.current.classList.remove("-column-not-allowed-to-drop");
      this.unassignedColumnRef.current.classList.remove("-column-not-allowed-to-drop");
      this.carReadyColumnRef.current.classList.remove("-column-not-allowed-to-drop");
      this.qualityControlColumnRef.current.classList.remove("-column-not-allowed-to-drop");
    });
  };

  cancelDragAppointment = (e = null) => {
    const { draggedAppointment, draggedFromMechanic } = this.state;

    if (!draggedAppointment || e?.defaultPrevented) return;

    if (draggedFromMechanic) this.cancelDragAppointmentFromAssignedMechanic(draggedFromMechanic);
    else this.cancelDragAppointmentFromUnassignedColumn(draggedAppointment);

    this.onDragAppointmentEnd();
  };

  cancelDragAppointmentIfOutOfScreen = e => {
    if (e.clientX === 0 && e.clientY === 0) this.cancelDragAppointment(e);
  };

  cancelDragAppointmentFromAssignedMechanic = draggedFromMechanic => {
    const newMechanics = this.state.mechanicsColumn.map(mechanic => (mechanic.id === draggedFromMechanic.id ? draggedFromMechanic : mechanic));

    this.setState(
      {
        draggedAppointment: null,
        draggedFromMechanic: null,
        mechanicsColumn: newMechanics,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => this.completeEvents()
    );
  };

  cancelDragAppointmentFromUnassignedColumn = draggedAppointment => {
    const newUnassignedColumn = this.sortAppointments([...this.state.unassignedColumn, draggedAppointment]);

    this.setState(
      {
        draggedAppointment: null,
        draggedFromMechanic: null,
        unassignedColumn: newUnassignedColumn,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => this.completeEvents()
    );
  };

  onDragAppointmentStart = (e, appointment, mechanic) => {
    e.persist();

    this.appointmentsColumnRef.current.classList.add("-column-not-allowed-to-drop");
    this.carReadyColumnRef.current.classList.add("-column-not-allowed-to-drop");
    this.qualityControlColumnRef.current.classList.add("-column-not-allowed-to-drop");

    if (appointment.assigned_mechanic) {
      const newMechanics = [...this.state.mechanicsColumn];
      const mechanicIdx = newMechanics.findIndex(m => m.id === mechanic.id);
      const newSourceMechanic = { ...newMechanics[mechanicIdx] };

      newSourceMechanic.appointments = newSourceMechanic.appointments.reduce((newApps, app) => {
        if (app.assigned_mechanic_order > appointment.assigned_mechanic_order) app = { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };

        if (app.id !== appointment.id) newApps.push(app);

        return newApps;
      }, []);

      newMechanics[mechanicIdx] = newSourceMechanic;

      window.requestAnimationFrame(() => {
        // needed for the browser to snapshot what's dragged, otherwise re-render can remove it before it's done
        this.setState({
          mechanicsColumn: newMechanics,
          draggedAppointment: appointment,
          draggedFromMechanic: mechanic,
          scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
        });
      });
    } else {
      const newUnassignedColumn = this.state.unassignedColumn.filter(app => app.id !== appointment.id);

      window.requestAnimationFrame(() => {
        // needed for the browser to snapshot what's dragged, otherwise re-render can remove it before it's done
        this.setState({
          unassignedColumn: newUnassignedColumn,
          draggedAppointment: appointment,
          scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
        });
      });
    }
  };

  onDragAppointmentEnd = () => {
    this.appointmentsColumnRef.current.classList.remove("-column-not-allowed-to-drop");
    this.carReadyColumnRef.current.classList.remove("-column-not-allowed-to-drop");
    this.qualityControlColumnRef.current.classList.remove("-column-not-allowed-to-drop");

    this.setState(prevState => {
      const updatedMechanicsColumn = prevState.mechanicsColumn.map(mechanic => ({ ...mechanic, auto_expand: false }));
      const updatedDefaultMechanicsColumn = prevState.defaultMechanicsColumn.map(mechanic => ({ ...mechanic, auto_expand: false }));

      return {
        mechanicsColumn: updatedMechanicsColumn,
        defaultMechanicsColumn: updatedDefaultMechanicsColumn,
      };
    });
  };

  handleDraggedAppointmentEnterUnassignColumn = e => {
    e.preventDefault();
    e.currentTarget.classList.add("-on-drag-hover");
  };

  handleDraggedAppointmentLeaveUnassignColumn = e => {
    e.preventDefault();
    e.currentTarget.classList.remove("-on-drag-hover");
  };

  handleDropAppointmentToUnassignColumn = e => {
    e.stopPropagation();
    e.preventDefault();

    const { draggedAppointment, draggedFromMechanic, unassignedColumn, mechanicsColumn, defaultMechanicsColumn, defaultUnassignedColumn } = this.state;

    if (!draggedAppointment) return;

    if (!draggedFromMechanic) return this.cancelDragAppointment();

    const newDraggedAppointment = { ...draggedAppointment };

    newDraggedAppointment.assigned_mechanic = null;
    newDraggedAppointment.assigned_mechanic_order = 0;

    const sourceMechanic = mechanicsColumn.find(m => m.id === draggedFromMechanic.id);
    const newDefaultMechanics = defaultMechanicsColumn.map(m => (m.id === draggedFromMechanic.id ? sourceMechanic : m));
    const newUnassignedColumn = this.sortAppointments(unassignedColumn.concat(newDraggedAppointment));
    const newDefaultUnassignedColumn = defaultUnassignedColumn.concat(newDraggedAppointment);

    this.setState(
      {
        isLoading: true,
        defaultMechanicsColumn: newDefaultMechanics,
        unassignedColumn: newUnassignedColumn,
        defaultUnassignedColumn: newDefaultUnassignedColumn,
        draggedAppointment: null,
        draggedFromMechanic: null,
      },
      () => this.unassignAppointment(e, { appointment_id: newDraggedAppointment.id })
    );

    e.currentTarget.classList.remove("-on-drag-hover");
    this.onDragAppointmentEnd();
  };

  unassignAppointment = async (e, requestData) => {
    try {
      await Service.unassignAppointment(requestData);
      this.setState({ isLoading: false }, () => this.completeEvents());
    } catch (error) {
      this.getDayplannerData(); // reload needed, we might be outdated
      setAlert({ type: "error", title: this.props.t("error_reassign_appointment").message || "Failed to re-assign appointment" });
    }
  };

  handleDraggedAppointmentEnterMechanicPlaceholder = e => {
    e.preventDefault();
    e.currentTarget.classList.add("-on-drag-hover");
  };

  handleDraggedAppointmentLeaveMechanicPlaceholder = e => {
    e.preventDefault();
    e.currentTarget.classList.remove("-on-drag-hover");

    this.unshiftMechanicAppointments(e);
  };

  unshiftMechanicAppointments = e => {
    const { draggedAppointment, mechanicsColumn, defaultMechanicsColumn } = this.state;
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    const destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);

    if (!draggedAppointment || !destMechanicId) return;

    let destMechanic = { ...mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };
    const hasHigherOrder = destMechanic.appointments.some(app => app.assigned_mechanic_order > destMechanicOrder);

    if (!hasHigherOrder) return;

    destMechanic = {
      ...destMechanic,
      appointments: destMechanic.appointments.map(app =>
        app.assigned_mechanic_order > destMechanicOrder ? { ...app, assigned_mechanic_order: --app.assigned_mechanic_order } : app
      ),
    };

    const newMechanics = mechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic));
    const newDefaultMechanics = defaultMechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic));
    this.setState({
      mechanicsColumn: newMechanics,
      defaultMechanicsColumn: newDefaultMechanics,
      scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
    });
  };

  handleDropAppointmentToMechanicPlaceholder = e => {
    e.stopPropagation();
    e.preventDefault();

    const { draggedAppointment, draggedFromMechanic } = this.state;

    if (!draggedAppointment) return;

    const newDraggedAppointment = { ...draggedAppointment };

    if (draggedFromMechanic) this.handleReassignAppointmentToMechanicPlaceholder(e, newDraggedAppointment, draggedFromMechanic);
    else this.handleAssignAppointmentToMechanicPlaceholder(e, newDraggedAppointment);

    e.currentTarget.classList.remove("-on-drag-hover");
    this.onDragAppointmentEnd();
  };

  handleReassignAppointmentToMechanicPlaceholder = (e, newDraggedAppointment, draggedFromMechanic) => {
    const { mechanicsColumn, defaultMechanicsColumn } = this.state;
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    let destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);
    const newMechanic = { ...mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };

    if (newMechanic.appointments.length > 9) return; // being safe, not supposed to happen

    if (destMechanicOrder > 1 && newMechanic.appointments.length < destMechanicOrder - 1) destMechanicOrder -= destMechanicOrder - 1 - newMechanic.appointments.length;

    if (newMechanic.id === draggedFromMechanic.id && newDraggedAppointment.assigned_mechanic_order === destMechanicOrder) {
      return this.cancelDragAppointment();
    }

    newDraggedAppointment.assigned_mechanic = newMechanic.id;
    newDraggedAppointment.assigned_mechanic_order = destMechanicOrder;

    newMechanic.appointments = newMechanic.appointments.concat(newDraggedAppointment);
    const newMechanics = mechanicsColumn.map(mechanic => (mechanic.id === newMechanic.id ? newMechanic : mechanic));

    let newDefaultMechanics;
    if (newMechanic.id === draggedFromMechanic.id) {
      newDefaultMechanics = defaultMechanicsColumn.map(m => (m.id === draggedFromMechanic.id ? newMechanic : m));
    } else {
      const sourceMechanic = mechanicsColumn.find(m => m.id === draggedFromMechanic.id);
      newDefaultMechanics = defaultMechanicsColumn.map(m => {
        if (m.id === draggedFromMechanic.id) return sourceMechanic;
        else if (m.id === destMechanicId) return newMechanic;
        else return m;
      });
    }

    this.setState(
      {
        isLoading: true,
        mechanicsColumn: newMechanics,
        defaultMechanicsColumn: newDefaultMechanics,
        draggedAppointment: null,
        draggedFromMechanic: null,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => this.reassignAppointment(e, { appointment_id: newDraggedAppointment.id, user_id: newMechanic.id, order: destMechanicOrder })
    );
  };

  reassignAppointment = async (e, requestData) => {
    try {
      await Service.reassignAppointment(requestData);
      this.setState({ isLoading: false, scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => this.completeEvents());
    } catch (error) {
      this.getDayplannerData(); // reload needed, we might be outdated
      setAlert({ type: "error", title: this.props.t("error_reassign_appointment").message || "Failed to re-assign appointment" });
    }
  };

  handleAssignAppointmentToMechanicPlaceholder = (e, newDraggedAppointment) => {
    const { mechanicsColumn, defaultMechanicsColumn, defaultUnassignedColumn } = this.state;
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    let destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);
    const newMechanic = { ...mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };

    if (newMechanic.appointments.length > 9) return; // being safe, not supposed to happen

    if (destMechanicOrder > 1 && newMechanic.appointments.length < destMechanicOrder - 1) destMechanicOrder -= destMechanicOrder - 1 - newMechanic.appointments.length;

    newDraggedAppointment.assigned_mechanic = newMechanic.id;
    newDraggedAppointment.assigned_mechanic_order = destMechanicOrder;

    newMechanic.appointments = newMechanic.appointments.concat(newDraggedAppointment);
    const newMechanics = mechanicsColumn.map(mechanic => (mechanic.id === newMechanic.id ? newMechanic : mechanic));
    const newDefaultMechanics = defaultMechanicsColumn.map(mechanic => (mechanic.id === newMechanic.id ? newMechanic : mechanic));
    const newDefaultUnassignedColumn = defaultUnassignedColumn.filter(app => app.id !== newDraggedAppointment.id);

    this.setState(
      {
        mechanicsColumn: newMechanics,
        defaultMechanicsColumn: newDefaultMechanics,
        defaultUnassignedColumn: newDefaultUnassignedColumn,
        draggedAppointment: null,
        draggedFromMechanic: null,
        scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
      },
      () => this.assignAppointment(e, { appointment_id: newDraggedAppointment.id, user_id: newMechanic.id, order: destMechanicOrder })
    );
  };

  assignAppointment = async (e, requestData) => {
    try {
      await Service.assignAppointment(requestData);
      this.setState({ isLoading: false, scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => this.completeEvents());
    } catch (error) {
      this.getDayplannerData(); // reload needed, we might be outdated
      setAlert({ type: "error", title: this.props.t("error_assign_appointment").message || "Failed to assign appointment" });
    }
  };

  handleDraggedAppointmentEnterAnotherAppointment = e => {
    e.preventDefault();

    const { draggedAppointment, mechanicsColumn, defaultMechanicsColumn, numberOfCardShownInMechanicColumn } = this.state;
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);

    if (!draggedAppointment) return;

    const destMechanicIndex = mechanicsColumn.findIndex(mechanic => mechanic.id === destMechanicId);
    if (destMechanicIndex === -1) return;

    const destMechanic = mechanicsColumn[destMechanicIndex];

    if (!destMechanic.expanded && destMechanic.appointments.length >= numberOfCardShownInMechanicColumn) {
      const newMechanicsColumn = mechanicsColumn.map(mechanic => (mechanic.id === destMechanicId ? { ...mechanic, expanded: true, auto_expand: true } : mechanic));
      const newDefaultMechanicsColumn = defaultMechanicsColumn.map(mechanic =>
        mechanic.id === destMechanicId ? { ...mechanic, expanded: true, auto_expand: true } : mechanic
      );

      this.setState({ mechanicsColumn: newMechanicsColumn, defaultMechanicsColumn: newDefaultMechanicsColumn });
    } else {
      if (destMechanicId) this.shiftMechanicAppointments(e, destMechanicId);
      else e.target.parentElement.classList.add("-on-drag-hover");
    }
  };

  shiftMechanicAppointments = (e, destMechanicId) => {
    const { mechanicsColumn, defaultMechanicsColumn } = this.state;
    const destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);
    let destMechanic = { ...mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };

    if (destMechanic.appointments.length > 9) return;

    destMechanic = {
      ...destMechanic,
      appointments: destMechanic.appointments.map(app => {
        return app.assigned_mechanic_order >= destMechanicOrder ? { ...app, assigned_mechanic_order: ++app.assigned_mechanic_order } : app;
      }),
    };

    const newMechanics = mechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic));
    const newDefaultMechanics = defaultMechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic));
    this.setState({
      mechanicsColumn: newMechanics,
      defaultMechanicsColumn: newDefaultMechanics,
      scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
    });
  };

  handleDropAppointmentToAnotherAppointment = e => {
    e.stopPropagation();
    e.preventDefault();

    const { draggedAppointment, draggedFromMechanic } = this.state;

    if (!draggedAppointment) return;

    if (draggedFromMechanic) this.handleDropAppointmentToUnassignColumn(e);
    else this.cancelDragAppointment();
  };

  handleStatusFilterClick = activeFilter => {
    this.setState({ activeFilter, scrollTop: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => {
      this.applySearchAndFilter();
      setPreference("dayplanner-activeFilter", activeFilter);
    });
  };

  handleBackOrderStatusFilterClick = isInactive => {
    const { appointmentsColumn, unassignedColumn, mechanicsColumn, carReadyColumn, qualityControlColumn } = this.state;

    const updateAppointmentActivity = app => (app.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER ? { ...app, inactive_: isInactive } : app);

    let filteredAppointmentsColumn = appointmentsColumn.map(updateAppointmentActivity);
    let filteredUnassignedColumn = unassignedColumn.map(updateAppointmentActivity);
    let filteredCarReadyColumn = carReadyColumn.map(updateAppointmentActivity);
    let filteredQualityControlColumn = qualityControlColumn.map(updateAppointmentActivity);
    let filteredMechanicsColumn = mechanicsColumn.map(mechanic => {
      return {
        ...mechanic,
        appointments: mechanic.appointments.map(updateAppointmentActivity),
      };
    });

    this.setState({
      unassignedColumn: filteredUnassignedColumn,
      appointmentsColumn: filteredAppointmentsColumn,
      mechanicsColumn: filteredMechanicsColumn,
      carReadyColumn: filteredCarReadyColumn,
      qualityControlColumn: filteredQualityControlColumn,
      backorderFilterInactive: isInactive,
      scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop,
    });
  };

  handleToggleCollapseColumn = column => {
    const collapsedColumns = [...this.state.collapsedColumns];
    const columnIndex = collapsedColumns.findIndex(col => col === column);

    if (columnIndex !== -1) collapsedColumns.splice(columnIndex, 1);
    else collapsedColumns.push(column);

    this.setState({ collapsedColumns, scrollTo: document.getElementsByClassName("-mechanics-tasks")[0].scrollTop }, () => {
      setPreference("dayplanner-collapsedColumns", collapsedColumns);
    });
  };

  renderAppointmentDetailPage = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetailPage = () => {
    const { selectedCarInfo } = this.state;

    if (!selectedCarInfo?.car) return null;

    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  getStatusIcon = identifier => {
    switch (identifier) {
      case APPOINTMENT_STATUSES.NEW_CAR:
        return <FontAwesomeIcon icon={faCarAlt} />;

      case APPOINTMENT_STATUSES.CUSTOMER_ANSWERED:
        return <FontAwesomeIcon icon={faUserCheck} />;

      case APPOINTMENT_STATUSES.CAR_IN_SHOP:
        return <FontAwesomeIcon icon={faCarGarage} />;

      default:
        return null;
    }
  };

  renderStatusIcon = id => {
    const { selectedLocation } = this.props.globalState;

    let statusToRender = selectedLocation.statuses.find(s => s.identifier === id);

    if (!statusToRender) return "";

    if ([APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CUSTOMER_ANSWERED, APPOINTMENT_STATUSES.CAR_IN_SHOP].includes(statusToRender.identifier)) {
      return (
        <span
          className="StatusIcon"
          style={{
            backgroundColor: statusToRender.color,
          }}
        >
          {this.getStatusIcon(statusToRender.identifier)}
        </span>
      );
    }

    if (id === APPOINTMENT_STATUSES.BACK_ORDER) {
      return (
        <span className="StatusIcon" style={{ backgroundColor: statusToRender.color }}>
          BO
        </span>
      );
    }

    return (
      <span className="StatusIcon" style={{ backgroundColor: statusToRender.color }}>
        <i className="material-icons">{statusToRender.icon}</i>
      </span>
    );
  };

  renderExtendedSearchResult = result => {
    if (!result) return;

    return (
      <div key={result.id}>
        <span className="SearchResult-Title -text-ellipsis">{`${result.make} ${result.model}`}</span>
        <span className="SearchResult-Detail">
          <>
            {moment(result.date).format("DD-MM-YYYY")} | {result.wo_number && result.wo_number + " |"} {result.ref_nr && result.ref_nr + " | "}
            <span
              className="SearchResult-RegNumber"
              onClick={e => {
                e.stopPropagation();
                this.handleCarClick(result.car_id, result.dealer_location_id);
              }}
            >
              {result.reg_number}
            </span>
            {` | ${result.dealer_location_name} `}
            {this.renderStatusIcon(result.status_identifier)}
          </>
        </span>
      </div>
    );
  };

  renderMechanicsColumn = () => {
    const { mechanicsColumn, selectedMechanicFilter, numberOfCardShownInMechanicColumn, activeFilter, searchTerm } = this.state;

    const SortableItem = SortableElement(({ mechanic }) => {
      const hasActiveCollapsedAppointments =
        (activeFilter || searchTerm) &&
        !mechanic.expanded &&
        mechanic.appointments.some((app, i) => !app.inactive_ && app.assigned_mechanic_order > numberOfCardShownInMechanicColumn);

      return (
        <div className={`MechanicBox ${!mechanic.is_available ? "-not-available" : ""}`}>
          {mechanic.is_available && this.renderMechanicContent(mechanic)}

          <div className="MechanicBox-side">
            <div className="MechanicBox-profile">
              <Popup
                trigger={
                  mechanic.profile_picture ? (
                    <img
                      alt={`${mechanic.first_name?.toUpperCase().charAt(0) || ""} ${mechanic.last_name?.toUpperCase().charAt(0) || ""}`}
                      src={mechanic.profile_picture}
                    />
                  ) : (
                    <div>
                      {mechanic.first_name?.toUpperCase().charAt(0) || ""}
                      {mechanic.last_name?.toUpperCase().charAt(0) || ""}
                    </div>
                  )
                }
                content={
                  <span className="MechanicBox-name">
                    {mechanic.first_name} {mechanic.last_name}
                  </span>
                }
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "offsetParent",
                  },
                }}
              />
            </div>

            <div className="MechanicBox-appointments">
              <Label
                className={`extend-appointments-button ${hasActiveCollapsedAppointments ? "-border-green" : ""} ${
                  mechanic.appointments.length < numberOfCardShownInMechanicColumn ? "-no-pointer" : !mechanic.appointments.length ? "-disabled" : ""
                }`}
                basic
                onClick={e => (mechanic.appointments.length < numberOfCardShownInMechanicColumn ? null : this.handleToggleExpandMechanic(e, mechanic))}
              >
                <span>{mechanic.appointments.length}</span>
                {mechanic.appointments.length > numberOfCardShownInMechanicColumn && (
                  <span className="extend-appointments">
                    <FontAwesomeIcon icon={faEllipsisH} color="green" />
                  </span>
                )}
              </Label>
            </div>

            <div className="MechanicBox-toggle">{this.renderMechanicsAvailabilityToggle(mechanic)}</div>
          </div>
        </div>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="Dayplanner-column -mechanics-tasks">
          {items.map((mechanic, index) => {
            if (
              (selectedMechanicFilter === MECHANIC_FILTER.HIDE_OCCUPIED_MECHANICS && mechanic.appointments.length > 0) ||
              (selectedMechanicFilter === MECHANIC_FILTER.HIDE_UNOCCUPIED_MECHANICS && mechanic.appointments.length === 0)
            )
              return null;

            return <SortableItem mechanic={mechanic} key={`sortable-${mechanic.id}`} index={index} />;
          })}
        </div>
      );
    });

    return (
      <SortableList
        items={mechanicsColumn}
        onSortStart={this.onSortMechanicStart}
        shouldCancelStart={this.shouldCancelSortMechanicStart}
        onSortEnd={this.onSortMechanicEnd}
        helperClass="DayPlannerMechanicBoxSortableHelper"
      />
    );
  };

  renderMechanicContent = mechanic => {
    const { numberOfCardShownInMechanicColumn } = this.state;

    const content = [];
    let rows = 1;
    if (mechanic.expanded || mechanic.auto_expand) {
      rows = Math.ceil(mechanic.appointments.length / numberOfCardShownInMechanicColumn);
      if (mechanic.appointments.length % numberOfCardShownInMechanicColumn === 0 && mechanic.appointments.length < 10) rows++;
    }

    for (let row = 0; row < rows; row++) {
      content.push([]);

      for (let i = row * numberOfCardShownInMechanicColumn + numberOfCardShownInMechanicColumn; i > row * numberOfCardShownInMechanicColumn; i--) {
        if (i > 10) {
          content[row].push(this.renderEmptyPlaceholder(i));
          continue;
        }
        const app = mechanic.appointments.find(app => app.assigned_mechanic_order === i);

        if (app) content[row].push(this.renderAppointmentCard(app, DAYPLANNER_COLUMNS.MECHANICS, mechanic));
        else content[row].push(this.renderMechanicPlaceholder(mechanic.id, i));
      }
    }

    return (
      <div className="MechanicBox-contentContainer">
        {content.map((rowContent, index) => (
          <div key={index} className="MechanicBox-content">
            {rowContent}
          </div>
        ))}
      </div>
    );
  };

  renderMechanicPlaceholder(mechanicId, order) {
    const { activeFilter } = this.state;

    return (
      <div
        key={`placeholder-${mechanicId}-${order}`}
        className={`MechanicBox-placeholder ${activeFilter ? "-inactive" : ""}`}
        data-mechanic-order={order}
        data-mechanic-id={mechanicId}
        onDragEnter={this.handleDraggedAppointmentEnterMechanicPlaceholder}
        onDragLeave={this.handleDraggedAppointmentLeaveMechanicPlaceholder}
        onDrop={this.handleDropAppointmentToMechanicPlaceholder}
        onDragOver={e => e.preventDefault()}
      >
        <div className="MechanicBox-placeholder__number">{order}</div>
      </div>
    );
  }

  renderEmptyPlaceholder = order => {
    return <div key={`placeholder-${order}`} className={`MechanicBox-placeholder -empty`}></div>;
  };

  renderAppointmentCard = (appointment, column = 0, mechanic = null) => {
    const {
      globalState: { selectedLocation },
    } = this.props;
    const { collapsedColumns } = this.state;

    if (appointment.inactive_ && column !== DAYPLANNER_COLUMNS.MECHANICS) return;

    const isDraggable = [DAYPLANNER_COLUMNS.UNASSIGNED, DAYPLANNER_COLUMNS.MECHANICS].includes(column);
    const planningWorkStop = appointment.car_return_time || appointment.planning_work_stop;
    let extraClasses = isDraggable ? " -cursor-move" : "";

    if (planningWorkStop) {
      const diff = moment(planningWorkStop).diff(moment(), "minutes");

      if (diff <= 0) extraClasses += " -bg-color-red";
      else if (diff <= 60) extraClasses += " -bg-color-yellow";
    }

    if (appointment.internal) extraClasses += " -appointment-internal";
    if (appointment.has_panic) extraClasses += " -panic";
    if (column === DAYPLANNER_COLUMNS.MECHANICS && appointment.inactive_) extraClasses += " -inactive";

    if (collapsedColumns.includes(column)) {
      return (
        <Popup
          basic
          trigger={
            <div className="BoardItem-appointment-status -collapsed" onClick={e => !isDraggable && this.handleAppointmentClick(e, appointment)}>
              <AppointmentCardStatus
                value={{ id: appointment.appointment_status_identifier, customcom_status: appointment.customcom_status, check_paused_at: appointment.check_paused_at }}
                statuses={selectedLocation.statuses}
                car_out_of_shop={appointment.car_out_of_shop}
              />
            </div>
          }
          content={
            <div key={"app-" + appointment.id} className={"BoardItem -popup" + extraClasses}>
              <div className="BoardItem-right-top">
                <div className="BoardItem-appointment-status">
                  <AppointmentCardStatus
                    value={{
                      id: appointment.appointment_status_identifier,
                      customcom_status: appointment.customcom_status,
                      check_paused_at: appointment.check_paused_at,
                    }}
                    statuses={selectedLocation.statuses}
                    car_out_of_shop={appointment.car_out_of_shop}
                  />
                </div>

                {appointment.kiosk_label_number > 0 && (
                  <Label className="BoardItem-kiosk-label" color="green">
                    <FontAwesomeIcon icon={faTag} />
                    <span>{appointment.kiosk_label_number}</span>
                  </Label>
                )}
              </div>

              <div className="BoardItem-info -cursor-move">
                <span className="-cursor-pointer">{appointment.reg_number ? `${appointment.reg_number}` : ""}</span>
                {appointment.reg_number ? " - " : ""}

                <span className="-cursor-pointer wo-nr">{appointment.wo_nr}</span>
              </div>

              <div className="BoardItem-last-update">{planningWorkStop && moment(planningWorkStop).format("DD-MM-YYYY HH:mm")}</div>

              <div className="BoardItem-bottom-elements">
                <div className="BoardItem-appointment-special-indicator">{this.renderNotificationIcons(appointment)}</div>

                {[DAYPLANNER_COLUMNS.UNASSIGNED, DAYPLANNER_COLUMNS.CAR_READY].includes(column) && (
                  <div className="BoardItem-planning-mechanic">{this.renderMechanicInfo(appointment, column)}</div>
                )}
              </div>
            </div>
          }
          style={{ padding: "0", boxShadow: "none", border: "none" }}
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "offsetParent",
            },
            flip: {
              flipVariationsByContent: true,
            },
          }}
        />
      );
    }

    return (
      <div
        key={"app-" + appointment.id}
        className={"BoardItem" + extraClasses}
        onClick={e => !isDraggable && this.handleAppointmentClick(e, appointment)}
        data-mechanic-order={appointment.assigned_mechanic_order}
        data-mechanic-id={appointment.assigned_mechanic || null}
        data-appointment-id={appointment.id}
        draggable={isDraggable && !this.state.isLoading}
        onDragStart={e => this.onDragAppointmentStart(e, appointment, mechanic)}
        onDragEnter={e => isDraggable && this.handleDraggedAppointmentEnterAnotherAppointment(e)}
        onDragLeave={e => e.preventDefault()}
        onDrop={e => isDraggable && this.handleDropAppointmentToAnotherAppointment(e)}
        onDragOver={e => e.preventDefault()}
      >
        <div className="BoardItem-right-top">
          <div className="BoardItem-appointment-status">
            <AppointmentCardStatus
              value={{ id: appointment.appointment_status_identifier, customcom_status: appointment.customcom_status, check_paused_at: appointment.check_paused_at }}
              statuses={selectedLocation.statuses}
              car_out_of_shop={appointment.car_out_of_shop}
            />
          </div>

          {appointment.kiosk_label_number > 0 && (
            <Label className="BoardItem-kiosk-label" color="green">
              <FontAwesomeIcon icon={faTag} />
              <span>{appointment.kiosk_label_number}</span>
            </Label>
          )}
        </div>

        <div className="BoardItem-info -cursor-move">
          <CarQuickView
            appointment={appointment}
            popupTrigger={
              <span className="-cursor-pointer" onClick={e => this.handleCarClick(appointment.car_id, selectedLocation.id, e)}>
                {appointment.reg_number ? `${appointment.reg_number}` : ""}
              </span>
            }
          />

          {appointment.reg_number ? " - " : ""}

          <InterventionQuickView
            appointment={appointment}
            popupTrigger={
              <span className="-cursor-pointer wo-nr" onClick={e => this.handleAppointmentClick(e, appointment)}>
                {appointment.wo_nr}
              </span>
            }
          />
        </div>

        <div className="BoardItem-last-update">{planningWorkStop && moment(planningWorkStop).format("DD-MM-YYYY HH:mm")}</div>

        <div className="BoardItem-bottom-elements">
          <div className="BoardItem-appointment-special-indicator">{this.renderNotificationIcons(appointment)}</div>

          {[DAYPLANNER_COLUMNS.UNASSIGNED, DAYPLANNER_COLUMNS.CAR_READY].includes(column) && (
            <div className="BoardItem-planning-mechanic">{this.renderMechanicInfo(appointment, column)}</div>
          )}
        </div>
      </div>
    );
  };

  renderMechanicsAvailabilityToggle = mechanic => {
    if (mechanic?.appointments.length) return;

    return (
      <Label basic onClick={evt => this.handleToggleIsAvailableMechanic(evt, mechanic)} style={{ cursor: "pointer" }}>
        <FontAwesomeIcon icon={mechanic.is_available ? faCompressArrowsAlt : faExpandArrowsAlt} style={{ color: "#21BA45" }} />
      </Label>
    );
  };

  renderMechanicInfo = (appointment, column) => {
    if (!column && !appointment?.planning_mechanic_id && !appointment.last_assigned_mechanic) return null;

    const { mechanicsColumn } = this.state;
    const { t } = this.props;

    const mechanic = mechanicsColumn.find(m => {
      if (column === DAYPLANNER_COLUMNS.CAR_READY) {
        return m.id === appointment.last_assigned_mechanic;
      }

      return m.id === appointment.planning_mechanic_id;
    });

    if (!mechanic) return null;

    const content = (
      <div>
        <span className="-margin-right-5" style={{ fontWeight: "bold" }}>
          {mechanic.first_name} {mechanic.last_name}
        </span>
        {column !== DAYPLANNER_COLUMNS.CAR_READY && (
          <>
            <div>{`${t("start_time").message || "Start time"}: ${moment(appointment.planning_work_start).format("DD-MM-YYYY HH:mm")}`}</div>
            <div>{`${t("end_time").message || "End time"}: ${moment(appointment.planning_work_stop).format("DD-MM-YYYY HH:mm")}`}</div>
          </>
        )}
      </div>
    );

    const trigger = mechanic.profile_picture ? (
      <Image src={mechanic.profile_picture} avatar />
    ) : (
      <div className="initials-fallback">
        {mechanic.first_name?.toUpperCase().charAt(0) || ""}
        {mechanic.last_name?.toUpperCase().charAt(0) || ""}
      </div>
    );

    return <Popup content={content} trigger={trigger} position="top right" />;
  };

  renderNotificationIcons = appointment => {
    const { t, globalState } = this.props;

    const mainNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.MAIN);
    const callCustomerNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER);
    const recurringCarNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RECURRING_CAR);

    return (
      <>
        {mainNote && (
          <Popup
            wide
            basic
            content={
              <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                <div className="AppointmentNoteTitle">
                  <FontAwesomeIcon icon={faStickyNote} color="#DB2828" />
                  <span>{t("main_note").message || "Main Note"}</span>
                </div>
                <div className="AppointmentNoteDescription">
                  {mainNote.updated_by
                    ? `${mainNote.updated_by.first_name} ${mainNote.updated_by.last_name} ${t("updated_main_note").message || "updated Main Note"} `
                    : `${mainNote.user.first_name} ${mainNote.user.last_name} ${t("added_main_note").message || "added Main Note"} `}
                  {`${moment(mainNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                </div>
                <div className="AppointmentNoteContent">{mainNote.note}</div>
              </div>
            }
            trigger={
              <Label circular color="red" className="-cursor-pointer">
                <FontAwesomeIcon icon={faStickyNote} color="white" />
              </Label>
            }
            position="left center"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            }}
          />
        )}

        {callCustomerNote && (
          <Popup
            wide
            basic
            content={
              <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                <div className="AppointmentNoteTitle">
                  <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" />
                  <span>{t("call_customer_note").message || "Call Customer Note"}</span>
                </div>
                <div className="AppointmentNoteDescription">
                  {callCustomerNote.updated_by
                    ? `${callCustomerNote.updated_by.first_name} ${callCustomerNote.updated_by.last_name} ${
                        t("updated_call_customer_note").message || "updated Call Customer Note"
                      } `
                    : `${callCustomerNote.user.first_name} ${callCustomerNote.user.last_name} ${t("added_call_customer_note").message || "added Call Customer Note"} `}
                  {`${moment(callCustomerNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                </div>

                <div className="AppointmentNoteContent">
                  <Label className="CallCustomerPhoneNr">{callCustomerNote.phone_nr}</Label>
                  <div>{callCustomerNote.note}</div>
                </div>
              </div>
            }
            trigger={
              <Label circular color="green">
                <FontAwesomeIcon icon={faPhoneAlt} color="white" />
              </Label>
            }
            position="left center"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            }}
          />
        )}
        {appointment.has_dbb && appointment.dbb_appointment_date && (
          <>
            {appointment.final_car_check_dbb_status > 0 && (
              <Label
                className="checkedTire"
                circular
                color={appointment.final_car_check_dbb_status === 1 ? "green" : appointment.final_car_check_dbb_status === 2 ? "orange" : "red"}
              >
                <FontAwesomeIcon className="checkedTire-tire" icon={faTire} />
                <FontAwesomeIcon className="checkedTire-check" icon={faCheck} />
              </Label>
            )}
            {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status > 0 && (
              <Label circular color={appointment.car_check_dbb_status === 1 ? "green" : appointment.car_check_dbb_status === 2 ? "orange" : "red"}>
                <FontAwesomeIcon icon={faTire} />
              </Label>
            )}
            {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status === 0 && (
              <Label circular color={moment().isAfter(appointment.dbb_appointment_date) ? "green" : "orange"}>
                <FontAwesomeIcon icon={faTire} />
              </Label>
            )}
          </>
        )}
        {(appointment.is_recurring || recurringCarNote) && (
          <Popup
            wide
            basic
            disabled={!recurringCarNote}
            content={
              recurringCarNote && (
                <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                  <div className="AppointmentNoteTitle">
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#C83628" />
                    <span>{t("recurring_car").message || "Recurring Car"}</span>
                  </div>
                  <div className="AppointmentNoteDescription">
                    {recurringCarNote.updated_by
                      ? `${t("updated_by").message || "Updated by"} ${recurringCarNote.updated_by.first_name} ${recurringCarNote.updated_by.last_name} ${
                          t("at").message || "at"
                        } `
                      : `${t("added_by").message || "Added by"}  ${recurringCarNote.user.first_name} ${recurringCarNote.user.last_name} ${t("at").message || "at"} `}
                    {`${moment(recurringCarNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                  </div>
                  <div className="AppointmentNoteContent">{recurringCarNote.note}</div>
                </div>
              )
            }
            trigger={
              <Label circular color="red">
                <Icon name="warning sign" />
              </Label>
            }
            position="left center"
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            }}
          />
        )}
        {appointment.customer_waiting && (
          <Label circular color="brown">
            <Icon name="coffee" />
          </Label>
        )}
        {appointment.is_money && (
          <Label circular color="green">
            <Icon name="dollar" />
          </Label>
        )}
        {appointment.is_star && (
          <Label
            circular
            color={appointment.is_star_color.startsWith("#") ? null : appointment.is_star_color}
            style={appointment.is_star_color.startsWith("#") ? { color: "white", backgroundColor: appointment.is_star_color } : null}
          >
            <Icon name="star" />
          </Label>
        )}
        {appointment.is_shop && (
          <Label
            circular
            color={appointment.is_shop_color.startsWith("#") ? null : appointment.is_shop_color}
            style={appointment.is_shop_color.startsWith("#") ? { color: "white", backgroundColor: appointment.is_shop_color } : null}
          >
            <Icon name="shop" />
          </Label>
        )}
        {appointment.has_extra_check && (
          <Label circular color="blue">
            <i className="material-icons">format_color_fill</i>
          </Label>
        )}
        {appointment.num_approved_items_not_fixed > 0 && (
          <Label circular color="red">
            {appointment.num_approved_items_not_fixed}
          </Label>
        )}
        {appointment.final_check_has_remarks && (
          <Label circular color="orange">
            <Icon name="wrench" />
          </Label>
        )}
        <PinNotification appointment={appointment} />
        {((appointment.key_dropped_at && !appointment.key_picked_up_at) ||
          (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
          (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
          (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at)) && (
          <Label circular className="keylocker-icon">
            <Icon name="key" />
          </Label>
        )}
      </>
    );
  };

  render() {
    const { t } = this.props;

    const {
      appointments,
      appointmentsColumn,
      unassignedColumn,
      carReadyColumn,
      qualityControlColumn,
      searchTerm,
      isAppointmentDetailVisible,
      isCarDetailVisible,
      selectedMechanicFilter,
      collapsedColumns,
      extendedSearchResults,
      backorderFilterInactive,
    } = this.state;

    return (
      <div className="Dayplanner">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.getDayplannerData} />
        </UserMenuActionsPortal>
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("dayplanner_search").message || "Search by WO, reg number or name of the owner/driver",
            }}
            loading={false}
            showNoResults
            onSearchChange={this.handleSearchChange}
            value={searchTerm}
            open={!!extendedSearchResults.length}
            results={extendedSearchResults}
            resultRenderer={this.renderExtendedSearchResult}
            onResultSelect={(e, data) => {
              this.handleAppointmentClick(e, data.result);
            }}
            fluid
          />
          {searchTerm?.length > 3 && (
            <Icon
              className="ExtendedSearchIcon"
              name="ellipsis horizontal"
              style={{
                right: "2.5em",
              }}
              onClick={this.handleExtendedSearch}
            />
          )}
          {extendedSearchResults.length > 0 && <Icon className="ClearSearchIcon" name="close" onClick={this.handleClearSearch} />}
        </SearchPortal>
        <SubHeader>
          <Grid.Column width={16} className="header-container">
            <DayplannerStatusFilters
              appointments={appointments}
              backorderFilterInactive={backorderFilterInactive}
              onStatusFilterClick={this.handleStatusFilterClick}
              onBackorderStatusFilterClick={this.handleBackOrderStatusFilterClick}
            />
          </Grid.Column>
        </SubHeader>
        <div className="Dayplanner-board">
          <div
            className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.APPOINTMENTS) ? "-collapsed" : ""}`}
            ref={this.appointmentsColumnRef}
          >
            <div className="Dayplanner-columnHeader">
              <h4>
                {collapsedColumns.includes(DAYPLANNER_COLUMNS.APPOINTMENTS) ? t("appointments_short").message || "AP" : t("appointments").message || "Appointments"}
              </h4>
              <Label color="white" onClick={() => this.handleToggleCollapseColumn(DAYPLANNER_COLUMNS.APPOINTMENTS)}>
                <FontAwesomeIcon icon={collapsedColumns.includes(DAYPLANNER_COLUMNS.APPOINTMENTS) ? faExpandArrowsAlt : faCompressArrowsAlt} color="green" />
              </Label>
            </div>
            <div className="Dayplanner-column">{appointmentsColumn.map(app => this.renderAppointmentCard(app, DAYPLANNER_COLUMNS.APPOINTMENTS))}</div>
          </div>

          <div className="Dayplanner-columnContainer" ref={this.unassignedColumnRef}>
            <h4 className="Dayplanner-columnHeader">{t("unassigned").message || "Unassigned"}</h4>
            <div
              className="Dayplanner-column"
              onDrop={this.handleDropAppointmentToUnassignColumn}
              onDragEnter={this.handleDraggedAppointmentEnterUnassignColumn}
              onDragLeave={this.handleDraggedAppointmentLeaveUnassignColumn}
              onDragOver={e => e.preventDefault()}
            >
              {unassignedColumn.map(app => this.renderAppointmentCard(app, DAYPLANNER_COLUMNS.UNASSIGNED))}
            </div>
          </div>

          <div className="Dayplanner-columnContainer -mechanic-tasks -flex-grow-3" ref={this.mechanicsColumnRef}>
            <h4 className="Dayplanner-columnHeader">
              {t("mechanics_tasks").message || "Mechanic's Tasks"}

              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon="filter"
                options={[
                  { key: 1, value: MECHANIC_FILTER.HIDE_OCCUPIED_MECHANICS, text: t("hide_occupied_mechanics").message || "Hide occupied mechanics" },
                  { key: 2, value: MECHANIC_FILTER.HIDE_UNOCCUPIED_MECHANICS, text: t("hide_unoccupied_mechanics").message || "Hide unoccupied mechanics" },
                ]}
                text={t("filters").message || "Filters"}
                clearable
                value={selectedMechanicFilter}
                onChange={this.handleMechanicFilter}
              />
            </h4>

            {this.renderMechanicsColumn()}
          </div>

          <div className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.CAR_READY) ? "-collapsed" : ""}`} ref={this.carReadyColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>{collapsedColumns.includes(DAYPLANNER_COLUMNS.CAR_READY) ? t("cars_ready_short").message || "CR" : t("cars_ready").message || "Cars Ready"}</h4>
              <Label color="white" onClick={() => this.handleToggleCollapseColumn(DAYPLANNER_COLUMNS.CAR_READY)}>
                <FontAwesomeIcon icon={collapsedColumns.includes(DAYPLANNER_COLUMNS.CAR_READY) ? faExpandArrowsAlt : faCompressArrowsAlt} color="green" />
              </Label>
            </div>
            <div className="Dayplanner-column">{carReadyColumn.map(app => this.renderAppointmentCard(app, DAYPLANNER_COLUMNS.CAR_READY))}</div>
          </div>

          <div
            className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.QUALITY_CHECK) ? "-collapsed" : ""}`}
            ref={this.qualityControlColumnRef}
          >
            <div className="Dayplanner-columnHeader">
              <h4>
                {collapsedColumns.includes(DAYPLANNER_COLUMNS.QUALITY_CHECK)
                  ? t("quality_control_short").message || "QC"
                  : t("quality_control").message || "Quality Control"}
              </h4>
              <Label color="white" onClick={() => this.handleToggleCollapseColumn(DAYPLANNER_COLUMNS.QUALITY_CHECK)}>
                <FontAwesomeIcon icon={collapsedColumns.includes(DAYPLANNER_COLUMNS.QUALITY_CHECK) ? faExpandArrowsAlt : faCompressArrowsAlt} color="green" />
              </Label>
            </div>
            <div className="Dayplanner-column">{qualityControlColumn.map(app => this.renderAppointmentCard(app, DAYPLANNER_COLUMNS.QUALITY_CHECK))}</div>
          </div>
        </div>

        {isAppointmentDetailVisible && this.renderAppointmentDetailPage()}
        {isCarDetailVisible && this.renderCarDetailPage()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { appointmentsState: state.appointments, globalState: state.global, webSocket: state.webSocket, authState: state.auth, carsState: state.cars };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: updates => dispatch(applyUpdateToAppointment(updates)),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    webSocketDayplannerUpdatesApplied: () => dispatch(webSocketDayplannerUpdatesApplied()),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Dayplanner));
