import React, { useEffect, useState } from "react";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import Loader from "react-loader-spinner";
import { saveAs } from "file-saver";
import moment from "moment";

import { QUESTION_RESULT_STATUS } from "../../Appointments/common";

const QualityChecks = ({ report, t }) => {
  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_date").message || "WO date") + ", ";
    header += (t("wo_nr").message || "WO Nr") + ", ";
    header += (t("check_date").message || "Check date") + ", ";
    header += (t("mechanic").message || "Mechanic") + ", ";
    header += (t("qc_status").message || "QC Status") + ", ";
    header += (t("qc_officier").message || "QC Officier") + ", ";
    header += (t("qc_checklist").message || "QC Checklist") + ", ";
    header += (t("ok_with_note_remark").message || "Ok With Note Remarks") + ", ";
    header += (t("advised_remark").message || "Advised Remarks") + ", ";
    header += t("critical_remark").message || "Critical Remarks";

    let values = filteredData
      .map(
        d =>
          d.location +
          "," +
          moment(d.wo_date).format("YYYY-MM-DD") +
          "," +
          d.wo_nr +
          "," +
          moment(d.check_date).format("YYYY-MM-DD") +
          "," +
          d.mechanic +
          "," +
          d.qc_status +
          "," +
          d.qc_officer +
          "," +
          d.qc_checklist +
          ',"' +
          (d.ok_with_note_remark || "") +
          '","' +
          (d.advised_remark || "") +
          '","' +
          (d.critical_remark || "") +
          '"'
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "QualityChecks_" + moment().format("YYYYMMDD") + ".csv");
  };

  let [data, setData] = useState([]);
  let [filteredData, setFilteredData] = useState([]);
  let [qcStatusFilter, setQCStatusFilter] = useState("");
  let [mechanicFilter, setMechanicFilter] = useState("");
  let [qcOfficierFilter, setQCOfficierFilter] = useState("");
  let [locationFilter, setLocationFilter] = useState("");
  let [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    const data = report.report_data.map(d => JSON.parse(d)).sort((a, b) => new Date(b.wo_date) - new Date(a.wo_date));

    setData(data);
    setFilteredData(data);
  }, [report]);

  const filterData = async (qc_status, mechanic_id, qc_officer_id, location_name) => {
    setIsFiltering(true);

    const filtered = data.filter(d => {
      if (Number.isInteger(qc_status) && d.qc_status !== qc_status) return false;
      if (mechanic_id && d.mechanic_id !== mechanic_id) return false;
      if (qc_officer_id && d.qc_officer_id !== qc_officer_id) return false;
      if (location_name && d.location !== location_name) return false;

      return true;
    });

    setFilteredData(filtered);
    setIsFiltering(false);
  };

  const handleAppointmentClick = appointment_id => {
    window.open(`${document.location.origin}/#!/appointments/${appointment_id}`, "_blank");
  };

  const getQuestionStatusOptions = () => [
    {
      value: QUESTION_RESULT_STATUS.OK,
      text: (
        <span
          style={{
            color: "green",
          }}
        >
          <Icon name="check" />
          <span>{t("OK").message || "OK"}</span>
        </span>
      ),
      className: "positive",
      color: "green",
    },
    {
      value: QUESTION_RESULT_STATUS.OK_WITH_REMARKS,
      text: (
        <span
          style={{
            color: "green",
          }}
        >
          <Icon name="file outline" />
          <span>{t("ok_with_a_note").message || "OK with a note"}</span>
        </span>
      ),
      className: "positive",
      color: "green",
    },
    {
      value: QUESTION_RESULT_STATUS.ADVISED,
      text: (
        <span
          style={{
            color: "orange",
          }}
        >
          <Icon name="attention" />
          <span>{t("advised").message || "Advised"}</span>
        </span>
      ),
      className: "warning",
      color: "orange",
    },
    {
      value: QUESTION_RESULT_STATUS.NECESSARY,
      text: (
        <span
          style={{
            color: "red",
          }}
        >
          <Icon name="dont" />
          <span>{t("Critical").message || "Critical"}</span>
        </span>
      ),
      className: "emergency",
      color: "red",
    },
  ];

  if (isFiltering) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Loader type="Oval" color="#46923d" height="100" width="100" />
      </div>
    );
  }

  const getQCStatusText = status => {
    switch (status) {
      case QUESTION_RESULT_STATUS.OK:
        return t("OK").message || "OK";
      case QUESTION_RESULT_STATUS.OK_WITH_REMARKS:
        return t("ok_with_a_note").message || "OK with a note";
      case QUESTION_RESULT_STATUS.ADVISED:
        return t("advised").message || "Advised";
      case QUESTION_RESULT_STATUS.NECESSARY:
        return t("Critical").message || "Critical";
      default:
        return "";
    }
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <Dropdown
          options={getQuestionStatusOptions()}
          placeholder={t("select_qc_status").message || "Select QC Status"}
          selection
          clearable
          selectOnBlur={false}
          value={qcStatusFilter}
          onChange={(event, option) => {
            setQCStatusFilter(option.value);
            filterData(option.value, mechanicFilter, qcOfficierFilter, locationFilter);
          }}
        />

        <Dropdown
          options={Object.values(
            data
              .reduce((acc, cur) => {
                acc[cur.mechanic_id] = { value: cur.mechanic_id, text: (t("mechanic").message || "Mechanic") + " " + cur.mechanic };
                return acc;
              }, [])
              .sort((a, b) => (a.text < b.text ? -1 : 1))
          )}
          placeholder={t("select_mechanic").message || "Select Mechanic"}
          selection
          search
          clearable
          selectOnBlur={false}
          value={mechanicFilter}
          onChange={(event, option) => {
            setMechanicFilter(option.value);
            filterData(qcStatusFilter, option.value, qcOfficierFilter, locationFilter);
          }}
        />

        <Dropdown
          options={Object.values(
            data
              .reduce((acc, cur) => {
                acc[cur.qc_officer_id] = { value: cur.qc_officer_id, text: (t("qc_officier").message || "QC Officier") + " " + cur.qc_officer };
                return acc;
              }, [])
              .sort((a, b) => (a.text < b.text ? -1 : 1))
          )}
          placeholder={t("select_qc_officier").message || "Select QC Officier"}
          selection
          search
          clearable
          selectOnBlur={false}
          value={qcOfficierFilter}
          onChange={(event, option) => {
            setQCOfficierFilter(option.value);
            filterData(qcStatusFilter, mechanicFilter, option.value, locationFilter);
          }}
        />

        <Dropdown
          options={Object.values(
            data
              .reduce((acc, cur) => {
                acc[cur.location] = { text: cur.location, value: cur.location };
                return acc;
              }, [])
              .sort((a, b) => a.text.localeCompare(b.text))
          )}
          placeholder={t("select_location").message || "Select Location"}
          selection
          search
          clearable
          selectOnBlur={false}
          value={locationFilter}
          onChange={(_, option) => {
            setLocationFilter(option.value);
            filterData(qcStatusFilter, mechanicFilter, qcOfficierFilter, option.value);
          }}
        />

        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin hasLongRemark"
          data={filteredData}
          showPagination={filteredData?.length > 99}
          showPageSizeOptions={false}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          sortable={false}
          minRows={0}
          onPageChange={() => document.querySelector(".ReactTable.hasLongRemark .rt-tbody").scrollTo(0, 0)}
          style={{ maxHeight: "600px" }}
          resizable={false}
          pageSize={filteredData?.length > 99 ? 100 : filteredData?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_date").message || "WO date"}</strong>,
              accessor: "wo_date",
              Cell: cell => moment(cell.value).format("D MMM, YYYY"),
            },
            {
              Header: <strong>{t("wo_nr").message || "WO Nr"}</strong>,
              id: "wo_nr",
              accessor: d => (
                <span onClick={() => handleAppointmentClick(d.appointment_id)} className="quality_checks_wo_cell">
                  {d.wo_nr}
                </span>
              ),
            },
            {
              Header: <strong>{t("check_date").message || "Check date"}</strong>,
              accessor: "check_date",
              Cell: cell => moment(cell.value).format("D MMM, YYYY"),
            },
            {
              Header: <strong>{t("mechanic").message || "Mechanic"}</strong>,
              accessor: "mechanic",
            },
            {
              Header: <strong>{t("qc_status").message || "QC Status"}</strong>,
              id: "qc_status",
              accessor: d => <span>{getQCStatusText(d.qc_status)}</span>,
            },
            {
              Header: <strong>{t("qc_officier").message || "QC Officier"}</strong>,
              accessor: "qc_officer",
            },
            {
              Header: <strong>{t("qc_checklist").message || "QC Checklist"}</strong>,
              accessor: "qc_checklist",
            },
            {
              Header: <strong>{t("ok_with_note_remark").message || "OK With Note Remarks"}</strong>,
              accessor: "ok_with_note_remark",
              style: { whiteSpace: "initial", textAlign: "left" },
            },
            {
              Header: <strong>{t("advised_remark").message || "Advised Remarks"}</strong>,
              accessor: "advised_remark",
              style: { whiteSpace: "initial", textAlign: "left" },
            },
            {
              Header: <strong>{t("critical_remark").message || "Critical Remarks"}</strong>,
              accessor: "critical_remark",
              style: { whiteSpace: "initial", textAlign: "left" },
            },
          ]}
        />
      </div>
    </>
  );
};

export { QualityChecks };
