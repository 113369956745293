import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import Loader from "react-loader-spinner";
import moment from "moment";

import { DateRange } from "../../components";
import { SimpleBarChart, StackedBarChart, SimplePieChart, TotalPieChart, PercentStackedBarChart, Tabular } from "./charts/";
import { translateDataForCharts } from "../../util/common";

import Service from "./service";

import "./locationReports.css";

class LocationReports extends Component {
  state = {
    groups: [],
    selectedGroup: null,
    days: 1,
    dateFrom: moment().subtract(1, "d").format("YYYY-MM-DDT00:00:00Z"),
    dateTo: moment().subtract(1, "d").format("YYYY-MM-DDT23:59:59Z"),
    report_list: [],
    report_data: [],
    isLoadingList: false,
    isLoadingReport: false,
    oneDayData: [],
    sevenDaysData: [],
    monthData: [],
    threeMonthsData: [],
  };

  unmounted = false;

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
      this.changeLocation();
    }
  }

  componentDidMount() {
    this.changeLocation();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  changeLocation = () => {
    this.setState({ isLoadingList: true }, () => {
      Service.getReportGroups(this.props.globalState.selectedLocation.id)
        .then(res => {
          let groups = [];
          if (Array.isArray(res.data?.data?.groups)) groups = res.data.data.groups;

          this.setState({ isLoadingList: false, groups, selectedGroup: groups[0] }, () => {
            this.loadReportsList();
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoadingList: false });
        });
    });
  };

  loadReportsList = () => {
    this.setState({ isLoadingList: true }, async () => {
      let report_list = [];
      const { groups } = this.state;

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        try {
          let res = await Service.getReportList(this.props.globalState.selectedLocation.id, group);
          if (Array.isArray(res.data?.data?.template_ids)) report_list[group] = res.data.data.template_ids;
          else report_list[group] = [];
        } catch (err) {
          console.log(err);
        }
      }

      this.setState({ report_list, report_data: [], oneDayData: [], sevenDaysData: [], monthData: [], threeMonthsData: [], isLoadingList: false }, () => {
        this.loadReports();
      });
    });
  };

  getGroupDropdown = () => {
    return this.state.groups.map(group => ({ key: group, text: this.props.t("report_group_" + group).message || "Group " + group, value: group }));
  };

  getPeriodDropdown = () => {
    return [
      {
        key: "1",
        text: this.props.t("yesterday").message || "yesterday",
        value: 1,
      },
      {
        key: "7",
        text: "7 " + this.props.t("days").message || "days",
        value: 7,
      },
      {
        key: "30",
        text: "30 " + this.props.t("days").message || "days",
        value: 30,
      },
      {
        key: "90",
        text: "90 " + this.props.t("days").message || "days",
        value: 90,
      },
      {
        key: "custom",
        text: this.props.t("custom").message || "Custom",
        value: "custom",
      },
    ];
  };

  sortReports = (a, b) => {
    return a.report_order - b.report_order;
  };

  handleSetData = (_days, data) => {
    let { oneDayData, sevenDaysData, monthData, threeMonthsData, report_data, days } = this.state;

    if (_days === 1) {
      oneDayData.push(data);
      oneDayData.sort(this.sortReports);
    } else if (_days === 7) {
      sevenDaysData.push(data);
      sevenDaysData.sort(this.sortReports);
    } else if (_days === 30) {
      monthData.push(data);
      monthData.sort(this.sortReports);
    } else if (_days === 90) {
      threeMonthsData.push(data);
      threeMonthsData.sort(this.sortReports);
    }

    if (_days === days) {
      report_data.push(data);
      report_data.sort(this.sortReports);
    }

    this.setState({ isLoadingReport: false, oneDayData, sevenDaysData, monthData, threeMonthsData, report_data });
  };

  handleLoadFromCache = data => {
    this.setState({ report_data: [...data] }, () => {
      if (data.length < this.state.report_list.length) this.loadReports();
    });
  };

  handleChangeGroup = (_, data) => {
    this.setState({ selectedGroup: data.value, report_data: [], oneDayData: [], sevenDaysData: [], monthData: [], threeMonthsData: [] }, () => this.loadReports());
  };

  handleChangeDays = (_, { value: days }) => {
    if (days === "custom") {
      this.setState({ days });
      return;
    }

    const dateFrom = moment().subtract(days, "d").format("YYYY-MM-DDT12:00:00Z");
    const dateTo = moment().subtract(1, "d").format("YYYY-MM-DDT12:00:00Z");

    this.setState({ days, dateFrom, dateTo }, () => {
      if (days === 1) {
        this.handleLoadFromCache(this.state.oneDayData);
      } else if (days === 7) {
        this.handleLoadFromCache(this.state.sevenDaysData);
      } else if (days === 30) {
        this.handleLoadFromCache(this.state.monthData);
      } else if (days === 90) {
        this.handleLoadFromCache(this.state.threeMonthsData);
      }
    });
  };

  handleChangeCustomDates = ([dateFrom, dateTo]) => {
    this.setState({ dateFrom, dateTo }, () => {
      if (this.state.dateFrom && this.state.dateTo) {
        this.setState({ report_data: [] }, () => this.loadReports());
      }
    });
  };

  loadReports = () => {
    let { selectedGroup, report_list, days, dateFrom, dateTo } = this.state;
    let { id } = this.props.globalState.selectedLocation;

    (async (locID, report_to_load, selectedGroup, days, dateFrom, dateTo) => {
      while (report_to_load.length > 0) {
        if (
          this.unmounted ||
          this.props.globalState.selectedLocation.id !== locID ||
          this.state.selectedGroup !== selectedGroup ||
          this.state.dateFrom !== dateFrom ||
          this.state.dateTo !== dateTo
        )
          break;

        let reportID = report_to_load.shift();
        if (this.state.report_data.findIndex(r => r.id === reportID) > -1) continue;

        this.setState({ isLoadingReport: true });

        try {
          let res = await Service.getReportData(locID, reportID, dateFrom, dateTo);

          if (this.unmounted || this.props.globalState.selectedLocation.id !== locID || this.state.selectedGroup !== selectedGroup) break;

          if (res?.data?.data) this.handleSetData(days, res.data.data);

          if (this.state.dateFrom !== dateFrom || this.state.dateTo !== dateTo) break;
        } catch (err) {
          console.log(err);
          if (
            !this.unmounted &&
            this.props.globalState.selectedLocation.id === locID &&
            this.state.selectedGroup === selectedGroup &&
            this.state.dateFrom === dateFrom &&
            this.state.dateTo === dateTo &&
            report_to_load.length < 1
          )
            this.setState({ isLoadingReport: false });
        }
      }
    })(id, [...report_list[selectedGroup]], selectedGroup, days, dateFrom, dateTo);
  };

  renderReport = report => {
    if (!report.report_data) return <p className="no-data">{this.props.t("no_data").message || "No data"}</p>;

    const thresholds = report.thresholds ? JSON.parse(report.thresholds) : [];
    if (thresholds.length > 0) {
      thresholds.forEach(th => {
        if (th.name) th.name = this.props.t(th.name).message || th.name;
      });
    }

    switch (report.report_type) {
      case "SimplePieChart":
        return (
          <SimplePieChart
            key={report.report_name}
            colors={report.report_colors || []}
            t={this.props.t}
            data={report.report_data.map(data => translateDataForCharts(data, this.props.t))}
          />
        );

      case "TotalPieChart":
        return (
          <TotalPieChart
            key={report.report_name}
            colors={report.report_colors || []}
            t={this.props.t}
            data={report.report_data.map(data => translateDataForCharts(data, this.props.t))}
          />
        );

      case "SimpleBarChart":
        return (
          <SimpleBarChart
            key={report.report_name}
            colors={report.report_colors || []}
            thresholds={thresholds}
            t={this.props.t}
            data={report.report_data.map(data => translateDataForCharts(data, this.props.t))}
            unit={report.unit}
          />
        );

      case "StackedBarChart":
        return (
          <StackedBarChart
            key={report.report_name}
            colors={report.report_colors || []}
            t={this.props.t}
            data={report.report_data.map(data => translateDataForCharts(data, this.props.t))}
            unit={this.props.t(report.unit).message || report.unit}
          />
        );

      case "PercentStackedBarChart":
        return (
          <PercentStackedBarChart
            key={report.report_name}
            colors={report.report_colors || []}
            t={this.props.t}
            data={report.report_data.map(data => translateDataForCharts(data, this.props.t))}
            unit={this.props.t(report.unit).message || report.unit}
          />
        );

      case "Tabular":
        return <Tabular key={report.report_name} t={this.props.t} data={report.report_data.map(data => translateDataForCharts(data, this.props.t))} />;

      default:
        return null;
    }
  };

  render() {
    let { report_data, isLoadingList, isLoadingReport, days, dateFrom, dateTo } = this.state;

    if (isLoadingList) {
      return (
        <div className="Loader">
          <Loader type="Oval" color="#46923d" height="100" width="100" />
        </div>
      );
    }

    return (
      <div className="reports-container">
        <div className="reports-header">
          <h2 className="reports-heading">{this.props.t("location_reports").message || "Location Reports"}</h2>
          <div className="reports-header-dropdown-container">
            <div className="dropdown-container">
              <div className="dropdown-lable">{this.props.t("reports").message || "Reports"}</div>
              <Dropdown
                className="reports-days-dropdown"
                onChange={this.handleChangeGroup}
                value={this.state.selectedGroup}
                selection
                options={this.getGroupDropdown()}
              />
            </div>
            <div className="dropdown-container">
              <div className="dropdown-lable">{this.props.t("select_time_period").message || "Select time period"}</div>
              <Dropdown onChange={this.handleChangeDays} value={this.state.days} selection options={this.getPeriodDropdown()} />
            </div>
            {days === "custom" && (
              <div className="dropdown-container">
                <div className="dropdown-lable">{this.props.t("select_date_range").message || "Select date range"}</div>
                <DateRange dateFrom={dateFrom} dateTo={dateTo} onChange={this.handleChangeCustomDates} maxDate={moment(dateFrom).add(3, "M").toDate()} />
              </div>
            )}
          </div>
        </div>

        <div className={"report-full-width"}>
          {report_data.length > 0 &&
            report_data.map((report, i) => {
              return (
                <div className={"report-box-full-width"} key={i}>
                  <h3 className="report-heading">{this.props.t(report.report_name).message || report.report_name}</h3>
                  {this.renderReport(report)}
                </div>
              );
            })}

          {isLoadingReport && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Loader type="Oval" color="#46923d" height="100" width="100" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps, {})(LocationReports));
