import React from "react";
import { Label, Icon } from "semantic-ui-react";

const PinNotification = ({ appointment }) => {
  if (appointment.pin_count < 1) return null;

  return (
    <Label circular color={appointment.pin_icon_color}>
      <Icon name={appointment.warranty_pin_count > 0 ? "shield" : "pin"} />
    </Label>
  );
};

export default PinNotification;
