import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB, nl, de, fr } from "date-fns/locale";
import { getBrowserLanguage } from "./common";
import ENV from "./env-config";

if (!localStorage.getItem("locale")) {
  const language = getBrowserLanguage();
  localStorage.setItem("locale", language);
}

const LOCALE = localStorage.getItem("locale") !== "undefined" ? localStorage.getItem("locale") : "en-GB";

switch (LOCALE) {
  case "fr-FR":
    registerLocale(LOCALE, fr);
    break;
  case "nl-NL":
    registerLocale(LOCALE, nl);
    break;
  case "de-DE":
    registerLocale(LOCALE, de);
    break;
  default:
    registerLocale(LOCALE, enGB);
    break;
}

setDefaultLocale(LOCALE);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-GB",
    returnObjects: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    debug: false,

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: ENV.apiBaseB + "/translations/list_by_language_id?language=" + LOCALE,
      crossDomain: true,
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
