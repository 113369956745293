import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getAgreements(dealer_id) {
    return axios.post(ENV.apiBaseB + "/check_in/agreement/list", { dealer_id }, this.requestConfig());
  }

  static createAgreement(data) {
    return axios.post(ENV.apiBaseB + "/check_in/agreement/create", data, this.requestConfig());
  }

  static updateAgreement(data) {
    return axios.post(ENV.apiBaseB + "/check_in/agreement/update", data, this.requestConfig());
  }

  static deleteAgreement(id) {
    return axios.post(ENV.apiBaseB + "/check_in/agreement/delete", { id }, this.requestConfig());
  }
}

export default Service;
