import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getMediums(requestData) {
    return axios.post(ENV.apiBaseB + "/customcom/list_mediums", requestData, this.requestConfig());
  }

  static getDefaultTemplates() {
    return axios.get(ENV.apiBaseB + "/customcom/list_default_mediums", this.requestConfig());
  }

  static getDetail(dealer_location_id) {
    return axios.post(ENV.apiBaseB + "/customcom/get_settings", { dealer_location_id }, this.requestConfig());
  }

  static updateMedium(requestData) {
    return axios.post(ENV.apiBaseB + "/customcom/update_medium", requestData, this.requestConfig());
  }

  static duplicateMedium(requestData) {
    return axios.post(ENV.apiBaseB + "/customcom/duplicate_medium", requestData, this.requestConfig());
  }

  static deleteDuplicateMedium(requestData) {
    return axios.post(ENV.apiBaseB + "/customcom/delete_duplicate_medium", requestData, this.requestConfig());
  }

  static uploadEmailTemplateImages(requestData) {
    return axios.post(ENV.apiBaseB + "/files/cc_emails_pictures", requestData, this.requestConfig());
  }
}

export default Service;
