import React, { Component } from "react";

import StatusFilter from "./StatusFilter";
import { APPOINTMENT_STATUSES } from "../../../Appointments/common";
import { getPreference } from "../../../../util/preferences";

import "./StatusFilters.scss";

export const ShouldBeFiltered = (appointment, filterValue) => {
  switch (filterValue) {
    case FILTERS.NEW_CAR:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.NEW_CAR;
    case FILTERS.CAR_IN_SHOP:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_IN_SHOP && appointment.car_in_shop && !appointment.car_out_of_shop;
    case FILTERS.PANIC:
      return appointment.has_panic;
    case FILTERS.INITIAL_CHECK_DONE:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.INITIAL_CHECK_DONE;
    case FILTERS.HANDLE_CHECK_IN:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.HANDLE_CHECK_IN;
    case FILTERS.CAR_CHECK:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_CHECK;
    case FILTERS.DIAGNOSE:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.DIAGNOSE;
    case FILTERS.CUSTOMER_ANSWERED:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CUSTOMER_ANSWERED;
    case FILTERS.CUSTOMER_OK:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CUSTOMER_OK;
    case FILTERS.BACK_ORDER:
      return appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER;
    default:
      return;
  }
};

export const FILTERS = {
  NEW_CAR: 1,
  CAR_IN_SHOP: 2,
  PANIC: 3,
  INITIAL_CHECK_DONE: 4,
  HANDLE_CHECK_IN: 5,
  CAR_CHECK: 6,
  DIAGNOSE: 7,
  CUSTOMER_ANSWERED: 8,
  CUSTOMER_OK: 9,
  BACK_ORDER: 10,
};

class DayplannerStatusFilters extends Component {
  state = {
    activeFilter: getPreference("dayplanner-activeFilter", null),
    filtersCounts: Object.values(FILTERS).reduce((arr, value) => {
      arr[value] = 0;
      return arr;
    }, []),
  };

  componentDidMount() {
    this.updateFiltersCounts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointments !== this.props.appointments) {
      this.updateFiltersCounts();
    }
  }

  updateFiltersCounts = () => {
    const counts = [];

    Object.values(FILTERS).forEach(filterValue => {
      counts[filterValue] = this.props.appointments.filter(app => {
        return ShouldBeFiltered(app, filterValue);
      }).length;
    });

    this.setState({ filtersCounts: counts });
  };

  handleClick = selectedFilter => {
    if (selectedFilter === FILTERS.BACK_ORDER) {
      const { backorderFilterInactive, onBackorderStatusFilterClick } = this.props;

      onBackorderStatusFilterClick(!backorderFilterInactive);
    } else {
      const activeFilter = this.state.activeFilter === selectedFilter ? null : selectedFilter;

      this.setState({ activeFilter }, () => {
        this.props.onStatusFilterClick(this.state.activeFilter);
      });
    }
  };

  getColorForFilter = filterStatus => {
    switch (filterStatus) {
      case FILTERS.NEW_CAR:
        return "#F49523";
      case FILTERS.CAR_IN_SHOP:
        return "#EFC56B";
      case FILTERS.PANIC:
        return "#DB2828";
      case FILTERS.INITIAL_CHECK_DONE:
        return "#FF5722";
      case FILTERS.HANDLE_CHECK_IN:
        return "#5F7D8B";
      case FILTERS.CAR_CHECK:
        return "#F8C02B";
      case FILTERS.DIAGNOSE:
        return "#69BEC0";
      case FILTERS.CUSTOMER_ANSWERED:
        return "#EA2D62";
      case FILTERS.CUSTOMER_OK:
        return "#2D85D0";
      case FILTERS.BACK_ORDER:
        return "#6C0699";
      default:
        return "#FFFFFF";
    }
  };

  render() {
    const { activeFilter, filtersCounts } = this.state;
    const { backorderFilterInactive } = this.props;

    return (
      <div className="status-filters">
        {Object.keys(FILTERS).map((key, index) => {
          const status = FILTERS[key];
          const isDisabled = status === FILTERS.BACK_ORDER ? backorderFilterInactive : activeFilter !== null && activeFilter !== status;

          return (
            <StatusFilter
              key={key}
              color={this.getColorForFilter(status)}
              name={key}
              count={filtersCounts[status]}
              filter={status}
              onClick={() => this.handleClick(status)}
              disabled={isDisabled}
            />
          );
        })}
      </div>
    );
  }
}

export default DayplannerStatusFilters;
