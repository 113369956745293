import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import AlertContainer from "react-alert";
import moment from "moment";
import { Grid, Search, Icon, Button } from "semantic-ui-react";

import { SearchPortal, SubHeader, UserMenuActionsPortal } from "../../../components";
import { updateLocation } from "../../App/store";
import { Can } from "../../";
import { DMS, getDMSName } from "../util";
import DMSDealersTable from "./components/DealersTable";
import DMSDealerDetail from "./components/DealerDetail";
import DMSLocationDetail from "./components/LocationDetail";

import Service from "./service";

import "./index.scss";

class DMSv3Settings extends Component {
  state = {
    searchTerm: "",
    dealers: [],
    dealersForDisplay: [],
    dealersOptions: [],
    loadingDealers: false,
    selectedDealer: null,
    selectedLocation: null,
    isDealerDetailVisible: false,
    isEditingDealer: false,
    isLocationDetailVisible: false,
    isEditingLocation: false,
    showValidationError: false,
    locationValidationError: false,
  };

  componentDidMount() {
    this.getDealers();

    this.prepareDealerOptions();
  }

  filterDealers = dealers => {
    const { searchTerm } = this.state;
    const term = searchTerm.toLowerCase();

    return searchTerm ? dealers.filter(d => d.dealer_name.toLowerCase().includes(term) || getDMSName(d.dms_id) === term) : dealers;
  };

  handleSearchChange = (event, data) => {
    const searchTerm = data.value;
    this.setState({ searchTerm }, () => {
      this.setState({ dealersForDisplay: this.filterDealers(this.state.dealers) });
    });
  };

  getDealers() {
    this.setState({ loadingDealers: true }, () => {
      Service.getDealers()
        .then(res => {
          const dealers = res?.data ? res.data.sort((a, b) => a.dealer_name.localeCompare(b.dealer_name)) : [];

          const dealersForDisplay = this.filterDealers(dealers);

          this.setState({ loadingDealers: false, dealers, dealersForDisplay });
        })
        .catch(err => {
          console.log("Loading dealers error", err);

          this.setState({ loadingDealers: false }, () => {
            const errorMsg = err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred";

            this.msg.show(errorMsg, {
              time: 4000,
              type: "error",
            });
          });
        });
    });
  }

  addOrUpdateDealerRequestValid = dealerConfiguration => {
    const mandatoryFields = ["duration_threshold", "start_time", "end_time", "time_to_sleep", "date_range", "dms_id"];

    return mandatoryFields.every(field => dealerConfiguration[field] && dealerConfiguration[field] !== "0");
  };

  handleAddOrUpdateDealer = dealerConfiguration => {
    if (!this.addOrUpdateDealerRequestValid(dealerConfiguration)) {
      this.setState({ showValidationError: true });
      return;
    }

    const { isEditingDealer } = this.state;
    const { duration_threshold, start_time, end_time, time_to_sleep, date_range, dms_id, dsn, full_run_hour_interval, delay, carit_config } = dealerConfiguration;

    const internal_customer_group_ids = !carit_config?.internal_customer_group_ids?.length ? "" : carit_config.internal_customer_group_ids.trim().split(" ").join(",");

    const formatedParams = {
      duration_threshold: duration_threshold ? Number(duration_threshold) : null,
      start_time: start_time && start_time instanceof Date ? moment(start_time).format("HH:mm:ss") : start_time ? start_time : null,
      end_time: end_time && end_time instanceof Date ? moment(end_time).format("HH:mm:ss") : end_time ? end_time : null,
      time_to_sleep: time_to_sleep ? Number(time_to_sleep) : null,
      date_range: date_range ? Number(date_range) : null,
      delay: delay ? Number(delay) : null,
      dsn: dsn ? dsn : null,
      dms_id: dms_id ? dms_id : DMS.UNKNOWN,
      full_run_hour_interval: full_run_hour_interval ? Number(full_run_hour_interval) : null,
      carit_config: { ...carit_config, internal_customer_group_ids },
    };

    dealerConfiguration = { ...dealerConfiguration, ...formatedParams };

    this.setState({ showValidationError: false }, async () => {
      try {
        if (isEditingDealer) await Service.updateDealer(dealerConfiguration);
        else await Service.addDealer(dealerConfiguration);

        this.setState({ isDealerDetailVisible: false });
        this.getDealers();
      } catch (err) {
        console.log(err);

        const errorMsg =
          typeof err.response?.data === "string" ? err.response.data : err.response.data.errors ? err.response.data.errors[0] : "Error adding/updating location";

        this.setState({ isDealerDetailVisible: false }, () => {
          this.msg.show(errorMsg, {
            time: 4000,
            type: "error",
          });
        });
      }
    });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  validateUpdateLocation = ({ dms_databases, dms_location_ids }) => {
    const locationIdsLength = dms_location_ids ? dms_location_ids.split(",").length : 0;
    const databasesLength = dms_databases ? dms_databases.split(",").length : 0;

    if (databasesLength > 1 && databasesLength !== locationIdsLength) return false;

    if (locationIdsLength > 1 && databasesLength > 1 && locationIdsLength !== databasesLength) return false;

    return true;
  };

  handleAddOrUpdateLocation = async locationConfiguration => {
    const dealer = this.props.globalState.dealers.find(dealer => dealer.locations?.find(l => l.id === Number(locationConfiguration.dealer_location_id)));
    const location = dealer?.locations?.find(l => l.id === Number(locationConfiguration.dealer_location_id)) || {};

    if (!location) return;

    if (!this.validateUpdateLocation(locationConfiguration)) {
      this.setState({ locationValidationError: true });
      return;
    }

    const { not_visible_to_customer, customer_not_approved, maintenance_intervention, exclude_from_import } = locationConfiguration;

    const formatedParams = {
      not_visible_to_customer: not_visible_to_customer ? not_visible_to_customer : null,
      customer_not_approved: customer_not_approved ? customer_not_approved : null,
      maintenance_intervention: maintenance_intervention ? maintenance_intervention : null,
      exclude_from_import: exclude_from_import ? exclude_from_import : null,
    };

    try {
      const updatedLocation = {
        ...location,
        ...locationConfiguration,
        ...formatedParams,
        ...(!this.state.isEditingLocation && { dms_id: this.state.selectedDealer.dms_id }),
      };

      await Service.addOrUpdateLocation({
        ...updatedLocation,
        dms_location_ids: locationConfiguration.dms_location_ids.replace(/\s/g, ""),
        dms_databases: locationConfiguration.dms_databases
          .split(",")
          .map(d => d.trim())
          .join(","),
      });
      this.props.updateLocation(updatedLocation);

      this.getDealers();
    } catch (error) {
      console.log(error);

      const errorMsg =
        typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Error adding/updating location";

      this.msg.show(errorMsg, {
        time: 4000,
        type: "error",
      });
    }

    this.setState({ isLocationDetailVisible: false, selectedLocation: null, selectedDealer: null });
  };

  handleDeleteDealer = async requestDate => {
    try {
      await Service.deleteDealer(requestDate);
      this.getDealers();
    } catch (error) {
      console.log("Error deleting dealer", error);

      const errorMsg =
        typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Error deleting dealer";
      this.msg.show(errorMsg, {
        time: 4000,
        type: "error",
      });
    }

    this.setState({ isDealerDetailVisible: false, isEditingDealer: false });
  };

  handleDeleteLocation = async () => {
    const { selectedLocation } = this.state;

    await Service.addOrUpdateLocation({
      dealer_location_id: selectedLocation.id,
      dsn: "",
      dms_databases: "",
      dms_location_ids: "",
      dms_write_back: false,
      dms_id: DMS.UNKNOWN,
    });
    this.props.updateLocation({ ...selectedLocation, dsn: "", dms_databases: "", dms_location_ids: "", dms_write_back: false, dms_id: DMS.UNKNOWN });
    this.getDealers();
    this.setState({ isLocationDetailVisible: false, selectedLocation: null });
  };

  renderDealersTable = () => {
    const { dealersForDisplay, loadingDealers } = this.state;
    if (loadingDealers) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
          <section>{this.props.t("loading_dealers").message || "Loading dealers"}</section>
        </div>
      );
    }

    return (
      <DMSDealersTable
        dealers={dealersForDisplay}
        handleToggleAddOrEditDealer={this.toggleAddOrEditDealer}
        handleToggleAddLocation={this.toggleAddLocation}
        handleToggleEditLocation={this.toggleEditLocation}
        allDealers={this.props.globalState.dealers}
      />
    );
  };

  prepareDealerOptions = () => {
    const dealersOptions = this.props.globalState.dealers.map(dealer => ({
      key: dealer.id,
      text: dealer.name,
      value: dealer.id,
    }));

    this.setState({ dealersOptions });
  };

  toggleAddOrEditDealer = selectedDealer => {
    this.setState({ isDealerDetailVisible: !this.state.isDealerDetailVisible, isEditingDealer: !!selectedDealer, selectedDealer, showValidationError: false });
  };

  toggleAddLocation = (evt, selectedDealer) => {
    evt.stopPropagation();
    this.setState({ isLocationDetailVisible: !this.state.isLocationDetailVisible, isEditingLocation: false, locationValidationError: false, selectedDealer });
  };

  toggleEditLocation = selectedLocation => {
    this.setState({ isLocationDetailVisible: !this.state.isLocationDetailVisible, isEditingLocation: true, locationValidationError: false, selectedLocation });
  };

  prepareLocationOptions = () => {
    const { isEditingLocation, selectedLocation, selectedDealer } = this.state;
    const { dealers } = this.props.globalState;

    if (isEditingLocation) return [{ key: selectedLocation.id, text: selectedLocation.name, value: selectedLocation.id }];

    const dealer = dealers.find(dealer => dealer.id === selectedDealer?.dealer_id);
    const locationOptions = [];

    dealer?.locations &&
      dealer.locations.forEach(l => {
        if (l.dms_id === DMS.UNKNOWN)
          locationOptions.push({
            key: l.id,
            text: l.name,
            value: l.id,
          });
      });

    return locationOptions;
  };

  handleRefresh = () => {
    this.getDealers();
  };

  render() {
    const {
      dealers,
      dealersOptions,
      isDealerDetailVisible,
      isEditingDealer,
      selectedDealer,
      isLocationDetailVisible,
      isEditingLocation,
      selectedLocation,
      showValidationError,
      locationValidationError,
    } = this.state;
    const { t } = this.props;

    return (
      <div className="DMSv3Settings">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_dealers").message || "Start searching dealers...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 300)}
            fluid
          />
        </SearchPortal>

        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Column width={14}>
              <h1>{t("dms_settings").message || "DMS Settings"}</h1>
            </Grid.Column>

            <Grid.Column width={2} className="add-dealer-button-wrapper">
              <Can I="add" the="dmsv3-setting">
                <Button onClick={() => this.toggleAddOrEditDealer(null)} color="green">
                  {t("add_dealer").message || "Add Dealer"}
                </Button>
              </Can>
            </Grid.Column>
          </Grid>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderDealersTable()}</Grid.Column>
        </Grid>

        {isDealerDetailVisible && (
          <DMSDealerDetail
            handleAddOrUpdateDealer={this.handleAddOrUpdateDealer}
            isEditingDealer={isEditingDealer}
            dealersOptions={dealersOptions}
            handleToggleAddOrEditDealer={this.toggleAddOrEditDealer}
            handleDeleteDealer={this.handleDeleteDealer}
            selectedDealer={selectedDealer}
            allDealers={this.props.globalState.dealers}
            dealers={dealers}
            showValidationError={showValidationError}
          />
        )}

        {isLocationDetailVisible && (
          <DMSLocationDetail
            isEditingLocation={isEditingLocation}
            selectedLocation={selectedLocation}
            locationOptions={this.prepareLocationOptions()}
            handleToggleAddLocation={this.toggleAddLocation}
            handleToggleEditLocation={this.toggleEditLocation}
            handleAddOrUpdateLocation={this.handleAddOrUpdateLocation}
            handleDeleteLocation={this.handleDeleteLocation}
            locationValidationError={locationValidationError}
          />
        )}

        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLocation: location => dispatch(updateLocation(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DMSv3Settings));
