import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import { Auth, App, PageNotFound } from "./modules";
import { Raket } from "./components";
import { getToken, getUserFromToken, logoutSuccess, AUTH_ACTION_TYPES, socketUpdateUser } from "./modules/Auth/store";
import {
  setAlert,
  socketUpdateSelectedLocation,
  addKeyLockerAlert,
  removeKeyLockerAlert,
  websocketShareboxUpdate,
  websocketWarrantyNotification,
} from "./modules/App/store";
import {
  webSocketConnectionUpdate,
  webSocketAppointmentsUpdate,
  webSocketAppointmentsRefresh,
  updateAppointmentDetailOnlineUsers,
  webSocketAppointmentAssignmentUpdated,
  websocketMechanicAvailabilityUpdated,
} from "./modules/Appointments/store";
import { websocketCarUpdate } from "./modules/Cars/store";
import { websocketQueryUpdate } from "./modules/DMSv3/store";
import { websocketWarrantyUpdate } from "./modules/WarrantyDashboard/store";
import APP_CONFIG from "./util/app-config";
import { getSessionCookie } from "./util/common";

import AuthService from "./modules/Auth/service";

class Root extends Component {
  componentWillMount() {
    const authCookie = getSessionCookie();

    if (!authCookie) {
      if (window.location.hash !== "#!/login" && !window.location.hash.includes("reset-password")) {
        let app_id, wo, car_id, dealer_id, location_id;

        if ((app_id = window.location.hash.match(/appointments\/([0-9]+)$/))) sessionStorage.setItem("_urlHandlerAppID", app_id[1]);
        else if ((wo = window.location.hash.match(/appointments\/wo\/(.+)$/))) sessionStorage.setItem("_urlHandlerWONumber", wo[1]);
        else if ((car_id = window.location.hash.match(/appointments\/car\/([0-9]+)$/))) sessionStorage.setItem("_urlHandlerCarID", car_id[1]);
        else if ((dealer_id = window.location.hash.match(/dealer_reports\/([0-9]+)$/))) sessionStorage.setItem("_urlHandlerDealerID", dealer_id[1]);
        else if ((location_id = window.location.hash.match(/location_reports\/([0-9]+)$/))) sessionStorage.setItem("_urlHandlerLocationID", location_id[1]);

        this.props.history.push("/login");
        return;
      }
    }

    let to = this.props.history.location.pathname ? this.props.history.location.pathname + this.props.history.location.search : "/";

    if (to !== this.props.history.location.pathname) {
      this.props.history.push(to);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.authState.actionType !== nextProps.authState.actionType) {
      if (nextProps.authState.actionType === AUTH_ACTION_TYPES.UPDATE_USER_SUCCESS) {
        return;
      }

      if (nextProps.authState.actionType === AUTH_ACTION_TYPES.GET_TOKEN_SUCCESS || nextProps.authState.actionType === AUTH_ACTION_TYPES.GET_TOKEN_VIA_GOOGLE_SUCCESS) {
        this.props.getUserFromToken();

        if (nextProps.authState.wasLoggedIn) {
          localStorage.setItem("was_logged_in", nextProps.authState.wasLoggedIn);
        }
      }

      if (nextProps.authState.actionType === AUTH_ACTION_TYPES.LOGIN_SUCCESS) {
        this.completeLogin();
      }

      if (nextProps.authState.actionType === AUTH_ACTION_TYPES.LOGOUT_SUCCESS) {
        this.props.history.push("/login");
      }
    }
  }

  completeLogin = async () => {
    const {
      data: { latest_version },
    } = await AuthService.loadLatestVersion();

    if (latest_version > APP_CONFIG.version) {
      await this.props.persistor.flush();

      document.location.reload();
    }
  };

  handleKeyLockerCallForHelp = payload => {
    if (payload?._topic === "KeyLockerCallForHelpHandledMessage") {
      this.props.removeKeyLockerAlert(payload.body?.keylocker_call_for_help_id);
    } else if (payload?._topic === "KeyLockerCallForHelpMessage") {
      this.props.addKeyLockerAlert(payload.body?.keylocker_call_for_help_id);
    }
  };

  handleSocketUserUpdate = payload => {
    const user = payload?.body?.update;
    if (user) this.props.socketUpdateUser(user);
  };

  handleSocketManufacturerRequestsCountUpdate = payload => {
    if (payload?._topic) this.props.socketUpdateSelectedLocation(payload);
  };

  handleAppDetailUsersUpdate = users => {
    this.props.updateAppointmentDetailOnlineUsers(users);
  };

  render() {
    const isLoggedIn = !!getSessionCookie();

    return (
      <React.Fragment>
        <Switch>
          {isLoggedIn && <Redirect from="/login" to="/" />}
          {isLoggedIn && <Route render={props => <App {...props} />} />}

          <Route exact path={"/login"} render={props => <Auth {...props} />} />
          <Route exact path={"/reset-password"} render={props => <Auth {...props} />} />
          <Route exact path={"/reset-password-request"} render={props => <Auth {...props} />} />
          <Redirect from="*" to="/login" />

          <Route component={PageNotFound} />
        </Switch>
        <Raket
          user={this.props.authState?.user}
          {...this.props.webSocketState}
          onWebSocketConnectionUpdated={this.props.webSocketConnectionUpdate}
          onAppointmentUpdated={this.props.webSocketAppointmentsUpdate}
          onAppointmentsRefresh={this.props.webSocketAppointmentsRefresh}
          onAppDetailUsersUpdate={this.handleAppDetailUsersUpdate}
          onUserUpdated={this.handleSocketUserUpdate}
          onUpdateManufacturerRequestsCount={this.handleSocketManufacturerRequestsCountUpdate}
          onKeyLockerCallForHelp={this.handleKeyLockerCallForHelp}
          onCarUpdated={this.props.websocketCarUpdate}
          onQueryResolved={this.props.websocketQueryUpdate}
          onWarrantyUpdated={this.props.websocketWarrantyUpdate}
          onWebsocketShareboxUpdate={this.props.websocketShareboxUpdate}
          onWebsocketWarrantyNotification={this.props.websocketWarrantyNotification}
          onWebSocketAppointmentAssignment={this.props.webSocketAppointmentAssignmentUpdated}
          onWebSocketMechanicAvailability={this.props.websocketMechanicAvailabilityUpdated}
          showIndicator={true}
          authToken={this.props.authState.token}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, webSocketState: state.webSocket };
};

const mapDispatchToProps = dispatch => {
  return {
    getToken: credentials => dispatch(getToken(credentials)),
    getUserFromToken: () => dispatch(getUserFromToken()),
    logoutSuccess: () => dispatch(logoutSuccess()),
    socketUpdateUser: user => dispatch(socketUpdateUser(user)),
    socketUpdateSelectedLocation: payload => dispatch(socketUpdateSelectedLocation(payload)),
    webSocketConnectionUpdate: event => dispatch(webSocketConnectionUpdate(event)),
    webSocketAppointmentsUpdate: (isUpdate, event) => dispatch(webSocketAppointmentsUpdate(isUpdate, event)),
    webSocketAppointmentsRefresh: (isUpdate, event) => dispatch(webSocketAppointmentsRefresh(isUpdate, event)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    addKeyLockerAlert: id => dispatch(addKeyLockerAlert(id)),
    removeKeyLockerAlert: id => dispatch(removeKeyLockerAlert(id)),
    updateAppointmentDetailOnlineUsers: users => dispatch(updateAppointmentDetailOnlineUsers(users)),
    websocketCarUpdate: payload => dispatch(websocketCarUpdate(payload)),
    websocketQueryUpdate: payload => dispatch(websocketQueryUpdate(payload)),
    websocketWarrantyUpdate: payload => dispatch(websocketWarrantyUpdate(payload)),
    websocketShareboxUpdate: payload => dispatch(websocketShareboxUpdate(payload)),
    websocketWarrantyNotification: payload => dispatch(websocketWarrantyNotification(payload)),
    webSocketAppointmentAssignmentUpdated: payload => dispatch(webSocketAppointmentAssignmentUpdated(payload)),
    websocketMechanicAvailabilityUpdated: payload => dispatch(websocketMechanicAvailabilityUpdated(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
