import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getTemplates() {
    return axios.get(ENV.apiBaseB + "/checklists/template_list_short", this.requestConfig());
  }

  static createTemplate(requestData) {
    return axios.post(ENV.apiBaseB + "/checklists/create_template", requestData, this.requestConfig());
  }

  static updateTemplate(requestData) {
    return axios.put(ENV.apiBaseB + "/checklists/update_template", requestData, this.requestConfig());
  }

  static getReportTemplates(requestData) {
    return axios.post(ENV.apiBaseB + "/report_templates/check_list", requestData, this.requestConfig());
  }

  static getTemplateDetail(requestData) {
    return axios.post(ENV.apiBaseB + "/checklists/template_details", requestData, this.requestConfig());
  }

  static deleteTemplate(requestData) {
    return axios.post(ENV.apiBaseB + "/checklists/delete_template", requestData, this.requestConfig());
  }
}

export default Service;
