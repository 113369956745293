import React, { useState } from "react";
import UserInput from "../UserInput";

const PasswordInput = ({ value, onChange, showAsteriskPlaceholder, ...restProps }) => {
  const [passwordValue, setPasswordValue] = useState(showAsteriskPlaceholder ? "************" : value);

  const handleFocus = () => {
    if (showAsteriskPlaceholder) setPasswordValue("");
  };

  const handleChange = (e, data) => {
    setPasswordValue(e.target.value);
    onChange(e, data);
  };

  return <UserInput {...restProps} type="password" value={passwordValue} onChange={handleChange} onFocus={handleFocus} />;
};

export default PasswordInput;
